import React, {useContext} from 'react';

import {
  IconX,
  IIpDataIntegrations,
  ISegmentFilter,
  lightGray,
  Row,
  ICrmIntegrations,
} from '@markettailor/common-markettailor';

import {IntegrationContext, crmIntegrations, ipDataIntegrations} from '../../../../contexts/IntegrationContext';
import {getActiveIntegrations} from '../../util';
import ConditionSelection from './ConditionSelection';
import SelectorSelection from './SelectorSelection';
import ValueSelection from './ValueSelection';

interface Props {
  labelIndex: number;
  segmentFilter: ISegmentFilter;
  renderRemoveIcon: boolean;
  removeFilter: (labelIndex: number) => void;
  handleFilterChange: (segmentFilter: ISegmentFilter) => void;
}

export default function Segment(props: Props) {
  const {integrations} = useContext(IntegrationContext)!;
  const {labelIndex, segmentFilter, renderRemoveIcon, removeFilter, handleFilterChange} = props;
  const {selector, condition} = segmentFilter;

  const selectorIntegrations: Array<keyof IIpDataIntegrations | keyof ICrmIntegrations> = [
    ...ipDataIntegrations,
    ...crmIntegrations,
  ];
  const activeIntegrations = getActiveIntegrations(integrations, selectorIntegrations);

  function handleSelectChange(changeObj: Partial<ISegmentFilter>): void {
    changeObj = changeObj || {valueRange: []};
    if (changeObj.valueRange) {
      changeObj.valueRange = changeObj.valueRange.map((item) => item['value']);
    }
    handleFilterChange({...segmentFilter, ...changeObj});
  }

  return (
    <Row>
      <div data-intercom-id="inbound-segment-rules-selector">
        <SelectorSelection
          selector={selector}
          handleSelectChange={handleSelectChange}
          activeIntegrations={activeIntegrations}
        />
      </div>
      <div data-intercom-id="inbound-segment-rules-condition">
        <ConditionSelection selector={selector} condition={condition} handleSelectChange={handleSelectChange} />
      </div>
      <div data-intercom-id="inbound-segment-rules-valueRange">
        <ValueSelection
          selector={selector}
          segmentFilter={segmentFilter}
          handleSelectChange={handleSelectChange}
          handleFilterChange={handleFilterChange}
          activeIntegrations={activeIntegrations}
        />
      </div>
      {renderRemoveIcon && (
        <IconX
          width="30px"
          color={lightGray}
          $hoverColor={lightGray}
          cursor="pointer"
          margin={'0 0 0 5px'}
          onClick={() => removeFilter(labelIndex)}
        />
      )}
    </Row>
  );
}
