import React, {useContext, useEffect} from 'react';

import {Col, Header, StyledBox, Text, TextLink, AuthContext, SignInForm} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {useSetNewPage} from '../../../functions/util';
import '../../../styles/common/muiOverrides.css';
import GoogleSignInButton from './GoogleSignInButton';

export default function SignIn() {
  const authContext = useContext(AuthContext)!;
  const {clearMessageStates} = authContext;
  const history = useHistory();

  useSetNewPage('Sign in');

  useEffect(() => {
    localStorage.setItem('googleAutoSignUp', 'false');
  }, []);

  return (
    <>
      <Col alignItems={'center'}>
        <Header color={'white'} margin={'0 0 20px 0'} fontSize={'20px'} fontWeight={500}>
          Sign in to Markettailor
        </Header>
      </Col>
      <StyledBox padding={'20px'} minWidth={'260px'} maxWidth={'260px'} minHeight={'280px'}>
        <SignInForm />
        <Col alignItems={'center'}>
          <Text margin={'15px 0 15px 0 '}>or</Text>
          <GoogleSignInButton />
        </Col>
        <Col>
          <Text margin={'0 0 10px 0 '}>
            Forgot your password?{' '}
            <TextLink
              onClick={() => {
                clearMessageStates();
                history.push('forgot-password');
              }}
            >
              Reset Password
            </TextLink>
          </Text>
          <Text>
            No Account?{' '}
            <TextLink
              onClick={() => {
                clearMessageStates();
                history.push('/sign-up');
              }}
            >
              Create account
            </TextLink>
          </Text>
        </Col>
      </StyledBox>
    </>
  );
}
