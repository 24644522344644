import React, {useContext} from 'react';

import {StyledBtn, Col, Text, OnHover} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {ConversionEvents, IConversions} from '../../contexts/ConversionAnalyticsContext';
import {IntegrationContext} from '../../contexts/IntegrationContext';

export const getConversionEvents = (conversions: IConversions): ConversionEvents => {
  const conversionEvents = Object.values(conversions)
    .flatMap((conversion) => [conversion.personalized?.events || {}, conversion.control?.events || {}])
    .reduce((previous, current) => {
      return {...previous, ...current};
    }, {});
  return conversionEvents;
};

export const NewConversionGoalBtn = ({setEventCardsIsOpen}: {setEventCardsIsOpen: Function}) => {
  return <StyledBtn onClick={() => setEventCardsIsOpen(true)}>Add new conversion</StyledBtn>;
};

export function SegmentEventText() {
  const history = useHistory();
  const {integrations} = useContext(IntegrationContext)!;
  if (!integrations.segment?.isActive) return null;
  return (
    <Col alignItems="center">
      <img width="100px" src="./segment-logo.svg" alt="Segment logo" />
      <Text margin="10px 0 0 0">
        All Segment conversion events can be found directly from the{' '}
        <OnHover cursor="pointer">
          <b onClick={() => history.push('conversions/manage')}>Conversion Management</b>
        </OnHover>{' '}
        tab
      </Text>
    </Col>
  );
}
