import {useContext} from 'react';

import {omit} from 'lodash';

import {IRevealCompany, RevealContext} from '../../contexts/RevealContext';
import {useAllMapping} from '../../termMaps/technicalNamesToLabels';
import {IListItem} from './RevealTable';

export const sortCompanies = (companies: IListItem[]) => {
  return companies.sort((prevCompany, nextCompany) => {
    return new Date(prevCompany.lastSeen) < new Date(nextCompany.lastSeen) ? 1 : -1;
  });
};

export const useConvertLabels = (companies: IRevealCompany[]): IListItem[] => {
  const mapping = useAllMapping();
  return companies.map((company) => {
    const industry = company.naicsDesc;
    omit(company, ['naicsDesc']);
    return {
      ...company,
      revenue: mapping.revenue[company.revenue || ''] || company.revenue,
      employees: mapping.employees[company.employees || ''] || company.employees,
      countryLong: mapping.country[company.countryShort || ''],
      industry: industry,
    };
  });
};

export const usePrepareListData = () => {
  const {companies} = useContext(RevealContext)!;
  const convertedLabelCompanies = useConvertLabels(companies);
  const sortedCompanies = sortCompanies(convertedLabelCompanies);
  return sortedCompanies;
};

export const countFrequencies = (key: string, data: IListItem[]) => {
  return data.reduce((acc, item) => {
    const value = item[key];
    if (value in acc) {
      acc[value] = acc[value] + 1;
    } else {
      acc[value] = 1;
    }
    return acc;
  }, {});
};
