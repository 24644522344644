import React, {useState, ChangeEvent} from 'react';

import {StyledBtn} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

export interface IFormField {
  name: string;
  label: string;
}

interface IStyledFormProps {
  fields: IFormField[];
  submitBtnText: string;
  handleSubmit: (formData: {[fieldName: string]: string}) => void;
}
export function StyledForm(props: IStyledFormProps) {
  const [formData, setFormData] = useState({});
  return (
    <>
      {props.fields.map((field) => (
        <TextField
          required
          multiline
          maxRows="5"
          key={field.name}
          id={field.name}
          label={field.label}
          name={field.name}
          type={'text'}
          variant={'outlined'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFormData((prev) => ({...prev, [field.name]: e.target.value.trim()}));
          }}
        />
      ))}
      <StyledBtn onClick={() => props.handleSubmit(formData)}>{props.submitBtnText}</StyledBtn>
    </>
  );
}
