import {ISegmentFilter, IFilterCondition} from '@markettailor/common-markettailor';

import {cleanDomainFromUrl} from '../../../../functions/util';
import {checkFiltersForCompleteness} from '../../util';
import {BaseInsight, AudienceInsight} from './AudienceInsights';
import {SegmentOverviewTotals} from './SegmentOverview';

export interface IQuery {
  selector: string;
  queryRequest: ISegmentFilter[];
  filterCondition: IFilterCondition;
}

export function createValidQuery(segmentFilters: ISegmentFilter[], filterCondition: IFilterCondition): IQuery {
  const cleanCityValues = (segmentFilter: ISegmentFilter) => {
    if (segmentFilter.selector !== 'city') return segmentFilter;
    segmentFilter.valueRange = segmentFilter.valueRange.map((city: string) => {
      return city.split(' (')[0];
    });
    return segmentFilter;
  };

  const completeFilters: ISegmentFilter[] = [];
  let selector = '';
  segmentFilters.forEach((segmentFilter) => {
    if (checkFiltersForCompleteness([segmentFilter])) {
      segmentFilter = cleanCityValues(segmentFilter);
      completeFilters.push(segmentFilter);
    } else selector = selector || segmentFilter?.selector;
  });
  return {selector: selector, queryRequest: completeFilters, filterCondition: filterCondition};
}

export const cleanURLselectors = (
  insightsAll: AudienceInsight[],
  selector: string,
  clientDomain: string
): AudienceInsight[] => {
  const urlSelectors = ['referer', 'visitedPages'];
  if (!urlSelectors.includes(selector)) return insightsAll;
  return insightsAll.map((insight) => {
    insight.value = !clientDomain ? '' : cleanDomainFromUrl(insight.value, clientDomain);
    return insight;
  });
};

export const sortInsights = <T extends BaseInsight>(parsedInsights: T[]) =>
  parsedInsights.sort((a, b) => b.count - a.count);

export function createSegmentTotals(insights: BaseInsight[] | undefined): SegmentOverviewTotals | undefined {
  if (!insights) return;
  return {
    visitors: insights.reduce((a, insight) => {
      return a + insight.count;
    }, 0),
    trafficPercentage: insights.reduce((a, insight) => {
      return a + insight.fraction;
    }, 0),
  };
}
