import React, {useContext} from 'react';

import {
  Col,
  ICrmDataSource,
  Row,
  Card,
  PersonIcon,
  BuildingIcon,
  ICrmObjectType,
  BriefcaseIcon,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';
import {useHistory} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import {SegmentContext} from '../../../../contexts/SegmentContext';
import {crmObjectTypeMapping} from '../../../../termMaps/crmTerms';
import {supportedCrmObjectTypes} from './../../../../contexts/IntegrationContext';

interface CrmCardProps {
  crmName: ICrmDataSource;
}

export default function CrmCreation({crmName}: CrmCardProps) {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {createNewSegment} = segmentContext!;
  const newSegmentId = uuid();

  const createOutboundSegment = (crmObjectType: ICrmObjectType) => {
    createNewSegment({
      segmentId: newSegmentId,
      segmentType: 'outbound',
      crmObjectType: crmObjectType,
      crmDataSource: crmName,
    });
    history.push('/outbound/' + newSegmentId);
  };

  const descStart = `Create a campaign for `;
  const descAfter = ` from ${capitalize(crmName)} CRM.`;
  const descMapping = {
    contact: descStart + `individual ${crmObjectTypeMapping[crmName].contact.plural}` + descAfter,
    company: descStart + `your target ${crmObjectTypeMapping[crmName].company.plural}` + descAfter,
    deal: descStart + `your pipeline ${crmObjectTypeMapping[crmName].deal.plural}` + descAfter,
  };

  const cta = 'Create campaign';
  const iconStyles = {margin: '10px 0 0 0', width: '30px'};
  const iconMapping = {
    contact: <PersonIcon {...iconStyles} />,
    company: <BuildingIcon {...iconStyles} />,
    deal: <BriefcaseIcon {...iconStyles} />,
  };

  return (
    <Row margin="0 0 15px 0">
      {supportedCrmObjectTypes[crmName].map((objectType: ICrmObjectType, index) => {
        return (
          <Col
            key={crmName + objectType}
            margin={supportedCrmObjectTypes[crmName].length - 1 === index ? '0' : '0 20px 0 0'}
          >
            <Card
              header={capitalize(crmObjectTypeMapping[crmName][objectType].plural)}
              description={descMapping[objectType]}
              midSection={<MidSection icon={iconMapping[objectType]} crmName={crmName} />}
              ctaText={cta}
              ctaCallback={() => createOutboundSegment(objectType)}
            />
          </Col>
        );
      })}
    </Row>
  );
}

const MidSection = ({icon, crmName}: {icon: JSX.Element; crmName: ICrmDataSource}) => {
  return (
    <Col alignItems="center">
      <CrmLogo crmName={crmName} />
      {icon}
    </Col>
  );
};

const CrmLogo = ({crmName}: {crmName: ICrmDataSource}) => {
  const logoSizeMapping = {
    activeCampaign: '140px',
    hubspot: '70px',
  };

  return (
    <img
      src={`./${crmName}-logo.svg`}
      width={logoSizeMapping[crmName] ? logoSizeMapping[crmName] : '60px'}
      alt={`${capitalize(crmName)} logo`}
    />
  );
};
