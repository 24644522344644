import React from 'react';

import {Headline, Col, OnHover, StyledBtn, Row, StyledDivider} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

import {IConversions, ActiveIntegrationType} from '../../contexts/ConversionAnalyticsContext';
import {IEvents} from '../../contexts/ConversionManagementContext';
import {NewConversionGoalBtn, SegmentEventText} from './util';

export const checkRenderEmptyScreen = (
  events: IEvents,
  activeIntegration: ActiveIntegrationType,
  conversions: IConversions,
  setEventCardsIsOpen: Function
): JSX.Element | null => {
  const visibleEvents = Object.values(events).filter((event) => event.isActive);

  if (isEmpty(events) && !activeIntegration)
    return <RenderNoEventsNoIntegration setEventCardsIsOpen={setEventCardsIsOpen} />;
  if (isEmpty(events) && activeIntegration) return <RenderWaitConversions setEventCardsIsOpen={setEventCardsIsOpen} />;
  if (isEmpty(visibleEvents)) return <NoVisibleEvents setEventCardsIsOpen={setEventCardsIsOpen} />;
  if (isEmpty(conversions)) return <EmptyConversions setEventCardsIsOpen={setEventCardsIsOpen} />;
  return null;
};

export const NoVisibleEvents = ({setEventCardsIsOpen}: {setEventCardsIsOpen: Function}) => {
  const history = useHistory();
  const imageSrc = './noVisibleEventsConversions.svg';
  return (
    <CommonEmptyContainer
      imageSrc={imageSrc}
      setEventCardsIsOpen={setEventCardsIsOpen}
      renderCTABtn={false}
      imageWidth="450px"
    >
      <>
        <Headline margin="0 0 30px 0">
          Select which conversion events you want to see here from the{' '}
          <OnHover cursor="pointer">
            <b onClick={() => history.push('conversions/manage')}>Conversion Management</b>
          </OnHover>{' '}
          tab.
        </Headline>
        <StyledDivider border margin="10px 0 20px 0" />
        <Headline margin="0 0 10px 0">You can also create new events.</Headline>
        <NewConversionGoalBtn setEventCardsIsOpen={setEventCardsIsOpen} />
        <StyledDivider border margin="10px 0 20px 0" />
        <SegmentEventText />
      </>
    </CommonEmptyContainer>
  );
};

export const RenderWaitConversions = ({setEventCardsIsOpen}: {setEventCardsIsOpen: Function}) => {
  const imageSrc = './emptyConversionsInConversions.svg';
  return (
    <CommonEmptyContainer imageSrc={imageSrc} setEventCardsIsOpen={setEventCardsIsOpen}>
      <Headline textAlign={'center'} margin={'0 0 20px 0'}>
        Patience, it might take a while to detect first conversions. You can also create some now.
      </Headline>
    </CommonEmptyContainer>
  );
};

export const RenderNoEventsNoIntegration = ({setEventCardsIsOpen}: {setEventCardsIsOpen: Function}) => {
  const history = useHistory();
  const imageSrc = './emptyEventsNoIntegrationConversions.svg';
  return (
    <CommonEmptyContainer imageSrc={imageSrc} setEventCardsIsOpen={setEventCardsIsOpen} renderCTABtn={false}>
      <>
        <Headline>
          To measure the performance of personalization, add conversion events or integrate a conversion tracking
          service.
        </Headline>
        <Row margin="10px 0 0 0">
          <NewConversionGoalBtn setEventCardsIsOpen={setEventCardsIsOpen} />
          <StyledBtn onClick={() => history.push('/integrations')}>Go to Integrations</StyledBtn>
        </Row>
      </>
    </CommonEmptyContainer>
  );
};

export const EmptyConversions = ({setEventCardsIsOpen}: {setEventCardsIsOpen: Function}) => {
  const imageSrc = './emptyConversionsInConversions.svg';
  return (
    <CommonEmptyContainer imageSrc={imageSrc} setEventCardsIsOpen={setEventCardsIsOpen}>
      <>
        <Headline>Patience, you don't have enough visitor data to show conversions yet.</Headline>
        <Headline>Check back in a while.</Headline>
      </>
    </CommonEmptyContainer>
  );
};

interface EmptyStateProps {
  imageSrc: string;
  setEventCardsIsOpen: Function;
  children: JSX.Element;
  renderCTABtn?: boolean;
  imageWidth?: string;
}
const CommonEmptyContainer = ({
  imageSrc,
  setEventCardsIsOpen,
  children,
  renderCTABtn = true,
  imageWidth = '550px',
}: EmptyStateProps) => {
  return (
    <Col alignItems={'center'} maxWidth="1000px" margin="0 auto">
      <Col margin="20px 0 50px 0">
        <img src={imageSrc} width={imageWidth} alt={imageSrc} />
      </Col>
      {children}
      {renderCTABtn && (
        <Col margin={'20px 0 0 0'}>
          <NewConversionGoalBtn setEventCardsIsOpen={setEventCardsIsOpen} />
        </Col>
      )}
    </Col>
  );
};

export function ConversionSkeleton() {
  return (
    <>
      <Col margin={'0 0 50px 0'}>
        <Skeleton width={600} height={400} />
      </Col>
      <Skeleton width={300} height={200} />
    </>
  );
}
