import React, {useState, useContext, useEffect, useCallback, ChangeEvent} from 'react';

import {IDomElemFullState} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import PersonalizationVariableBtn from '../dynamicValues/PersonalizationVariableBtn';
import AttributeEditor from '../sideControls/AttributeEditor';
import TypographyToolbar from './TypographyToolbar';
import {editorFormatToHtml, addSelectorToInnerHTML, htmlToEditorFormat} from './util';

export interface ITextareaCaret {
  start: number;
  end: number;
}
interface Props {
  isDisabled: boolean;
  selectedElem: IDomElemFullState;
}

const INNERHTML_EDITOR_ID = 'innerHtmlEditor';

export default function TypographyEditor({isDisabled, selectedElem}: Props) {
  const editorContext = useContext(EditorContext)!;
  const {changeElemContent} = editorContext;
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [selectedElemInnerHTML, setSelectedElemInnerHTML] = useState('');
  const [textareaCaret, setTextareaCaret] = useState<ITextareaCaret>({start: 0, end: 0});
  const autoFocus = useCallback(
    (el) => (el ? el.focus() : null),
    //eslint-disable-next-line
    [selectedElem.domElemId]
  );

  useEffect(() => {
    const editorSafeInnerHTML = htmlToEditorFormat(selectedElem.innerHTML || '');
    setSelectedElemInnerHTML(editorSafeInnerHTML);
    //eslint-disable-next-line
  }, [selectedElem.domElemId]); //removed selectedElem.innerHTML because < gets replaced with &lt when refreshed in state. Earlier added innerHTML for some specific reason

  useEffect(() => {
    const elem: any = document.getElementById(INNERHTML_EDITOR_ID);
    const listener = () => setTextareaCaret({start: elem.selectionStart, end: elem.selectionEnd});
    elem.addEventListener('click', listener);
    return () => {
      elem.removeEventListener('click', listener);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const elem: any = document.getElementById(INNERHTML_EDITOR_ID);
    setTextareaCaret({start: elem.selectionStart, end: elem.selectionEnd});
  }, [selectedElem.innerHTML]);

  function handleHtmlChange(innerHTML: string) {
    const sendChangesToDom = (innerHTML: string) => {
      if (timerId) clearTimeout(timerId);
      const domSafeInnerHTML = editorFormatToHtml(innerHTML);
      const changeTimerId: NodeJS.Timeout = setTimeout(
        () =>
          changeElemContent(selectedElem.domElemId, {
            innerHTML: domSafeInnerHTML,
          }),
        50
      );
      setTimerId(changeTimerId);
    };
    setSelectedElemInnerHTML(innerHTML);
    sendChangesToDom(innerHTML);
  }

  return (
    <>
      <TypographyToolbar selectedElem={selectedElem} />
      <AttributeEditor
        id={INNERHTML_EDITOR_ID}
        test-id={INNERHTML_EDITOR_ID}
        refProp={selectedElemInnerHTML ? autoFocus : undefined}
        attr={'innerHTML'}
        isDisabled={isDisabled}
        customHandleChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleHtmlChange(e.target.value)}
        customValue={selectedElemInnerHTML}
      />
      <PersonalizationVariableBtn
        handleSelection={(selector: string) => {
          handleHtmlChange(addSelectorToInnerHTML(selectedElemInnerHTML, selector, textareaCaret));
        }}
        isDisabled={isDisabled}
      />
    </>
  );
}
