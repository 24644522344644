import {useState, useEffect, useContext} from 'react';

import {Row, Col, StyledBox, getSelectCustomStyles, IValueLabel} from '@markettailor/common-markettailor';
import {cloneDeep, capitalize} from 'lodash';
import Select from 'react-select';

import {SegmentContext} from '../../contexts/SegmentContext';
import OutboundSearchSelector from './OutboundSearchSelector';
import {DataRowOriginal} from './table/OutboundTable';

export interface ISearchFilter {
  header: string;
  searchValue: string;
  searchSelection: string;
}
export interface FilterDropdownProps {
  handleFilterChange: (filterData: ISearchFilter) => void;
  resetFilters: boolean;
}

export default function OutboundFilterDropdown(props: FilterDropdownProps): JSX.Element {
  const segmentContext = useContext(SegmentContext)!;
  const {outboundHeaderMap, outboundAllData} = segmentContext.state;

  const {handleFilterChange, resetFilters} = props;
  const [optionSelection, setOptionSelection] = useState<IValueLabel>({value: '_all', label: 'All'});
  const [searchSelection, setSearchSelection] = useState<IValueLabel | null>(null);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    setOptionSelection({value: '_all', label: 'All'});
    setSearchSelection(null);
    setSearch('');
  }, [resetFilters]);

  function getOptions(): IValueLabel[] {
    const campaignDataRow: Partial<DataRowOriginal> = cloneDeep(outboundAllData[0].original);
    delete campaignDataRow.id;
    delete campaignDataRow.personalizedLink;
    return [
      {value: '_all', label: 'All'},
      ...Object.keys(campaignDataRow).map((header) => {
        return {value: header, label: outboundHeaderMap[header] || capitalize(header)};
      }),
    ];
  }

  const CategorySelector = ({optionSelection}) => {
    function handleCategorySelect(selection: IValueLabel | null) {
      selection && setOptionSelection(selection);
      setSearch('');
      setSearchSelection(null);
      handleFilterChange({header: optionSelection.value, searchValue: '', searchSelection: ''});
    }

    return (
      <Select
        isSearchable={false}
        options={getOptions()}
        value={optionSelection}
        onChange={handleCategorySelect}
        styles={getSelectCustomStyles({
          fontSize: 14,
          control: {
            width: 250,
          },
        })}
      />
    );
  };

  return (
    <StyledBox width="fit-content" height="fit-content">
      <Row>
        <Col margin="0 10px 0 0">
          <CategorySelector optionSelection={optionSelection} />
        </Col>
        <OutboundSearchSelector
          {...{
            search,
            setSearch,
            searchSelection,
            setSearchSelection,
            optionSelection,
            handleFilterChange,
            outboundAllData,
          }}
        />
      </Row>
    </StyledBox>
  );
}
