import React from 'react';

import {Col} from '@markettailor/common-markettailor';

interface Props {
  children?: JSX.Element;
  imageSrc?: string;
  imageWidth?: string;
}

export default function EmptyScreen({imageSrc, children, imageWidth = '550px'}: Props) {
  return (
    <Col alignItems={'center'} maxWidth="1000px" margin="100px auto 0">
      {imageSrc && (
        <Col margin="20px 0 50px 0">
          <img src={imageSrc} width={imageWidth} alt={imageSrc} />
        </Col>
      )}
      {children}
    </Col>
  );
}
