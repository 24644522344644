import React, {useContext} from 'react';

import {Headline} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {removeEmptyDomElems} from '../../../contexts/editorContextUtil';

export const ChangeCounter = (): JSX.Element => {
  const editorContext = useContext(EditorContext);
  const {domElems} = editorContext!;

  const changeCount = Object.keys(removeEmptyDomElems(domElems)).length;
  const changeCountStr = changeCount === 1 ? '1 change' : `${changeCount} changes`;
  return (
    <Headline fontSize="12px" fontSizeLarge="12px" fontSizeExtraLarge="12px" margin="0">
      {changeCountStr}
    </Headline>
  );
};
