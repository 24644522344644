import {useContext} from 'react';

import {cloneDeep} from 'lodash';

import {
  cities,
  regions,
  clearbitTechLabels,
  sicCode,
} from '../components/segmentation/segmentationOptions/data/dataInterfaces';
import {getSelectorMappingCRM} from '../components/segmentation/segmentationOptions/groupedOptionsCRM';
import {IntegrationContext, crmIntegrations} from '../contexts/IntegrationContext';
import countryCodesJson from './countryCodes.json';
import employees from './employees.json';
import preferredLanguage from './preferredLanguage.json';
import revenue from './revenue.json';
import selector from './selector.json';
import sicGroup from './sicGroup.json';
import sicSector from './sicSector.json';
import timeZone from './timeZone.json';

const countryCodes: {[key: string]: string} = countryCodesJson;
const selectorFlat = {};
Object.values(selector).forEach((vals) => Object.assign(selectorFlat, vals));

export function useAllMapping() {
  let mappingAll = cloneDeep(mapping);
  crmIntegrations.forEach((integrationName) => {
    mappingAll = useAppendActiveSelectors(integrationName, mappingAll);
  });
  return mappingAll;
}

function useAppendActiveSelectors(integrationName: string, mappingAll: typeof mapping) {
  const integrations = useContext(IntegrationContext)!.integrations;
  if (integrations[integrationName]?.isActive) {
    mappingAll.selector = {
      ...mappingAll.selector,
      ...getSelectorMappingCRM(integrations[integrationName]),
    };
  }
  return mappingAll;
}

function generateMappingWithCountry(values: object) {
  const mapping = {};
  for (const country of Object.keys(values)) {
    for (const value of Object.values(values[country])) {
      mapping[value + ' (' + country + ')'] = value + ' (' + country + ')';
    }
  }
  return mapping;
}

export const mapping = {
  selector: selectorFlat,
  condition: {
    __equals: 'is',
    __not__equals: 'is not',
    __lte: 'is below',
    __gte: 'is above',
    __startswith: 'starts with',
    __contains: 'contains',
    __not__contains: 'does not contain',
  },
  preferredLanguage: preferredLanguage,
  tech: clearbitTechLabels,
  revenue: revenue,
  employees: employees,
  timeZone: timeZone,
  country: countryCodes,
  city: generateMappingWithCountry(cities),
  region: generateMappingWithCountry(regions),
  sicSector: sicSector,
  sicGroup: sicGroup,
  sicCode: sicCode,
};
