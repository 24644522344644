import React, {useContext} from 'react';

import {AuthContext, RequireNewPassword} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {useSetNewPage} from '../../../functions/util';

export default function RequireNewPasswordWithAnalytics() {
  const history = useHistory();
  useSetNewPage('New password');

  const auth = useContext(AuthContext)!.state;
  if (!auth.cognitoUser) {
    history.push('/sign-in');
    return null;
  }

  return <RequireNewPassword />;
}
