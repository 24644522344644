import React from 'react';

import {Col} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

export default function RecommendedSegmentsSkeleton() {
  return (
    <Col>
      <Col margin={'60px 20px 40px  0'}>
        <Skeleton width={700} height={220} />
      </Col>
      <Col margin={'0 20px 40px  0'}>
        <Skeleton width={700} height={220} />
      </Col>
    </Col>
  );
}
