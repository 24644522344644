import React, {ReactNode} from 'react';

import {NotificationProvider} from '@markettailor/common-markettailor';
import '@markettailor/common-markettailor/dist/index.css';
import {Router, Switch, Route, Redirect} from 'react-router-dom';

import './App.css';
import AccountContainer from './components/account/accountInformation/AccountContainer';
import {ChangePasswordPage} from './components/account/ChangePasswordPage';
import DataCollectionContainer from './components/account/DataCollectionContainer';
import Script from './components/account/Script';
import SignOut from './components/account/SignOut';
import Subscription from './components/account/subscription/Subscription';
import UsageContainer from './components/account/usage/UsageContainer';
import Users from './components/account/users/Users';
import WelcomePage from './components/account/WelcomePage';
import SideNavContainer from './components/common/SideNavContainer';
import RevealContainer from './components/companyReveal/RevealContainer';
import ConversionContainer from './components/conversions/ConversionContainer';
import EditorContainer from './components/editor/EditorContainer';
import IntegrationContainer from './components/integrations/IntegrationContainer';
import ManageConversionsContainer from './components/manageConversions/ManageConversionsContainer';
import OutboundContainer from './components/outbound/OutboundContainer';
import PageContainer from './components/pages/PageContainer';
import RecommendedSegments from './components/recommendedSegments/RecommendedSegments';
import SegmentationContainer from './components/segmentation/InboundContainer';
import SegmentsOverview from './components/segmentsOverview/SegmentContainer';
import {AccountProvider} from './contexts/AccountContext';
import {ConversionAnalyticsProvider} from './contexts/ConversionAnalyticsContext';
import {ConversionManagementProvider} from './contexts/ConversionManagementContext';
import {EditorProvider} from './contexts/EditorContext';
import {EditorPageProvider} from './contexts/EditorPageContext';
import {ImageProvider} from './contexts/ImageContext';
import {IntegrationProvider} from './contexts/IntegrationContext';
import {OutboundSegmentItemsProvider} from './contexts/OutboundSegmentItemsContext';
import {RecommendationProvider} from './contexts/RecommendationContext';
import {RevealProvider} from './contexts/RevealContext';
import {SegmentProvider} from './contexts/SegmentContext';
import {StripeProvider} from './contexts/StripeContext';
import history from './history';

function AppLevelProviders({children}: {children: ReactNode}) {
  return (
    <NotificationProvider>
      <AccountProvider>
        <StripeProvider>
          <IntegrationProvider>
            <SegmentProvider>
              <OutboundSegmentItemsProvider>
                <RecommendationProvider>
                  <ConversionAnalyticsProvider>
                    <ConversionManagementProvider>
                      <EditorPageProvider>{children}</EditorPageProvider>
                    </ConversionManagementProvider>
                  </ConversionAnalyticsProvider>
                </RecommendationProvider>
              </OutboundSegmentItemsProvider>
            </SegmentProvider>
          </IntegrationProvider>
        </StripeProvider>
      </AccountProvider>
    </NotificationProvider>
  );
}

export function App() {
  return (
    <Router history={history}>
      <AppLevelProviders>
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/segments" />} />
          <Route
            path="/segments"
            exact
            render={() => (
              <SideNavContainer>
                <SegmentsOverview />
              </SideNavContainer>
            )}
          />
          <Route
            path="/recommended-segments"
            exact
            render={() => (
              <SideNavContainer>
                <RecommendedSegments />
              </SideNavContainer>
            )}
          />
          <Route
            path="/company-reveal"
            exact
            render={() => (
              <SideNavContainer>
                <RevealProvider>
                  <RevealContainer />
                </RevealProvider>
              </SideNavContainer>
            )}
          />
          <Route
            path="/editor*"
            exact
            render={() => (
              <EditorProvider>
                <ImageProvider>
                  <EditorContainer />
                </ImageProvider>
              </EditorProvider>
            )}
          />
          <Route
            path="/segmentation/*"
            exact
            render={() => (
              <SideNavContainer>
                <SegmentationContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/outbound/*"
            exact
            render={() => (
              <SideNavContainer>
                <OutboundContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/pages"
            exact
            render={() => (
              <SideNavContainer>
                <PageContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/conversions/manage"
            exact
            render={() => (
              <SideNavContainer>
                <ManageConversionsContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/conversions"
            exact
            render={() => (
              <SideNavContainer>
                <ConversionContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/integrations"
            exact
            render={() => (
              <SideNavContainer>
                <IntegrationContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/account"
            exact
            render={() => (
              <SideNavContainer>
                <AccountContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/usage"
            exact
            render={() => (
              <SideNavContainer>
                <UsageContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/subscription"
            exact
            render={() => (
              <SideNavContainer>
                <Subscription />
              </SideNavContainer>
            )}
          />
          <Route
            path="/users"
            exact
            render={() => (
              <SideNavContainer>
                <Users />
              </SideNavContainer>
            )}
          />
          <Route
            path="/privacy"
            exact
            render={() => (
              <SideNavContainer>
                <DataCollectionContainer />
              </SideNavContainer>
            )}
          />
          <Route
            path="/change-password"
            exact
            render={() => (
              <SideNavContainer>
                <ChangePasswordPage />
              </SideNavContainer>
            )}
          />
          <Route
            path="/installation"
            exact
            render={() => (
              <SideNavContainer>
                <Script />
              </SideNavContainer>
            )}
          />
          <Route path="/welcome" exact render={() => <WelcomePage />} />
          <Route path="/sign-out" exact render={() => <SignOut />} />
          <Route path="*" render={() => <Redirect to={'/segments'} />} />
        </Switch>
      </AppLevelProviders>
    </Router>
  );
}
