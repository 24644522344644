import React, {useState, useContext} from 'react';

import {Col, Headline, ISegments} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';
import moment from 'moment';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {formatNum} from '../../../functions/util';
import InlineNav from '../../common/InlineNav';
import {sortSegments} from '../../segmentation/util';
import {ConversionRateLift} from './ConversionRateLift';
import {PersonalizedVisitors} from './PersonalizedVisitors';
import SegmentsTable, {TableItem} from './SegmentsTable';
import {StatusCell} from './StatusCell';

type TableSegmentType = 'active' | 'inactive';

export default function SegmentTableContainer() {
  const segmentContext = useContext(SegmentContext);
  const {segments} = segmentContext!.state;
  const [tableSegmentType, setTableSegmentType] = useState<TableSegmentType>('active');

  const getSegmentType = (row: TableItem): TableSegmentType => {
    if (row.isActive) return 'active';
    return 'inactive';
  };

  const items = useCreateListItems(segments);
  const activeCount = items.filter((row) => row.isActive).length;
  const filteredSegments = items.filter((row) => getSegmentType(row) === tableSegmentType);
  return (
    <Col data-intercom-id="segments-table">
      <Headline margin={'0 0 10px 0'}>Personalized segments</Headline>
      <Col margin={'0 0 10px 0'}>
        <InlineNav
          currentComponent={tableSegmentType}
          componentOptions={[
            {value: 'active', label: `Active (${activeCount})`},
            {value: 'inactive', label: `Inactive (${items.length - activeCount})`},
          ]}
          handleSelect={(selection: TableSegmentType) => setTableSegmentType(selection)}
        />
      </Col>
      <SegmentsTable items={filteredSegments} />
    </Col>
  );
}

export function useCreateListItems(segments: ISegments): TableItem[] {
  const [selectedSegment, setSelectedSegment] = useState<string | null>(null);

  const sortedSegments = sortSegments(segments);
  return sortedSegments.map((segment, index) => {
    const isActive = (
      <div data-intercom-id={`segments-table-row-status-${index}`}>
        <StatusCell segment={segment} selectedSegment={selectedSegment} setSelectedSegment={setSelectedSegment} />
      </div>
    );

    return {
      id: segment.segmentId,
      rowIsActive: segment.isActive,
      segmentName: segment.segmentName || 'Unnamed segment',
      isActive: isActive,
      segmentType: capitalize(segment.segmentType),
      lastEditDate: (
        <Headline fontSize="14px" fontSizeMedium="12px">
          {moment(segment.lastEditDate).fromNow()}
        </Headline>
      ),
      pageCount: formatNum(Object.entries(segment.pageUrls || {}).length),
      personalizedVisitors: <PersonalizedVisitors segmentId={segment.segmentId} />,
      conversionRateLift: <ConversionRateLift segmentId={segment.segmentId} />,
    };
  });
}
