import React from 'react';

import {Col, Headline, Row} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {ImageGalleryBottom} from './ImageGalleryBottom';

export const ImageError = () => {
  return (
    <Col height="100px" justifyContent="center">
      <Headline textAlign="center">An error happened when fetching images</Headline>
    </Col>
  );
};

export const RenderSkeleton = () => {
  return (
    <Row margin={'10px'} flexWrap="wrap">
      <Row margin={'10px'}>
        <Skeleton width="200px" height="200px" />
      </Row>
      <Row margin={'10px'}>
        <Skeleton width="200px" height="200px" />
      </Row>
      <Row margin={'10px'}>
        <Skeleton width="200px" height="200px" />
      </Row>
    </Row>
  );
};

interface Props {
  handleClose: () => void;
}

export const EmptyImages = ({handleClose}: Props) => {
  return (
    <>
      <Col height="200px" justifyContent="center">
        <Headline textAlign="center">Looks like you don't have any images yet</Headline>
      </Col>
      <ImageGalleryBottom handleClose={handleClose} />
    </>
  );
};
