const quoteChars = ['"', "'", '&#039;', '&quot;'];
const linkAttrs = ['href', 'src', 'srcset'];

export default function editHtml(htmlStr: string, pageUrl: string) {
  if (pageUrl.slice(pageUrl.length - 1) !== '/') pageUrl = pageUrl + '/';
  // domain with protocol and '/' at the end
  const domain = pageUrl.replace('//', '\u001a').split('/')[0].replace('\u001a', '//') + '/';

  htmlStr = addScriptToHtml(htmlStr, domain);
  htmlStr = editTagsWithRelativePath(htmlStr, domain, pageUrl);
  htmlStr = addDomainToCssStyleUrl(htmlStr, domain);
  htmlStr = makeHtmlHubspotCompatible(htmlStr, domain);

  return htmlStr;
}

function addScriptToHtml(htmlStr: string, domain: string) {
  htmlStr = htmlStr.replace('<head>', `<head><script src="${window.origin}/localEditor.js"></script>`);
  htmlStr = htmlStr.replace('<head>', `<head><script>var domain="${domain}";</script>`);
  htmlStr = htmlStr + '<script>document.close();</script>';
  return htmlStr;
}

function editTagsWithRelativePath(htmlStr: string, domain: string, pageUrl: string) {
  htmlStr = makeAttrCopyWithOriginalSuffix(htmlStr);
  htmlStr = parsePaths(htmlStr, pageUrl);
  return htmlStr;
}

const parsePaths = (htmlStr: string, pageUrl: string) => {
  const protocol = pageUrl.split('//')[0];
  function addOmittedProtocol(attr: string, quoteChar: string) {
    const attrRegex = new RegExp(`${attr}=${quoteChar}\\/\\/`, 'gm');
    htmlStr = htmlStr.replace(attrRegex, `${attr}=${quoteChar}${protocol + '//'}`);
  }

  function updateHtmlStr(pageUrl: string, attr: string, quoteChar: string) {
    const attrRegex = new RegExp(`${attr}=${quoteChar}.*?(?=${quoteChar})${quoteChar}`, 'gm');
    const attrsToCopy = htmlStr.match(attrRegex);
    if (!attrsToCopy) return;
    attrsToCopy.forEach((str) => {
      const replaceLastQuoteRegex = new RegExp(quoteChar + '$');
      const htmlUrl = str.replace(replaceLastQuoteRegex, '').replace(`${attr}=${quoteChar}`, '');
      let replacementHref: string;
      if (htmlUrl.includes(' ') && attr === 'srcset') return;
      try {
        const htmlUrlParsed = new URL(htmlUrl, pageUrl);
        replacementHref = htmlUrlParsed.href;
        htmlStr = htmlStr.replace(
          str,
          str.replace(quoteChar + htmlUrl + quoteChar, quoteChar + replacementHref + quoteChar)
        );
      } catch (error) {
        console.log('htmlUrl', htmlUrl);
        console.log('pageUrl', pageUrl);
        console.error(error);
      }
    });
  }

  forEachAttrQuoteCombination(addOmittedProtocol);
  forEachAttrQuoteCombination(updateHtmlStr.bind(undefined, pageUrl));
  return htmlStr;
};

function makeAttrCopyWithOriginalSuffix(htmlStr: string) {
  function updateHtmlStr(attr: string, quoteChar: string) {
    /**
     * Copy original attribute value to another attribute.
     * E.g. Copy value src -> data-src-original
     * Needed for both debugging and identifying the element from unmodified page.
     */
    const attrRegex = new RegExp(`${attr}=${quoteChar}.*?(?=${quoteChar})${quoteChar}`, 'gm');
    const attrsToCopy = [...new Set(htmlStr.match(attrRegex))];
    if (!attrsToCopy) return;
    attrsToCopy.forEach((str) => {
      const replacement = str + ' ' + str.replace(`${attr}=`, `data-${attr}-original=`);
      htmlStr = htmlStr.replaceAll(str, replacement);
    });
  }
  forEachAttrQuoteCombination(updateHtmlStr);
  return htmlStr;
}

const forEachAttrQuoteCombination = (func: (attr: string, quoteChar: string) => void) => {
  const combinations = linkAttrs.flatMap((attr) => quoteChars.map((char): [string, string] => [attr, char]));
  combinations.forEach(([attr, quoteChar]) => func(attr, quoteChar));
};

function addDomainToCssStyleUrl(htmlStr: string, domain: string) {
  function replaceString(quoteChar: string) {
    const str = `url(${quoteChar}/`;
    htmlStr = htmlStr.replaceAll(str, `url(${quoteChar}${domain}`);
  }
  quoteChars.forEach((char) => replaceString(char));
  return htmlStr;
}

function makeHtmlHubspotCompatible(htmlStr: string, domain: string) {
  htmlStr = htmlStr.replaceAll('hbspt.cta._relativeUrls=true', 'hbspt.cta._relativeUrls=false');
  htmlStr = htmlStr.replaceAll("formsBaseUrl: '/_hcms/forms/',", `formsBaseUrl: '${domain}/_hcms/forms/',`);
  return htmlStr;
}
