import {useEffect, useState, useCallback, useRef} from 'react';

import {ISegment, ISegments, IPage, URLType} from '@markettailor/common-markettailor';
import {size, findIndex, isEmpty, cloneDeep} from 'lodash';

import config from '../config.json';
import {analytics} from './analytics';
import {updateIntercom} from './initAnalytics';

export const useIsMounted = () => {
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );
  return isMounted;
};

export const useInterval = (callback: () => void, interval_ms: number) => {
  const savedFunc = useRef(callback);

  useEffect(() => (savedFunc.current = callback), [callback]);

  useEffect(() => {
    const callCurrentFunc = () => {
      savedFunc.current();
    };
    const interval = setInterval(callCurrentFunc, interval_ms);
    return () => clearInterval(interval);
  }, [interval_ms]);
};

export function getRenderedConversionRateLift(conversionLift: number): number {
  const conversionLiftPercent = conversionLift * 100;
  return Math.min(1000, conversionLiftPercent);
}

export function formatNum(num?: string | number, decimals: undefined | number = undefined): string {
  if (num === undefined) return '0';
  if (decimals) {
    num = parseFloat(num as string).toFixed(decimals);
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function getSignByValue(num: number): string {
  if (num === 0) return '';
  return num >= 0 ? (num > 999 ? '>' : '+') : '';
}

export function formatPercent(num: number | string | undefined, decimals = 1, undefinedDefault = '0 %'): string {
  if (num === undefined) return undefinedDefault;
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toFixed(decimals) + ' %';
}

export function parsePercent(num: number | undefined, decimals = 1, undefinedDefault = '0 %'): string {
  return formatPercent(num && 100 * num, decimals, undefinedDefault);
}

export function formatUrl(url: string, onlyPath = false) {
  if (typeof url !== 'string') return '';

  url = url.replace(/^(http|https):\/\//, '');
  url = url.replace(/^(www)./, '');
  if (onlyPath) {
    const index = url.indexOf('/');
    if (index === -1) {
      return '/';
    }
    url = url.slice(index);
  }
  return url;
}

export function isLocalhost() {
  return document.location.origin.includes('localhost');
}

export function isDebug() {
  return localStorage.getItem('mt_debug') === 'true';
}

export function isDev() {
  const emailDomain = sessionStorage.getItem('emailDomain') || '';
  const email = sessionStorage.getItem('email') || '';
  const isDevAccount = emailDomain.includes('markettailor.io') && email.includes('test');
  return isLocalhost() || isDevAccount;
}

export function isDeploying() {
  return document.location.origin === 'http://localhost:3000' && config.env === 'production';
}

export function parseCookie(cookieName: string) {
  const cookie = document.cookie
    .split(';')
    .map((cookie) => {
      const cookieParts = cookie.trim().split('=');
      return {key: cookieParts[0], value: cookieParts[1]};
    })
    .filter((cookie) => cookie.key === cookieName)[0];
  return cookie;
}

export function getEmailDomainFromCookie() {
  const domainCookie = parseCookie('mt_domain');
  return domainCookie ? domainCookie.value : '';
}

export function setDomainCookie(email: string) {
  if (email) {
    const domain = email.split('@')[1];
    if (!parseCookie('mt_domain') && domain) {
      document.cookie =
        'mt_domain=' + domain + ';Secure;Domain=.markettailor.io;SameSite=Lax;max-age=' + 60 * 60 * 24 * 30 + ';';
    }
  }
}

export function cleanDomainFromUrl(url: URLType, domain: string): string {
  if (!url) return url;
  const regex = new RegExp(`.*${domain}`, 'i');
  const shortUrl = url.replace(regex, '');
  return ['', '/'].includes(shortUrl) ? '(Your homepage)' : shortUrl;
}

export function getPageIdOrFirst(segmentId: string | undefined, segments: ISegments) {
  const path = window.location.pathname;
  if (path.includes('editor')) {
    const pageId = path.split('/')[2];
    return pageId;
  }
  if (!segmentId) return undefined;
  if (segmentId in segments) {
    const segment: ISegment = segments[segmentId];
    if (size(segment.pageUrls)) {
      const pageId: string = Object.values(segment.pageUrls)[0].pageId;
      return pageId;
    }
  }
}

export function getSegmentId(segments: ISegments): string | undefined {
  const path = window.location.pathname;
  const segmentIdStorage = sessionStorage.getItem('segmentId');
  let segmentId: string | undefined = undefined;
  if (isEmpty(segments)) return segmentId;
  else if (path.includes('outbound') || path.includes('segmentation')) {
    segmentId = path.split('/')[2];
  } else if (path.includes('editor')) {
    const pageId = path.split('/')[2];
    segmentId = Object.values(segments).find((segment) => {
      return pageId in segment.pageUrls;
    })?.segmentId;
  } else if (segmentIdStorage !== null) {
    segmentId = segmentIdStorage;
  }
  if (segmentId && !(segmentId in segments)) {
    segmentId = undefined;
  }
  return segmentId;
}

export function getLabelExampleRow<IRow extends {}>(data: IRow[], omitKeys: string[] = []): Partial<IRow> {
  const findFirstPopulatedField = (header: string, data: IRow[]) => {
    const index = findIndex(data, (row) => Boolean(row[header]));
    const exampleValue = data[index]?.[header] || header;
    return exampleValue;
  };

  if (data.length === 0) return {};
  const exampleRow = Object.keys(data[0]).reduce((acc, header) => {
    return omitKeys.includes(header) ? acc : {...acc, [header]: data[0][header]};
  }, {});

  Object.keys(exampleRow).forEach((header) => {
    if (exampleRow[header]) return;
    const exampleValue = findFirstPopulatedField(header, data);
    exampleRow[header] = exampleValue;
  });
  return exampleRow;
}

export const getSegmentIdFromPageId = (pageId: string, segments: ISegments): string | undefined => {
  if (!segments) return undefined;
  const segmentId = Object.values(segments).find((segment) => pageId in (segment.pageUrls || {}))?.segmentId;
  return segmentId;
};

export const addPageToSegments = (pageId: string, changes: Partial<IPage>, segments: ISegments, segmentId: string) => {
  const newSegments = cloneDeep(segments);
  if (!segmentId) return newSegments;

  newSegments[segmentId].pageUrls[pageId] = {
    ...newSegments[segmentId].pageUrls[pageId],
    ...changes,
  };
  return newSegments;
};

export function useToggle(initialValue = false) {
  const [active, setValue] = useState<boolean>(initialValue);

  const setToggle = useCallback(() => {
    setValue((v) => !v);
  }, []);

  useEffect(() => setValue(initialValue), [initialValue]);
  return {active, setToggle};
}

export const setTitle = (title: string) => (window.document.title = title);

export function deepFreeze<T>(object: T): T {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];
    if (value && typeof value === 'object') deepFreeze(value);
  }

  return Object.freeze(object);
}

export const useSetNewPage = (pageName: string, titleName: string | undefined = undefined) => {
  useEffect(() => {
    updateIntercom();
    analytics.page(pageName);
  }, [pageName]);

  useEffect(() => {
    setTitle(titleName ? titleName : pageName);
  }, [titleName, pageName]);
};
