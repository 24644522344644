import React, {ReactNode, useEffect} from 'react';

import {brandMain, StyledDivider} from '@markettailor/common-markettailor';
import {Tab, Tabs} from '@material-ui/core';
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {capitalize} from 'lodash';

interface ComponentOptions {
  value: string;
  label: string;
}

interface Props {
  children?: ReactNode;
  currentComponent: string;
  componentOptions: ComponentOptions[];
  handleSelect: (selection: any) => void;
}

export default function InlineNav(props: Props) {
  return (
    <ThemeProvider theme={theme}>
      <InlineNavContent {...props} />
    </ThemeProvider>
  );
}

const InlineNavContent = (props: Props) => {
  const {children, currentComponent, componentOptions, handleSelect} = props;

  checkForErrors(componentOptions, currentComponent);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  });

  const componentToIndex: ComponentOptions | {[key: string]: number} = componentOptions.reduce(
    (componentToIndex, key, index) => {
      return {...componentToIndex, [key.value]: index};
    },
    {}
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledDivider border width={'fit-content'} margin={'0'}>
        <Tabs
          className={classes.tabs}
          classes={{indicator: classes.indicator}}
          value={componentToIndex[currentComponent]}
          aria-label="simple tabs example"
        >
          {componentOptions.map((componentOption, index) => {
            return (
              <Tab
                data-intercom-id={`inlineNav-${currentComponent}-${componentOption.value}`}
                key={index}
                className={classes.label}
                label={capitalize(componentOption.label)}
                onClick={() => handleSelect(componentOption.value)}
                {...tabProps(componentToIndex[currentComponent])}
              />
            );
          })}
        </Tabs>
      </StyledDivider>
      {children}
    </div>
  );
};

const checkForErrors = (componentOptions: ComponentOptions[], currentComponent: string) => {
  const componentOptionsList: string[] = componentOptions.map((option) => {
    return option.value;
  });
  if (!componentOptionsList.includes(currentComponent)) throw new Error('InlineNav current value not in options');
};

const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  tabs: {
    minHeight: '20px',
  },
  indicator: {
    backgroundColor: brandMain,
  },
  label: {
    fontFamily: 'Montserrat',
    padding: '0 6px',
    minWidth: '10px !important',
    minHeight: '30px',
    fontWeight: 600,
    marginRight: '30px',
    textTransform: 'unset',
  },
}));
