import {useState, useEffect} from 'react';

import axios, {CancelTokenSource} from 'axios';

import config from '../../../config.json';
import {EDITOR_ID} from '../../../contexts/EditorContext';
import editHtml from './editHtml';

export const setInitialIframeContent = (iframeRequestData?: string) => {
  const iframe = document.getElementById(EDITOR_ID) as HTMLIFrameElement | null;
  if (iframeRequestData && iframe) iframe.srcdoc = iframeRequestData;
};

export const useGetIframeRequestData = (pageUrl: string) => {
  const [iframeRequestData, setIframeRequestData] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!pageUrl) return;
    setIsLoading(true);
    setIframeRequestData(undefined);

    const source: CancelTokenSource = axios.CancelToken.source();
    const awaitWrapper = async () => {
      try {
        const formattedUrl = formatUrlProtocol(pageUrl);
        const iframeRequestData = await fetchParseWebsiteHtml(formattedUrl, source);
        setIframeRequestData(iframeRequestData);
      } catch (err) {
        if (!axios.isCancel(err)) setIsLoading(false);
      }
    };
    awaitWrapper();
    return () => source.cancel('Editor unmounted.');
    //eslint-disable-next-line
  }, [pageUrl]);

  return {iframeRequestData, isLoading};
};

const fetchParseWebsiteHtml = async (pageUrl: string, source: CancelTokenSource) => {
  let websiteHtml: string | undefined;
  try {
    websiteHtml = await proxyRequestPrimary(source, pageUrl);
  } catch (error) {
    if (axios.isCancel(error)) return;
    console.debug('Primary proxy failed.');
    console.debug(error);
  }
  try {
    if (!websiteHtml) websiteHtml = await proxyRequestFallback(source, pageUrl);
  } catch (error) {
    if (axios.isCancel(error)) return;
    console.debug('Secondary proxy failed.');
    console.debug(error);
  }
  if (websiteHtml) return editHtml(websiteHtml, pageUrl);
};

export function formatUrlProtocol(url: string) {
  if (!url.includes('https://') && !url.includes('http://')) {
    url = 'https://' + url;
  }
  return url;
}

const proxyRequestPrimary = async (source: CancelTokenSource, pageUrl: string): Promise<string> => {
  const corsProxy = pageUrl.includes('localhost') ? '' : config.proxy;
  const fetchUrl = corsProxy + formatUrlProtocol(pageUrl);
  const timerId = setTimeout(() => source.cancel('Request timeout.'), 10000);
  const res = await axios.get(fetchUrl, {
    cancelToken: source.token,
    headers: {Authorization: '', Accept: 'text/html, text/plain', 'Accept-Language': 'en-US,en;q=0.9'},
  });
  clearTimeout(timerId);
  return res.data;
};

const proxyRequestFallback = async (source: CancelTokenSource, pageUrl: string): Promise<string> => {
  const timerId = setTimeout(() => source.cancel('Request timeout.'), 10000);
  const res = await axios.get(config.api.baseURL + 'proxy?url=' + formatUrlProtocol(pageUrl), {
    cancelToken: source.token,
    headers: {'Accept-Language': 'en-US,en;q=0.9'},
  });
  clearTimeout(timerId);
  return res.data.content;
};
