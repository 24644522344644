interface Currencies {
  [currency: string]: string;
}

export const currencies: Currencies = {
  eur: '€',
  usd: '$',
};

export const defaultCurrency = 'eur';
