import React from 'react';

import {Headline, brandMain} from '@markettailor/common-markettailor';

import type {IDynamicValues} from '../../../contexts/editorContextTypes';
import {VariableMenuItem} from './VariableMenuItem';

interface DynamicValueBlockProps {
  headline: string;
  dynamicValues: IDynamicValues;
  handleSelection: (selector: string) => void;
  setOpenVariableMenu: (state: boolean) => void;
}

export default function DynamicValueMenuBlock({
  headline,
  dynamicValues,
  handleSelection,
  setOpenVariableMenu,
}: DynamicValueBlockProps) {
  if (!dynamicValues.length) return null;

  return (
    <>
      <Headline margin="20px 10px 10px 10px" fontSize="14px" color={brandMain} textAlign="center">
        {headline}
      </Headline>
      {dynamicValues.map((dynamicValue, idx) => {
        return (
          <VariableMenuItem
            key={dynamicValue.key}
            handleSelection={handleSelection}
            setOpenVariableMenu={setOpenVariableMenu}
            selector={dynamicValue.key}
            exampleValue={dynamicValue.value}
            idx={idx}
          />
        );
      })}
    </>
  );
}
