import React from 'react';

import {
  Col,
  Headline,
  Row,
  StyledBox,
  Text,
  GridContainer,
  GridItem as GridItemBase,
  brandMain,
  IFilterCondition,
} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {formatPercent} from '../../../../functions/util';
import {useAllMapping} from '../../../../termMaps/technicalNamesToLabels';

export interface BaseInsight {
  count: number;
  fraction: number;
}

export interface PageInsight extends BaseInsight {
  pageUrl: string;
}

export interface AudienceInsight extends BaseInsight {
  value: string;
  conversionRate?: number;
}
export interface PageVisitorCounts extends PageInsight {
  type: 'personalized' | 'control';
}

interface Props {
  filterCondition: IFilterCondition;
  parsedInsights: AudienceInsight[];
  querySelector: string;
  hasCompleteFilters: boolean;
}

export default function AudienceInsights(props: Props) {
  return (
    <>
      <InsightsTopHeader />
      <StyledBox minHeight={'40px'} margin={'0 0 10px 0'}>
        <InsightsContent {...props} />
      </StyledBox>
    </>
  );
}

const GridItem = (props) => <GridItemBase {...props} style={{padding: '0 10px 0 0'}}></GridItemBase>;

const InsightsContent = ({parsedInsights, filterCondition, querySelector, hasCompleteFilters}: Props) => {
  if (isEmpty(parsedInsights)) return <Headline>No visitor has matched your segment</Headline>;
  return (
    <Col>
      <GridContainer columns="2fr 1fr 1fr 1fr" columnGap="0px" rowGap="10px">
        <InsightsHeaders hasCompleteFilters={hasCompleteFilters} filterCondition={filterCondition} />
        <CurrentSelector querySelector={querySelector} />
        {parsedInsights.slice(0, 10).map((insight, index) => (
          <RenderInsightRow insight={insight} querySelector={querySelector} key={index} />
        ))}
        <EmptyBorderRow />
        <TotalRow parsedInsights={parsedInsights} />
      </GridContainer>
    </Col>
  );
};

const TotalRow = ({parsedInsights}: {parsedInsights: AudienceInsight[]}) => {
  const totalCount = parsedInsights.slice(0, 10).reduce((prev, insight) => prev + insight.count, 0);
  const totalFraction = parsedInsights.slice(0, 10).reduce((prev, insight) => prev + insight.fraction, 0);
  const totalConversion = parsedInsights.reduce(
    (prev, insight) => prev + (insight.conversionRate || 0) * (insight.count / totalCount),
    0
  );

  return (
    <>
      <GridItem width="100%">
        <Headline fontSize={'14px'} color={brandMain}>
          Total
        </Headline>
      </GridItem>
      <GridItem textAlign="end" width="100%">
        <Text margin="0 5px 0 0">{totalCount}</Text>
      </GridItem>
      <GridItem textAlign="end" width="100%">
        <Text>{formatPercent(totalFraction * 100, 0)}</Text>
      </GridItem>
      <GridItem textAlign="end" width="100%">
        <Text>{formatPercent(totalConversion * 100, 1)}</Text>
      </GridItem>
    </>
  );
};

const EmptyBorderRow = () => {
  return (
    <>
      <GridItem width="100%" border />
      <GridItem width="100%" border />
      <GridItem width="100%" border />
      <GridItem width="100%" border />
    </>
  );
};

const RenderInsightRow = ({insight, querySelector}: {insight: AudienceInsight; querySelector: string}) => {
  const mapping = useAllMapping();
  const insightValue = mapping[querySelector]?.[insight.value] || insight.value || '(unknown)';
  return (
    <>
      <GridItem>
        {querySelector && (
          <Text margin="0 5px 0 0" wordBreak="break-all">
            {insightValue}
          </Text>
        )}
      </GridItem>
      <GridItem justifySelf="end">
        <Text margin="0 5px 0 0">{insight.count}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{formatPercent(insight.fraction * 100, 0)}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{formatPercent((insight.conversionRate || 0) * 100, 1)}</Text>
      </GridItem>
    </>
  );
};

const CurrentSelector = ({querySelector}: {querySelector: string}) => {
  const mapping = useAllMapping();

  if (!querySelector) return null;
  return (
    <>
      <GridItem width="100%" justifySelf="end" alignSelf="end">
        <Headline fontSize={'14px'} color={brandMain}>
          {mapping.selector[querySelector as keyof typeof mapping.selector]}
        </Headline>
      </GridItem>
      <GridItem width="100%" />
      <GridItem width="100%" />
      <GridItem width="100%" />
    </>
  );
};

const InsightsHeaders = ({hasCompleteFilters, filterCondition}) => {
  return (
    <>
      <GridItem width="100%" border>
        {hasCompleteFilters && (
          <Col>
            <Headline fontSize={'14px'} color={brandMain}>
              Current segment
            </Headline>
            {<Text margin={'5px 0'}>{filterCondition}</Text>}
          </Col>
        )}
      </GridItem>
      <GridItem textAlign="end" width="100%" border>
        <Text margin="0 5px 0 0">Visitors</Text>
      </GridItem>
      <GridItem textAlign="end" width="100%" border>
        <Text>Traffic (%)</Text>
      </GridItem>
      <GridItem textAlign="end" width="100%" border>
        <Text>Conversion rate (%)</Text>
      </GridItem>
    </>
  );
};
const InsightsTopHeader = () => {
  return (
    <Row alignItems={'center'}>
      <Headline color={'black'} margin={'0 5px 10px 0'}>
        Audience insights
      </Headline>
      <Headline margin={'0 0 10px 20px'} fontSize={'13px'}>
        Last 30 days
      </Headline>
    </Row>
  );
};
