import React, {useState} from 'react';

import {getSelectCustomStyles, IValueLabel, Headline} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import CreatableSelect from 'react-select/creatable';

import {IKeyValueType} from '../../segmentInterfaces';
import {parseOptionsToValueLabel} from '../../util';

type IKeyValueTuple = [IValueLabel | never[], IValueLabel | never[]];

interface QueryStringProps {
  valueRange: string[];
  handleValueRangeChange: Function;
  saveCreatableOption: Function;
  userCreatedOptions: {[key: string]: string[]};
}

export function QueryStringSelection(props: QueryStringProps) {
  const {valueRange, handleValueRangeChange, saveCreatableOption, userCreatedOptions} = props;
  const keyValueRange = getKeyValueRange(valueRange);
  const [keyValue, setKeyValue] = useState<IKeyValueType>({
    key: keyValueRange[0]['value'] || '',
    value: keyValueRange[1]['value'] || '',
  });

  function getKeyValueRange(valueRange: string[]): IKeyValueTuple {
    if (isEmpty(valueRange)) {
      return [[], []];
    }
    const keyValue = valueRange[0].split('=');
    return [
      keyValue[0] ? {value: keyValue[0], label: keyValue[0]} : [],
      keyValue[1] ? {value: keyValue[1], label: keyValue[1]} : [],
    ];
  }

  function handleChangeCreatableKeyValue(selection: IValueLabel | null, actionMeta: any): void {
    selection = selection || {value: '', label: ''};
    if (actionMeta.name === 'key') {
      handleValueRangeChange([{value: selection.value + '=' + keyValue.value, label: selection.label}]);
    } else if (actionMeta.name === 'value') {
      handleValueRangeChange([{value: keyValue.key + '=' + selection.value, label: selection.label}]);
    }

    setKeyValue({
      ...keyValue,
      [actionMeta.name]: selection?.value || '',
    });
    if (actionMeta.action === 'create-option') {
      saveCreatableOption('query_string_' + actionMeta.name, selection.value);
    }
  }

  const queryStringKey = getKeyValueRange(valueRange)[0];
  const queryStringValue = getKeyValueRange(valueRange)[1];
  return (
    <>
      <CreatableSelect
        isClearable={true}
        options={parseOptionsToValueLabel(userCreatedOptions['query_string_key'])}
        styles={getSelectCustomStyles({
          control: {
            width: 160,
            mediaSmall: {
              width: 150,
              margin: '0 5px',
            },
          },
        })}
        name="key"
        value={queryStringKey}
        onChange={handleChangeCreatableKeyValue}
        placeholder="Key"
      />
      <Headline margin={'6px 8px 0 8px'} fontSize={'22px'}>
        =
      </Headline>
      <CreatableSelect
        isClearable={true}
        options={parseOptionsToValueLabel(userCreatedOptions['query_string_value'])}
        styles={getSelectCustomStyles({control: {width: 160, mediaSmall: {width: 150, margin: '0 5px'}}})}
        name={'value'}
        value={queryStringValue}
        onChange={handleChangeCreatableKeyValue}
        placeholder={'Value'}
      />
    </>
  );
}
