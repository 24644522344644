import React, {useState, useEffect, useContext, ReactNode, createContext} from 'react';

import {
  NotificationContext,
  IIntegrations,
  ICrmIntegrations,
  ICrmDataSource,
  IIpDataIntegrations,
  IConversionIntegrations,
} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {cloneDeep} from 'lodash';

import {IAuthOptionsCommonApiKey} from '../components/integrations/CommonApiKeyIntegration';
import {IAuthOptionsGA} from '../components/integrations/conversionTracking/GoogleAnalytics';
import {IAnalyticsAccount} from '../components/integrations/conversionTracking/googleAnalytics/AccountOptionsSelector';
import {
  IGAIntegrationAuthOptionsType,
  ISubmitGAOptionsResponseData,
} from '../components/integrations/conversionTracking/googleAnalytics/OptionsWindowGA';
import {IAuthOptionsSegment} from '../components/integrations/conversionTracking/SegmentIntegrationSettings';
import {IAuthOptionsZoomInfo} from '../components/integrations/ZoominfoBadge';
import config from '../config.json';
import {analytics, getIntegrationMapping, sendIntegrationAnalytics} from '../functions/analytics';
import {deepFreeze, useIsMounted} from '../functions/util';
import {AccountContext} from './AccountContext';
import {ConversionManagementContext} from './ConversionManagementContext';

export const ipDataIntegrations: Array<keyof IIpDataIntegrations> = ['kickfire', 'clearbit', 'zoominfo'];
export const conversionIntegrations: Array<keyof IConversionIntegrations> = ['googleAnalytics', 'segment'];
export const crmIntegrations: Array<keyof ICrmIntegrations> = ['salesforce', 'hubspot', 'activeCampaign'];
export const crmDataSources: ICrmDataSource[] = crmIntegrations;
export const supportedCrmObjectTypes: any = {
  hubspot: ['company', 'deal', 'contact'],
  salesforce: ['company', 'contact'],
  activeCampaign: ['company', 'contact'],
};
export type IAuthIntegrationOptions =
  | IAuthOptionsCommonApiKey
  | IAuthOptionsGA
  | IAuthOptionsSegment
  | IAuthOptionsZoomInfo;
export interface IAuthIntegrationResponse {
  data: IAuthIntegrationResponseData;
  status: number;
}
export interface IAuthIntegrationResponseData {
  accountSummary?: IAnalyticsAccount[];
}

export type OAuthCrmsType = 'hubspot' | 'salesforce';
type AuthenticateCrmIntegrationType = {
  (integrationName: 'activeCampaign', integrationOptions: {url: string; apiKey: string}): Promise<void>;
  (integrationName: OAuthCrmsType, integrationOptions: {code: string}): Promise<void>;
};

interface IIntegrationContext {
  updatingCrmIntegrationOptions: boolean;
  integrations: IIntegrations;
  isLoading: boolean;
  hasCompanyLookupIntegration: boolean;
  getIntegrationOptions: (
    integrationName: keyof ICrmIntegrations,
    optionalIntegrations?: IIntegrations | undefined
  ) => void;
  removeIntegration: (integrationName: keyof IIntegrations) => void;
  submitGoogleAnalyticsOptions: (
    integrationOptions: IGAIntegrationAuthOptionsType
  ) => Promise<undefined | ISubmitGAOptionsResponseData>;
  authIntegration: (
    integrationName: keyof IIntegrations,
    integrationOptions: IAuthIntegrationOptions
  ) => Promise<IAuthIntegrationResponse | undefined>;
  authenticateCrmIntegration: AuthenticateCrmIntegrationType;
}

const IntegrationContext = createContext<IIntegrationContext | undefined>(undefined);
function IntegrationProvider({children}: {children: ReactNode}) {
  const isMounted = useIsMounted();
  const source: CancelTokenSource = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {integrations: accountIntegrations} = useContext(AccountContext)!;
  const [updatingCrmIntegrationOptions, setUpdatingCrmIntegrationOptions] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<IIntegrations>({});
  const conversionManagement = useContext(ConversionManagementContext);
  const hasCompanyLookupIntegration = Boolean(integrations.clearbit?.isActive || integrations.kickfire?.isActive);

  useEffect(() => {
    if (!accountIntegrations) return;
    setIntegrations(accountIntegrations);

    getCrmIntegrationOptions(accountIntegrations);
    sendIntegrationAnalytics(accountIntegrations);
    setIsLoading(false);
    return () => source.cancel('Integrations unmounted');
    //eslint-disable-next-line
  }, [accountIntegrations]);

  const getCrmIntegrationOptions = async (integrations: IIntegrations) => {
    try {
      setUpdatingCrmIntegrationOptions(true);
      await Promise.all(crmIntegrations.map((integrationName) => getIntegrationOptions(integrationName, integrations)));
      if (!isMounted.current) return;
      setUpdatingCrmIntegrationOptions(false);
    } catch (err) {
      if (axios.isCancel(err)) return;
      console.error(err);
      setInfoNotification({message: 'Fetching CRM options failed', level: 'error'});
    }
  };

  const submitGoogleAnalyticsOptions = async (
    integrationOptions: IGAIntegrationAuthOptionsType
  ): Promise<undefined | ISubmitGAOptionsResponseData> => {
    try {
      const res = await axios.put(
        config.api.baseURL + 'integration/googleAnalytics/options/customDimensions',
        integrationOptions,
        {cancelToken: source.token}
      );
      if (res.status !== 200) {
        console.error('Ended here in submitGoogleAnalyticsOptions, maybe 200 check is useful');
        setInfoNotification({message: 'Integration failed!', level: 'error'});
      } else {
        if (res.data.message === 'success') {
          setIntegrations(res.data.integrations);
          setInfoNotification({message: 'Integration successful!'});
          const mappedIntegration = getIntegrationMapping('googleAnalytics');
          analytics.track('Integrated service', {
            Name: mappedIntegration.label,
            Type: mappedIntegration.type,
          });
        }
      }
      return res.data;
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Integration failed!', level: 'error'});
    }
  };

  const authIntegration = async (
    integrationName: keyof IIntegrations,
    integrationOptions: IAuthIntegrationOptions
  ): Promise<IAuthIntegrationResponse | undefined> => {
    try {
      let res;
      if (integrationName === 'zoominfo') {
        res = await axios.post(
          config.api.baseURL + 'integration/common/auth',
          {[integrationName]: integrationOptions},
          {cancelToken: source.token}
        );
      } else {
        res = await axios.post(config.api.baseURL + 'integration/' + integrationName, integrationOptions, {
          cancelToken: source.token,
        });
      }
      setIntegrations(res.data.integrations);
      const mappedIntegration = getIntegrationMapping(integrationName);
      if (integrationName !== 'googleAnalytics')
        analytics.track(`Integrated service`, {
          Name: mappedIntegration.label,
          Type: mappedIntegration.type,
        });
      return res;
    } catch (e: any) {
      if (axios.isCancel(e)) return;
      return e.response;
    }
  };

  const removeIntegration = async (integrationName: keyof IIntegrations) => {
    const extraUrlParam = crmIntegrations.some((crmIntegration) => crmIntegration === integrationName) ? '/auth' : '';
    try {
      let res;
      if (integrationName === 'zoominfo') {
        res = await axios.delete(config.api.baseURL + 'integration/common/auth?integrationName=' + integrationName, {
          cancelToken: source.token,
        });
      } else {
        res = await axios.delete(config.api.baseURL + 'integration/' + integrationName + extraUrlParam, {
          cancelToken: source.token,
        });
      }
      setIntegrations(res.data.integrations);
      const mappedIntegration = getIntegrationMapping(integrationName);
      analytics.track('Removed integration', {
        Name: mappedIntegration.label,
        Type: mappedIntegration.type,
      });
      if (conversionManagement) conversionManagement.getMainConversionMetric();
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Removing integration failed', level: 'error'});
    }
  };

  const authenticateCrmIntegration: AuthenticateCrmIntegrationType = async (
    integrationName: keyof ICrmIntegrations,
    integrationOptions: {[key: string]: string}
  ) => {
    try {
      window.history.replaceState(null, '', '/integrations');
      const res = await axios.post(config.api.baseURL + 'integration/' + integrationName + '/auth', integrationOptions);
      setInfoNotification({message: 'Integration successful'});
      setIntegrations(res.data.integrations);
      getIntegrationOptions(integrationName, res.data.integrations);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Integration failed', level: 'error'});
      console.error(e);
    }
  };

  const getIntegrationOptions = async (
    integrationName: keyof ICrmIntegrations,
    optionalIntegrations: IIntegrations | undefined = undefined
  ) => {
    try {
      const newIntegrations = optionalIntegrations || integrations;
      if (!newIntegrations[integrationName]?.isActive) return;
      const newIntegration = cloneDeep(newIntegrations[integrationName])!;
      const res = await axios.get(config.api.baseURL + 'integration/' + integrationName + '/options', {
        cancelToken: source.token,
      });
      newIntegration.options = res.data.options;
      setIntegrations((prevIntegrations) => ({...prevIntegrations, [integrationName]: newIntegration}));
    } catch (e) {
      if (axios.isCancel(e)) return;
      throw e;
    }
  };

  return (
    <IntegrationContext.Provider
      value={deepFreeze<IIntegrationContext>({
        updatingCrmIntegrationOptions,
        integrations,
        hasCompanyLookupIntegration,
        isLoading,
        getIntegrationOptions,
        authIntegration,
        removeIntegration,
        submitGoogleAnalyticsOptions,
        authenticateCrmIntegration,
      })}
    >
      {children}
    </IntegrationContext.Provider>
  );
}

export {IntegrationContext, IntegrationProvider};
