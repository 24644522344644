import React, {useContext, useState} from 'react';

import {Col, Headline, PopoverWindow, Row, StyledBtn, Text} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {useHasElements} from '../util';

export const ResetAllButton = () => {
  const editorContext = useContext(EditorContext)!;
  const {errorState, resetAllElements} = editorContext!;

  const [warningWindowIsOpen, setWarningWindowIsOpen] = useState(false);
  const closeWarningWindow = () => setWarningWindowIsOpen(false);
  const openWarningWindow = () => setWarningWindowIsOpen(true);

  const hasNoChanges = !useHasElements();
  const hasError = Boolean(errorState);

  return (
    <>
      <PopoverWindow display={warningWindowIsOpen} handleClose={closeWarningWindow}>
        <Col alignItems={'center'}>
          <Headline margin={'20px 0 30px 0 '}>Are you sure you want to delete all changes?</Headline>
          <Row justifyContent="flex-end">
            <StyledBtn
              warn
              onClick={() => {
                resetAllElements();
                closeWarningWindow();
              }}
            >
              {'Yes, delete all changes'}
            </StyledBtn>
            <StyledBtn margin="10px 20px 10px 20px" onClick={closeWarningWindow}>
              {'No'}
            </StyledBtn>
          </Row>
        </Col>
      </PopoverWindow>
      <StyledBtn
        fontSize={'13px'}
        boxShadow={'none'}
        padding={'10px 15px'}
        disabled={hasNoChanges || hasError}
        onClick={openWarningWindow}
        warn
      >
        Reset all
      </StyledBtn>
    </>
  );
};
