import {PageInsight} from '../../segmentation/sideContainer/audienceInsights/AudienceInsights';

export const getTopInsights = (pageInsights: PageInsight[]) => {
  if (!pageInsights) return [];
  return pageInsights
    .sort((a, b) => {
      return b.count - a.count;
    })
    .slice(0, 8);
};
