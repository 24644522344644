import React, {useContext} from 'react';

import {brandMain} from '@markettailor/common-markettailor';
import ImageUploader from 'react-images-upload';

import {ImageContext} from '../../../contexts/ImageContext';

export default function ImageUpload() {
  const imageContext = useContext(ImageContext)!;

  async function handleChange(picture: File[]) {
    const formData = new FormData();
    formData.append('editorImage', picture[0]);
    await imageContext.uploadImage(formData, picture[0].type);
  }

  return (
    <ImageUploader
      withIcon={false}
      withLabel={false}
      buttonText="Upload image"
      onChange={handleChange}
      imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
      maxFileSize={5242880}
      singleImage={true}
      fileContainerStyle={{boxShadow: 'none', padding: '0', margin: '0'}}
      buttonStyles={{
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        background: brandMain,
        minHeight: '30px',
        minWidth: '100px',
        borderRadius: '20px',
        padding: '10px 15px',
        whiteSpace: 'no-wrap',
        boxShadow: '2px 2px 6px #a6a6a6',
      }}
    />
  );
}
