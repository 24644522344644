import React, {useContext} from 'react';

import {
  Row,
  Text,
  NotificationContext,
  Headline,
  StyledBtn,
  Col,
  CursorClickIcon,
  StyledDivider,
  IDomElemChanges,
} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {ConversionManagementContext} from '../../../contexts/ConversionManagementContext';
import {EditorContext} from '../../../contexts/EditorContext';
import {customStyles} from '../util';

export const EventEditor = () => {
  return (
    <Row margin={'5px 10px 5px 0'} alignItems="center">
      <Text margin={'0 10px 0 10px'}>Conversion event</Text>
      <EventSelector />
    </Row>
  );
};

function EventSelector() {
  const editorContext = useContext(EditorContext)!;
  const {setInfoNotification, setWindowNotification, closeWindowNotification} = useContext(NotificationContext)!;
  const notificationId = 'editorModeSelectorNotification';
  const {selectedElemId, selectedElem, changeElemContent} = editorContext;

  const conversionManagementContext = useContext(ConversionManagementContext)!;
  const eventOptions = Object.values(conversionManagementContext.events || {})
    .filter((event) => event.type === 'track')
    .map((event) => ({name: event.eventId, label: event.eventName, event}))
    .sort((a, b) => a.label.localeCompare(b.label));

  const currentValue = eventOptions.filter((option) => option.name === selectedElem.conversionEventId);

  const handleConfirm = (
    option: {label: string; name: string},
    pageUrl: string,
    additionalChanges: Partial<IDomElemChanges> = {}
  ) => {
    changeElemContent(selectedElemId, {conversionEventId: option?.name, pageUrl, ...additionalChanges}, false)!;
    closeWindowNotification(notificationId);
    setInfoNotification({message: 'Track event added!'});
  };

  return (
    <Select
      id="editorModeSelector"
      isSearchable={false}
      styles={customStyles}
      options={eventOptions}
      value={currentValue}
      isDisabled={!selectedElemId}
      onChange={(option: {label: string; name: string}) => {
        const handleConfirmSingle = () => handleConfirm(option, document.location.href);
        const handleConfirmAll = () =>
          handleConfirm(option, '', {
            id: undefined,
            innerText: undefined,
            innerHTML: undefined,
            style: undefined,
            mt_index: undefined,
            mt_index_max: undefined,
          });
        setWindowNotification({
          id: notificationId,
          boxStyles: {boxHeight: '130px'},
          message: <ConfirmAttachEvent handleConfirmSingle={handleConfirmSingle} handleConfirmAll={handleConfirmAll} />,
        });
      }}
    />
  );
}

const ConfirmAttachEvent = ({
  handleConfirmSingle,
  handleConfirmAll,
}: {
  handleConfirmSingle: (e: React.ChangeEvent) => void;
  handleConfirmAll: (e: React.ChangeEvent) => void;
}) => {
  return (
    <Col alignItems="center" height="100%">
      <CursorClickIcon width="25px" />
      <Headline margin="10px 0 15px 0">Which elements do you want to attach the event?</Headline>
      <StyledDivider border margin="10px 0" />
      <Row margin="10px 0 20px 0" justifyContent="space-evenly">
        <StyledBtn onClick={handleConfirmSingle} margin="0 10px 0 10px" minWidth="200px">
          Only this element
        </StyledBtn>
        <StyledBtn onClick={handleConfirmAll} margin="0 10px 0 10px" minWidth="200px">
          All similar elements
        </StyledBtn>
      </Row>
    </Col>
  );
};
