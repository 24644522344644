import React, {useState, useRef} from 'react';

import {Text, Col, StyledBtn} from '@markettailor/common-markettailor';

import {parseCsvFile, ParsedCsvDataType} from './csvUploadUtil';

interface Props {
  handleDataOutput: (parsedCsvData: ParsedCsvDataType) => void;
  dropEmptyColumns?: boolean;
  buttonStyles?: object;
}
export default function CsvUploadButton({handleDataOutput, dropEmptyColumns, buttonStyles}: Props) {
  const [error, setError] = useState('');
  const inputEl = useRef<HTMLInputElement>(null);

  const handleLoad = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const inputElem = e.target;
    if (!inputElem.files || !inputElem.files.length) return;
    const csvFile = inputElem.files[0];
    console.debug('File info:', csvFile);
    parseCsvFile(csvFile, handleDataOutput, setError, dropEmptyColumns);
  };

  const handleClick = () => {
    if (inputEl.current === null) return;
    inputEl.current.click();
  };

  return (
    <Col alignItems="center">
      <StyledBtn onClick={handleClick} {...buttonStyles}>
        CSV upload
      </StyledBtn>
      <input type="file" ref={inputEl} onChange={handleLoad} style={{display: 'none'}} />
      {error && (
        <Text textAlign="center" color="red" margin="25px 0 0 0">
          {error}
        </Text>
      )}
    </Col>
  );
}
