import React, {useState, useCallback} from 'react';

import {Row, StyledBox} from '@markettailor/common-markettailor';

import {StyledInputEditor} from '../../../styles/editor/editorStyles';

interface Props {
  initialValue: string;
  handleClickAway: (cellContent: string) => void;
}

export default function EditableCell({initialValue, handleClickAway}: Props) {
  const [cellContent, setCellContent] = useState<string>(initialValue || '');
  const autoFocus = useCallback((el) => (el ? el.focus() : null), []);

  return (
    <Row
      key={'editableCell'}
      minWidth="100%"
      alignItems="center"
      justifyContent="space-between"
      style={{
        padding: '0px',
      }}
    >
      <StyledBox padding="6px" minWidth="105%" boxSizing="border-box" position="relative" left="-8px">
        <StyledInputEditor
          minWidth="100%"
          fontSize="15px"
          padding="4px"
          border="none"
          onBlur={() => {
            handleClickAway(cellContent);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleClickAway(cellContent)}
          style={{boxSizing: 'border-box', fontWeight: 600}}
          ref={autoFocus}
          value={cellContent}
          onChange={(e) => setCellContent(e.target.value)}
        />
      </StyledBox>
    </Row>
  );
}
