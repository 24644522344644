import React, {useState, useContext} from 'react';

import {Col, StyledDivider} from '@markettailor/common-markettailor';

import {IntegrationContext, crmIntegrations} from '../../../../contexts/IntegrationContext';
import CrmCreation from './CrmCreation';
import CSVCreation from './CSVCreation';

export default function OutboundTypeSelection() {
  const {integrations} = useContext(IntegrationContext)!;
  const [csvCleanup, setCsvCleanup] = useState<boolean>(false);

  const CrmCreationCards = () => {
    return (
      <>
        {crmIntegrations
          .filter((crmIntegration) => integrations[crmIntegration]?.isActive)
          .map((crmIntegration) => (
            <CrmCreation key={crmIntegration} crmName={crmIntegration} />
          ))}
      </>
    );
  };

  return (
    <Col alignItems="center">
      {!csvCleanup && <CrmCreationCards />}
      <StyledDivider margin="0 0 15px 0" />
      <CSVCreation csvCleanup={csvCleanup} setCsvCleanup={setCsvCleanup} />
    </Col>
  );
}
