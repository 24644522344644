import React, {useContext} from 'react';

import {CheckmarkIcon, IconX, StyledTable, StyledBtn, lightGray} from '@markettailor/common-markettailor';

import {AccountContext} from '../../../contexts/AccountContext';
import {StripeContext} from '../../../contexts/StripeContext';
import {currencies, defaultCurrency} from '../../util/currencies';
import {stripePlanNameMapping} from './ConfirmationPage';

interface Props {
  setSelectedPlan: (selectedPlan: keyof typeof stripePlanNameMapping) => void;
}

export default function FeaturePlans(props: Props) {
  const accountContext = useContext(AccountContext)!;
  const {stripe, features} = useContext(StripeContext)!.state;
  const {permissions} = accountContext.account || {};
  const currentPlan = permissions?.plan;

  const plans: {[key: string]: number} = {
    freemium: 0,
    //basic: 1,
    //growth: 2,
  };

  function getPlanItems() {
    function getPlanBtnText(currentPlan: string | undefined, plan: string) {
      if (!currentPlan || currentPlan === plan) {
        return 'Current plan';
      } else {
        return plans[currentPlan] > plans[plan] ? 'Downgrade' : 'Upgrade';
      }
    }

    function selectAccountPlan(planName: keyof typeof stripePlanNameMapping) {
      props.setSelectedPlan(planName);
    }

    function createFeatureListings() {
      const featureListing = features.map((feature, idx) => {
        const notGrowth = feature.tier !== 'growth';
        const listing = {id: String(idx), name: feature.name, growth: true, freemium: notGrowth, basic: notGrowth};
        return listing;
      });
      return featureListing;
    }

    const buttons = {
      id: 'buttons',
      name: '',
      freemium: (
        <StyledBtn
          disabled={currentPlan === 'freemium'}
          onClick={() => {
            selectAccountPlan('freemium');
          }}
        >
          {getPlanBtnText(currentPlan, 'freemium')}
        </StyledBtn>
      ),
    };

    const currency = stripe?.productPricing.basic.currency || defaultCurrency;
    const prices = {
      id: 'prices',
      name: '',
      freemium: currencies[currency] + '0/mo',
    };

    return [buttons, prices, ...createFeatureListings()];
  }

  return (
    <StyledTable
      headers={[{name: ''}, {freemium: 'Freemium'}, {basic: 'Basic'}, {growth: 'Growth'}]}
      items={getPlanItems()}
      booleanIcon={{
        true: <CheckmarkIcon color={'black'} width="25px" />,
        false: <IconX width="30px" color={lightGray} $hoverColor={lightGray} cursor="pointer" />,
      }}
      style={{row: {cursor: 'initial'}, table: {maxWidth: '1000px', minWidth: 600}}}
    />
  );
}
