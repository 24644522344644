import React, {useContext} from 'react';

import {Row, IIntegrations, Text} from '@markettailor/common-markettailor';

import {AccountContext} from '../../contexts/AccountContext';
import {IntegrationContext, ipDataIntegrations} from '../../contexts/IntegrationContext';
import {isUsingMarkettailorIpData} from '../common/util';
import Permission from '../util/Permission';
import CommonApiKeyIntegration from './CommonApiKeyIntegration';
import ZoominfoBadge from './ZoominfoBadge';

export const IPDataIntegrations = () => {
  const accountContext = useContext(AccountContext);

  const kickfireButtonIsDisabled = useIpDataButtonIsDisabled('kickfire');
  const clearbitButtonIsDisabled = useIpDataButtonIsDisabled('clearbit');
  const zoominfoButtonIsDisabled = useIpDataButtonIsDisabled('zoominfo');

  return (
    <>
      <MarkettailorIpDataText />
      <Row>
        <CommonApiKeyIntegration
          title={'Kickfire'}
          description={'Enable reverse IP enrichment with Kickfire to use firmographic data for segments.'}
          integrationName={'kickfire'}
          logoWidth={'100px'}
          isDisabled={kickfireButtonIsDisabled}
        />
        <CommonApiKeyIntegration
          title={'Clearbit'}
          description={'Enable reverse IP enrichment with Clearbit to use firmographic data for segments.'}
          integrationName={'clearbit'}
          logoWidth={'120px'}
          isDisabled={clearbitButtonIsDisabled}
        />
        <ZoominfoBadge isDisabled={zoominfoButtonIsDisabled} />
      </Row>
    </>
  );
};

const MarkettailorIpDataText = () => {
  const featureFlags = useContext(AccountContext)?.account?.permissions.featureFlags;
  if (!featureFlags) return null;
  if (!isUsingMarkettailorIpData(featureFlags)) return null;
  return (
    <Text margin={'0 0 10px 0'} style={{fontStyle: 'italic'}}>
      You are using Markettailor IP data, if you want to use your own 3rd party integration, talk to sales.
    </Text>
  );
};

const useIpDataButtonIsDisabled = (integrationName: keyof IIntegrations) => {
  const {integrations} = useContext(IntegrationContext)!;
  const featureFlags = useContext(AccountContext)!.account?.permissions.featureFlags || [];

  const integrationIsActive = integrations[integrationName]?.isActive;
  if (integrationIsActive) return false;

  const otherIpIntegrationIsActive = ipDataIntegrations.filter(
    (integration) => integrations[integration]?.isActive && integration !== integrationName
  ).length;
  if (otherIpIntegrationIsActive) return true;

  if (isUsingMarkettailorIpData(featureFlags)) return true;

  return false;
};
