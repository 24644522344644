import React, {useState, useContext, useEffect} from 'react';

import {
  AlignCenterScreen,
  Headline,
  MainContentView,
  Row,
  StyledBox,
  StyledDivider,
  Text,
  Activity,
  brandMain,
  CopyableBox,
  LoadingPage,
} from '@markettailor/common-markettailor';
import moment from 'moment';

import config from '../../config.json';
import {AccountContext} from '../../contexts/AccountContext';
import {useSetNewPage} from '../../functions/util';

export default function Script() {
  const accountContext = useContext(AccountContext)!;
  const {getAccount, account} = accountContext;
  const {clientScriptFileId, lastVisitorDate} = account || {};
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const scriptTagText = '<script async src="' + config.s3.clientBucketUrl + clientScriptFileId + '.js"></script>';

  useSetNewPage('Installation');

  useEffect(() => {
    if (!lastVisitorDate) {
      const newIntervalId: NodeJS.Timeout = setInterval(() => {
        getAccount();
        if (lastVisitorDate && intervalId) clearInterval(intervalId);
      }, 1000 * 10);
      setIntervalId(newIntervalId);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
    //eslint-disable-next-line
  }, [lastVisitorDate]);

  if (!account) return <LoadingPage />;
  return (
    <MainContentView>
      <AlignCenterScreen wideElement>
        <StyledBox minWidth={'580px'} maxWidth={'600px'} background={brandMain} border={'none'} margin={'0 0 40px 0'}>
          <Text color={'white'}>
            You can skip this step if you're just browsing around. The installation is needed when you want to set
            personalizations live on your website.
          </Text>
        </StyledBox>
        <Headline margin={'0 0 10px 0'}>Installation</Headline>
        <StyledBox minWidth={'580px'} maxWidth={'600px'}>
          <Headline color={'black'} margin={'0 0 10px 0'}>
            1. Copy the javascript tag
          </Headline>
          <Text margin={'0 0 10px 0'}>Here is your javascript snippet to start personalizing your websites.</Text>
          {clientScriptFileId && <CopyableBox>{scriptTagText}</CopyableBox>}
          <Headline color={'black'} margin={'20px 0 10px 0'}>
            {'2. Paste into <head> section of your sites'}
          </Headline>
          <Text margin={'0 0 20px 0'}>
            Embed the code <b>as high as possible</b> into the <b>{'<head>'}</b> section of all the pages you want to
            personalize. OR contact the wizard in your company handling the tech stuff.
          </Text>
          <Headline color={'black'} margin={'0 0 10px 0'}>
            3. Check that the code works
          </Headline>
          <Text margin={'0 0 20px 0'}>
            Once you're done, the status indicator below turns to <b>Active</b> when we have detected the first visitor
            on your site. You can also visit the page yourself or continue with creating segments and check the status
            later.
          </Text>
          <Text margin={'0 0 20px 0'}>You can always contact us to confirm that the tag is on your site.</Text>

          <StyledDivider border margin={'0 0 15px 0'} />
          <Row alignItems={'baseline'} justifyContent={'space-between'}>
            <Row alignItems={'baseline'}>
              <Headline fontSize={'13px'}>Status:</Headline>
              <Activity active={Boolean(lastVisitorDate)} margin={'0 0 0 10px'} />
            </Row>
            <Headline fontSize={'13px'}>
              Last visitor: {lastVisitorDate ? moment(lastVisitorDate).fromNow() : ' ─'}
            </Headline>
          </Row>
        </StyledBox>
      </AlignCenterScreen>
    </MainContentView>
  );
}
