import React from 'react';

import {AlignCenterScreen, Col, StyledBtn, Text} from '@markettailor/common-markettailor';

import {analytics} from '../../functions/analytics';
import {useSignAccountOut} from '../account/SignOut';

export default function ErrorPage(props: {position?: string}) {
  const signOut = useSignAccountOut();

  async function handleSignOut() {
    analytics.track('Signed out after network error');
    await signOut();
  }

  return (
    <AlignCenterScreen position={props.position || 'fixed'}>
      <Col alignItems={'center'} maxWidth={'700px'} minWidth={'300px'}>
        <Text fontSize={'16px'} margin={'10px 0 '} textAlign={'center'}>
          Something went wrong. Please try to refresh the page or send us a message through the chat.
        </Text>
        <StyledBtn warn onClick={handleSignOut}>
          Sign out
        </StyledBtn>
      </Col>
    </AlignCenterScreen>
  );
}
