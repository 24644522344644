import React, {useContext, useEffect} from 'react';

import {IntegrationContext, OAuthCrmsType} from '../../../contexts/IntegrationContext';
import CrmBadge from './CrmBadge';

interface Props {
  integrationName: OAuthCrmsType;
  oauthUrl: string;
  logoWidth: string;
}

export default function OAuthCrmIntegration(props: Props) {
  const {integrationName, logoWidth, oauthUrl} = props;
  useOauthCode(integrationName);

  return (
    <CrmBadge
      integrationName={integrationName}
      logoWidth={logoWidth}
      authorizeCallback={() => (window.location.href = oauthUrl)}
    />
  );
}

const useOauthCode = (integrationName: OAuthCrmsType) => {
  const {authenticateCrmIntegration} = useContext(IntegrationContext)!;

  useEffect(() => {
    const landingParams = sessionStorage.getItem('mt_landingParams') || '';
    const params = new URLSearchParams(landingParams);
    if (params.get('integration') !== integrationName) return;

    sessionStorage.removeItem('mt_landingParams'); // Cleanup

    const code = params.get('code');
    if (typeof code === 'string') authenticateCrmIntegration(integrationName, {code});
    else console.error('An oauth code needs to be present');
    // eslint-disable-next-line
  }, []);
};
