import React from 'react';

import styled from 'styled-components';

interface LogoStyles extends StyledLogoProps {
  width?: string | number;
}

export function Logo(props: LogoStyles) {
  return (
    <StyledLogo {...props}>
      <img src="../markettailor-logo.svg" width={props.width || 100} alt={'Markettailor logo'} />
    </StyledLogo>
  );
}

export function LogoSmall(props: LogoStyles) {
  return (
    <StyledLogo margin={props.margin}>
      <img src="../logoM.png" width={props.width || 20} alt={'Markettailor logo'} />
    </StyledLogo>
  );
}

interface StyledLogoProps {
  margin?: string;
  padding?: string;
  pointer?: boolean;
}

const StyledLogo = styled.div<StyledLogoProps>`
  user-select: none;
  display: flex;
  margin: ${(props) => props.margin || '20px 10px 30px 10px'};
  padding: ${(props) => props.padding || '0'};
  justify-content: center;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'initial')};
`;
