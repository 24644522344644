import React, {FC, useContext, useState} from 'react';

import {Row, StyledSwitch} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../../contexts/SegmentContext';

export const ListModeSwitch: FC = () => {
  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;
  const {updateSegment} = segmentContext;
  const outbound = segments[segmentId].outbound;
  if (!outbound) return null;

  const [state, setState] = useState(outbound.isDynamic);
  const toggleListMode = () => {
    const newState = !state;
    setState(newState);
    updateSegment(segmentId, {outbound: {...outbound, isDynamic: newState}});
  };

  return (
    <>
      <StyledSwitch defaultState={state} onChange={toggleListMode}></StyledSwitch>
    </>
  );
};
