//selectorOption values map to clearbit data fields (see segment_ids.py)
import {zoominfoIndustries} from './data/dataInterfaces';
import zoominfoCountry from './data/zoominfoCountry.json';
import zoominfoState from './data/zoominfoState.json';

interface IOptionsZoominfo {
  companyName: never[];
  website: never[];
  //   street: never[];
  city: never[];
  state: string[];
  zipCode: never[];
  country: string[];
  //   ticker: never[];
  employeeCount: never[];
  revenue: never[];
  industries: string[];
}

export const optionsZoominfo: IOptionsZoominfo = {
  companyName: [],
  website: [],
  //   street: [],
  city: [],
  state: zoominfoState,
  zipCode: [],
  country: zoominfoCountry,
  //   ticker: [],
  employeeCount: [],
  revenue: [],
  industries: zoominfoIndustries,
};
