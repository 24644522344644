import React from 'react';

import {AbsoluteDiv, Col, useIsWidthSmaller} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {PageInsight} from '../../segmentation/sideContainer/audienceInsights/AudienceInsights';
import PageInsightContainer from './PageInsightContainer';

export const PageInsightContainerRenderer = ({
  isInsightsLoading,
  pageInsights,
}: {
  isInsightsLoading: boolean;
  pageInsights: PageInsight[];
}) => {
  const isDesktopLarge = !useIsWidthSmaller(1500);
  const isDesktop = !useIsWidthSmaller(1400);

  if (!isDesktop) return null;
  return (
    <AbsoluteDiv data-intercom-id="page-insights" right={'0'}>
      <Col minWidth={isDesktopLarge ? '500px' : '400px'} maxWidth={isDesktopLarge ? '500px' : '400px'}>
        {isInsightsLoading ? (
          <Skeleton width="400px" height="300px" />
        ) : (
          <PageInsightContainer pageInsights={pageInsights} />
        )}
      </Col>
    </AbsoluteDiv>
  );
};
