import React, {useContext} from 'react';

import {MainContentView, StyledNavTop} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {StripeContext} from '../../../contexts/StripeContext';
import {useSetNewPage} from '../../../functions/util';
import AccountInlineNav from '../AccountInlineNav';
import {EmptyUsage, RenderSkeleton} from './emptyStates';
import {Usage} from './Usage';

export default function UsageContainer() {
  useSetNewPage('Usage');

  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'usage'}>
          <UsageContainerContent />
        </AccountInlineNav>
      </MainContentView>
    </>
  );
}

const UsageContainerContent = () => {
  const {usage, isLoading} = useContext(StripeContext)!.state;

  if (isLoading) return <RenderSkeleton />;
  if (isEmpty(usage)) return <EmptyUsage />;
  return <Usage />;
};
