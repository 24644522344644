import React, {useContext} from 'react';

import Select from 'react-select';

import {EditorContext} from '../../../contexts/EditorContext';
import type {EditorModeType} from '../../../contexts/editorContextTypes';
import {customStyles} from '../util';

const editorModeOptions: {name: EditorModeType; label: string}[] = [
  {name: 'edit', label: 'Edit page'},
  {name: 'original', label: 'Show original'},
];

export function EditorMode() {
  const editorContext = useContext(EditorContext)!;
  const {changeEditorMode} = editorContext;
  const {editorMode} = editorContext;

  const currentValue = editorModeOptions.filter((elem) => elem.name === editorMode)[0];

  return (
    <Select
      id="editorModeSelector"
      isSearchable={false}
      styles={customStyles}
      options={editorModeOptions}
      value={currentValue}
      onChange={(option) => changeEditorMode(option?.name)}
    />
  );
}
