import React from 'react';

import {
  IValueLabel,
  ICrmIntegrations,
  ICrmIntegration,
  ICrmObject,
  ICrmSelectionOption,
  LockIcon,
  Row,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import LogoIcon from '../../../styles/logoIcons';
import {ISelectOptionsWithHeadline, Mapping} from '../segmentInterfaces';

export function getSelectorsCRM(
  integrationName: keyof ICrmIntegrations,
  crmIntegration: ICrmIntegration | undefined
): ISelectOptionsWithHeadline[] {
  if (!(crmIntegration?.isActive && crmIntegration?.options)) return [];
  const options: ISelectOptionsWithHeadline[] = crmIntegration.options.map((crmObject: ICrmObject) => {
    return {
      label: `${capitalize(integrationName)} (${crmObject.objectType})`,
      options: crmObject.objectOptions.map((field: ICrmSelectionOption) => {
        return {
          value: field.selectorValueLabel[0],
          label: field.selectorValueLabel[1],
          icon: <CRMIcon integrationName={integrationName} />,
        };
      }),
    };
  });
  return options;
}

export function getSelectorOptionsCRM(selector: string, crmIntegration: ICrmIntegration | undefined): IValueLabel[] {
  if (!(crmIntegration?.isActive && crmIntegration?.options)) return [];
  const valueRange: IValueLabel[] = crmIntegration.options
    .map((crmObject: ICrmObject) => crmObject.objectOptions)
    .flat()
    .filter((field: ICrmSelectionOption) => field.selectorValueLabel[0] === selector)
    .map((field) => field.options)
    .flat();
  return valueRange;
}

export function getSelectorMappingCRM(crmIntegration: ICrmIntegration | undefined) {
  if (!(crmIntegration?.isActive && crmIntegration?.options)) return {};
  const mapping: Mapping = crmIntegration.options
    .map((crmObject: ICrmObject) => crmObject.objectOptions)
    .flat()
    .map((field: ICrmSelectionOption) => {
      return {[field.selectorValueLabel[0]]: field.selectorValueLabel[1]};
    })
    .reduce((oldObject: Mapping, keyValue: Mapping) => ({...oldObject, ...keyValue}), {});
  return mapping;
}

const CRMIcon = ({integrationName}) => {
  return (
    <LogoIcon
      logoHeight={'15px'}
      logoWidth={'15px'}
      logoSrc={`../${integrationName}-small-logo.svg`}
      logoAlt={`${capitalize(integrationName)} small logo`}
    />
  );
};
