import React, {useContext} from 'react';

import {Headline, Row, Col, useIsWidthSmaller} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {AccountContext} from '../../contexts/AccountContext';
import {RevealContext} from '../../contexts/RevealContext';
import {useHasPermission} from '../../contexts/util';
import {useSetNewPage} from '../../functions/util';
import {RevealSkeleton, EmptyRevealCompanies, NoPermission} from './emptyStates';
import RevealCSVExport from './RevealCSVExport';
import RevealInsights from './RevealInsights';
import RevealTable from './RevealTable';

export default function CompanyReveal() {
  const {isLoading: accountIsLoading} = useContext(AccountContext)!;
  const {companies, isLoading: revealIsLoading} = useContext(RevealContext)!;
  const isMedium = useIsWidthSmaller(1250);

  useSetNewPage('Company Reveal');

  const hasPermission = useHasPermission('companyReveal');
  if (accountIsLoading || revealIsLoading) return <RevealSkeleton />;
  if (!hasPermission) return <NoPermission />;
  if (isEmpty(companies)) return <EmptyRevealCompanies />;
  return (
    <Row alignItems="start" justifyContent="space-between">
      <Col>
        <Row alignItems="center" justifyContent="space-between" margin="0 0 10px 0">
          <Headline margin="0 30px 0 0">Companies</Headline>
          <RevealCSVExport />
        </Row>
        <div data-intercom-id="reveal-table">
          <RevealTable />
        </div>
      </Col>
      {!isMedium && (
        <div data-intercom-id="reveal-insights">
          <RevealInsights />
        </div>
      )}
    </Row>
  );
}
