import React from 'react';

import {Col, Row, Headline} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import EmptyScreen from '../common/EmptyScreen';

export function RenderSkeleton() {
  return (
    <Row>
      <Col margin={'0 40px 0 0'}>
        <Skeleton width={250} height={260} />
      </Col>
    </Row>
  );
}

export function EmptyEvents() {
  return (
    <EmptyScreen>
      <Headline>
        Looks like you don't have any conversion events yet. Go back to create some or integrate a conversion tracking
        tool.
      </Headline>
    </EmptyScreen>
  );
}
