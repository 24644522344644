import React, {useContext} from 'react';

import {InfoCircleIcon, Row, StyledBtn, Tooltip} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {IntegrationContext} from '../../../contexts/IntegrationContext';
import '../../../styles/editor/editorStyles.css';

interface Props {
  handleClose: () => void;
}

export const ReplaceWithLogo = ({handleClose}: Props) => {
  const {changeElemContent, selectedElem} = useContext(EditorContext)!;
  const {integrations} = useContext(IntegrationContext)!;

  const replaceWithLogo = () =>
    changeElemContent(selectedElem.domElemId, {
      src: '{{mt_logo}}',
    });

  const onClick = () => {
    replaceWithLogo();
    handleClose();
  };

  const isClearbitActive = integrations.clearbit?.isActive;

  const tooltipContent = isClearbitActive
    ? "Replace image automatically with an identified company's logo"
    : "Add Company Lookup integration or upgrade your plan to replace image automatically with an identified company's logo";

  return (
    <StyledBtn disabled={!isClearbitActive} onClick={onClick} minWidth="190px" margin="10px 30px">
      <Row>
        Use company logo{' '}
        <Tooltip content={tooltipContent}>
          <InfoCircleIcon margin="0 0 0 10px" color="white" />
        </Tooltip>
      </Row>
    </StyledBtn>
  );
};
