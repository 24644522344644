import React from 'react';

import {Col, StyledBtn, Text, Row} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

export function EmptyUsage() {
  const history = useHistory();
  return (
    <Col height={'200px'} maxWidth={'400px'} margin={'0 0 0 100px '} alignItems={'center'} justifyContent={'flex-end'}>
      <Text textAlign={'center'} margin={'0 0 10px 0 '}>
        You don't have any usage data yet. Make sure you have have followed the installation instructions and check back
        after a while.
      </Text>
      <StyledBtn
        onClick={() => {
          history.push('/installation');
        }}
      >
        To installation page
      </StyledBtn>
    </Col>
  );
}

export function RenderSkeleton() {
  return (
    <Row margin="20px 0 0 0">
      <Skeleton width={600} height={300} />
      <Col margin={'0 0 0 40px'}>
        <Skeleton width={250} height={250} />
      </Col>
    </Row>
  );
}
