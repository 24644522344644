import React, {MouseEvent} from 'react';

import {Col} from '@markettailor/common-markettailor';
import {Auth} from 'aws-amplify';

import './googleButton.css';

export default function GoogleSignInButton({label}: {label?: string}) {
  return (
    <Col alignItems={'center'}>
      <GoogleButton onClick={signInGoogle} label={label} />
    </Col>
  );
}

const signInGoogle = async () => {
  // @ts-ignore
  Auth.federatedSignIn({provider: 'Google'}); // Redirects to AWS OAuth flow
};

interface Props {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  label?: string;
}
const GoogleButton = ({onClick, label}: Props) => (
  <div
    className="g-sign-in-button"
    onClick={onClick}
    style={{borderRadius: '5px', padding: '2px', width: '205px', margin: '0 0 25px 0'}}
  >
    <div className="content-wrapper">
      <div className="logo-wrapper">
        <img src="/google-logo.svg" />
      </div>
      <span className="text-container" style={{fontSize: '13px'}}>
        {label || 'Sign in with Google'}
      </span>
    </div>
  </div>
);
