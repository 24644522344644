import React, {useContext} from 'react';

import {Headline, Text} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {ConversionAnalyticsContext, IConversionRateLiftSummary} from '../../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext} from '../../../contexts/ConversionManagementContext';
import {formatPercent} from '../../../functions/util';
import {getSegmentConversionRateLift} from '../util';

export const ConversionRateLift = ({segmentId}) => {
  const {conversions, isLoading: conversionsIsLoading} = useContext(ConversionAnalyticsContext)!;
  const mainConversionMetric = useContext(ConversionManagementContext)?.mainConversionMetric || '';

  if (conversionsIsLoading) return <Skeleton width={50} height={30} />;
  const conversionRateLift = formatPercent(
    getSegmentConversionRateLift(segmentId, mainConversionMetric, conversions),
    0
  );

  return (
    <>
      <Headline color="#000">{conversionRateLift}</Headline>
      <Text fontSize="10px" textAlign="center" lineHeight="16px">
        {getHelperText()}
      </Text>
    </>
  );
};

function getHelperText(conversionRateLiftSummary?: IConversionRateLiftSummary) {
  if (conversionRateLiftSummary?.isSignificant)
    return (
      <Text fontSize="10px" textAlign="center" lineHeight="16px">
        Statistically significant
      </Text>
    );

  const dayEstimate = conversionRateLiftSummary?.timeEstimateToIsSignificant;
  const weekEstimate = dayEstimate ? dayEstimate / 7 : undefined;

  if (weekEstimate)
    return (
      <Text fontSize="10px" textAlign="center" lineHeight="16px">
        <strong>{weekEstimate + ' weeks'}</strong>
        {' to significance'}
      </Text>
    );
  return null;
}
