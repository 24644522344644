import React from 'react';

import {Col, Headline, StyledBtn, AlignCenterScreen} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

import EmptyScreen from '../common/EmptyScreen';

export const EmptyPages = ({hasPageInsights, handleClick}: {hasPageInsights: boolean; handleClick: () => void}) => {
  return (
    <AlignCenterScreen>
      <Col alignItems={'center'}>
        <Col margin="20px 0 60px 0">
          <img src="./emptyPagesInSegment.svg" width="600px" alt="emptyPagesInSegment" />
        </Col>
        <Headline textAlign={'center'} margin={'0 0 5px 0'}>
          Looks like this segment doesn't have any pages yet. Add first one now.
        </Headline>
        {hasPageInsights && (
          <Headline textAlign={'center'} margin={'0 0 20px 0'}>
            Or pick one from the Page Insights.
          </Headline>
        )}
        <StyledBtn onClick={handleClick}>Add a new page</StyledBtn>
      </Col>
    </AlignCenterScreen>
  );
};

export const EmptySegments = () => {
  const history = useHistory();
  return (
    <EmptyScreen imageSrc="./emptySegmentsPagesView.svg">
      <>
        <Headline margin="0 0 10px 0">
          Segments can have multiple pages, but it looks like you don't have any segments yet!
        </Headline>
        <Headline margin="0 0 10px 0">Create your first segment before checking it's pages.</Headline>
        <StyledBtn onClick={() => history.push('/segments')}>Go to Segments</StyledBtn>
      </>
    </EmptyScreen>
  );
};

export const NoSegmentSelected = () => {
  return (
    <AlignCenterScreen>
      <Col alignItems={'center'}>
        <Headline textAlign={'center'} margin={'0 0 5px 0'}>
          Select a segment to see its pages.
        </Headline>
      </Col>
    </AlignCenterScreen>
  );
};

export const RenderSkeleton = () => {
  return (
    <>
      <Col margin={'0 0 120px 0'}>
        <Skeleton width={200} height={100} />
      </Col>
      <Skeleton width={1000} height={160} />
    </>
  );
};
