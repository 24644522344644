import React from 'react';

import {StyledBtn} from '@markettailor/common-markettailor';
import {ExportToCsv} from 'export-to-csv';

type CSVData = object[];

interface Props {
  data: CSVData;
  filename?: string;
  disabled?: boolean;
  options?: object;
  buttonText?: string;
  buttonStyles?: object;
}

export default function CSVExport({
  data,
  disabled = false,
  filename = 'Markettailor export',
  buttonText = 'Export',
  buttonStyles = {margin: '0'},
  options = {},
}: Props) {
  const allOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    ...options,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const unifyDataKeys = (data) => {
    const allKeys = [
      ...data.reduce((acc, item) => {
        const keys = Object.keys(item);
        return new Set([...acc, ...keys]);
      }, []),
    ];

    const allKeysObj = allKeys.reduce((acc, key) => ({...acc, [key]: ''}), {});
    const unifiedData = data.map((item) => {
      return {...allKeysObj, ...item};
    });
    return unifiedData;
  };

  const assignEmptyFieldsToStr = (data) => {
    function removeEmpty(obj) {
      return Object.entries(obj).reduce((acc, [k, v]) => {
        if (v === null || v === undefined) v = '';
        return {...acc, [k]: v};
      }, {});
    }

    return data.map((item) => {
      return removeEmpty(item);
    });
  };

  const handleExport = () => {
    const unifiedData = unifyDataKeys(data);
    const exportData = assignEmptyFieldsToStr(unifiedData);
    const csvExporter = new ExportToCsv(allOptions);
    csvExporter.generateCsv(exportData);
  };

  return (
    <StyledBtn onClick={handleExport} disabled={disabled} {...buttonStyles}>
      {buttonText}
    </StyledBtn>
  );
}
