import {brandMain, disabledColor} from '@markettailor/common-markettailor';
import styled from 'styled-components';

export const StyledEditor = styled.div<any>`
  display: flex;
  width: 100%;
  background: #f2f2f2;
  height: calc(100vh - 70px);
`;

export const StyledViewbox = styled.div<any>`
  margin: 20px 30px 20px 20px;
  height: 92vh;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
`;

export const StyledViewboxNoPage = styled.div<any>`
  margin: 20px 30px 20px 20px;
  height: 92vh;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSideControls = styled.div<any>`
  background: white;
  min-width: 250px;
  max-width: 250px;
  border-left: 1px solid lightGray;
  display: flex;
  flex-direction: column;
  padding: 0 5px 100px 0;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTextBox = styled.textarea<any>`
  border: 1px solid lightGray;
  min-height: ${(props) => props.height || '80px'};
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  padding: 4px;
  margin: ${(props) => props.margin || '0px 5px'};
  background-color: ${(props) => (props.disabled ? disabledColor : props.background || 'white')};
`;

export const StyledEditorText = styled.div<any>`
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0 5px 0;
  padding: 0 10px 0 10px;
  user-select: none;
`;

export const StyledNav = styled.div<any>`
  background: linear-gradient(90deg, ${brandMain}, #060710);
  height: 70px;
  width: 100%;
  display: flex;
`;

export const PageTitle = styled.p<any>`
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin: ${(props) => props.margin || '0 0 5px 0'};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'initial')};
  &:hover {
    text-decoration: ${(props) => (props.underline ? 'underline' : 'initial')};
  }
`;

export const StyledInputEditor = styled.input<any>`
  border: ${(props) => props.border || '1px solid hsl(0, 0%, 80%)'};
  background: none;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  border-radius: 4px;
  padding:  ${(props) => props.padding || '0 8px'};
  height: : 28px;
  font-size: ${(props) => props.fontSize || '12px'};
  color: ${(props) => props.color || 'black'};
  margin: ${(props) => props.margin || '0'};
  min-width: ${(props) => props.minWidth || '40px'};
  max-width: ${(props) => props.maxWidth || '70px'};
  align-self: ${(props) => props.alignSelf || 'initial'};
  &:focus {
    outline: none;
    text-decoration: none;
  }
`;

export const StyledColorBox = styled.div<any>`
  border: 1px solid lightGray;
  height: 25px;
  width: 25px;
  margin: 0 5px 0 0;
  border-radius: 6px;
  background-color: ${(props) => (props.isDisabled ? disabledColor : props.color ? '#' + props.color : 'black')};
  cursor: ${(props) => (props.isDisabled ? 'initial' : 'pointer')};
`;

export const IconBox = styled.div<any>`
  margin: ${(props) => props.margin || '0'};
`;

export const BottomContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  background: white;
  padding: ${(props) => props.margin || '0 20px 0 0'};
  margin: ${(props) => props.margin || '0'};
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  box-shadow: 0px -8px 10px rgb(0 0 0 / 30%);
`;
