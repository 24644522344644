import React, {useContext, useEffect} from 'react';

import {
  Col,
  Headline,
  NumberLarge,
  Row,
  StyledBox,
  Text,
  VerticalDiv,
  WarningIcon,
  QuestionMarkIcon,
  Tooltip,
  yellow,
  lightGray,
  darkYellow,
  brandMain,
  darkGray,
} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {ConversionAnalyticsContext} from '../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';
import {saveSummaryAnalytics} from '../../functions/analytics';
import {formatPercent, formatNum} from '../../functions/util';
import {
  useGetTotalNonPersonalizedVisitors,
  getControlVisitors,
  useGetTotalConversionRateLift,
  useGetTotalPersonalizedVisitors,
  useIsConversionTrackingEnabled,
} from './util';

export default function SegmentsSummary() {
  const {isLoading, conversions} = useContext(ConversionAnalyticsContext)!;
  const nonPersonalizedVisitors = useGetTotalNonPersonalizedVisitors();
  const personalizedVisitors = useGetTotalPersonalizedVisitors();
  const controlVisitors = getControlVisitors(conversions);
  const totalConversionRateLift = useGetTotalConversionRateLift();

  useEffect(() => {
    !isLoading && saveSummaryAnalytics({nonPersonalizedVisitors, personalizedVisitors, totalConversionRateLift});
    //eslint-disable-next-line
  }, [isLoading]);

  return (
    <Row displayColumnMedium={true}>
      <PersonalizationSummary
        personalizedVisitors={personalizedVisitors}
        controlVisitors={controlVisitors}
        totalConversionRateLift={totalConversionRateLift}
      />
      <PersonalizationOpportunity nonPersonalizedVisitors={nonPersonalizedVisitors} />
    </Row>
  );
}
const PersonalizationSummary = ({personalizedVisitors, controlVisitors, totalConversionRateLift}) => {
  return (
    <Col data-intercom-id="personalization-summary" margin={'0 40px 0 0'}>
      <Headline margin={'0 0 10px 0'}>Personalization summary</Headline>
      <StyledBox padding={'30px'} margin={'0 0 40px 0 '}>
        <Row>
          <Col>
            <Headline margin={'0 0 10px 0'}>Personalized visitors</Headline>
            <TotalVisitorCount visitorCount={personalizedVisitors} />
            <Text fontSize="10px" lineHeight="16px">
              {`vs ${controlVisitors || 0} in control`}
            </Text>
          </Col>
          <VerticalDiv />
          <Col data-intercom-id="personalization-summary-lift">
            <Headline margin={'0 0 10px 0'}>Conversion rate lift</Headline>
            <TotalConversionRateLift totalConversionRateLift={totalConversionRateLift} />
          </Col>
        </Row>
      </StyledBox>
    </Col>
  );
};

const PersonalizationOpportunity = ({nonPersonalizedVisitors}) => {
  return (
    <Col data-intercom-id="personalization-opportunity">
      <Headline margin={'0 0 10px 0 '}>Opportunity</Headline>
      <StyledBox padding={'30px'} margin={'0 0 40px 0'} width="fit-content">
        <Col>
          <Headline margin={'0 0 10px 0'}>Non-personalized visitors</Headline>
          <TotalVisitorCount visitorCount={nonPersonalizedVisitors} />
        </Col>
      </StyledBox>
    </Col>
  );
};

const TotalVisitorCount = ({visitorCount}) => {
  const {isLoading} = useContext(ConversionAnalyticsContext)!;
  if (isLoading) return <Skeleton width={100} height={30} />;
  return (
    <Row alignItems={'baseline'}>
      <NumberLarge margin={'0 5px 0 0'}>{formatNum(visitorCount)}</NumberLarge>
      <Text>Visitors</Text>
    </Row>
  );
};

const TotalConversionRateLift = ({totalConversionRateLift}) => {
  const {mainConversionMetric, events} = useContext(ConversionManagementContext)!;
  const {isLoading} = useContext(ConversionAnalyticsContext)!;
  const conversionTrackingEnabled = useIsConversionTrackingEnabled();
  const mainConversionMetricExists = Boolean(mainConversionMetric && mainConversionMetric in events);
  if (isLoading) return <Skeleton width={100} height={30} />;
  return (
    <Row alignItems={'center'}>
      <NumberLarge
        margin={'0 10px 0 0'}
        color={conversionTrackingEnabled && mainConversionMetricExists ? brandMain : darkGray}
      >
        {formatPercent(totalConversionRateLift)}
      </NumberLarge>
      {conversionTrackingEnabled && mainConversionMetricExists ? (
        <Tooltip content="The total conversion rate lift is shown only for your main conversion event set in the Manage Conversions tab.">
          <Row>
            <QuestionMarkIcon width="18px" color={lightGray} />
          </Row>
        </Tooltip>
      ) : (
        <Tooltip
          content={
            !conversionTrackingEnabled
              ? 'Set up conversion tracking to see the total performance of personalization.'
              : 'Select a main conversion event from the Manage Conversions to see personalization results here.'
          }
        >
          <Row>
            <WarningIcon color={darkYellow} $hoverColor={yellow} width="20px" />
          </Row>
        </Tooltip>
      )}
    </Row>
  );
};
