//selectorOption values map to clearbit data fields (see segment_ids.py)
import {
  clearbitTech,
  IClearbitTechCategory,
  clearbitIndustryGroup,
  clearbitIndustry,
  clearbitSubIndustry,
  clearbitCompanyTags,
} from './data/dataInterfaces';

interface IOptionsClearbit {
  estimatedAnnualRevenue: string[];
  employeesRange: string[];
  sector: string[];
  industryGroup: string[];
  industry: string[];
  subIndustry: string[];
  companyTags: string[];
  businessClassification: string[];
  companyType: string[];
  foundedYear: string[];
  tech: IClearbitTechCategory;
  name: never[];
  domain: never[];
}

export const optionsClearbit: IOptionsClearbit = {
  name: [],
  domain: [],
  estimatedAnnualRevenue: [
    '$0-$1M',
    '$1M-$10M',
    '$10M-$50M',
    '$50M-$100M',
    '$100M-$250M',
    '$250M-$500M',
    '$500M-$1B',
    '$1B-$10B',
    '$10B+',
  ],
  employeesRange: ['1-10', '11-50', '51-250', '251-1K', '1K-5K', '5K-10K', '10K-50K', '50K-100K', '100K+'],
  sector: [
    'Consumer Discretionary',
    'Consumer Staples',
    'Energy',
    'Financials',
    'Health Care',
    'Industrials',
    'Information Technology',
    'Materials',
    'Telecommunication Services',
    'Utilities',
  ],
  industryGroup: clearbitIndustryGroup,
  industry: clearbitIndustry,
  subIndustry: clearbitSubIndustry,
  companyTags: clearbitCompanyTags,
  businessClassification: ['B2B', 'B2C', 'E-commerce', 'Enterprise', 'ISP', 'Marketplace', 'Mobile', 'SAAS'],
  companyType: ['Education', 'Government', 'Nonprofit', 'Private', 'Public', 'Personal'],
  foundedYear: ['2018-2020', '2014-2017', '2010-2013', '2000-2009', '1990-1999', '1980-1989', '1979'],
  tech: clearbitTech,
};
