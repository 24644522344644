import React from 'react';

import {Row} from '@markettailor/common-markettailor';

import {BottomContainer} from '../../../styles/editor/editorStyles';
import ImageUpload from './ImageUpload';
import {ReplaceWithLogo} from './ReplaceWithLogo';

interface Props {
  handleClose: () => void;
}

export const ImageGalleryBottom = ({handleClose}: Props) => {
  return (
    <BottomContainer>
      <Row>
        <ReplaceWithLogo handleClose={handleClose} />
        <ImageUpload />
      </Row>
    </BottomContainer>
  );
};
