import React, {useEffect, useState} from 'react';

import axios from 'axios';

import config from '../../../config.json';

interface technologyMapValueLabel {
  webTechTags: {
    value: string;
    label: string;
  }[];
  techTagCategories: {
    value: string;
    label: string;
  }[];
}

export function useTechnologyOptions() {
  const source = axios.CancelToken.source();

  const [technologyMapValueLabel, setTechnologyMapValueLabel] = useState<technologyMapValueLabel>({
    webTechTags: [],
    techTagCategories: [],
  });

  useEffect(() => {
    const getTechnologyMap = async () => {
      try {
        const response = await axios.get(config.api.baseURL + 'enrichment/properties', {cancelToken: source.token});
        const technologyMap: {[key: string]: string[]} = response.data.data;

        const toValueLabel = (val: string) => ({value: val, label: val});
        const technologyMapValueLabel = {
          webTechTags: Object.values(technologyMap).flat().sort().map(toValueLabel),
          techTagCategories: Object.keys(technologyMap).sort().map(toValueLabel),
        };
        setTechnologyMapValueLabel(technologyMapValueLabel);
      } catch (e) {
        if (axios.isCancel(e)) return;
        throw e;
      }
    };
    getTechnologyMap();

    return () => source.cancel('useTechnologyOptions unmounted');
  }, []);

  return technologyMapValueLabel;
}
