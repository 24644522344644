import React, {useState, useContext, useEffect} from 'react';

import {Col, MainContentView, StyledNavTop} from '@markettailor/common-markettailor';

import {AccountContext} from '../../contexts/AccountContext';
import {ConversionAnalyticsContext} from '../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';
import {useSetNewPage} from '../../functions/util';
import {checkRenderEmptyScreen, ConversionSkeleton} from './emptyStates';
import NavigateManageButton from './NavigateManageButton';
import NewConversionGoalPopover from './NewConversionGoalPopover';
import ConversionTable from './table/ConversionTable';
import {NewConversionGoalBtn} from './util';

export default function ConversionContainer() {
  useSetNewPage('Conversions');

  return (
    <>
      <StyledNavTop>
        Conversions
        <NavigateManageButton />
      </StyledNavTop>
      <MainContentView>
        <ContainerContent />
      </MainContentView>
    </>
  );
}

const ContainerContent = () => {
  const {isLoading: accountIsLoading} = useContext(AccountContext)!;
  const {events} = useContext(ConversionManagementContext)!;
  const {conversions, isLoading: conversionsIsLoading, activeIntegration} = useContext(ConversionAnalyticsContext)!;
  const [eventCardsIsOpen, setEventCardsIsOpen] = useState<boolean>(false);

  if (conversionsIsLoading || accountIsLoading) return <ConversionSkeleton />;
  const emptyView = checkRenderEmptyScreen(events, activeIntegration, conversions, setEventCardsIsOpen);
  return (
    <Col>
      <NewConversionGoalPopup
        eventCardsIsOpen={eventCardsIsOpen}
        activeIntegration={activeIntegration}
        setEventCardsIsOpen={setEventCardsIsOpen}
      />
      {emptyView || (
        <>
          <ConversionTable />
          <Col data-intercom-id="new-conversion-button" margin="10px 0 0 0">
            <NewConversionGoalBtn setEventCardsIsOpen={setEventCardsIsOpen} />
          </Col>
        </>
      )}
    </Col>
  );
};

const NewConversionGoalPopup = ({eventCardsIsOpen, activeIntegration, setEventCardsIsOpen}) => {
  if (!eventCardsIsOpen || activeIntegration === 'googleAnalytics') return null;
  return <NewConversionGoalPopover eventCardsIsOpen={eventCardsIsOpen} setEventCardsIsOpen={setEventCardsIsOpen} />;
};
