import React, {useContext} from 'react';

import {ISegment, IValueLabel, getSelectCustomStyles} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {SegmentContext} from '../../contexts/SegmentContext';

export default function SegmentDropdown(): JSX.Element {
  const segmentContext = useContext(SegmentContext)!;
  const {segments, segmentId} = segmentContext!.state;
  const {setSegmentId} = segmentContext;
  const selectedSegment = {
    value: segmentId,
    label: segments[segmentId]?.segmentName || 'Unnamed segment',
  };

  function getSegmentLabels(): IValueLabel[] {
    return Object.values(segments)
      .map((segment: ISegment) => {
        return {value: segment.segmentId, label: segment.segmentName || 'Unnamed segment'};
      })
      .sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
  }

  interface ISelection {
    value: string;
    label?: string;
  }
  function handleSelect(selection: ISelection | null) {
    if (!selection || !selection.value) return;
    setSegmentId(selection.value);
  }

  return (
    <Select
      isSearchable={false}
      styles={getSelectCustomStyles({
        fontSize: 14,
        control: {width: 250},
      })}
      options={getSegmentLabels()}
      value={segmentId ? selectedSegment : undefined}
      onChange={handleSelect}
      placeholder="Select a segment"
    />
  );
}
