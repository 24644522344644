import React, {useState, ChangeEvent} from 'react';

import {Col, Headline, StyledBtn, Text, brandMain} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

interface Props {
  title: string;
  handleSubmit: (apiKey: string) => void;
}

export default function OptionsWindowApiKey(props: Props): JSX.Element {
  const [apiKey, setApiKey] = useState<string>('');

  return (
    <Col minWidth={'500px'} alignItems={'center'}>
      <Headline color={brandMain}>{props.title + ' integration'}</Headline>
      <Text margin={'20px 0 40px 50px '}>Set your {props.title} API key to integrate with Markettailor</Text>
      <Col alignItems={'center'}>
        <TextField
          required
          id="apiKey"
          label="API key"
          name={'apiKey'}
          type={'text'}
          variant={'outlined'}
          value={apiKey}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setApiKey(e.target.value.trim());
          }}
        />
        <StyledBtn onClick={() => props.handleSubmit(apiKey)}>Integrate</StyledBtn>
      </Col>
    </Col>
  );
}
