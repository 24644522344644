import React, {useContext} from 'react';

import {MainContentView, StyledNavTop, Col} from '@markettailor/common-markettailor';

import {RecommendationContext, RecommendationType} from '../../contexts/RecommendationContext';
import {useSetNewPage} from '../../functions/util';
import {EmptyRecommendations} from './emptyStates';
import Recommendation from './Recommendation';
import RecommendedSegmentsSkeleton from './RecommendedSegmentsSkeleton';
import SegmentationInlineNav from './SegmentationsInlineNav';

export default function RecommendedSegments() {
  useSetNewPage('Recommended segments');

  return (
    <>
      <StyledNavTop>Recommended segments</StyledNavTop>
      <MainContentView>
        <Col margin="0 0 20px 0">
          <SegmentationInlineNav currentComponent="recommended-segments" />
        </Col>
        <ContainerContent />
      </MainContentView>
    </>
  );
}

const ContainerContent = () => {
  const {recommendations, isLoading} = useContext(RecommendationContext)!;

  if (isLoading) return <RecommendedSegmentsSkeleton />;
  if (recommendations.length === 0) return <EmptyRecommendations />;
  return (
    <>
      {recommendations.map((recommendation: RecommendationType, index: number) => (
        <Recommendation key={index} recommendation={recommendation} />
      ))}
    </>
  );
};
