import React, {useContext, useState} from 'react';

import {
  Col,
  Headline,
  Row,
  StyledBox,
  StyledBtn,
  StyledDivider,
  Text,
  PopoverWindow,
  NotificationContext,
} from '@markettailor/common-markettailor';
import {PaymentMethod} from '@stripe/stripe-js';
import {capitalize} from 'lodash';

import {StripeContext} from '../../../contexts/StripeContext';
import PaymentInformationForm from './paymentInformation/PaymentInformationForm';

export default function Payment() {
  const {stripe} = useContext(StripeContext)!.state;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [renderPaymentOptions, setPaymentOptions] = useState(false);

  return (
    <Col minWidth={'240px'}>
      <PopoverWindow display={renderPaymentOptions} handleClose={() => setPaymentOptions(false)} boxMinWidth={'500px'}>
        <PaymentInformationForm
          handleClose={() => {
            setInfoNotification({message: 'Payment information updated!'});
            setPaymentOptions(false);
          }}
        />
      </PopoverWindow>
      <Headline margin={'0 0 10px 0'}>Payment information</Headline>
      <PaymentInformationDetailsBox paymentMethod={stripe?.paymentMethod} taxId={stripe?.taxId}>
        <Col alignItems={'center'}>
          <StyledBtn onClick={() => setPaymentOptions(true)}>Update payment information</StyledBtn>
        </Col>
      </PaymentInformationDetailsBox>
    </Col>
  );
}

const PaymentInformationDetailsBox = ({paymentMethod, taxId, children}): JSX.Element => {
  return (
    <StyledBox maxWidth={'250px'} minWidth={'250px'} margin={'0 0 50px 0'} padding={'30px'} minHeight={'270px'}>
      <Col height={'65px'}>
        <Text margin={'0 0 10px 0'} color={'black'} fontWeight={600}>
          Payment method
        </Text>
        <CardDetails paymentMethod={paymentMethod} />
      </Col>
      <StyledDivider border margin={'20px 0'} />
      <Col height={'65px'}>
        <Text margin={'0 0 10px 0'} color={'black'} fontWeight={600}>
          Company info
        </Text>
        <Row justifyContent={'space-between'}>
          <Text margin={'0 10px 0 0'}>{taxId ? 'Tax ID:' : 'No tax ID set'}</Text>
          <Text fontWeight={600}>{taxId}</Text>
        </Row>
      </Col>
      <StyledDivider border margin={'20px 0'} />
      {children}
    </StyledBox>
  );
};

const CardDetails = ({paymentMethod}: {paymentMethod: PaymentMethod | undefined}): JSX.Element => {
  if (!paymentMethod) return <Text margin={'0 0 10px 0'}>No payment method added</Text>;
  return (
    <>
      <Row justifyContent={'space-between'}>
        <Text margin={'0 10px 10px 0'}>Card:</Text>
        <Text margin={'0 0 10px 0'} fontWeight={600}>
          {capitalize(paymentMethod.card?.brand) + ' ending **' + paymentMethod.card?.last4}
        </Text>
      </Row>
      <Row justifyContent={'space-between'}>
        <Text margin={'0 10px 10px 0'}>Expiration:</Text>
        <Text margin={'0 0 10px 0'} fontWeight={600}>
          {paymentMethod.card?.exp_month + '/' + paymentMethod.card?.exp_year}
        </Text>
      </Row>
    </>
  );
};
