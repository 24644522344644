import React, {useContext, useState, FormEvent, ChangeEvent} from 'react';

import {
  StyledBtn,
  AuthContext,
  NotificationContext,
  Col,
  Headline,
  StyledBox,
  StyledNavTop,
  MainContentView,
} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

import {useStyles} from '../../styles/common/formInputStyles';
import AccountInlineNav from './AccountInlineNav';

export const ChangePasswordPage = () => {
  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'change-password'}>
          <Col margin="20px 0 0 0">
            <ChangePasswordFormContainer />
          </Col>
        </AccountInlineNav>
      </MainContentView>
    </>
  );
};

const ChangePasswordFormContainer = () => {
  return (
    <>
      <Col>
        <Headline margin="0 0 5px 0">Change password</Headline>
        <StyledBox maxWidth={'400px'} height="fit-content">
          <ChangePasswordForm />
        </StyledBox>
      </Col>
    </>
  );
};

function ChangePasswordForm() {
  const {handleChangePassword} = useContext(AuthContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const classes = useStyles();
  const emptyState = {
    oldPasswd: '',
    newPasswd: '',
  };
  const [state, setState] = useState(emptyState);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (state.newPasswd) {
      await handleChangePassword(state.oldPasswd, state.newPasswd);
      setInfoNotification({message: 'Password changed!'});
      setState(emptyState);
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setState((prevState) => {
      return {...prevState, [e.target.name]: e.target.value};
    });
  }

  return (
    <form id={'passwordChange'} className={classes.root} autoComplete="off" action={'#'} onSubmit={handleSubmit}>
      <div>
        <TextField
          required
          id="oldPasswd"
          label="Old password"
          name={'oldPasswd'}
          type={'password'}
          value={state.oldPasswd}
          onChange={handleChange}
        />
        <TextField
          required
          id="newPasswd"
          label="New password"
          name={'newPasswd'}
          type={'password'}
          value={state.newPasswd}
          onChange={handleChange}
        />
      </div>
      <StyledBtn onClick={handleSubmit} disabled={!(state.oldPasswd && state.newPasswd)}>
        Save
      </StyledBtn>
    </form>
  );
}
