import config from './config.json';

export const awsConfig = {
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.OAUTH_DOMAIN,
      redirectSignIn: config.cognito.OAUTH_REDIRECT_URL,
      redirectSignOut: config.cognito.OAUTH_REDIRECT_URL,
      responseType: 'code',
    },
  },
};
