import React, {useContext, useState, FormEvent, ChangeEvent} from 'react';

import {
  StyledBtn,
  NotificationContext,
  Text,
  Col,
  Tooltip,
  Headline,
  StyledBox,
  darkGray,
} from '@markettailor/common-markettailor';

import {AccountContext} from '../../../contexts/AccountContext';
import {StyledTextBox} from '../../../styles/editor/editorStyles';

export function ExcludeUrlsContainer() {
  const TooltipContent = (
    <>
      <Text margin="8px 0">If the website URL contains the rule, the page is not personalized.</Text>
      <Text margin="8px 0">
        If you write */blog/*, the visitor is not personalized on any pages that contain /blog/, e.g.
        www.markettailor.io/blog/1
      </Text>
      <Text margin="8px 0">
        Special characters:
        <br />* is a wildcard match
      </Text>
    </>
  );
  return (
    <Col>
      <Headline margin="0 0 5px 0">
        Page exclude rules <Tooltip content={TooltipContent} iconStyles={{color: darkGray, margin: '0 4px'}}></Tooltip>
      </Headline>
      <StyledBox maxWidth={'400px'} height="fit-content">
        <ExcludeUrlsForm />
      </StyledBox>
    </Col>
  );
}

function ExcludeUrlsForm() {
  const {handleSubmitAccountInfo, account} = useContext(AccountContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const originalState = (account?.excludeUrls || []).join('\n');
  const [state, setState] = useState<string>(originalState);
  const hasChanges = state !== originalState;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const parsedState = state
      .split('\n')
      .map((str) => str.trim())
      .filter((str) => str);
    await handleSubmitAccountInfo({excludeUrls: parsedState});
    setInfoNotification({message: 'URLs changed!'});
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setState(e.target.value);
  };

  const placeholder = 'www.markettailor.io/excludethispage\n*markettailor.io/blog/*';
  return (
    <>
      <Col>
        <Text margin="8px 0">List URL patterns on separate lines to exclude personalization on specific pages.</Text>
        <StyledTextBox placeholder={placeholder} value={state} onChange={handleChange} />
      </Col>
      <StyledBtn onClick={handleSubmit} disabled={!hasChanges}>
        Save
      </StyledBtn>
    </>
  );
}
