import {useContext} from 'react';

import {IUser} from '@markettailor/common-markettailor';

import {initializeIntercom} from '../functions/initAnalytics';
import {setDomainCookie} from '../functions/util';
import {AccountContext} from './AccountContext';

interface IFeaturePermissions {
  companyReveal: boolean;
  outboundLimit: boolean;
}

export const useHasPermission = (permissionName: keyof IFeaturePermissions): boolean => {
  const accountContext = useContext(AccountContext)!;
  const {permissions, billing} = accountContext.account || {};
  if (!permissions || !billing) return false;
  if (permissions.plan === 'dev') return true;

  const featurePermissions: IFeaturePermissions = {
    companyReveal: permissions.companyLookups > 0,
    outboundLimit: billing.outboundVisitorsUsed >= permissions.outboundVisitors,
  };

  const hasPermission = featurePermissions[permissionName];
  if (hasPermission) return true;
  return false;
};

export const setGlobalUserInfo = (user: IUser) => {
  const {email, userId} = user;
  initializeIntercom(email, userId);
  sessionStorage.setItem('emailDomain', email.split('@')[1]);
  sessionStorage.setItem('email', email);
  sessionStorage.setItem('userId', userId);
  setDomainCookie(email);
};
