import React, {useState} from 'react';

import {Col, Headline, StyledBtn} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

export default function ToEditorForm() {
  const history = useHistory();
  const [websiteUrl, setWebsiteUrl] = useState('');
  return (
    <>
      <Headline margin={'0 0 20px 0'}>Conversion event</Headline>
      <form>
        <TextField
          required
          label="Website URL"
          name={'url'}
          type={'text'}
          onChange={(e) => setWebsiteUrl(e.target.value)}
        />
        <Col alignItems={'center'}>
          <StyledBtn onClick={() => history.push('/editor?pageUrl=' + websiteUrl)}>Go to editor</StyledBtn>
        </Col>
      </form>
    </>
  );
}
