import React, {useContext} from 'react';

import {EyeSlashIcon, EyeIcon, IconX, Row, darkGray, lightBlue} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';

interface visibilityClassOption {
  name: string;
  class: null | string;
}

const visibilityOptions: visibilityClassOption[] = [
  {name: 'visible', class: null},
  {name: 'visibilityHidden', class: 'mt-hidden'},
  {name: 'displayNone', class: 'mt-display-none'},
];

export default function VisibilityClassSelector() {
  const editorContext = useContext(EditorContext)!;
  const {selectedElem, changeElemContent} = editorContext;

  const handleChange = (newVisibilityOption: visibilityClassOption) => {
    const newClassList = selectedElem.classList?.filter((elem) => !['mt-hidden', 'mt-display-none'].includes(elem));

    const newVisibilityClass = newVisibilityOption.class;
    if (newVisibilityClass) newClassList?.push(newVisibilityClass);
    changeElemContent(selectedElem.domElemId, {classList: newClassList});
  };

  const getVisibilityValue = () => {
    let currentValue: visibilityClassOption | undefined;
    if (selectedElem.classList?.includes('mt-hidden')) currentValue = visibilityOptions[1];
    if (selectedElem.classList?.includes('mt-display-none')) currentValue = visibilityOptions[2];
    if (!currentValue && selectedElem.domElemId) currentValue = visibilityOptions[0];
    return currentValue;
  };

  const currentVisibilityValue = getVisibilityValue();

  return (
    <Row margin="0 10px 0 10px">
      <EyeIcon
        data-testid="showButton"
        width="18px"
        cursor="pointer"
        $hoverColor={lightBlue}
        color={currentVisibilityValue?.name === visibilityOptions[0].name ? lightBlue : darkGray}
        onClick={() => {
          handleChange(visibilityOptions[0]);
        }}
      />
      <EyeSlashIcon
        data-testid="hideButton"
        width="18px"
        cursor="pointer"
        margin="0 10px"
        $hoverColor={lightBlue}
        color={currentVisibilityValue?.name === visibilityOptions[1].name ? lightBlue : darkGray}
        onClick={() => {
          handleChange(visibilityOptions[1]);
        }}
      />
      <IconX
        data-testid="removeButton"
        width="27px"
        cursor="pointer"
        $hoverColor={lightBlue}
        color={currentVisibilityValue?.name === visibilityOptions[2].name ? lightBlue : darkGray}
        onClick={() => {
          handleChange(visibilityOptions[2]);
        }}
      />
    </Row>
  );
}
