import {useContext, useState, useEffect} from 'react';

import {URLType, ISegment, ISegmentFilter, ICrmObjects} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {OutboundSegmentItemsContext} from '../../contexts/OutboundSegmentItemsContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {getSegmentIdFromPageId} from '../../functions/util';
import {crmObjectTypeMapping} from '../../termMaps/crmTerms';
import {useAllMapping} from '../../termMaps/technicalNamesToLabels';

export const useGetPagePreviewUrl = (pageId: string): URLType | undefined => {
  const {segments} = useContext(SegmentContext)!.state;
  const segmentId = getSegmentIdFromPageId(pageId, segments);
  if (!segmentId) return;
  const segment = segments[segmentId];
  if (!segment) return;
  return getPagePreviewUrl(pageId, segment);
};

const isUuid = (str: string) =>
  Boolean(str.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i));

export const getPagePreviewUrl = (pageId: string, segment: ISegment): URLType | undefined => {
  const {pageUrl} = segment.pageUrls[pageId];
  if (!segment.segmentId || !pageUrl) return;
  if (!isUuid(segment.segmentId)) console.error('Segment id is not valid');
  const inboundPreviewUrl = `${pageUrl}?mt_preview=${segment.segmentId}`;
  return inboundPreviewUrl;
};

const getSegmentLabels = (segment: ISegment | undefined, crmObjects: ICrmObjects, mapping): string[] | null => {
  const getOutboundLabel = (segment: ISegment) => {
    const {crmDataSource, crmObjectType} = segment.outbound!;
    const labelStr =
      crmDataSource === 'csv'
        ? `CSV ${Object.keys(crmObjects).length === 1 ? 'contact' : 'contacts'}`
        : `${capitalize(crmDataSource)} ${
            Object.keys(crmObjects).length === 1
              ? crmObjectTypeMapping[crmDataSource][crmObjectType].singular
              : crmObjectTypeMapping[crmDataSource][crmObjectType].plural
          }`;
    return [Object.keys(crmObjects).length + ' ' + labelStr];
  };

  const getInboundLabel = (segment: ISegment, mapping) => {
    const segmentFilters: ISegmentFilter[] = segment.segmentQuery?.queryRequest || [];
    return segmentFilters.map((segment) => {
      const selector: string = mapping.selector[segment.selector] || '';
      const condition: string = mapping.condition[segment.condition] || '';
      const valueRange: string[] = segment.valueRange.map((valueRange) => {
        return mapping[segment.selector]?.[valueRange] || valueRange;
      });

      const valueRangeStr =
        valueRange.length > 3
          ? valueRange.slice(0, 3).join(', ') + `... +${valueRange.length - 3} others`
          : valueRange.join(', ');
      return selector + ' ' + condition + ' ' + valueRangeStr;
    });
  };

  if (!segment) return null;
  if (segment.segmentType === 'outbound') return getOutboundLabel(segment);
  return getInboundLabel(segment, mapping);
};

export const useGetSegmentLabels = (segment: ISegment | undefined, segmentId: string): string[] | null => {
  const mapping = useAllMapping();
  const [segmentLabels, setSegmentLabels] = useState<string[] | null>(null);

  const outboundSegmentItemsContext = useContext(OutboundSegmentItemsContext)!;
  const {crmObjects} = outboundSegmentItemsContext;

  useEffect(() => {
    const segmentLabels = getSegmentLabels(segment, crmObjects || {}, mapping);
    setSegmentLabels(segmentLabels);
  }, [segmentId, crmObjects]);

  return segmentLabels;
};
