import React from 'react';

import {Headline, Col} from '@markettailor/common-markettailor';

import AttributeEditor from '../sideControls/AttributeEditor';

export default function VideoEditor({isDisabled}: {isDisabled: boolean}) {
  return (
    <Col margin={'10px 0 0 0'}>
      <Headline margin={'0 0 8px 5px'} fontSize={'13px'}>
        Video source (src)
      </Headline>
      <AttributeEditor attr={'src'} margin={'0 0 10px 0'} isDisabled={isDisabled} />
      <Headline margin={'0 0 8px 5px'} fontSize={'13px'}>
        Thumbnail (poster)
      </Headline>
      <AttributeEditor attr={'poster'} height={'14px'} isDisabled={isDisabled} />
    </Col>
  );
}
