import React, {ReactNode, useEffect, useContext, useState, createContext} from 'react';

import {
  ISegment,
  ISegments,
  IPage,
  ISegmentType,
  ICrmObjectType,
  ICrmDataSource,
  NotificationContext,
} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {cloneDeep, capitalize} from 'lodash';
import {useHistory} from 'react-router-dom';

import ErrorPage from '../components/common/ErrorPage';
import {DataRow} from '../components/outbound/table/OutboundTable';
import {checkFiltersForCompleteness} from '../components/segmentation/util';
import config from '../config.json';
import {saveSegmentsTotalAnalytics, saveUpdatedSegmentAnalytics, analytics} from '../functions/analytics';
import {getPageIdOrFirst, getSegmentId, addPageToSegments, getSegmentIdFromPageId, deepFreeze} from '../functions/util';
import {useAllMapping} from '../termMaps/technicalNamesToLabels';
import {AccountContext} from './AccountContext';
import {combineDynamicValues, getDynamicValues} from './dynamicValueUtils';
import {IntegrationContext, crmDataSources} from './IntegrationContext';

interface SegmentPostData extends Partial<ISegment> {
  segmentType: ISegmentType;
  crmObjectType?: ICrmObjectType;
  crmDataSource?: ICrmDataSource;
  csvData?: object[];
}

export interface SegmentProviderState {
  segmentId: string;
  pageId: string;
  segments: ISegments;
  isLoading: boolean;
  renderErrorPage: boolean;
  outboundHeaderMap: {[key: string]: string};
  outboundAllData: DataRow[];
  outboundDataSampleIsLoading: boolean;
  outboundDataIsLoading: boolean;
}

export interface ISegmentContext {
  state: SegmentProviderState;
  createNewSegment: (segmentData: SegmentPostData) => Promise<void>;
  updateSegment: (segmentId: string, changes: Partial<ISegment>) => Promise<void>;
  deleteSegment: (segmentId: string) => Promise<void>;
  updatePage: (pageId: string, changes: Partial<IPage>) => Promise<void>;
  deletePage: (pageId: string) => Promise<void>;
  createNewPage: (pageUrl: string) => Promise<undefined | string>;
  setPageId: (pageId: string) => void;
  setSegmentId: (segmentId: string) => void;
  setOutboundAllData: (allOutboundData: DataRow[]) => void;
  addOutboundCsvRows: (newData: {[key: string]: string}[]) => Promise<void>;
}

export interface IOutboundPagedItemsResponse {
  items: DataRow[];
  nextPage: string;
}

const SegmentContext = createContext<ISegmentContext | undefined>(undefined);

function SegmentProvider({children}: {children: ReactNode}) {
  const history = useHistory();
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification, setClearableNotification} = useContext(NotificationContext)!;
  const {integrations, isLoading: integrationsIsLoading} = useContext(IntegrationContext)!;
  const {handleSubmitAccountInfo, account} = useContext(AccountContext)!;

  const [segments, setSegments] = useState<ISegments>({});
  const [pageId, setPageId] = useState('');
  const [segmentId, setSegmentId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [renderErrorPage, setRenderErrorPage] = useState(false);
  const [outboundHeaderMap, setOutboundHeaderMap] = useState<{[key: string]: string}>({});
  const [outboundAllData, setOutboundAllData] = useState<DataRow[]>([]);
  const [outboundDataSampleIsLoading, setOutboundDataSampleIsLoading] = useState<boolean>(true);
  const [outboundDataIsLoading, setOutboundDataIsLoading] = useState<boolean>(true);

  const mapping = useAllMapping();

  useEffect(() => {
    const awaitWrapper = async () => {
      const newSegments = await getSegments();
      const segmentId = getSegmentId(newSegments);
      const path = window.location.pathname.split('/')[1];
      if (!segmentId && ['segmentation', 'outbound'].includes(path)) {
        console.warn('No segment found. Redirecting to segments');
        history.push('/segments');
      }
      const pageId = getPageIdOrFirst(segmentId, newSegments);
      if (_isMounted) {
        setSegments(newSegments);
        setPageId(pageId || '');
        setSegmentId(segmentId || '');
        setIsLoading(false);
      }
    };
    let _isMounted = true;
    awaitWrapper();
    return () => {
      source.cancel('SegmentProvider unmounted');
      _isMounted = false;
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    segmentId && sessionStorage.setItem('segmentId', segmentId);
    if (integrationsIsLoading) return;
    segmentId && refreshOutbound(segmentId);
    //eslint-disable-next-line
  }, [segmentId, integrationsIsLoading]);

  useEffect(() => {
    segmentId && saveExampleDynamicValues(segmentId);
    //eslint-disable-next-line
  }, [segmentId, integrationsIsLoading, account?.permissions]);

  const getSegments = async () => {
    try {
      const res = await axios.get(config.api.baseURL + 'segments', {
        cancelToken: source.token,
      });
      saveSegmentsTotalAnalytics(res.data);
      return res.data;
    } catch (e: any) {
      if (axios.isCancel(e)) return;
      if (e.message === 'Network Error') setRenderErrorPage(true);
      console.debug(e);
    }
  };

  const createNewSegment = async (segmentData: SegmentPostData) => {
    try {
      setSegmentId('');
      const res = await axios.post(config.api.baseURL + 'segments', segmentData, {
        cancelToken: source.token,
      });
      analytics.track('Created a new segment');
      setSegments((prevState) => ({
        ...prevState,
        [res.data.segmentId]: res.data,
      }));
      setSegmentId(res.data.segmentId);
      setPageId(Object.keys(res.data.pageUrls)[0]);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Creating new segment failed', level: 'error'});
    }
  };

  const updateSegment = async (segmentId: string, changes: Partial<ISegment>) => {
    try {
      const newSegment = {...segments[segmentId], ...changes, lastEditDate: new Date()};
      const newSegments = {...segments, [segmentId]: newSegment};
      setSegments(newSegments);

      const queryRequest = changes.segmentQuery?.queryRequest;
      if (queryRequest && !checkFiltersForCompleteness(queryRequest)) return;

      await axios.put(config.api.baseURL + 'segment/' + segmentId, changes, {
        cancelToken: source.token,
      });
      saveUpdatedSegmentAnalytics(newSegment, mapping);
    } catch (error) {
      setSegments(segments);
      setInfoNotification({message: 'Updating segment failed', level: 'error'});
    }
  };

  const deleteSegment = async (segmentId: string) => {
    try {
      if (!(segmentId in segments)) return;
      const newSegments = cloneDeep(segments);
      delete newSegments[segmentId];
      setSegments(newSegments);
      await axios.delete(config.api.baseURL + 'segment/' + segmentId, {
        cancelToken: source.token,
      });
      setInfoNotification({message: 'Segment deleted'});
      analytics.track('Deleted a segment');
    } catch (e) {
      if (axios.isCancel(e)) return;
      setSegments(segments);
      console.debug(e);
      setInfoNotification({message: 'Updating segment failed', level: 'error'});
    }
  };

  // Page methods

  const createNewPage = async (pageUrl: string): Promise<undefined | string> => {
    if (!account) return;
    try {
      if (!account.clientDomain) await handleSubmitAccountInfo({clientDomain: pageUrl});
      const res = await axios.post(
        config.api.baseURL + 'pages',
        {segmentId: state.segmentId, pageUrl: pageUrl},
        {cancelToken: source.token}
      );
      setPageId(res.data.pageId);
      setSegments(addPageToSegments(res.data.pageId, res.data, state.segments, state.segmentId));
      return res.data.pageId;
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Creating new page failed', level: 'error'});
    }
  };

  const updatePage = async (pageId: string, changes: Partial<IPage>) => {
    if (!account) return;
    try {
      if (!account.clientDomain && 'pageUrl' in changes) await handleSubmitAccountInfo({clientDomain: changes.pageUrl});
      const newChanges = {...changes, lastEditDate: new Date()};
      setSegments(addPageToSegments(pageId, newChanges, segments, segmentId));
      await axios.put(config.api.baseURL + 'page/' + pageId, changes, {
        cancelToken: source.token,
      });
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Updating page failed', level: 'error'});
    }
  };

  const deletePage = async (pageId: string) => {
    try {
      const newSegments = cloneDeep(segments);
      const segmentId = getSegmentIdFromPageId(pageId, segments);
      if (segmentId) delete newSegments[segmentId].pageUrls[pageId];
      setSegments(newSegments);
      await axios.delete(config.api.baseURL + 'page/' + pageId, {
        cancelToken: source.token,
      });
    } catch (e) {
      if (axios.isCancel(e)) return;
      setSegments(segments);
      setInfoNotification({message: 'Deleting page failed', level: 'error'});
    }
  };

  const refreshOutbound = async (segmentId: string): Promise<void> => {
    const segment = state.segments[segmentId];
    if (!segment.outbound) return;
    const crmDataSource = segment.outbound.crmDataSource;
    const integrationIsNotActive =
      !integrationsIsLoading && crmDataSources.includes(crmDataSource) && !integrations[crmDataSource]?.isActive;
    if (integrationIsNotActive) {
      setClearableNotification({
        id: 'Refresh outbound, integration not active',
        message: `Re-integrate ${capitalize(crmDataSource)} to continue using segment: ${
          segment.segmentName || 'Unnamed'
        }`,
        level: 'error',
      });
      setOutboundDataIsLoading(false);
      setOutboundDataSampleIsLoading(false);
      return;
    }
    refreshOutboundHeaderMap(segmentId);
    getOutboundAllData(segmentId);
  };

  const addOutboundCsvRows = async (newData: {[key: string]: string}[]): Promise<void> => {
    const segment = state.segments[segmentId];
    if (segment?.outbound?.crmDataSource !== 'csv') return;
    try {
      await axios.post(config.api.baseURL + `outbound/${segmentId}/csv/addRow`, newData, {
        cancelToken: source.token,
      });
      getOutboundAllData(segmentId);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Updating CSV data failed', level: 'error'});
      console.debug(e);
    }
  };

  const refreshOutboundHeaderMap = async (segmentId: string): Promise<void> => {
    const segment = state.segments[segmentId];
    if (!segment.outbound) return;
    try {
      const res = await axios.get(
        config.api.baseURL +
          `/labels?dataSource=${segment.outbound.crmDataSource}&objectType=${segment.outbound.crmObjectType}`,
        {cancelToken: source.token}
      );
      setOutboundHeaderMap(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Fetching CRM data for outbound failed', level: 'error'});
      console.debug(e);
    }
  };

  const getOutboundAllData = async (segmentId: string) => {
    const setOutboundSampleData = async () => {
      const response = await axios.get<IOutboundPagedItemsResponse>(
        config.api.baseURL + 'outbound/pagedItems/' + segmentId,
        {cancelToken: source.token, params: {pagesToFetch: 1}}
      );
      setOutboundAllData(response.data.items);
    };

    const setOutboundFullData = async () => {
      const response = await axios.get<IOutboundPagedItemsResponse>(
        config.api.baseURL + 'outbound/pagedItems/' + segmentId,
        {cancelToken: source.token, params: {pagesToFetch: 1000}}
      );
      setOutboundAllData(response.data.items);
    };

    const segment = state.segments[segmentId];
    if (!segment.outbound) return;
    setOutboundDataSampleIsLoading(true);
    setOutboundDataIsLoading(true);
    try {
      await setOutboundSampleData();
      setOutboundDataSampleIsLoading(false);
      await setOutboundFullData();
      setOutboundDataIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setOutboundDataIsLoading(false);
      setOutboundDataSampleIsLoading(false);
      setInfoNotification({message: 'Getting outbound campaign data failed', level: 'error'});
      console.debug(e);
    }
  };

  const saveExampleDynamicValues = async (segmentId: string) => {
    if (!account) return;
    try {
      const dynamicValues = await getDynamicValues(account.permissions, segments[segmentId], integrations, source);
      await axios.put(
        config.api.baseURL + `segment/${segmentId}/exampleDynamicValues`,
        combineDynamicValues(dynamicValues),
        {cancelToken: source.token}
      );
    } catch (e) {
      if (axios.isCancel(e)) return;
      throw e;
    }
  };

  const state: SegmentProviderState = {
    pageId,
    segmentId,
    segments,
    isLoading,
    renderErrorPage,
    outboundHeaderMap,
    outboundAllData,
    outboundDataIsLoading,
    outboundDataSampleIsLoading,
  };

  if (state.renderErrorPage) return <ErrorPage />;
  return (
    <SegmentContext.Provider
      value={deepFreeze<ISegmentContext>({
        state: state,
        createNewSegment,
        deleteSegment,
        updatePage,
        deletePage,
        createNewPage,
        updateSegment,
        setPageId,
        setSegmentId,
        setOutboundAllData,
        addOutboundCsvRows,
      })}
    >
      {children}
    </SegmentContext.Provider>
  );
}

export {SegmentContext, SegmentProvider};
