import React, {useContext} from 'react';

import {StyledBtn, ICrmIntegrations} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {IntegrationContext, crmIntegrations} from '../../../contexts/IntegrationContext';
import IntegrationBadge from '../IntegrationBadge';
import CrmSettings from './CrmSettings';

interface Props {
  integrationName: keyof ICrmIntegrations;
  logoWidth: string;
  authorizeCallback: () => void;
}

export default function CrmBadge(props: Props) {
  const {integrations} = useContext(IntegrationContext)!;
  const {integrationName, logoWidth, authorizeCallback} = props;

  return (
    <IntegrationBadge
      title={capitalize(integrationName)}
      description={`Enable ${capitalize(
        integrationName
      )} to use data from your CRM (such as pipeline stages) for personalization.`}
      integrationName={integrationName}
      isActive={integrations[integrationName]?.isActive}
      logoWidth={logoWidth}
      customAuthButton={
        <StyledBtn
          data-intercom-id={`integration-crm-${integrationName}-authorize`}
          margin={'0'}
          onClick={authorizeCallback}
        >
          Authorize
        </StyledBtn>
      }
      settingsRenderer={
        crmIntegrations.some((crmIntegration) => crmIntegration === integrationName) ? (
          <CrmSettings crmName={props.integrationName} />
        ) : undefined
      }
    />
  );
}
