import React from 'react';

import {Text, Row} from '@markettailor/common-markettailor';

import ControlContainer from '../ControlContainer';
import ImageEditor from '../images/ImageEditor';
import VideoEditor from '../images/VideoEditor';
import TypographyEditor from '../typography/TypographyEditor';
import AttributeEditor from './AttributeEditor';
import HierarchyEditor from './HierarchyEditor';
import VisibilityClassSelector from './VisibilityClassSelector';

export const HtmlEditor = ({selectedElem}) => {
  const isImage = selectedElem.tagName.toLowerCase() === 'img';
  const isVideo = selectedElem.tagName.toLowerCase() === 'video';
  const isMedia = isImage || isVideo;
  return (
    <>
      <ControlContainer header={'Element'} menuOpenInitial={false}>
        <Row justifyContent="space-between">
          <Text margin={'5px 10px 0 10px'}>Level</Text>
          <HierarchyEditor />
        </Row>
      </ControlContainer>
      <ControlContainer header={'Layout'} menuOpenInitial={false} toggleDataTestId="elementControlContainerToggle">
        <Row justifyContent="space-between">
          <Text margin={'5px 10px 0 10px'}>Visibility</Text>
          <VisibilityClassSelector />
        </Row>
      </ControlContainer>
      <ControlContainer header={'Typography'} menuOpenInitial={!isMedia}>
        <TypographyEditor isDisabled={!selectedElem.domElemId || isMedia} selectedElem={selectedElem} />
      </ControlContainer>
      <ControlContainer header={'Images'} menuOpenInitial={isImage}>
        <ImageEditor isImage={isImage} />
      </ControlContainer>
      <ControlContainer header={'Videos'} menuOpenInitial={isVideo}>
        <VideoEditor isDisabled={!isVideo} />
      </ControlContainer>
      <ControlContainer header={'Links'} menuOpenInitial={false}>
        <AttributeEditor attr={'href'} />
      </ControlContainer>
    </>
  );
};
