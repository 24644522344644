import React, {useContext, useState} from 'react';

import {Col, Headline, Text, brandMain, PopoverWindow, NotificationContext} from '@markettailor/common-markettailor';

import {IntegrationContext} from '../../contexts/IntegrationContext';
import {IFormField, StyledForm} from '../common/StyledForm';
import IntegrationBadge from './IntegrationBadge';

export interface IAuthOptionsCommonApiKey {
  apiKey: string;
}

interface Props {
  isDisabled?: boolean;
  settingsRenderer?: JSX.Element | null;
}

export interface IAuthOptionsZoomInfo {
  username: string;
  clientId: string;
  privateKey: string;
}

export default function ZoominfoBadge(props: Props): JSX.Element {
  const {integrations, authIntegration} = useContext(IntegrationContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [openIntegrationWindow, setOpenIntegrationWindow] = useState(false);

  async function handleIntegration(formData: Partial<IAuthOptionsZoomInfo>) {
    const {username, clientId, privateKey} = formData;
    if (!username || !clientId || !privateKey) {
      setInfoNotification({message: 'Field data is missing', level: 'error'});
      return;
    }
    const res = await authIntegration('zoominfo', {username, clientId, privateKey});
    if (res && res.status === 200) {
      setInfoNotification({message: 'Integration successful!'});
      setOpenIntegrationWindow(false);
    } else {
      setInfoNotification({message: 'Integration failed, check the data again', level: 'error'});
    }
  }

  return (
    <>
      <PopoverWindow hasMenu={true} display={openIntegrationWindow} handleClose={() => setOpenIntegrationWindow(false)}>
        <OptionsWindow handleSubmit={(formData: any) => handleIntegration(formData)} />
      </PopoverWindow>
      <IntegrationBadge
        title={'ZoomInfo'}
        integrationName={'zoominfo'}
        description={'Enable reverse IP enrichment with ZoomInfo to use firmographic data for segments.'}
        isDisabled={props.isDisabled}
        isActive={integrations.zoominfo?.isActive || false}
        logoWidth={'120px'}
        handleAuth={() => setOpenIntegrationWindow(true)}
        settingsRenderer={props.settingsRenderer}
      />
    </>
  );
}

interface IOptionsWindowProps {
  handleSubmit: (formData: {[fieldName: string]: string}) => void;
}

function OptionsWindow(props: IOptionsWindowProps): JSX.Element {
  const fields: IFormField[] = [
    {
      name: 'username',
      label: 'Username / email',
    },
    {
      name: 'clientId',
      label: 'Client ID',
    },
    {
      name: 'privateKey',
      label: 'Private key',
    },
  ];

  return (
    <Col minWidth={'500px'} alignItems={'center'}>
      <Headline color={brandMain}>{'ZoomInfo integration'}</Headline>
      <Text margin={'20px 0 40px 50px '}>Set your ZoomInfo API key to integrate with Markettailor</Text>
      <Col alignItems={'center'}>
        <StyledForm fields={fields} submitBtnText="Integrate" handleSubmit={props.handleSubmit} />
      </Col>
    </Col>
  );
}
