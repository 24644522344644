import React, {useContext, useState} from 'react';

import {Col, Row, ICrmDataSource, ICrmObjectType} from '@markettailor/common-markettailor';
import {cloneDeep} from 'lodash';

import {OutboundSegmentItemsContext} from '../../contexts/OutboundSegmentItemsContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {crmObjectTypeMapping} from '../../termMaps/crmTerms';
import {AddRowBtn} from './AddRowBtn';
import {EmptyTable} from './emptyStates';
import {FilterContainer} from './FilterContainer';
import OutboundCSVExport from './OutboundCSVExport';
import {ISearchFilter} from './OutboundFilterDropdown';
import {OutboundInlineNav} from './OutboundInlineNav';
import {DataRow, OutboundTable} from './table/OutboundTable';
import {useIsRenderedTableEmpty} from './util';

export type OutboundListType = 'allList' | 'segmentList';

export default function OutboundContainerContent() {
  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;

  const outboundSegmentItemsContext = useContext(OutboundSegmentItemsContext)!;
  const {crmObjects, updateOutboundSegmentItem} = outboundSegmentItemsContext;

  const [dataFilter, setDataFilter] = useState<ISearchFilter>({header: '_all', searchValue: '', searchSelection: ''});
  const [listTypeSelected, setListTypeSelected] = useState<OutboundListType>('allList');

  const segment = segments[segmentId];
  const crmObjectType: ICrmObjectType = segment.outbound!.crmObjectType;
  const crmDataSource: ICrmDataSource = segment.outbound!.crmDataSource;

  async function removeListRows(dataRows: DataRow[]) {
    if (!crmObjects) return;
    const newCrmObjects = cloneDeep(crmObjects);
    dataRows.forEach((dataRow) => delete newCrmObjects[dataRow.original.personalizedLink]);
    updateOutboundSegmentItem(newCrmObjects);
  }

  async function addListRows(dataRows: DataRow[]) {
    if (!crmObjects) return;
    const newCrmObjects = cloneDeep(crmObjects);
    dataRows.forEach((dataRow) => {
      newCrmObjects[dataRow.original.personalizedLink] = dataRow.original.id;
    });
    updateOutboundSegmentItem(newCrmObjects);
  }

  const noTableData = useIsRenderedTableEmpty(listTypeSelected, dataFilter);

  return (
    <Col margin={'0 0 40px 0'}>
      <Col data-intercom-id="outbound-filters" margin={'0 0 40px 0'}>
        <FilterContainer
          dataFilter={dataFilter}
          listTypeSelected={listTypeSelected}
          removeListRows={removeListRows}
          addListRows={addListRows}
          setDataFilter={setDataFilter}
        />
      </Col>
      <Col margin={'0 0 10px 0'} width="fit-content">
        <Row
          data-intercom-id="outbound-inlineNav"
          justifyContent="space-between"
          alignItems="end"
          margin="0 0 10px 0"
          minHeight="38px"
        >
          <OutboundInlineNav
            listTypeSelected={listTypeSelected}
            inlineNavAllLabelType={
              crmDataSource === 'csv' ? 'contacts' : crmObjectTypeMapping[crmDataSource][crmObjectType].plural
            }
            handleSelect={(selection) => {
              setListTypeSelected(selection);
              setDataFilter({header: '_all', searchValue: '', searchSelection: ''});
            }}
          />
          {listTypeSelected === 'segmentList' && <OutboundCSVExport dataFilter={dataFilter} />}
        </Row>
        {noTableData ? (
          <EmptyTable listTypeSelected={listTypeSelected} dataFilter={dataFilter} />
        ) : (
          <OutboundTable
            listTypeSelected={listTypeSelected}
            dataFilter={dataFilter}
            removeListRows={removeListRows}
            addListRows={addListRows}
          />
        )}
      </Col>
      <AddRowBtn />
    </Col>
  );
}
