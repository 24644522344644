export const crmObjectTypeMapping = {
  hubspot: {
    deal: {singular: 'deal', plural: 'deals'},
    company: {singular: 'company', plural: 'companies'},
    contact: {singular: 'contact', plural: 'contacts'},
  },
  salesforce: {
    deal: {singular: 'opportunity', plural: 'opportunities'},
    company: {singular: 'account', plural: 'accounts'},
    contact: {singular: 'contact', plural: 'contacts'},
  },
  activeCampaign: {
    deal: {singular: 'deal', plural: 'deals'},
    company: {singular: 'account', plural: 'accounts'},
    contact: {singular: 'contact', plural: 'contacts'},
  },
};
