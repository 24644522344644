import {useContext} from 'react';

import {
  brandMain,
  IBilling,
  IDatasetInterface,
  IPermissions,
  lightGray,
  yellow,
} from '@markettailor/common-markettailor';
import moment, {Moment} from 'moment';

import {AccountContext} from '../../../contexts/AccountContext';
import {IUsage, IUsageDataPoint} from '../../../contexts/StripeContext';

export const getHighestLimit = (permissions: IPermissions): number => {
  return Math.max(permissions.companyLookups, permissions.inboundVisitors, permissions.outboundVisitors);
};

function createChartDataPoints(usageDataPoints: IUsageDataPoint[]) {
  const getCumulativeCounts = (usageDataPoints: IUsageDataPoint[]) => {
    let usageTotal = 0;
    return usageDataPoints.map((dailyUsage) => {
      const currentDate: Moment = moment(dailyUsage.date);
      usageTotal += dailyUsage.count;
      return {y: usageTotal, t: currentDate};
    });
  };

  const cumulativeCounts = getCumulativeCounts(usageDataPoints);
  return cumulativeCounts;
}

export const getChartData = (usage: IUsage): IDatasetInterface[] => {
  const datasetMapping = {
    inboundVisitors: 'Inbound visitors',
    outboundVisitors: 'Outbound visitors',
    companyLookups: 'Company lookups',
  };
  const colorMapping = {
    inboundVisitors: lightGray,
    outboundVisitors: yellow,
    companyLookups: brandMain,
  };

  return Object.entries(usage).map(([name, usageData]) => {
    return {
      datasetName: datasetMapping[name],
      lineColor: colorMapping[name],
      values: createChartDataPoints(usageData),
    };
  });
};

export const getUsageDates = (billing: IBilling) => {
  const periodStartDate = moment().date(billing.periodStartDay).startOf('day');
  const usageStartIsBeforeNow = periodStartDate.isBefore(moment());
  const usageStart: Moment = usageStartIsBeforeNow ? periodStartDate : periodStartDate.subtract(1, 'month');
  const usageEnd: Moment = usageStart.clone().add(1, 'month');
  return {usageStart, usageEnd};
};
