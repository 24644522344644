import React, {FormEvent, useContext, useState} from 'react';

import {StyledBtn, Headline, Col, Text} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import {ConversionSourceType, ConversionType} from '../../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext, IConversionEventCreation} from '../../../contexts/ConversionManagementContext';

interface ICreateEventFormProps {
  handleSubmitHook: (eventData: IConversionEventCreation) => void;
  buttonText: string;
  eventType: ConversionType;
  eventLabel: string;
  eventSource: ConversionSourceType;
}

const CreateEventForm = ({handleSubmitHook, buttonText, eventType, eventLabel, eventSource}: ICreateEventFormProps) => {
  const [eventName, setEventName] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const {events, createConversionEvent} = useContext(ConversionManagementContext)!;

  const eventNames = Object.values(events).map((event) => {
    return event.eventName;
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const eventData = {eventName, pageUrl, type: eventType, isActive: true, source: eventSource};
    createConversionEvent(eventData);
    handleSubmitHook(eventData);
  };

  const nameAlreadyExists = eventNames.includes(eventName);
  const isDisabled = nameAlreadyExists || !eventName;
  return (
    <Col minWidth={'350px'}>
      <Headline margin={'0 0 30px 0'}>Add {eventLabel} conversion</Headline>
      <form onSubmit={handleSubmit}>
        <Col>
          <TextField
            required
            label="Event name"
            name={'eventName'}
            type={'text'}
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
          <TextField
            required
            label="Website URL"
            name={'url'}
            type={'text'}
            value={pageUrl}
            onChange={(e) => setPageUrl(e.target.value)}
          />
          <Col alignItems={'center'}>
            {nameAlreadyExists && <Text style={{fontStyle: 'italic'}}>Event with a same name already exists</Text>}
            <StyledBtn disabled={isDisabled} onClick={handleSubmit}>
              {buttonText}
            </StyledBtn>
          </Col>
        </Col>
      </form>
    </Col>
  );
};

export const CreatePageEventForm = ({handleClose}) => (
  <CreateEventForm
    handleSubmitHook={handleClose}
    eventLabel={'page view'}
    buttonText={'Add'}
    eventType="page"
    eventSource="markettailor"
  />
);

export const CreateTrackEventForm = () => {
  const history = useHistory();

  return (
    <CreateEventForm
      handleSubmitHook={(eventData: IConversionEventCreation) => history.push('/editor/?pageUrl=' + eventData.pageUrl)}
      eventLabel={'click event'}
      buttonText={'To editor'}
      eventType="track"
      eventSource="markettailor"
    />
  );
};
