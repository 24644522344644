import {useContext, useEffect, useState} from 'react';

import {IDomElem, IDomElems, IDomElemFullState, IDomElemChanges} from '@markettailor/common-markettailor';
import {cloneDeep, isEmpty, isEqual} from 'lodash';
import {useLocation} from 'react-router';
import {v4 as uuid} from 'uuid';

import {getSegmentIdFromPageId} from '../functions/util';
import {ConversionDomElemsType, IConversionDomElem} from './conversionManagement/domElems';
import {combineDynamicValues} from './dynamicValueUtils';
import {EDITOR_ID} from './EditorContext';
import type {
  EditorModeType,
  IEditorToIframeMessageData,
  IDynamicValues,
  IframeToEditorMessage,
} from './editorContextTypes';
import {SegmentContext} from './SegmentContext';

export const addUnchangedDomEditorFields = (initialDomElems: IDomElems) => {
  return Object.entries(initialDomElems).reduce((acc, [key, domElem]) => {
    return {...acc, [key]: {...domElem, domEditor: {...domElem.domOriginal, ...domElem.domEditor}}};
  }, {});
};

export function removeEmptyDomElems(domElems: IDomElems): IDomElems {
  const checkIfAllPropertiesInEditorAreEqualToOriginal = (domElem, newDomElems) => {
    for (const key of Object.keys(domElem.domEditor)) {
      if (!isEqual(domElem.domEditor[key], domElem.domOriginal[key])) {
        return;
      }
    }
    delete newDomElems[domElem.elemId];
  };

  const newDomElems = cloneDeep(domElems);
  for (const domElem of Object.values(newDomElems)) {
    if (isEmpty(domElem.domEditor)) {
      delete newDomElems[domElem.elemId];
      continue;
    }

    if (domElem.elemId in newDomElems) checkIfAllPropertiesInEditorAreEqualToOriginal(domElem, newDomElems);
  }
  return newDomElems;
}

const getChangedValues = (oldObj: object, newObj: object) => {
  const resultObj = {};
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] !== oldObj[key]) resultObj[key] = newObj[key];
  });
  return resultObj;
};

const getAddedItems = (oldArray: any[], newArray: any[]) => newArray.filter((elem) => !oldArray.includes(elem));

export const removeUnchangedFields = (domElems: IDomElems): IDomElems => {
  const newDomElems = Object.entries(domElems)
    .map(([id, domElem]) => {
      const {style, classList, ...stringAttrs} = domElem.domEditor;
      const changedStringAttrs = getChangedValues(domElem.domOriginal, stringAttrs);
      const editedStyle = getChangedValues(domElem.domOriginal.style, domElem.domEditor.style || {});
      const addedClasses = getAddedItems(domElem.domOriginal.classList, domElem.domEditor.classList || []);
      const newDomEditor: IDomElemChanges = {
        style: editedStyle,
        classList: addedClasses,
        domElemId: domElem.domOriginal.domElemId,
        ...changedStringAttrs,
      };
      const newDomeElem = {...domElem, domEditor: newDomEditor};
      return {[id]: newDomeElem};
    })
    .reduce((acc, domElem) => ({...acc, ...domElem}), {});
  return newDomElems;
};

const getIframeWindow = () => {
  function isIFrame(input: HTMLElement | null): input is HTMLIFrameElement {
    return input !== null && input.tagName.toLowerCase() === 'iframe';
  }
  const editorElem = document.getElementById(EDITOR_ID);
  if (editorElem && isIFrame(editorElem)) return editorElem.contentWindow;
};

type IframeWindow = WindowProxy | null | undefined;
export const sendToBrowser = async (data: IEditorToIframeMessageData) => {
  const iframeWindow: IframeWindow = getIframeWindow();
  iframeWindow && iframeWindow.postMessage(data, iframeWindow.origin);
};

export const emptySelectedElem: IDomElemFullState = {
  id: '',
  domElemId: '',
  innerText: '',
  innerHTML: '',
  tagName: '',
  mt_index: -1,
  mt_index_max: -1,
  src: '',
  alt: '',
  href: '',
  conversionEventId: '',
  classList: [],
  style: {
    textAlign: null,
    color: null,
    fontSize: null,
    fontWeight: null,
    backgroundColor: null,
    fontStyle: null,
    textDecoration: null,
  },
};

export const useGetInitialEditorMode = (
  segmentId: string,
  pageUrl: string
): [EditorModeType, React.Dispatch<React.SetStateAction<EditorModeType>>] => {
  const [editorMode, setEditorMode] = useState<EditorModeType>('edit');
  useEffect(() => {
    if (!segmentId && pageUrl) return setEditorMode('track');
  }, [segmentId, pageUrl]);
  return [editorMode, setEditorMode];
};

export const checkFontStyleFormatting = (clickedElem: IDomElemFullState) => {
  if (!clickedElem.style.fontSize?.includes('px')) {
    clickedElem.style.fontSize = clickedElem.style.fontSize?.concat('px') || null;
  }
  return clickedElem;
};

export const createNewElementFromClicked = (clickedElem: IDomElemFullState): IDomElem => {
  const clickedElemId = clickedElem.domElemId || 'mt-' + uuid();
  const createdDomElem = {
    elemId: clickedElemId,
    domOriginal: {...clickedElem, domElemId: clickedElemId},
    domEditor: {domElemId: clickedElemId},
  };
  return createdDomElem;
};

export const makeChangesToExistingElement = (clickedElem: IDomElemFullState, newDomElems: IDomElems) => {
  const getEditedElemContent = (clickedElem: IDomElemFullState, domEditorContent: IDomElemChanges) => {
    if (!isEmpty(domEditorContent)) return domEditorContent;
    return clickedElem;
  };

  const elemId = clickedElem.domElemId;
  const domEditorContent = getEditedElemContent(clickedElem, newDomElems[elemId].domEditor);
  const modifiedDomElem = {
    ...newDomElems[elemId],
    domEditor: {...domEditorContent},
  };
  return modifiedDomElem;
};

export const getUpdatedConversionElem = (
  selectedElem: IDomElemFullState,
  conversionDomElems: ConversionDomElemsType,
  elemChanges: IDomElemChanges
): {newConversionDomElems: ConversionDomElemsType; newConversionDomElem: IConversionDomElem} => {
  const newConversionDomElem: IConversionDomElem = {...selectedElem, ...elemChanges};
  const newConversionDomElems = {
    ...conversionDomElems,
    [elemChanges.domElemId]: newConversionDomElem,
  };
  return {newConversionDomElems, newConversionDomElem};
};

export const populateEditorWithDomChanges = (domElems: IDomElems, dynamicValuesOrdered: IDynamicValues) => {
  const domElemsWithEditFlag = Object.entries(domElems)
    .map(([key, elem]) => {
      const editWithFlag = {...elem.domEditor, hasEdits: true};
      const elemWithEdit = {...elem, domEditor: editWithFlag};
      return {[key]: elemWithEdit};
    })
    .reduce((acc, elem) => ({...acc, ...elem}), {});
  sendToBrowser({
    task: 'populate editor',
    domElems: domElemsWithEditFlag,
    dynamicValues: combineDynamicValues(dynamicValuesOrdered),
  });
};

export const populateEditorWithDomIds = (domElems: IDomElems) => {
  const newDomElems = cloneDeep(domElems);
  const domIds = Object.keys(newDomElems).reduce((old, key) => {
    const value = newDomElems[key];
    value.domEditor = value.domOriginal;
    return {...old, ...{[key]: value}};
  }, {});
  sendToBrowser({
    task: 'populate editor',
    domElems: domIds,
    dynamicValues: {},
  });
};

export const populateEditorWithDomIdsTrack = (conversionDomElems: ConversionDomElemsType) => {
  const newConversionDomElems = cloneDeep(conversionDomElems);
  const domElems = Object.keys(newConversionDomElems).reduce((old, key) => {
    const value = {
      domEditor: {
        conversionEventId: newConversionDomElems[key].conversionEventId,
        mt_index: newConversionDomElems[key].mt_index,
        mt_index_max: newConversionDomElems[key].mt_index_max,
        pageUrl: newConversionDomElems[key].pageUrl,
        domElemId: newConversionDomElems[key].domElemId,
      },
      domOriginal: {...newConversionDomElems[key], conversionEventId: ''},
    };
    return {...old, ...{[key]: value}};
  }, {});
  sendToBrowser({task: 'populate editor', domElems});
};

const parsePageUrlFromQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const pageUrl = urlParams.get('pageUrl');
  return pageUrl || '';
};

export const useGetSegmentPage = (pageId: string) => {
  const segmentContext = useContext(SegmentContext)!;
  const {segments} = segmentContext!.state;
  const segmentId = getSegmentIdFromPageId(pageId, segments);
  if (segmentId) {
    const segment = segments[segmentId];
    const {domElems, pageUrl} = segment.pageUrls[pageId];
    return {segmentId, domElems, pageUrl};
  }

  const pageUrl = parsePageUrlFromQuery();
  return {segmentId: '', pageUrl, domElems: {}};
};

export const useGetPageIdFromWindowPath = () => {
  const location = useLocation();
  const path: string = location.pathname;
  if (/^\/editor\/.+/.test(path)) return path.split('/')[2];
  return '';
};

export const useAddPostMessageListenerToWindow = (editorMessageRouter: (e: IframeToEditorMessage) => void) => {
  useEffect(() => {
    window.addEventListener('message', editorMessageRouter);
    return () => {
      window.removeEventListener('message', editorMessageRouter);
    };
  }, [editorMessageRouter]);
};

export const removeEmptyConversionDomElems = (conversionDomElems: ConversionDomElemsType) => {
  const newConversionDomElems = {...conversionDomElems};
  Object.values(newConversionDomElems).forEach((domElem) => {
    if (domElem.conversionEventId) return;
    delete newConversionDomElems[domElem.domElemId || ''];
  });
  return newConversionDomElems;
};

export const mapConversionDomElemsToDomElems = (conversionDomElems: ConversionDomElemsType): IDomElems => {
  const domElems = Object.values(conversionDomElems).reduce((acc, elem) => {
    return {
      ...acc,
      [elem.domElemId]: mapConversionDomElemToDomElem(elem),
    };
  }, {});
  return domElems;
};

const mapConversionDomElemToDomElem = (conversionDomElem: IConversionDomElem): IDomElem => {
  return {
    elemId: conversionDomElem.domElemId,
    //@ts-ignore
    domOriginal: {...conversionDomElem, conversionEventId: ''},
    domEditor: {domElemId: conversionDomElem.domElemId, conversionEventId: conversionDomElem.conversionEventId},
  };
};
