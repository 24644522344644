import {useContext} from 'react';

import {ISegments} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {
  IConversions,
  ConversionEvents,
  ConversionAnalyticsContext,
  ISegmentAnalytics,
  IGroupConversions,
} from '../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';
import {IntegrationContext} from '../../contexts/IntegrationContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {getRenderedConversionRateLift} from '../../functions/util';

export function getPersonalizedVisitors(conversions: IConversions): number {
  if (isEmpty(conversions)) return 0;
  return Object.entries(conversions)
    .filter(([segmentId, _]) => segmentId !== '0000-0000-0000-0000')
    .reduce((acc, [_, conversionEvents]) => {
      return acc + (conversionEvents?.personalized?.visitorCount || 0);
    }, 0);
}
export function getNonPersonalizedVisitors(conversions: IConversions): number {
  return conversions?.['0000-0000-0000-0000']?.control?.visitorCount || 0;
}
export function getControlVisitors(conversions: IConversions): number {
  if (isEmpty(conversions)) return 0;
  return Object.entries(conversions)
    .filter(([segmentId, _]) => segmentId !== '0000-0000-0000-0000')
    .reduce((acc, [_, conversionEvents]) => {
      return acc + (conversionEvents?.control?.visitorCount || 0);
    }, 0);
}

export const getConversionRate = (
  segmentId: string,
  conversionName: keyof ConversionEvents,
  conversions: IConversions,
  group: 'personalized' | 'control' = 'personalized'
): number => {
  if (segmentId === '0000-0000-0000-0000') group = 'control';
  const segment: IGroupConversions | undefined = conversions?.[segmentId]?.[group];
  if (!segment?.events[conversionName] || segment.visitorCount === 0) return 0;
  const conversionRate = (segment.events[conversionName].count / segment.visitorCount) * 100;
  return conversionRate;
};

export function getTotalConversionRateLift(
  conversionMetric: keyof ConversionEvents | undefined,
  conversions: IConversions,
  segments: ISegments
): number {
  if (!conversionMetric) return 0;

  const totalConversionRateLiftWeights = Object.values(segments).reduce(
    (prev, segment) => {
      const segmentId = segment.segmentId;
      const segmentConversionCount = conversions[segmentId]?.personalized?.visitorCount || 0;
      const controlConversionCount = conversions[segmentId]?.control?.visitorCount || 0;
      if (segmentConversionCount === 0 || controlConversionCount === 0) return prev;
      const conversionRateWeight = segmentConversionCount + controlConversionCount;

      const conversionRateLiftPercent = getSegmentConversionRateLift(segmentId, conversionMetric, conversions);

      return [prev[0] + conversionRateLiftPercent * conversionRateWeight, prev[1] + conversionRateWeight];
    },
    [0, 0]
  );
  if (totalConversionRateLiftWeights[1] === 0) return 0;
  const totalConversionRateLiftPercent = totalConversionRateLiftWeights[0] / totalConversionRateLiftWeights[1];
  return Math.min(totalConversionRateLiftPercent, 1000);
}

export function getSegmentConversionRateLift(
  segmentId: string,
  conversionMetric: keyof ConversionEvents | undefined,
  conversions: IConversions
) {
  if (!conversionMetric) return 0;
  const conversionRateLift = conversions?.[segmentId]?.conversionRateLifts?.[conversionMetric]?.conversionRateLift || 0;
  return getRenderedConversionRateLift(conversionRateLift);
}

export const useGetTotalNonPersonalizedVisitors = () => {
  const {conversions, segmentAnalytics} = useContext(ConversionAnalyticsContext)!;
  return !isEmpty(conversions)
    ? getNonPersonalizedVisitors(conversions)
    : segmentAnalytics?.['0000-0000-0000-0000'] || 0;
};

export const useGetTotalPersonalizedVisitors = () => {
  const {conversions, segmentAnalytics} = useContext(ConversionAnalyticsContext)!;

  const calculatePersonalizations = (segmentAnalytics: ISegmentAnalytics) => {
    return Object.entries(segmentAnalytics || {})
      .filter(([segmentId, _]) => segmentId !== '0000-0000-0000-0000')
      .reduce((acc, [_, count]) => acc + count, 0);
  };

  return !isEmpty(conversions) ? getPersonalizedVisitors(conversions) : calculatePersonalizations(segmentAnalytics);
};

export const useGetTotalConversionRateLift = () => {
  const {segments} = useContext(SegmentContext)!.state;
  const {conversions} = useContext(ConversionAnalyticsContext)!;
  const {mainConversionMetric} = useContext(ConversionManagementContext)!;
  return getTotalConversionRateLift(mainConversionMetric, conversions, segments);
};

export const useIsConversionTrackingEnabled = (): boolean => {
  const {events} = useContext(ConversionManagementContext)!;
  const {integrations} = useContext(IntegrationContext)!;

  return integrations.segment?.isActive || integrations.googleAnalytics?.isActive || !isEmpty(events);
};
