import React, {useContext} from 'react';

import {Row, ISegmentFilter, IFilterCondition} from '@markettailor/common-markettailor';
import {findIndex} from 'lodash';

import {SegmentContext} from '../../../contexts/SegmentContext';
import Segment from './valueSelection/Segment';

interface Props {
  segmentFilter: ISegmentFilter;
  index: number;
  segmentFilters: ISegmentFilter[];
  filterCondition: IFilterCondition;
}

export const RenderSegmentFilter = ({segmentFilter, index, segmentFilters, filterCondition}: Props): JSX.Element => {
  const segmentContext = useContext(SegmentContext);
  const {updateSegment} = segmentContext!;
  const {segmentId} = segmentContext!.state;

  const removeFilter = async (filterIndex: number): Promise<void> => {
    segmentFilters.splice(filterIndex, 1);
    updateSegment(segmentId, {
      segmentQuery: {
        queryRequest: segmentFilters,
        filterCondition: filterCondition,
      },
    });
  };

  const handleFilterChange = async (newSegmentFilter: ISegmentFilter): Promise<void> => {
    const newFilterIndex = findIndex(segmentFilters, {
      filterId: newSegmentFilter.filterId,
    });
    const newSegmentFilters = segmentFilters.map((filter, index) =>
      index === newFilterIndex ? newSegmentFilter : filter
    );
    updateSegment(segmentId, {
      segmentQuery: {
        queryRequest: newSegmentFilters,
        filterCondition: filterCondition,
      },
    });
  };

  return (
    <Row key={segmentFilter.filterId} margin={segmentFilters.length - 1 === index ? '0' : '0 0 10px 0'}>
      <Segment
        segmentFilter={segmentFilter}
        labelIndex={index}
        removeFilter={(index) => removeFilter(index)}
        renderRemoveIcon={segmentFilters.length > 1}
        handleFilterChange={handleFilterChange}
      />
    </Row>
  );
};
