import {useContext} from 'react';

import {cloneDeep} from 'lodash';

import {IConversionEvent, IEvents} from '../../../contexts/ConversionManagementContext';
import {cleanDomainFromUrl} from '../../../functions/util';
import {IEnrichedManageEvents, IEnrichedManageEvent} from './ManageConversionsTable';

export const getMainEventRadioStates = (enrichedEvents: IEnrichedManageEvents, mainConversionMetric) => {
  return Object.values(enrichedEvents).reduce((acc, event) => {
    if (mainConversionMetric) acc[mainConversionMetric] = true;
    return {
      [event.eventId]: false,
      ...acc,
    };
  }, {});
};

export function enrichEventsWithPageUrls(
  events: IEvents,
  clientDomain?: string
): {[key: string]: IEnrichedManageEvent} {
  if (!events) return {};
  const enrichedEvents = Object.values(cloneDeep(events)).reduce((acc, event) => {
    return {...acc, [event.eventId]: {...event, pageUrls: getPageUrls(event, clientDomain)}};
  }, {});
  return enrichedEvents;
}

function getPageUrls(event: IConversionEvent, clientDomain?: string): string[] {
  if (event.pageUrl) return [clientDomain ? cleanDomainFromUrl(event.pageUrl, clientDomain) : event.pageUrl];
  if (event.type === 'page' && event.source === 'segment') {
    const pageUrl = decodeURIComponent(event.eventId);
    return [clientDomain ? cleanDomainFromUrl(pageUrl, clientDomain) : pageUrl];
  }
  return [];
}
