import React, {useEffect, useState} from 'react';

import {
  Col,
  Headline,
  Row,
  StyledDivider,
  BlueCheckbox,
  ToggleArrow,
  GridContainer,
  GridItem,
  Tooltip,
  darkGray,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {IDataField} from './CrmSettings';

interface CrmDataFieldsProps {
  updateDataFields: (dataField: IDataField) => void;
  currentDataFields: IDataField[];
}

export default function CrmDataFields({updateDataFields, currentDataFields}: CrmDataFieldsProps) {
  const [headerState, setHeaderState] = useState<{[keys: string]: boolean}>({});

  const groupedFieldOptions: {[keys: string]: IDataField[]} = {};
  currentDataFields.forEach((field) => {
    const header = !field.group ? 'All' : field.group;
    if (!groupedFieldOptions[header]) {
      groupedFieldOptions[header] = [];
    }
    groupedFieldOptions[header].push(field);
  });

  useEffect(() => {
    setHeaderState({
      ...Object.keys(groupedFieldOptions).reduce((acc, header) => {
        return {...acc, [header]: false};
      }, {}),
      All: true,
    });
  }, []); //eslint-disable-line

  return (
    <Col>
      {Object.entries(groupedFieldOptions).map(([header, fields], headerIndex) => {
        return (
          <Col key={headerIndex}>
            <GroupHeader header={header} setHeaderState={setHeaderState} isActive={headerState[header]} />
            <GridContainer columns="1fr 1fr 1fr" gridAutoFlow="column">
              <RowFields fields={fields} isActive={headerState[header]} updateDataFields={updateDataFields} />
            </GridContainer>
            {Object.keys(groupedFieldOptions).length !== headerIndex && <StyledDivider border margin="0 0 30px 0" />}
          </Col>
        );
      })}
    </Col>
  );
}

const RowFields = ({
  fields,
  isActive,
  updateDataFields,
}: {
  fields: IDataField[];
  isActive: boolean;
  updateDataFields: (dataField: IDataField) => void;
}) => {
  if (!isActive) return null;
  const sortedFields = fields.sort((field1, field2) => {
    return field1.label.localeCompare(field2.label);
  });

  const gridColumnItemHeight = Math.ceil(sortedFields.length / 3);
  return (
    <>
      {sortedFields.map((field, index) => {
        const gridColumn = sortedFields.length < 6 ? 1 : Math.ceil((index + 1) / gridColumnItemHeight);
        return (
          <GridItem key={index} gridColumn={gridColumn}>
            <Row alignItems="center">
              <BlueCheckbox
                css={{}}
                checked={field.isActive}
                onChange={() => {
                  updateDataFields({...field, isActive: !field.isActive});
                }}
              />
              <Headline fontSize="14px" fontSizeLarge="14px" fontSizeExtraLarge="14px" margin="0 5px 0 0">
                {capitalize(field.label.replaceAll('_', ' '))}
              </Headline>
              {field.description && <Tooltip content={field.description} iconStyles={{color: darkGray}} />}
            </Row>
          </GridItem>
        );
      })}
    </>
  );
};

const GroupHeader = ({
  header,
  setHeaderState,
  isActive,
}: {
  header: string;
  setHeaderState: Function;
  isActive: boolean;
}) => {
  return (
    <Row
      alignItems="center"
      margin="0 0 10px 0"
      onClick={() => {
        setHeaderState((prevState) => ({...prevState, [header]: !prevState[header]}));
      }}
    >
      <Headline color="#000" cursor="pointer" margin="0 5px 0 0">
        {header}
      </Headline>
      <div>{<ToggleArrow active={isActive} width="25px" />}</div>
    </Row>
  );
};
