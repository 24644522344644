import React from 'react';

import {Text, Headline, brandMain} from '@markettailor/common-markettailor';

export const CurrentBillingPeriod = ({usageStart, usageEnd}) => {
  return (
    <>
      <Text margin={'0 0 10px 0'}>Current billing period</Text>
      <Headline color={brandMain} fontSize={'18px'}>
        {usageStart.format('MMM DD, YYYY')} ─ {usageEnd.format('MMM DD, YYYY')}
      </Headline>
    </>
  );
};
