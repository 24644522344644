import React from 'react';

import {Col, Row, StyledDivider} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

export function RenderIntegrationSkeleton() {
  return (
    <>
      <StyledDivider border margin={'0 40px 10px 0'} />
      <Row margin={'0 0 40px 0'}>
        <Col margin={'0 40px 0 0'}>
          <Skeleton width={250} height={260} />
        </Col>
        <Skeleton width={250} height={260} />
      </Row>
      <StyledDivider border margin={'0 40px 10px 0'} />
      <Row>
        <Col margin={'0 40px 0 0'}>
          <Skeleton width={250} height={260} />
        </Col>
        <Skeleton width={250} height={260} />
      </Row>
    </>
  );
}

export const RenderOptionsSkeleton = () => {
  return (
    <Col alignItems="center">
      <Col margin="0 0 10px 0">
        <Skeleton width="800px" height="30px" />
      </Col>
      <Col margin="0 0 10px 0">
        <Skeleton width="800px" height="30px" />
      </Col>
      <Skeleton width="800px" height="30px" />
    </Col>
  );
};
