import {brandMain, warnColor} from '@markettailor/common-markettailor';

import {mapping} from '../../../termMaps/technicalNamesToLabels';

type IconStyleType = 'solid' | 'default' | undefined;

const iconStyle: IconStyleType = 'solid';
export const cardOptions = {
  iconStyle: iconStyle,
  style: {
    base: {
      iconColor: brandMain,
      fontFamily: 'Montserrat, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      color: brandMain,
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: warnColor,
    },
  },
};

export const countryOptions = Object.keys(mapping.country)
  .map((countryCode) => {
    return {value: countryCode, label: mapping.country[countryCode]};
  })
  .sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
