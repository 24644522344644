import React, {useState, Fragment} from 'react';

import {
  Col,
  Text,
  GridContainer,
  GridItem,
  StyledDivider,
  StyledBox,
  Headline,
  lightGray,
  darkGray,
  Row,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {AudienceInsight} from '../segmentation/sideContainer/audienceInsights/AudienceInsights';
import {IListItem} from './RevealTable';
import {countFrequencies, usePrepareListData} from './util';

const mapping = {
  industry: 'industries',
  revenue: 'revenues',
  countryLong: 'countries',
};

export default function RevealInsights() {
  const [selectedInsight, setSelectedInsight] = useState('industry');
  const sortedListData = usePrepareListData();

  const createInsights = (data: IListItem[], key: string): AudienceInsight[] => {
    const frequencyObj = countFrequencies(key, data);
    return Object.keys(frequencyObj)
      .filter((objectKey) => objectKey !== 'null')
      .sort()
      .map((objectKey) => {
        return {value: objectKey, count: frequencyObj[objectKey], fraction: frequencyObj[objectKey] / data.length};
      });
  };

  const insights = createInsights(sortedListData, selectedInsight);

  return (
    <Row>
      <Col margin="30px 8px 0 30px">
        <InsightSelection selectedInsight={selectedInsight} setSelectedInsight={setSelectedInsight} />
      </Col>
      <Col margin="0 0 0 10px">
        <Headline margin="0 0 10px 0">Reveal Insights</Headline>
        <StyledBox padding="6px">
          <Col margin={'10px 10px 15px 10px'}>
            <InsightHeader insightKey={selectedInsight} />
            <StyledDivider border />
            {<InsightContent insights={insights} />}
          </Col>
        </StyledBox>
      </Col>
    </Row>
  );
}

const InsightSelection = ({selectedInsight, setSelectedInsight}) => {
  const insightKeys = Object.keys(mapping);

  return (
    <>
      {insightKeys.map((insightKey) => {
        return (
          <Headline
            key={insightKey}
            margin="0 0 8px 0"
            fontSize="14px"
            fontSizeMedium="14px"
            hoverColor={darkGray}
            style={{cursor: 'pointer'}}
            color={insightKey === selectedInsight ? 'initial' : lightGray}
            onClick={() => setSelectedInsight(insightKey)}
          >
            {capitalize(mapping[insightKey])}
          </Headline>
        );
      })}
    </>
  );
};

const InsightContent = ({insights}: {insights: AudienceInsight[]}) => {
  return (
    <InsightContainer>
      {insights.map((insight, index) => {
        return (
          <Fragment key={index}>
            {getInsightRow(insight.value, insight.count, (insight.fraction * 100).toFixed(0))}
          </Fragment>
        );
      })}
    </InsightContainer>
  );
};

const InsightHeader = ({insightKey}: {insightKey: string}) => {
  return (
    <InsightContainer>
      {getInsightRow(
        <Text color="black" fontWeight={600}>
          {`Top ${mapping[insightKey]}`}
        </Text>,
        'Leads',
        '%'
      )}
    </InsightContainer>
  );
};

const getInsightRow = (header, value, percentage) => {
  return (
    <>
      <GridItem>
        <Text>{header}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{value}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{percentage}</Text>
      </GridItem>
    </>
  );
};

const InsightContainer = ({children}) => {
  return (
    <GridContainer columns="4fr 1fr 1fr" columnGap="5px" rowGap="5px">
      {children}
    </GridContainer>
  );
};
