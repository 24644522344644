import React, {useContext} from 'react';

import {
  Col,
  MainContentView,
  StyledNavTop,
  Headline,
  StyledBox,
  Text,
  Row,
  StyledSwitch,
  Tooltip,
  lightGray,
} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {AccountContext} from '../../contexts/AccountContext';
import {useSetNewPage} from '../../functions/util';
import AccountInlineNav from './AccountInlineNav';

export default function DataCollectionContainer() {
  useSetNewPage('Data collection');

  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'privacy'}>
          <Col margin="20px 0 0 0">
            <DataCollectionSettings />
          </Col>
        </AccountInlineNav>
      </MainContentView>
    </>
  );
}

const DataCollectionSettings = () => {
  const {isLoading: accountIsLoading} = useContext(AccountContext)!;
  if (accountIsLoading) return <MainContentSkeleton />;
  return (
    <>
      <Headline margin={'0 0 10px 0'}>Data collection settings</Headline>
      <StyledBox maxWidth={'fit-content'}>
        <Row alignItems="center" margin="0 0 20px 0">
          <Text margin="0 10px 0 0">Default mode of visitor behavioral data collection</Text>
          <Tooltip
            iconStyles={{color: lightGray}}
            content={
              'By default, behavioral data is collected for personalization purposes before visitor consent. To comply with local privacy laws, select Deny if you have traffic from EU (GDPR) or California (CCPA). In Deny mode, we automatically detect whether opt-in is required based on visitor geolocation. Selecting Deny affects our ability to track users so it has implications to behavioral and conversion tracking data accuracy.'
            }
          />
        </Row>
        <Row alignItems="center">
          <Text margin="0 10px 0 0">Allow</Text>
          <DataCollectionSwitch />
          <Text margin="0 0 0 10px">Deny</Text>
        </Row>
      </StyledBox>
    </>
  );
};

const DataCollectionSwitch = () => {
  const accountContext = useContext(AccountContext)!;
  const {changeDataCollectionMode, account} = accountContext;
  if (!account) return null;
  const {dataCollectionMode} = account;

  return (
    <StyledSwitch
      defaultState={dataCollectionMode === 'defaultAllow'}
      onChange={(state) => changeDataCollectionMode(state ? 'defaultDeny' : 'defaultAllow')}
    />
  );
};

const MainContentSkeleton = () => <Skeleton width={400} height={100} />;
