import React, {useContext} from 'react';

import {Col, Headline, ICrmDataSource, StyledBtn} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

import {SegmentContext} from '../../contexts/SegmentContext';
import EmptyScreen from '../common/EmptyScreen';
import {OutboundListType} from './OutboundContainerContent';
import {ISearchFilter} from './OutboundFilterDropdown';
import {DataRow} from './table/OutboundTable';
import {getFilteredData, useGetOutboundSegmentData} from './util';

export const OutboundEmpty = () => {
  const history = useHistory();
  return (
    <EmptyScreen>
      <>
        <Headline>
          Looks like you don't have any data in your segment. Go back to segment overview to create a new outbound or
          inbound segment.
        </Headline>
        <StyledBtn onClick={() => history.push('/segments')}>Go to Segments</StyledBtn>
      </>
    </EmptyScreen>
  );
};

export const IntegrationNotActive = () => {
  const history = useHistory();
  return (
    <EmptyScreen>
      <>
        <Headline>
          The integration related to the outbound segment is no longer active. Go to integrations to activate it again.
        </Headline>
        <StyledBtn onClick={() => history.push('/integrations')}>Go to Integrations</StyledBtn>
      </>
    </EmptyScreen>
  );
};

export const OutboundSkeleton = () => {
  return (
    <Col margin="30px 0 0 0">
      {[...Array(5)].map((_, idx) => {
        return (
          <Col key={idx} margin="10px 0 10px 0">
            <Skeleton width={'70vw'} height={'8vh'} />
          </Col>
        );
      })}
    </Col>
  );
};

interface EmptyTableProps {
  listTypeSelected: OutboundListType;
  dataFilter: ISearchFilter;
}

export const EmptyTable = ({listTypeSelected, dataFilter}: EmptyTableProps) => {
  const segmentContext = useContext(SegmentContext)!;
  const {segments, segmentId, outboundAllData} = segmentContext.state;
  const segment = segments[segmentId];
  const crmObjectType: string = segment.outbound!.crmObjectType;
  const crmDataSource: ICrmDataSource = segment.outbound!.crmDataSource;

  const outboundSegmentData = useGetOutboundSegmentData();

  const outboundFilteredData: DataRow[] = getFilteredData(outboundAllData, dataFilter);
  const segmentFilteredData: DataRow[] = getFilteredData(outboundSegmentData, dataFilter);

  const outboundType = crmDataSource === 'csv' ? 'contacts' : crmObjectType;
  const filteredAllListIsEmpty = listTypeSelected === 'allList' && isEmpty(outboundFilteredData);
  const filteredSegmentListIsEmpty = listTypeSelected !== 'allList' && isEmpty(segmentFilteredData);
  return (
    <Col margin="20px 0 0 20px">
      {filteredAllListIsEmpty && <NoMatchComponent outboundType={outboundType} />}
      {filteredSegmentListIsEmpty && (
        <>
          {isEmpty(outboundSegmentData) ? (
            <>
              <Headline textAlign="center">Your segment is empty.</Headline>
              <Headline textAlign="center">Add contacts from the main list to this one.</Headline>
            </>
          ) : (
            <NoMatchComponent outboundType={outboundType} />
          )}
        </>
      )}
    </Col>
  );
};

const NoMatchComponent = ({outboundType}) => {
  return (
    <Headline margin="0 0 10px 0" textAlign="center">
      {`Your filter criteria didn't match any ${outboundType}.`}
    </Headline>
  );
};
