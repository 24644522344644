import React from 'react';

import {Headline, StyledBtn} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import EmptyScreen from '../common/EmptyScreen';

export const EmptyRecommendations = () => {
  const history = useHistory();
  return (
    <EmptyScreen>
      <>
        <Headline>
          Looks like you need more visitor data for us to create recommendations for you. Please create a custom
          segment.
        </Headline>
        <StyledBtn onClick={() => history.goBack()}>Create a custom segment</StyledBtn>
      </>
    </EmptyScreen>
  );
};
