import React from 'react';

import {
  StyledTable,
  ITableHeader,
  Headline,
  StyledLink,
  Row,
  useIsWidthSmaller,
} from '@markettailor/common-markettailor';
import moment from 'moment';

import {IRevealCompany} from '../../contexts/RevealContext';
import {usePrepareListData} from './util';

//import FlagIcon from '../common/FlagIcon';

export interface IListItem extends IRevealCompany {
  countryLong: string;
}

export default function RevealTable() {
  const sortedListData = usePrepareListData();

  const getListItems = () => {
    return sortedListData.map((company) => {
      return {
        ...company,
        id: company.website,
        companyName: company.tradeName || company.companyName,
        website: <WebsiteLink company={company} />,
        location: <Location company={company} />,
        lastSeen: <Headline fontSize="14px">{moment(company.lastSeen).fromNow()}</Headline>,
      };
    });
  };

  const commonHeaders: ITableHeader[] = [
    {companyName: 'Company'},
    {website: 'Website'},
    {location: 'Location'},
    {employees: 'Employees'},
    {revenue: 'Revenue'},
    {naicsDesc: 'Industry'},
  ];
  const mediumDisplayHeaders: ITableHeader[] = [{lastSeen: 'Last seen'}];
  const headers = useIsWidthSmaller(1200) ? commonHeaders : [...commonHeaders, ...mediumDisplayHeaders];

  const items = getListItems();
  return (
    <StyledTable
      headers={headers}
      items={items}
      style={{
        headText: {padding: '10px'},
        cell: {padding: '10px'},
        tableGrid: {
          columns: '[col-start] minmax(100px, 1fr) [col-end]',
        },
      }}
    />
  );
}
const Location = ({company}) => {
  return (
    <>
      <Headline color="#000">{company.city}</Headline>
      <Row>
        {/*<FlagIcon country={company.countryShort} />*/}
        <Headline margin="0 0 0 5px" fontSizeLarge="14px" fontSize="12px" fontSizeMedium="12px">
          {company.countryLong}
        </Headline>
      </Row>
    </>
  );
};

const WebsiteLink = ({company}) => {
  return (
    <StyledLink href={'https://' + company.website} target="_blank" textDecoration="underline" color="#000">
      <Headline color="#000" cursor="pointer">
        {company.website}
      </Headline>
    </StyledLink>
  );
};
