import React, {useContext} from 'react';

import {Col, MainContentView, Row, StyledNavTop} from '@markettailor/common-markettailor';

import {StripeContext} from '../../../contexts/StripeContext';
import {useSetNewPage} from '../../../functions/util';
import AccountInlineNav from '../AccountInlineNav';
import Billing from './Billing';
import {RenderSkeleton} from './emptyStates';
import Payment from './Payment';
import Plan from './Plan';

export default function Subscription() {
  useSetNewPage('Subscription');

  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'subscription'}></AccountInlineNav>
        <ContainerContent />
      </MainContentView>
    </>
  );
}

const ContainerContent = () => {
  const {isLoading} = useContext(StripeContext)!.state;

  if (isLoading) return <RenderSkeleton />;
  return (
    <Col margin="20px 0 0 40px">
      <Row>
        <Col margin="0 20px 0 0">
          <Plan />
        </Col>
        <Col margin="0 20px 0 0">
          <Billing />
        </Col>
        <Payment />
      </Row>
    </Col>
  );
};
