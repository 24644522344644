import React, {useContext} from 'react';

import {Col, TextLink} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {EditorContext} from '../../../contexts/EditorContext';
import {formatUrl} from '../../../functions/util';
import {PageTitle} from '../../../styles/editor/editorStyles';

export const PageTitleContainer = () => {
  const history = useHistory();
  const editorContext = useContext(EditorContext)!;
  const {pageUrl, errorState} = editorContext!;
  const {docTitle} = editorContext;

  const hasError = Boolean(errorState);

  const getPageTitle = () => {
    if (!docTitle) return formatUrl(pageUrl);
    if (docTitle.length > 80) return docTitle.substring(0, 80) + '...';
    return docTitle;
  };

  const pageTitle = getPageTitle();

  return (
    <Col justifyContent={'center'}>
      <PageTitle
        margin={hasError ? '0' : '0 0 5px 0'}
        pointer={hasError}
        underline={hasError}
        onClick={() => (hasError ? history.goBack() : '')}
      >
        {hasError ? 'Go back' : pageTitle}
      </PageTitle>
      <TextLink>{formatUrl(pageUrl)}</TextLink>
    </Col>
  );
};
