import React, {useState, useContext, ChangeEvent} from 'react';

import {Col, PopoverWindow, Headline, StyledBtn, Text, brandMain} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

import {IntegrationContext} from '../../../contexts/IntegrationContext';
import CrmBadge from './CrmBadge';

export default function ActiveCampaignBadge() {
  const {authenticateCrmIntegration} = useContext(IntegrationContext)!;
  const [openSettings, setOpenSettings] = useState(false);

  const handleSubmit = async (url: string, apiKey: string) => {
    await authenticateCrmIntegration('activeCampaign', {url, apiKey});
    setOpenSettings(false);
  };

  return (
    <>
      <PopoverWindow display={openSettings} handleClose={() => setOpenSettings(false)}>
        <OptionsWindow handleSubmit={handleSubmit}></OptionsWindow>
      </PopoverWindow>
      <CrmBadge
        integrationName="activeCampaign"
        logoWidth="140px"
        authorizeCallback={() => setOpenSettings(true)}
      ></CrmBadge>
    </>
  );
}

interface Props {
  handleSubmit: (url: string, apiKey: string) => void;
}
function OptionsWindow(props: Props): JSX.Element {
  const [url, setUrl] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');

  return (
    <Col minWidth={'500px'} alignItems={'center'}>
      <Headline color={brandMain}>{'ActiveCampaign integration'}</Headline>
      <Col>
        <Text margin={'10px 50px 0 50px '}>
          Set your ActiveCampaign API URL and API key to integrate with Markettailor
        </Text>
        <Text margin={'10px 50px 0 50px '}>
          To find your keys, go to your CRM and find "Settings {'⇨'} Developer {'⇨'} API Access"
        </Text>
        <Text margin={'10px 50px 0 50px '}>
          {'OR Navigate there directly with: https://<you domain>.activehosted.com/app/settings/developer'}
        </Text>
      </Col>
      <Col alignItems={'center'} margin={'40px 0 0 0 '}>
        <TextField
          required
          id="url"
          label="URL"
          name={'url'}
          type={'text'}
          variant={'outlined'}
          value={url}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value.trim())}
        />
        <TextField
          required
          id="apiKey"
          label="API key"
          name={'apiKey'}
          type={'text'}
          variant={'outlined'}
          value={apiKey}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value.trim())}
        />
        <StyledBtn onClick={() => props.handleSubmit(url, apiKey)}>Integrate</StyledBtn>
      </Col>
    </Col>
  );
}
