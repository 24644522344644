import {useEffect, useContext, useState} from 'react';

import {ISegment, NotificationContext, IIntegrations, IPermissions} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';

import config from '../config.json';
import {getLabelExampleRow} from '../functions/util';
import {mapping} from '../termMaps/technicalNamesToLabels';
import {AccountContext} from './AccountContext';
import type {IDynamicValues} from './editorContextTypes';
import {IntegrationContext, crmDataSources} from './IntegrationContext';
import {IOutboundPagedItemsResponse, SegmentContext} from './SegmentContext';

export const defaultDynamicValueNames = ['mt_country', 'mt_city', 'mt_region', 'mt_preferredLanguage'];
const zoominfoDynamicValueNames = ['mt_revenue', 'mt_employeeCount', 'mt_industry', 'mt_companyName', 'mt_website'];
const clearbitDynamicValueNames = ['mt_revenue', 'mt_employees', 'mt_sicSector', 'mt_sicGroup', 'mt_sicCode'];
export const companyDynamicValueNames = [...new Set([...clearbitDynamicValueNames, ...zoominfoDynamicValueNames])];

export const combineDynamicValues = (objectList: IDynamicValues) => {
  return objectList.reduce((acc, value) => {
    return {...acc, ...{[value.key]: value.value}};
  }, {});
};

export const useGetDynamicValues = (segmentId: string) => {
  const source: CancelTokenSource = axios.CancelToken.source();
  const accountContext = useContext(AccountContext)!;
  const {permissions} = accountContext.account || {};
  const segmentContext = useContext(SegmentContext)!;
  const {integrations, isLoading: integrationsIsLoading} = useContext(IntegrationContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [dynamicValues, setDynamicValues] = useState<IDynamicValues | undefined>();
  const {segments, isLoading: segmentContextIsLoading} = segmentContext!.state;

  useEffect(() => {
    if (segmentContextIsLoading || integrationsIsLoading || !segments[segmentId] || !permissions) return;
    const awaitWrapper = async () => {
      try {
        const dynamicValues = await getDynamicValues(permissions, segments[segmentId], integrations, source);
        setDynamicValues(dynamicValues);
      } catch (e) {
        if (axios.isCancel(e)) return;
        setInfoNotification({message: 'Getting personalization variables failed', level: 'warn'});
        console.debug(e);
      }
    };
    awaitWrapper();
    return () => source.cancel('Editor unmounted.');
  }, [segmentId, segmentContextIsLoading, integrationsIsLoading, permissions]);

  return dynamicValues;
};

export const getDynamicValues = async (
  permissions: IPermissions,
  segment: ISegment,
  integrations: IIntegrations,
  source: CancelTokenSource
) => {
  let dynamicValues: IDynamicValues = [
    ...getCompanyLookupDynamicValues(permissions, integrations),
    ...getDefaultDynamicValues(),
    ...(await getOutboundDynamicValues(segment, integrations, source)),
  ];
  const crmDynamicValues = await getCrmDynamicValues(source);
  dynamicValues = concatWithoutDuplicates(crmDynamicValues, dynamicValues);
  return dynamicValues.sort((val1, val2) => val1.key.localeCompare(val2.key));
};

const concatWithoutDuplicates = (values: IDynamicValues, dynamicValues: IDynamicValues) => {
  const uniqueValues = values.filter((field) => {
    const keys = dynamicValues.map((field) => field.key);
    return !keys.includes(field.key);
  });
  return dynamicValues.concat(uniqueValues);
};

const getDefaultDynamicValues = () => {
  const defaultSelectors = ['country', 'city', 'region', 'preferredLanguage'];
  return defaultSelectors.map((selector) => {
    // @ts-ignore
    const values: string[] = Object.values(mapping[selector]);
    return {key: 'mt_' + selector, value: getRandomExample<string>(values)};
  });
};

const getOutboundDynamicValues = async (segment: ISegment, integrations: IIntegrations, source: CancelTokenSource) => {
  if (!segment.outbound) return [];

  const {crmDataSource} = segment.outbound;
  const isCrm = crmDataSources.includes(crmDataSource);
  const isCrmActive = isCrm && integrations[crmDataSource]?.isActive;
  if (crmDataSource !== 'csv' && !isCrmActive) return [];

  const res = await axios.get<IOutboundPagedItemsResponse>(
    config.api.baseURL + 'outbound/pagedItems/' + segment.segmentId,
    {cancelToken: source.token, params: {pagesToFetch: 1}}
  );

  const mergedData = res.data.items.map((item) => ({...item.original, ...item.changes}));
  const exampleRowWithUndefined: {[key: string]: string | undefined} = getLabelExampleRow(mergedData, [
    'id',
    'personalizedLink',
  ]);
  const exampleRow: {[key: string]: string} = Object.entries(exampleRowWithUndefined).reduce(
    (acc, [key, value]) => ({...acc, [key]: value || ''}),
    {}
  );
  const {crmObjectType} = segment.outbound;
  const outboundDynamicValues = Object.entries(exampleRow).map(([col, example]) => {
    const crmObjectTypeUsed = ['companyName', 'industry'].includes(col) ? 'company' : crmObjectType;
    return {key: 'mt_' + crmDataSource + '_' + crmObjectTypeUsed + '_' + col, value: example};
  });
  return outboundDynamicValues;
};

const getCompanyLookupDynamicValues = (permissions: IPermissions, integrations: IIntegrations) => {
  const isCompanyLookupActive = permissions.companyLookups;
  if (!isCompanyLookupActive) return [];
  if (integrations.zoominfo?.isActive) {
    return [
      {key: 'mt_companyName', value: 'Bank of America'},
      {key: 'mt_revenue', value: '21306298'},
      {key: 'mt_employeeCount', value: '43863'},
      {key: 'mt_industry', value: 'Finance'},
      {key: 'mt_website', value: 'www.bankofamerica.com'},
    ];
  }
  const companyLookupDynamicValues = [
    {key: 'mt_revenue', value: '$1M-$5M'},
    {key: 'mt_employees', value: '50-100'},
    {key: 'mt_sicSector', value: 'Insurance, Real Estate, and Finance'},
    {key: 'mt_sicGroup', value: 'Communications'},
    {key: 'mt_sicCode', value: 'Computers, Computer Peripheral Equipment and Software'},
  ];
  if (integrations.clearbit?.isActive)
    companyLookupDynamicValues.push({key: 'mt_logo', value: 'https://logo.clearbit.com/markettailor.io'});
  return companyLookupDynamicValues;
};

function getRandomExample<T = any>(values: T[]) {
  const valuesFiltered = values.filter((val) => val);
  const index = Math.floor(Math.random() * values.length + 1);
  return valuesFiltered[index];
}

const getCrmDynamicValues = async (source: CancelTokenSource) => {
  const res = await axios.get<{[key: string]: string}>(config.api.baseURL + `crmExampleDynamicValues`, {
    cancelToken: source.token,
  });
  const crmDynamicValues = Object.entries(res.data).map(([key, value]) => ({
    key: 'mt_' + key.replaceAll('&|', '_'),
    value: value || key.split('&|')[2],
  }));
  return crmDynamicValues;
};
