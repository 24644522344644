import React, {createContext, useEffect, useState, ReactNode, useContext} from 'react';

import {NotificationContext} from '@markettailor/common-markettailor';
import axios from 'axios';
import {cloneDeep} from 'lodash';

import {getConversionEvents} from '../components/conversions/util';
import config from '../config.json';
import {useSendConversionAnalytics} from '../functions/analytics';
import {deepFreeze} from '../functions/util';
import {ConversionAnalyticsContext, ConversionSourceType, ConversionType} from './ConversionAnalyticsContext';
import {IConversionDomElem, ConversionDomElemsType, useConversionDomElems} from './conversionManagement/domElems';

export interface IConversionEventCreation {
  eventId?: string;
  eventName: string;
  isActive: boolean;
  type: ConversionType;
  source: ConversionSourceType;
  pageUrl?: string;
}

export interface IConversionEvent extends IConversionEventCreation {
  eventId: string;
}
export interface IEvents {
  [eventId: string]: IConversionEvent;
}

interface IMainConversionMetric {
  eventId: string;
  source: ConversionSourceType;
}

type ConversionUpdateType = 'rename' | 'isVisible';
export interface IConversionEventContext {
  isLoading: boolean;
  events: IEvents;
  mainConversionMetric?: string;
  updateMainConversionMetric: (
    conversionSource: ConversionSourceType,
    newMainConversionMetric: string
  ) => Promise<void>;
  updateConversionEvent: (
    conversionEventId: string,
    conversionEventChanges: IConversionEvent,
    changeType: ConversionUpdateType
  ) => void;
  createConversionEvent: (conversionEvent: IConversionEventCreation) => void;
  deleteConversionEvent: (conversionEventId: string) => void;
  getMainConversionMetric: () => Promise<void>;
  conversionDomElems: ConversionDomElemsType;
  createConversionDomElem: (elem: IConversionDomElem) => Promise<void>;
  deleteConversionDomElem: (elemId: string) => Promise<void>;
}

const ConversionManagementContext = createContext<IConversionEventContext | undefined>(undefined);

function ConversionManagementProvider({children}: {children: ReactNode}) {
  const source = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {conversions, isLoading: inferredEventsIsLoading} = useContext(ConversionAnalyticsContext)!;
  const [savedEvents, setSavedEvents] = useState<IEvents>({});
  const [combinedEvents, setCombinedEvents] = useState<IEvents>({});
  const [analyticsInferredEvents, setAnalyticsInferredEvents] = useState<IEvents>({});
  const [mainConversionMetric, setMainConversionMetric] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    conversionDomElems,
    getConversionDomElems,
    createConversionDomElem,
    deleteConversionDomElem,
    isLoading: conversionDomElemsIsLoading,
  } = useConversionDomElems(source);

  useSendConversionAnalytics(
    mainConversionMetric,
    combinedEvents,
    isLoading && conversionDomElemsIsLoading && inferredEventsIsLoading
  );

  useEffect(() => {
    getConversions();
    return () => source.cancel('Conversions unmounted.');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCombinedEvents({...savedEvents, ...analyticsInferredEvents});
    // eslint-disable-next-line
  }, [savedEvents, analyticsInferredEvents]);

  useEffect(() => {
    /* Segment events are generated from analytics but saved events take priority */
    const conversionsClone = cloneDeep(conversions);
    const savedEventKeys = Object.keys(savedEvents);
    const analyticsInferredEvents: IEvents = Object.values(getConversionEvents(conversionsClone)).reduce(
      (acc, event) => {
        // @ts-ignore
        const eventCasted: IConversionEvent = {...event, isActive: false};
        delete eventCasted['count'];
        return !savedEventKeys.includes(event.eventId) ? {[event.eventId]: eventCasted, ...acc} : acc;
      },
      {}
    );
    setAnalyticsInferredEvents(analyticsInferredEvents);
    // eslint-disable-next-line
  }, [conversions, savedEvents]);

  async function getConversions() {
    try {
      const eventPromise = refreshEvents();
      const mainMetricPromise = getMainConversionMetric();
      await Promise.all([eventPromise, mainMetricPromise]);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Getting conversions failed', level: 'error'});
      console.debug(e);
    }
  }

  const refreshEvents = async () => {
    const res = await axios.get(config.api.baseURL + 'conversionEvents', {
      cancelToken: source.token,
    });

    const savedEvents: IEvents = res.data;
    setSavedEvents(savedEvents);
  };

  const getMainConversionMetric = async () => {
    try {
      const res = await axios.get<IMainConversionMetric>(config.api.baseURL + 'conversionEventMain', {
        cancelToken: source.token,
      });
      setMainConversionMetric(res.data.eventId);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  const updateMainConversionMetric = async (
    conversionSource: ConversionSourceType,
    newMainConversionMetricId: string
  ) => {
    const oldMainConversionMetric = mainConversionMetric;
    try {
      setMainConversionMetric(newMainConversionMetricId);
      await axios.post(
        config.api.baseURL + 'conversionEventMain',
        {source: conversionSource, eventId: newMainConversionMetricId},
        {cancelToken: source.token}
      );
    } catch (e) {
      if (axios.isCancel(e)) return;
      setMainConversionMetric(oldMainConversionMetric);
      setInfoNotification({message: 'Changing main conversion event failed', level: 'error'});
      console.error(e);
    }
  };

  const updateConversionEvent = async (
    eventId: string,
    conversionEvent: IConversionEvent,
    changeType: ConversionUpdateType
  ) => {
    try {
      const res = await axios.put(config.api.baseURL + 'conversionEvent/' + eventId, conversionEvent, {
        cancelToken: source.token,
      });
      setSavedEvents(res.data);
      changeType === 'rename' && setInfoNotification({message: 'Conversion event renamed'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.error(e);
      setInfoNotification({
        message: 'Conversion event update failed',
        level: 'error',
      });
    }
  };

  const deleteConversionEvent = async (conversionEventId: string) => {
    try {
      const res = await axios.delete(config.api.baseURL + 'conversionEvent/' + conversionEventId, {
        cancelToken: source.token,
      });
      setSavedEvents(res.data);
      const pagePromise = getConversionDomElems();
      const mainMetricPromise = getMainConversionMetric();
      await Promise.all([pagePromise, mainMetricPromise]);
      setInfoNotification({message: 'Conversion event deleted'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.error(e);
      setInfoNotification({
        message: 'Conversion event delete failed',
        level: 'error',
      });
    }
  };

  const createConversionEvent = async (conversionEvent: IConversionEventCreation) => {
    try {
      const res = await axios.post(config.api.baseURL + 'conversionEvents', conversionEvent, {
        cancelToken: source.token,
      });
      setSavedEvents(res.data);
      setInfoNotification({message: 'Conversion event created'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.error(e);
      setInfoNotification({
        message: 'Conversion event creation created',
        level: 'error',
      });
    }
  };

  return (
    <ConversionManagementContext.Provider
      value={deepFreeze<IConversionEventContext>({
        isLoading: isLoading || conversionDomElemsIsLoading || inferredEventsIsLoading,
        events: combinedEvents,
        mainConversionMetric,
        updateMainConversionMetric,
        updateConversionEvent,
        createConversionEvent,
        deleteConversionEvent,
        getMainConversionMetric,
        conversionDomElems: conversionDomElems || {},
        createConversionDomElem,
        deleteConversionDomElem,
      })}
    >
      {children}
    </ConversionManagementContext.Provider>
  );
}

export {ConversionManagementContext, ConversionManagementProvider};
