import React, {useContext, useEffect} from 'react';

import Skeleton from 'react-loading-skeleton';

import {EditorContext, EDITOR_ID} from '../../contexts/EditorContext';
import {StyledEditor, StyledViewbox} from '../../styles/editor/editorStyles';
import '../../styles/editor/editorStyles.css';
import EditorError from './EditorError';
import {setInitialIframeContent} from './localEditor/util';
import SideControls from './sideControls/SideControls';
import EditorTopNav from './topNav/EditorTopNav';
import {useSetPageEditor} from './util';

export default function EditorContainer() {
  const editorContext = useContext(EditorContext);
  const {iframeRequestData, errorState} = editorContext!;

  useSetPageEditor();

  useEffect(() => {
    setInitialIframeContent(iframeRequestData);
  }, [iframeRequestData]);

  if (errorState) return <EditorError errorState={errorState} />;
  return (
    <>
      <EditorTopNav />
      <StyledEditor>
        <StyledViewbox>
          {iframeRequestData ? (
            <iframe
              width={editorContext?.editorDimensionsController.width || '100%'}
              height={editorContext?.editorDimensionsController.height || '100%'}
              className="editor-iframe"
              id={EDITOR_ID}
              title={EDITOR_ID}
              sandbox={'allow-scripts allow-same-origin'}
              srcDoc={iframeRequestData}
            />
          ) : (
            <Skeleton
              width={'100%'}
              height={'100%'}
              style={{
                boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.16)',
                borderRadius: '10px',
                backgroundColor: 'white',
                backgroundImage: 'linear-gradient( 90deg,#fff,#f5f5f5,#fff)',
              }}
            />
          )}
        </StyledViewbox>
        <SideControls />
      </StyledEditor>
    </>
  );
}
