import React from 'react';

import {
  GridItem,
  IEditOption,
  IEditOptionCallback,
  RowInterface,
  TableEditButton,
  Col,
} from '@markettailor/common-markettailor';

interface IEditButton {
  row: RowInterface;
  editOptions: IEditOption[];
  droppableId?: string;
}
export function EditButton({row, editOptions, droppableId = undefined}: IEditButton): JSX.Element {
  return (
    <GridItem
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      justifySelf={'center'}
      style={{width: !droppableId ? '' : '100%'}}
    >
      <Col alignItems={'center'} height={'100%'} justifyContent={'center'}>
        <TableEditButton
          editOptions={editOptions || []}
          rowIsActive={row.rowIsActive}
          rowOptionSet={row.rowOptionSet}
          handleMenuClick={(editOptionCallback: IEditOptionCallback, selectedText: string) => {
            editOptionCallback(row.id, selectedText);
          }}
        />
      </Col>
    </GridItem>
  );
}
