import {IAnalytics} from '@markettailor/common-markettailor';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import config from '../config.json';
import {getAccountId} from './analytics';
import {isDev, isLocalhost} from './util';

declare global {
  interface Window {
    analytics: any;
    Intercom: any;
  }
}

export class SegmentAnalytics implements IAnalytics {
  // This is a class for wrapping segment.io analytics into one object
  track: (eventType: string, eventProperties?: any) => void;
  identify: (userId?: string | null, identityProperties?: any) => void;
  page: (pageName: string) => void;
  group: (accountId?: string | null, accountProperties?: any) => void;
  constructor() {
    const identifyAnalytics = (userId: string | null = null, identityProperties: any = {}) => {
      if (window.analytics === undefined) return;
      if (userId !== null) {
        window.analytics.identify(userId, identityProperties);
      } else {
        window.analytics.identify(identityProperties);
      }
      if (userId !== null && identityProperties.email) {
        identifySentry(userId, identityProperties.email);
        identifyLogRocket(userId, identityProperties.email);
      }
    };
    const groupAnalytics = (accountId: string | null = null, accountProperties: any = {}) => {
      if (window.analytics === undefined) return;
      if (accountId !== null) {
        window.analytics.group(accountId, accountProperties);
      } else {
        window.analytics.group(accountProperties);
      }
    };
    const pageAnalytics = (pageName: string) => {
      if (window.analytics === undefined) return;
      window.analytics.page(pageName);
    };
    const trackAnalytics = (eventType: string, eventProperties = {}) => {
      if (window.analytics === undefined) return;
      window.analytics.track(eventType, {...eventProperties, accountId: getAccountId()});
    };

    if (isDev()) {
      this.track = () => undefined;
      this.identify = () => undefined;
      this.page = () => undefined;
      this.group = () => undefined;
      return;
    }

    this.track = trackAnalytics;
    this.identify = identifyAnalytics;
    this.page = pageAnalytics;
    this.group = groupAnalytics;
  }
}

const identifySentry = (userId: string, email: string) => {
  if (isDev()) return;
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId,
      email: email,
    });
  });
};

// Intercom is temporarily disabled and chat functionality moved to Hubspot. Intention to move back when the budget allows.
function identifyLogRocket(userId: string, email: string) {
  if (!isDev()) {
    LogRocket.identify(userId, {email});
  }
}

// Intercom is temporarily disabled and chat functionality moved to Hubspot. Intention to move back when the budget allows.
export const initializeIntercom = (email: string, userId: string) => {
  if (!isLocalhost()) {
    window.Intercom('boot', {
      app_id: config.support.INTERCOM_ID,
      email: email,
      user_id: userId,
    });
  }
};

export const updateIntercom = () => {
  if (!isLocalhost()) window.Intercom('update');
};
