import React, {useState, useContext} from 'react';

import {Row, StyledBtn} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {EditorContext} from '../../../contexts/EditorContext';
import {DynamicValuesMenu} from './DynamicValuesMenu';

export interface PersonalizationVariableBtnProps {
  handleSelection: (selector: string) => void;
  isDisabled: boolean;
}

export default function PersonalizationVariableBtn(props: PersonalizationVariableBtnProps) {
  const editorContext = useContext(EditorContext)!;
  const [openVariableMenu, setOpenVariableMenu] = useState<boolean>(false);
  const {dynamicValuesOrdered} = editorContext;

  const {isDisabled} = props;

  return (
    <>
      <Row alignItems={'center'}>
        {dynamicValuesOrdered && (
          <DynamicValuesMenu {...props} openVariableMenu={openVariableMenu} setOpenVariableMenu={setOpenVariableMenu} />
        )}
      </Row>
      <StyledBtn
        data-intercom-id="editor-insertVariable-button"
        borderRadius={'4px'}
        maxWidth={'100%'}
        minWidth={'90%'}
        minHeight={'30px'}
        height={'30px'}
        fontSize={'12px'}
        padding={'0'}
        boxShadow={isDisabled ? 'none' : '2px 2px 6px #a6a6a6'}
        disabled={isDisabled || isEmpty(dynamicValuesOrdered)}
        onClick={() => setOpenVariableMenu(true)}
      >
        Insert personalization variable
      </StyledBtn>
    </>
  );
}
