import React, {useContext} from 'react';

import {
  StyledBox,
  Row,
  Col,
  Headline,
  Text,
  NumberLarge,
  StyledBtn,
  ISegment,
  IPage,
  FeatureFlag,
  brandMain,
  extraExtraLightGray,
  NotificationContext,
} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import {AccountContext} from '../../contexts/AccountContext';
import {RecommendationType} from '../../contexts/RecommendationContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {formatNum, parsePercent} from '../../functions/util';
import {useAllMapping} from '../../termMaps/technicalNamesToLabels';

interface RecommendationComponentProps {
  recommendation: RecommendationType;
}

export default function Recommendation(props: RecommendationComponentProps) {
  const {recommendation} = props;
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const accountContext = useContext(AccountContext);
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {updateSegment} = segmentContext!;
  const {segments, segmentId, pageId} = useContext(SegmentContext)!.state;
  const mapping = useAllMapping();
  function getLabelByTechName(techName: string, mappingField: keyof typeof mapping | '' = ''): string {
    if (
      mappingField &&
      Object.keys(mapping).includes(mappingField) &&
      Object.keys(mapping[mappingField]).includes(techName)
    ) {
      const selectedMapping: {[key: string]: string} = mapping[mappingField];
      return selectedMapping[techName];
    }
    return techName;
  }

  async function handleCreateSegment() {
    const page: IPage = segments[segmentId].pageUrls[pageId];
    const segmentData: Partial<ISegment> = {
      isActive: true,
      segmentType: 'inbound',
      lastEditDate: new Date(),
      segmentName: 'Visitors with ' + selectorLabel + ': ' + valueLabel,
      pageUrls: {[pageId]: page},
      segmentQuery: {
        queryRequest: [
          {
            filterId: uuid(),
            selector: recommendation.selector,
            condition: '__equals',
            valueRange: [recommendation.value],
          },
        ],
        filterCondition: 'and',
      },
    };
    updateSegment(segmentId, segmentData);
    setInfoNotification({message: 'Segment created'});
    history.push('segmentation/' + segmentId);
  }

  const selectorLabel = getLabelByTechName(recommendation.selector, 'selector').toLowerCase();
  const valueLabel = getLabelByTechName(recommendation.value, recommendation.selector);
  const visitorType = ['country', 'region', 'city', 'preferredLanguage'].includes(recommendation.selector)
    ? 'visitors'
    : 'companies';

  return (
    <Row>
      <StyledBox padding={'0 0 0 0'} margin={'0 20px 40px 0'} overflow="hidden">
        <StyledBox
          background={brandMain}
          borderRadius="0"
          border="0"
          margin="10px 0 0 0"
          boxShadow="none"
          justifyContent="center"
        >
          <Headline color="white">
            Personalize for {visitorType} with {selectorLabel}: <strong>{valueLabel.toString()}</strong>
          </Headline>
        </StyledBox>
        <StyledBox
          margin="0"
          border="0"
          borderRadius="0"
          boxShadow="none"
          padding="0px 15px 20px 15px"
          background={extraExtraLightGray}
        >
          <Headline color={'black'} margin={'20px 0 10px 0'}>
            Recommendation based on
          </Headline>
          <Row>
            <Col>
              <StyledBox padding={'10px 0 10px 30px'} margin={'0 10px 20px 0'} width="300px">
                <Headline margin={'0 0 10px 0'}>Segment size</Headline>
                <Row alignItems={'baseline'}>
                  <NumberLarge margin={'0 5px 0 0'}>{formatNum(recommendation.count)}</NumberLarge>
                  <Text>({parsePercent(recommendation.fraction, 0)}) monthly visitors</Text>
                </Row>
              </StyledBox>
            </Col>
            <Col>
              <FeatureFlag userEmail={accountContext?.account?.email}>
                <StyledBox padding={'10px 0 10px 30px'} margin={'0 0 20px 10px'} width="350px">
                  <Headline margin={'0 0 10px 0'}>Performance</Headline>
                  <Row alignItems={'baseline'}>
                    <NumberLarge margin={'0 5px 0 0'}>{parsePercent(0.1)}</NumberLarge>
                    <Text>lower compared to other segments</Text>
                  </Row>
                </StyledBox>
              </FeatureFlag>
            </Col>
          </Row>
          <Row>
            <StyledBtn margin="0" onClick={handleCreateSegment}>
              Create segment
            </StyledBtn>
          </Row>
        </StyledBox>
      </StyledBox>
    </Row>
  );
}
