import React, {useState, useContext} from 'react';

import {MainContentView, StyledBtn, StyledNavTop} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {SegmentContext} from '../../contexts/SegmentContext';
import {useSetNewPage} from '../../functions/util';
import {RenderNoSegments, RenderSkeleton} from './emptyStates';
import SegmentCreationWindow from './segmentCreation/SegmentCreationWindow';
import SegmentsSummary from './SegmentsSummary';
import SegmentTableContainer from './table/SegmentTableContainer';

export default function SegmentContainer() {
  const [openSegmentTypeSelection, setOpenSegmentTypeSelection] = useState(false);

  useSetNewPage('Segment overview');

  return (
    <>
      <StyledNavTop>Segment overview</StyledNavTop>
      <MainContentView>
        <SegmentCreationWindow
          openSegmentTypeSelection={openSegmentTypeSelection}
          setOpenSegmentTypeSelection={setOpenSegmentTypeSelection}
        />
        <ContainerContent setOpenSegmentTypeSelection={setOpenSegmentTypeSelection} />
      </MainContentView>
    </>
  );
}

const ContainerContent = ({setOpenSegmentTypeSelection}) => {
  const segmentContext = useContext(SegmentContext);
  const {segments, isLoading} = segmentContext!.state;

  if (isLoading) return <RenderSkeleton />;
  if (isEmpty(segments)) return <RenderNoSegments setOpenSegmentTypeSelection={setOpenSegmentTypeSelection} />;

  return (
    <>
      <SegmentsSummary />
      <SegmentTableContainer />
      <StyledBtn
        data-intercom-id="inbound-new-segment-button"
        margin={'20px 0 40px 0'}
        onClick={() => setOpenSegmentTypeSelection(true)}
      >
        Create new segment
      </StyledBtn>
    </>
  );
};
