import React from 'react';

import {
  Col,
  Headline,
  Row,
  StyledDivider,
  Text,
  PageIcon,
  Round,
  brandMain,
  extraLightGray,
  warnColor,
} from '@markettailor/common-markettailor';

import {ParsedInvoice} from './Billing';

interface Props {
  parsedInvoiceHistory: ParsedInvoice[];
  parsedUpcomingInvoice: ParsedInvoice;
}

export default function InvoiceHistory(props: Props) {
  const {parsedInvoiceHistory, parsedUpcomingInvoice} = props;
  return (
    <>
      <Col alignItems={'center'} minWidth={'400px'}>
        <Headline color={brandMain}>Invoices</Headline>
        <StyledDivider border margin={'20px 0'} />
      </Col>
      <Row minWidth={'400px'} justifyContent={'space-between'} margin={'0 0 20px 0'} alignItems={'center'}>
        <Col>
          <Text color={'black'} margin={'0 10px 0 0'}>
            {parsedUpcomingInvoice.periodEnd ? parsedUpcomingInvoice.periodEnd.format('MMMM DD, YYYY') : null}
          </Text>
          <Text fontSize={'13px'}>Next invoice</Text>
        </Col>
        <Row alignItems={'center'}>
          <Headline color={brandMain} margin={'0 10px 0 0'} fontSize={'16px'}>
            {parsedUpcomingInvoice.amount}
          </Headline>
        </Row>
      </Row>
      <Col alignItems={'center'} minWidth={'400px'}>
        <StyledDivider border margin={'0 0 10px 0'} />
        {parsedInvoiceHistory.length === 0 && (
          <Headline color={brandMain} margin={'0 10px 0 0'} fontSize={'16px'}>
            {'No past invoices'}
          </Headline>
        )}
      </Col>
      {props.parsedInvoiceHistory.map((invoice, index) => {
        return (
          <Row
            key={index}
            minWidth={'400px'}
            justifyContent={'space-between'}
            margin={'0 0 20px 0'}
            alignItems={'center'}
          >
            <Col>
              <Text color={'black'} margin={'0 10px 0 0'}>
                {invoice.periodEnd ? invoice.periodEnd.format('MMMM DD, YYYY') : null}
              </Text>
              <Text fontSize={'13px'}>{invoice.invoiceNumber}</Text>
            </Col>
            <Row alignItems={'center'}>
              <Text color={invoice.paid ? brandMain : warnColor} margin={'0 10px 0 0'} fontSize={'16px'}>
                {invoice.paid ? '(Paid)' : '(Unpaid)'}
              </Text>
              <Headline color={brandMain} margin={'0 10px 0 0'} fontSize={'16px'}>
                {invoice.amount}
              </Headline>
              {invoice.invoicePdf && (
                <Row alignItems={'center'} margin={'0 0 0 5px'}>
                  <a href={invoice.invoicePdf} style={{cursor: 'pointer'}}>
                    <Round backgroundColor={extraLightGray} width={'30px'}>
                      <PageIcon width="18px" />
                    </Round>
                  </a>
                </Row>
              )}
            </Row>
          </Row>
        );
      })}
    </>
  );
}
