import React, {useContext} from 'react';

import {
  Row,
  lightBlue,
  ArrowDownShortIcon,
  ArrowUpShortIcon,
  IDomElem,
  darkGray,
  Text,
} from '@markettailor/common-markettailor';
import {isEqual} from 'lodash';

import {EditorContext} from '../../../contexts/EditorContext';

enum change {
  up = 1,
  down = -1,
}

export default function HierarchyEditor() {
  const editorContext = useContext(EditorContext)!;
  const {selectedElem, changeElemContent, domElems} = editorContext;
  const selectedDomElem = domElems[selectedElem.domElemId];
  const isDisabled = selectedDomElem ? domElemHasContentChanges(selectedDomElem) : true;
  const color = isDisabled ? darkGray : lightBlue;

  const handleChange = (level: change) => {
    if (isDisabled) return;
    const originalLevel = selectedElem.parentLevel || 0;
    const newLevel = originalLevel + level;
    if (newLevel < 0) return;
    changeElemContent(selectedElem.domElemId, {parentLevel: newLevel});
  };

  return (
    <Row margin="0 10px 0 10px">
      <ArrowDownShortIcon width="25px" cursor="pointer" color={color} onClick={() => handleChange(change.down)} />
      <ArrowUpShortIcon
        width="25px"
        cursor="pointer"
        margin="0 10px"
        color={color}
        onClick={() => handleChange(change.up)}
      />
    </Row>
  );
}

const domElemHasContentChanges = (domElem: IDomElem) => {
  const edit = {...domElem.domEditor, parentLevel: undefined};
  const orig = {...domElem.domOriginal, parentLevel: undefined};
  const origFiltered = Object.fromEntries(Object.entries(orig).filter(([key]) => key in edit));
  return !isEqual(origFiltered, edit);
};
