import React, {useState} from 'react';

import {
  Col,
  Headline,
  StyledBtn,
  Text,
  brandMain,
  getSelectCustomStyles,
  IValueLabel,
} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {ICustomDimension} from './OptionsWindowGA';
import {customStyle} from './util';

export interface IGAIntegrationOptionCustomDimension {
  dimensionIndex: string;
}
interface Props {
  handleSubmitIntegrationOptions: (obj: IGAIntegrationOptionCustomDimension) => void;
  customDimensions: ICustomDimension[];
}

export default function CustomDimensionSelector({handleSubmitIntegrationOptions, customDimensions}: Props) {
  const [selectedCustomDimension, setSelectedCustomDimension] = useState<IValueLabel | null>(null);

  function getCustomDimensions(): IValueLabel[] {
    const dimensionOptions: IValueLabel[] = [];
    for (const dimension of customDimensions) {
      const active = dimension.active ? 'Active' : 'Not active';
      dimensionOptions.push({
        value: String(dimension.index),
        label: String(dimension.index) + ' ─ ' + dimension.name + ' ─ ' + active,
      });
    }
    return dimensionOptions;
  }

  return (
    <Col maxWidth={'500px'} alignItems={'center'}>
      <Headline color={brandMain}>Google Analytics integration</Headline>
      <Text margin={'20px 0 40px 0 '}>
        All your Google Analytics <u>custom dimensions</u> are in use. Choose one we can use so we can provide you
        conversion data for personalizations.
      </Text>
      <Col alignItems={'center'} height={'300px'}>
        <Select
          styles={getSelectCustomStyles(customStyle)}
          options={getCustomDimensions()}
          value={selectedCustomDimension}
          isSearchable={false}
          onChange={setSelectedCustomDimension}
          placeholder={'Custom dimension'}
        />
        <StyledBtn
          disabled={!selectedCustomDimension}
          onClick={() => {
            handleSubmitIntegrationOptions({dimensionIndex: selectedCustomDimension!.value});
          }}
        >
          Submit
        </StyledBtn>
      </Col>
    </Col>
  );
}
