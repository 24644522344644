import React, {useContext} from 'react';

import {StyledTable, Col, Headline, IEditOption, IRole, RowInterface} from '@markettailor/common-markettailor';

import {AccountContext} from '../../../contexts/AccountContext';

interface ListOption extends RowInterface {
  email: string;
  roleLabel: string;
}

const roleMapping = {
  regular_user: 'Regular user',
  admin: 'Admin',
};

export default function UserList() {
  const accountContext = useContext(AccountContext)!;
  const {deleteUser, changeUserRole, users} = accountContext;
  const {user: currentUser} = accountContext.account || {};

  function getUsersList(): ListOption[] {
    return users.map((user) => {
      const roleLabel = roleMapping[user.role];
      return {
        id: user.userId,
        email: user.email,
        roleLabel: roleLabel,
        rowOptionSet: currentUser?.userId === user.userId ? 'own empty options' : 'admin',
      };
    });
  }

  const optionsList: IEditOption[] = [
    {
      primary: 'Change role to regular',
      alt: 'Change role to admin',
      optionSet: [currentUser?.role || ''],
      callback: (userId, selectedText) => {
        const role: IRole = selectedText === 'Change role to admin' ? 'admin' : 'regular_user';
        changeUserRole(userId, role);
      },
    },
    {
      primary: 'Delete user',
      color: 'warn',
      optionSet: [currentUser?.role || ''],
      callback: (userId) => deleteUser(userId),
    },
  ];

  return (
    <Col maxWidth={'600px'}>
      <Headline margin={'0 0 10px 0'}>All users</Headline>
      <StyledTable
        style={{table: {maxWidth: '600px'}}}
        headers={[{email: 'Email'}, {roleLabel: 'User role'}]}
        items={getUsersList()}
        editOptions={optionsList}
      />
    </Col>
  );
}
