import React, {useContext} from 'react';

import {
  Col,
  Header,
  StyledBox,
  StyledBtn,
  Text,
  extraDarkGray,
  Row,
  AuthContext,
} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {useSetNewPage} from '../../../functions/util';
import '../../../styles/common/muiOverrides.css';

export default function SignUpOAuth() {
  const authContext = useContext(AuthContext)!;
  const {handleCreateAccountWithGoogle} = authContext;
  const history = useHistory();

  useSetNewPage('Sign up Oauth');

  async function handleCancel() {
    await authContext.signOut({});
    history.push('/sign-in');
  }

  return (
    <>
      <Col alignItems={'center'}>
        <Header color={'white'} margin={'0 0 20px 0'} fontSize={'20px'} fontWeight={500}>
          New Google Login
        </Header>
      </Col>
      <StyledBox padding={'20px'} minWidth={'260px'} maxWidth={'360px'} minHeight={'280px'}>
        <Text color={extraDarkGray} margin={'0 5px 20px 5px'} fontSize={'16px'}>
          Seems like this is a new Google login. Would you like to create a new account?
        </Text>
        <Row>
          <StyledBtn onClick={handleCreateAccountWithGoogle}>Create account</StyledBtn>
          <StyledBtn background={extraDarkGray} onClick={handleCancel}>
            Cancel
          </StyledBtn>
        </Row>
      </StyledBox>
    </>
  );
}
