import React, {useState, useEffect, useContext, ReactNode, createContext} from 'react';

import {
  LoadingPage,
  NotificationContext,
  IUser,
  IRole,
  IPermissions,
  IBilling,
  IIntegrations,
} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {omit} from 'lodash';
import {useHistory} from 'react-router-dom';

import {IUserInvite} from '../components/account/users/UserInviteForm';
import config from '../config.json';
import {analytics, sendAccountAnalytics} from '../functions/analytics';
import {deepFreeze} from '../functions/util';
import {setGlobalUserInfo} from './util';

interface AccountPutData {
  email: string;
  clientDomain: string;
  companyName: string;
  excludeUrls: string[];
}

export type IDataCollectionMode = 'defaultAllow' | 'defaultDeny';

export interface IAccount {
  accountId: string;
  userId: string;
  clientDomain: string;
  companyName: string;
  clientScriptFileId: string;
  lastVisitorDate?: Date;
  createdDate: Date;
  email: string;
  permissions: IPermissions;
  user: IUser;
  billing: IBilling;
  dataCollectionMode: IDataCollectionMode;
  excludeUrls: string[];
}

interface IAccountContext {
  account?: IAccount;
  integrations?: IIntegrations;
  isLoading: boolean;
  users: IUser[];
  getAccount: () => Promise<void>;
  handleSubmitAccountInfo: (accountInfo: Partial<AccountPutData>) => Promise<void>;
  inviteUser: (user: IUserInvite) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  changeUserRole: (userId: string, role: IRole) => Promise<void>;
  changeDataCollectionMode: (dataCollectionMode: IDataCollectionMode) => Promise<void>;
}

const AccountContext = createContext<IAccountContext | undefined>(undefined);
function AccountProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [account, setAccount] = useState<IAccount | undefined>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [integrations, setIntegrations] = useState<IIntegrations>();

  useRedirectToWelcomeIfNoClientDomain(account);

  useEffect(() => {
    const loadData = async () => {
      try {
        const userPromise = getUsers();
        const accountPromise = getAccount();
        await Promise.all([userPromise, accountPromise]);
      } catch (e) {
        if (axios.isCancel(e)) return;
        console.error(e);
      }
      return () => source.cancel('Account unmounted');
    };
    loadData();
    //eslint-disable-next-line
  }, []);

  const getAccount = async () => {
    try {
      const res = await axios.get(config.api.baseURL + 'account', {
        cancelToken: source.token,
      });
      setIntegrations(res.data.integrations);
      delete res.data.integrations;
      setAccount(res.data);
      setIsLoading(false);

      sendAccountAnalytics(res.data);
      setGlobalUserInfo(res.data.user);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Something went wrong when fetching your account', level: 'error'});
      console.debug(e);
    }
  };

  const getUsers = async () => {
    try {
      const res = await axios.get(config.api.baseURL + 'account/users', {
        cancelToken: source.token,
      });
      setUsers(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Fetching users failed', level: 'error'});
      console.debug(e);
    }
  };

  const handleSubmitAccountInfo = async (accountInfo: Partial<AccountPutData>) => {
    try {
      const res = await axios.put(config.api.baseURL + 'account', accountInfo, {
        cancelToken: source.token,
      });
      analytics.track('Submitted new account info');
      res.data = omit(res.data, ['features', 'integrations']);
      setAccount((prevState) => {
        if (!prevState) return;
        return {...prevState, ...res.data};
      });
    } catch (e) {
      if (axios.isCancel(e)) return;
      // @ts-ignore
      if (e?.response.data?.message?.includes('Provided domain is not valid')) {
        setInfoNotification({message: 'Provided domain is not valid', level: 'error'});
        return;
      }
      setInfoNotification({message: 'Changing account info failed', level: 'error'});
    }
  };

  const inviteUser = async (newUser: IUserInvite) => {
    try {
      const res = await axios.post(config.api.baseURL + 'account/users', newUser, {
        cancelToken: source.token,
      });
      setInfoNotification({message: 'Invite sent'});
      setUsers(res.data);
      analytics.track('Sent user invite');
    } catch (error) {
      if (axios.isCancel(error)) return;
      console.debug(error);
      // @ts-ignore
      const message: string = error?.response?.data?.message || '';
      if (message === 'User email is already in use')
        setInfoNotification({message: 'The email is already in use', level: 'error'});
    }
  };

  const deleteUser = async (userId: string) => {
    if (!account) return;
    try {
      const newUsers: IUser[] = users.filter((user) => {
        return user.userId !== userId;
      });
      setUsers(newUsers);
      await axios.delete(config.api.baseURL + 'account/user/management/' + userId, {
        cancelToken: source.token,
      });
      analytics.track('Deleted user');
      setInfoNotification({message: 'User deleted'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  const changeUserRole = async (userId: string, role: IRole) => {
    if (!account) return;
    try {
      const newUsers = [...users];
      const index = newUsers.findIndex((user) => user.userId === userId);
      newUsers[index].role = role;

      setUsers(newUsers);
      await axios.put(
        config.api.baseURL + 'account/user/management/' + userId,
        {role: role},
        {cancelToken: source.token}
      );
      analytics.track('Changed user role');
      setInfoNotification({message: 'User role changed'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  const changeDataCollectionMode = async (dataCollectionMode: IDataCollectionMode) => {
    if (!account) return;
    try {
      const response = await axios.put(
        config.api.baseURL + 'account',
        {dataCollectionMode},
        {cancelToken: source.token}
      );
      setAccount((prevState) => {
        if (!prevState) return;
        return {...prevState, dataCollectionMode: response.data.dataCollectionMode};
      });
      analytics.track('Changed data collection mode');
      setInfoNotification({message: 'Data collection mode changed'});
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  return (
    <AccountContext.Provider
      value={deepFreeze<IAccountContext>({
        account,
        integrations,
        isLoading,
        users,
        getAccount,
        handleSubmitAccountInfo,
        inviteUser,
        deleteUser,
        changeUserRole,
        changeDataCollectionMode,
      })}
    >
      {children}
    </AccountContext.Provider>
  );
}

const useRedirectToWelcomeIfNoClientDomain = (account: IAccount | undefined) => {
  const history = useHistory();
  useEffect(() => {
    if (!account || account.clientDomain) return;
    if (window.location.pathname.includes('/welcome')) return;
    history.push('/welcome');
  }, [account]);
};

export {AccountContext, AccountProvider};
