import React, {useContext, useState} from 'react';

import {
  Col,
  Headline,
  Row,
  StyledBox,
  StyledBtn,
  StyledDivider,
  Text,
  PopoverWindow,
  brandMain,
  darkGray,
} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import moment, {Moment} from 'moment';

import {StripeContext} from '../../../contexts/StripeContext';
import {currencies, defaultCurrency} from '../../util/currencies';
import InvoiceHistory from './InvoiceHistory';

export interface InvoiceInterface {
  currency: string;
  total: number;
  period_end: number;
  invoice_pdf: string;
  paid?: boolean;
  number: string;
}
export interface ParsedInvoice {
  amount?: string;
  periodEnd?: Moment;
  invoicePdf?: string;
  paid?: boolean;
  invoiceNumber?: string;
}

export default function Billing() {
  const {stripe} = useContext(StripeContext)!.state;
  const [renderInvoices, setRenderInvoices] = useState(false);
  const invoiceHistory = stripe?.invoices?.history ? stripe.invoices.history : [];
  const invoiceUpcoming = stripe?.invoices?.upcoming;

  function getInvoiceAmountAndDate(invoice: InvoiceInterface | undefined): ParsedInvoice {
    if (invoice) {
      const amount: string | undefined = invoice
        ? currencies[invoice.currency] + (invoice.total / 100).toFixed(2)
        : undefined;
      const date: Moment | undefined = invoice ? moment.unix(invoice.period_end) : undefined;
      return {
        amount: amount,
        periodEnd: date,
        invoicePdf: invoice.invoice_pdf,
        paid: invoice.paid,
        invoiceNumber: invoice.number,
      };
    } else {
      return {
        amount: undefined,
        periodEnd: undefined,
        invoicePdf: undefined,
        paid: undefined,
        invoiceNumber: undefined,
      };
    }
  }

  const parsedUpcomingInvoice = getInvoiceAmountAndDate(invoiceUpcoming);
  const parsedLastInvoice = getInvoiceAmountAndDate(invoiceHistory[0]);
  const parsedInvoiceHistory: ParsedInvoice[] = invoiceHistory.map((invoice: InvoiceInterface) =>
    getInvoiceAmountAndDate(invoice)
  );

  return (
    <Col minWidth={'300px'}>
      <PopoverWindow display={renderInvoices} handleClose={() => setRenderInvoices(false)} boxMinWidth={'600px'}>
        <InvoiceHistory parsedInvoiceHistory={parsedInvoiceHistory} parsedUpcomingInvoice={parsedUpcomingInvoice} />
      </PopoverWindow>
      <Headline margin={'0 0 10px 0'}>Invoices</Headline>
      <StyledBox maxWidth={'250px'} minWidth={'250px'} margin={'0 0 50px 0'} padding={'30px'} minHeight={'270px'}>
        <Col height={'65px'}>
          <Text margin={'0 0 10px 0'} color={'black'} fontWeight={600}>
            Next invoice date and amount
          </Text>
          <Row justifyContent={'space-between'}>
            {parsedUpcomingInvoice.periodEnd ? (
              <Headline color={brandMain} fontSize={'16px'}>
                {parsedUpcomingInvoice.periodEnd.format('MMM DD, YYYY')}
              </Headline>
            ) : (
              <Text margin={'0 0 10px 0'}>No upcoming invoices</Text>
            )}
            <Headline color={brandMain} fontSize={'16px'}>
              {parsedUpcomingInvoice.amount || currencies[defaultCurrency] + (0).toFixed(2)}
            </Headline>
          </Row>
        </Col>
        <StyledDivider border margin={'20px 0'} />
        <Col height={'65px'}>
          <Text margin={'0 0 10px 0'} color={'black'} fontWeight={600}>
            Last invoice
          </Text>
          <Row justifyContent={'space-between'}>
            <Text margin={'0 0 10px 0'}>
              {parsedLastInvoice.periodEnd ? parsedLastInvoice.periodEnd.format('MMM DD, YYYY') : 'No past invoices'}
            </Text>
            <Headline color={darkGray} margin={'0 0 0 5px'}>
              {parsedLastInvoice.amount || null}
            </Headline>
          </Row>
        </Col>
        <StyledDivider border margin={'20px 0'} />
        <Col alignItems={'center'}>
          <StyledBtn
            disabled={isEmpty(invoiceUpcoming) && isEmpty(invoiceHistory)}
            onClick={() => setRenderInvoices(true)}
          >
            See all invoices
          </StyledBtn>
        </Col>
      </StyledBox>
    </Col>
  );
}
