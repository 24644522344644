import {parse} from 'csv-parse';
import {detect} from 'jschardet';

interface ParsedCsvDataRow {
  [key: string]: string | number | null;
}
export type ParsedCsvDataType = ParsedCsvDataRow[];

type CsvRawData = CsvRow[];
type CsvRow = Array<string | number | null>;

export function parseCsvFile(
  csvFile: File,
  successCallback: (parsedData: ParsedCsvDataType) => void,
  errorCallback: (errorMessage: string) => void,
  dropEmptyColumns?: boolean
) {
  const isCsvFile = 'application/vnd.ms-excel' === csvFile.type || csvFile.type.includes('csv') || !csvFile.type;
  if (!isCsvFile) {
    errorCallback('File is not a CSV');
    return false;
  }

  const handleCsvDataBound = (data: CsvRawData) =>
    handleCsvData(data, successCallback, errorCallback, dropEmptyColumns);

  const reader = new FileReader();
  reader.onload = (e) => {
    const csvBinaryString = getCsvBinaryString(e?.target?.result);
    if (!csvBinaryString) {
      return;
    }
    const encoding = getEncoding(csvBinaryString);
    parseCsvWithEncoding(csvFile, encoding, handleCsvDataBound, errorCallback);
  };
  reader.readAsBinaryString(csvFile);

  const getCsvBinaryString = (readerData: string | ArrayBuffer | null | undefined) => {
    // Type should always be string due to using method "readAsBinaryString"
    if (typeof readerData === 'object') {
      console.error('Unknown type', typeof readerData, readerData);
      return;
    }

    if (!readerData) {
      errorCallback('Empty file');
      return;
    }

    return readerData;
  };
  const getEncoding = (csvBinaryString: string) => detect(csvBinaryString).encoding;
}

function parseCsvWithEncoding(
  csv: File,
  encoding: string,
  handleCsvDataBound: (data: CsvRawData) => void,
  errorCallback: (errorMessage: string) => void
) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(csv);
  reader.onload = (e) => {
    const data = e.target?.result;
    const decodedData = getDecodedData(data);

    const parser = getParserWithConfig();
    try {
      parser.write(decodedData);
    } catch (e) {
      errorCallback('The CSV file has an invalid format');
      throw e;
    }
    try {
      parser.end();
    } catch (error) {
      // @ts-ignore
      const message: string = error?.message;
      if (message.includes('Invalid Record Length')) errorCallback('CSV lines are of inconsistent length');
      else errorCallback(message);
    }
  };

  const getDecodedData = (data: string | ArrayBuffer | null | undefined) => {
    // Type should always be ArrayBuffer due to using method "readAsArrayBuffer"
    // Empty file should already be handled
    if (typeof data !== 'object' || !data) {
      console.error('Wrong data type or empty file');
      return;
    }

    const decoder = new TextDecoder(encoding);
    const decodedData = decoder.decode(data);

    if (!decodedData) {
      console.error('Data should not be empty here');
      return;
    }

    return decodedData;
  };
  const getParserWithConfig = () => {
    const outputData: string[][] = [];
    const parser = parse()
      .on('data', (dataRow: string[]) => outputData.push(dataRow))
      .on('end', () => {
        handleCsvDataBound(outputData);
      });
    return parser;
  };
}

const curlyBracketsInHeaders = (headers: any[]) =>
  headers
    .filter((header) => typeof header === 'string')
    .some((header: string) => header.includes('{') || header.includes('}'));
const dotInHeaders = (headers: any[]) =>
  headers.filter((header) => typeof header === 'string').some((header: string) => header.includes('.'));
const dollarStartingHeaders = (headers: any[]) =>
  headers.filter((header) => typeof header === 'string').some((header: string) => header.startsWith('$'));

function handleCsvData(
  data: CsvRawData,
  successCallback: (parsedData: ParsedCsvDataType) => void,
  errorCallback: (errorMessage: string) => void,
  dropEmptyColumns?: boolean
) {
  function validateData(data: CsvRawData) {
    if (data.length < 2) {
      errorCallback(`There are no rows in your CSV.`);
      return false;
    }

    const numberOfDuplicateHeaders = data[0].length - new Set(data[0]).size;
    if (numberOfDuplicateHeaders !== 0) {
      errorCallback(
        `There are ${numberOfDuplicateHeaders} duplicate headers in your data. Please rename or remove them.`
      );
      return false;
    }
    if (curlyBracketsInHeaders(data[0])) {
      errorCallback(`Characters { and } are not allowed in column headers.`);
      return false;
    }
    if (dotInHeaders(data[0])) {
      errorCallback(`Dot character (".") is not allowed in column headers.`);
      return false;
    }
    if (dollarStartingHeaders(data[0])) {
      errorCallback(`Column headers are not allowed to start with a dollar sign`);
      return false;
    }
    return true;
  }

  function parseDataToArray(data: CsvRawData) {
    const columns: CsvRow = data[0];
    let parsedData: ParsedCsvDataType = data.slice(1).map((row) => {
      return {
        ...columns.reduce((acc, col, idx) => {
          return col !== null ? {...acc, [String(col)]: row[idx]} : {...acc};
        }, {}),
      };
    });
    if (dropEmptyColumns) {
      parsedData = cleanEmptyColumns(parsedData);
    }
    return parsedData;
  }

  function cleanEmptyColumns(data: ParsedCsvDataType) {
    const keys = Object.keys(data[0]);
    keys.forEach((key) => {
      let hasValues = false;
      for (const row of data) {
        if (row[key]) {
          hasValues = true;
          break;
        }
      }
      if (!hasValues) {
        data.forEach((row) => {
          delete row[key];
          return row;
        });
      }
    });
    return data;
  }

  if (!validateData(data)) {
    return;
  }
  const parsedData = parseDataToArray(data);
  successCallback(parsedData);
}
