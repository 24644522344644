import React, {useContext} from 'react';

import {Card, darkGray, FunnelIcon} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import {SegmentContext} from '../../../contexts/SegmentContext';

export default function InboundCreation() {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {createNewSegment} = segmentContext!;
  const newSegmentId = uuid();

  return (
    <Card
      header={'Inbound'}
      description={'Create a personalized marketing campaign for inbound traffic on your site.'}
      midSection={<FunnelIcon width="45px" color={darkGray} />}
      ctaText={'Create segment'}
      ctaCallback={() => {
        createNewSegment({segmentId: newSegmentId, segmentType: 'inbound'});
        history.push('/segmentation/' + newSegmentId);
      }}
    />
  );
}
