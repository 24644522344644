// selectorOption values map to kickfire data
// https://www.kickfire.com/developers/docs-page.php#section-2
import {mapping} from '../../../termMaps/technicalNamesToLabels';
import {getFieldsSortedByValues} from '../util';
import {sicCode} from './data/dataInterfaces';

//import {naicsDesc} from './NAICS';

interface IOptionsKickfire {
  [selector: string]: string[];
}

export const optionsKickfire: IOptionsKickfire = {
  revenue: [
    '$1 to $1,000,000',
    '$1,000,000 to $5,000,000',
    '$5,000,000 to $10,000,000',
    '$10,000,000 to $50,000,000',
    '$50,000,000 to $100,000,000',
    '$100,000,000 to $250,000,000',
    '$250,000,000 to $500,000,000',
    '$500,000,000 to $1,000,000,000',
    '$1,000,000,000+',
  ],
  employees: [
    '1 to 10',
    '10 to 50',
    '50 to 100',
    '100 to 250',
    '250 to 500',
    '500 to 1,000',
    '1,000 to 2,500',
    '2,500 to 5,000',
    '5,000 to 10,000',
    '10,000 to 25,000',
    '25,000+',
  ],
  sicSector: getFieldsSortedByValues(mapping.sicSector),
  sicGroup: getFieldsSortedByValues(mapping.sicGroup),
  sicCode: getFieldsSortedByValues(sicCode),
  //naicsDesc: naicsDesc,
};
