import React, {useContext} from 'react';

import {StyledBox, Round, IconX, lightGray} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import Masonry from 'react-masonry-css';

import {EditorContext} from '../../../contexts/EditorContext';
import {ImageContext} from '../../../contexts/ImageContext';
import '../../../styles/editor/editorStyles.css';
import {EmptyImages, ImageError, RenderSkeleton} from './emptyStates';
import {ImageGalleryBottom} from './ImageGalleryBottom';

interface Props {
  handleClose: () => void;
}

export default function ImageGallery({handleClose}: Props) {
  const {galleryImages, imageError, isLoading} = useContext(ImageContext)!;

  if (isLoading) return <RenderSkeleton />;
  if (imageError) return <ImageError />;
  if (isEmpty(galleryImages)) return <EmptyImages handleClose={handleClose} />;
  return (
    <>
      <Masonry
        breakpointCols={{
          default: 5,
          1400: 4,
          1100: 3,
          700: 2,
          500: 1,
        }}
        className="masonry-grid"
        columnClassName="masonry-grid_column"
      >
        {galleryImages.map((imageSrc, index) => {
          return <ImageBoxes key={index} imageSrc={imageSrc} handleClose={handleClose} index={index} />;
        })}
      </Masonry>
      <ImageGalleryBottom handleClose={handleClose} />
    </>
  );
}

const ImageBoxes = ({imageSrc, handleClose, index}) => {
  const {deleteImage} = useContext(ImageContext)!;
  const {selectedElem, changeElemContent} = useContext(EditorContext)!;

  function handleSelectImage(imageSrc: string) {
    changeElemContent(selectedElem.domElemId, {src: imageSrc});
    handleClose();
  }

  return (
    <StyledBox cursor="pointer" className={`gallery-image-container-${index}`}>
      <Round backgroundColor="white" margin="10px" className="delete-icon" onClick={() => deleteImage(imageSrc)}>
        <IconX width="30px" color={lightGray} $hoverColor={lightGray} cursor="pointer" />
      </Round>
      <img
        src={imageSrc}
        alt={''}
        width="100%"
        className={'gallery-image'}
        onClick={() => handleSelectImage(imageSrc)}
      />
    </StyledBox>
  );
};
