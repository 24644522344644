import React, {useContext} from 'react';

import {AlignCenterScreen, AuthContext, LoadingPage, PageBg} from '@markettailor/common-markettailor';
import {Elements} from '@stripe/react-stripe-js';
import {Router, Switch, Route, Redirect} from 'react-router-dom';

import {App} from './App';
import ForgotPassword from './components/account/public/ForgotPassword';
import PublicTopNav from './components/account/public/PublicTopNav';
import RequireNewPasswordWithAnalytics from './components/account/public/RequireNewPasswordWithAnalytics';
import ResetPassword from './components/account/public/ResetPassword';
import SignIn from './components/account/public/SignIn';
import SignUp from './components/account/public/SignUp';
import SignUpOAuth from './components/account/public/SignUpOAuth';
import SignOut from './components/account/SignOut';
import ErrorPage from './components/common/ErrorPage';
import {StripeKeyContext} from './contexts/StripeKeyContext';
import history from './history';

export function AppWithAuth() {
  const {loadStripePromise, isLoading: stripeIsLoading} = useContext(StripeKeyContext)!;
  const auth = useContext(AuthContext)!.state;

  if (!auth.isAuthenticated) return <AppPublicSide />;
  if (!loadStripePromise && !stripeIsLoading) return <ErrorPage />;
  if (!loadStripePromise) return <LoadingPage />;

  return (
    <Elements stripe={loadStripePromise}>
      <App />
    </Elements>
  );
}

const AppPublicSide = () => {
  return (
    <PageBg>
      <Router history={history}>
        <PublicTopNav />
        <AlignCenterScreen>
          <Switch>
            <Route path={'/sign-in'} exact render={() => <SignIn />} />
            <Route path={'/sign-oauth'} exact render={() => <SignUpOAuth />} />
            <Route path={'/sign-up'} exact render={() => <SignUp />} />
            <Route path={'/forgot-password'} exact render={() => <ForgotPassword />} />
            <Route path={'/reset-password'} exact render={() => <ResetPassword />} />
            <Route path={'/new-password'} exact render={() => <RequireNewPasswordWithAnalytics />} />
            <Route path="/sign-out" exact render={() => <SignOut />} />
            <Route path="*" render={() => <Redirect to={'/sign-in'} />} />
          </Switch>
        </AlignCenterScreen>
      </Router>
    </PageBg>
  );
};
