import React from 'react';

import {
  StyledBox,
  Col,
  Headline,
  StyledDivider,
  Text,
  OnHover,
  lightBlue,
  darkGray,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {formatPercent} from '../../../functions/util';

export interface IExperimentStatus {
  text: string;
  color: string;
}

export const statuses = {
  0.5: {color: lightBlue, text: 'Experiment'},
  0.9: {color: '#000', text: 'Ongoing'},
  1: {color: '#000', text: 'Full personalization'},
  0: {color: darkGray, text: 'Inactive'},
  default: {color: '#000', text: 'Custom'},
};

interface Props {
  elemId: string;
  handleClick: (elemId: string, abPersonalizationWeight: number) => void;
}

export default function StatusCellMenu({elemId, handleClick}: Props) {
  function getStatusSelection(abPersonalizationWeight: number, description: string | JSX.Element) {
    const status: IExperimentStatus = statuses[abPersonalizationWeight] || statuses.default;
    return (
      <OnHover background="rgba(0, 0, 0, 0.07)" cursor="pointer">
        <Col
          margin="15px"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            handleClick(elemId, abPersonalizationWeight);
          }}
        >
          <Headline color={status.color} fontSize="14px" margin="0 0 5px 0">
            {capitalize(status.text)}
          </Headline>
          <Text color="#000" margin="0 0 5px 0" fontWeight={500}>
            Weight: {formatPercent(abPersonalizationWeight * 100, 0)}
          </Text>
          <Text>{description}</Text>
        </Col>
      </OnHover>
    );
  }
  return (
    <StyledBox padding="0" width="350px" style={{transform: 'translateY(-35%)'}}>
      {getStatusSelection(
        1.0,
        <>
          <Text>So you decided to go full 100?</Text>
          <br />
          <Text>Your conversion data becomes unreliable because we cannot A/B test your results.</Text>
        </>
      )}
      <StyledDivider border margin="0" />
      {getStatusSelection(0.9, 'Check regularly that the personalization is still valid.')}
      <StyledDivider border margin="0" />
      {getStatusSelection(0.5, 'Reach statistical significance quickly.')}
      <StyledDivider border margin="0" />
      {getStatusSelection(0, 'Stopped personalizations use the original version of the website.')}
    </StyledBox>
  );
}
