import React, {useState} from 'react';

import {
  getSelectCustomStyles,
  IValueLabel,
  ISegmentFilter,
  IIpDataIntegrations,
  ICrmIntegrations,
} from '@markettailor/common-markettailor';
import {isEmpty, cloneDeep} from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {useAllMapping} from '../../../../termMaps/technicalNamesToLabels';
import {getSelectorOptionsDefault} from '../../segmentationOptions/groupedOptionsDefault';
import {useTechnologyOptions} from '../../segmentationOptions/groupedOptionsTechnologies';
import {ISelectOptionsWithHeadline} from '../../segmentInterfaces';
import {
  MenuList,
  saveOptionsToLocalStorage,
  getUserCreatedOptionsFromLocalStorage,
  parseOptionsToValueLabel,
  useGetOptionsByIntegrationName,
  UserCreatedOptions,
} from '../../util';
import {QueryStringSelection} from './QueryStringSelection';

interface Props {
  selector: string;
  segmentFilter: ISegmentFilter;
  handleSelectChange: (changeObj: Partial<ISegmentFilter>) => void;
  handleFilterChange: (segmentFilter: ISegmentFilter) => void;
  activeIntegrations: (keyof IIpDataIntegrations | keyof ICrmIntegrations)[];
}

export default function ValueSelection(props: Props) {
  const {selector, segmentFilter, handleSelectChange, handleFilterChange, activeIntegrations} = props;
  const [searchInput, setSearchInput] = useState('');
  const [userCreatedOptions, setUserCreatedOptions] = useState<UserCreatedOptions>(
    getUserCreatedOptionsFromLocalStorage()
  );
  const {valueRange} = segmentFilter;
  const mapping = useAllMapping();

  const technologyOptions = useTechnologyOptions();
  const technologySelectorOptions = technologyOptions[selector] || [];

  const integrationSelectorOptions = useGetOptionsByIntegrationName(selector, activeIntegrations);
  const valueRangeOptions: Array<IValueLabel | ISelectOptionsWithHeadline> = integrationSelectorOptions.length
    ? integrationSelectorOptions
    : [...technologySelectorOptions, ...getSelectorOptionsDefault(selector)];

  function saveCreatableOption(selector: string, value: string): void {
    const newUserCreatedOptions = cloneDeep(userCreatedOptions);
    if (selector in newUserCreatedOptions) {
      !newUserCreatedOptions[selector].includes(value) && newUserCreatedOptions[selector].push(value);
    } else newUserCreatedOptions[selector] = [value];
    setUserCreatedOptions(newUserCreatedOptions);
    saveOptionsToLocalStorage(newUserCreatedOptions);
  }

  function handleChangeCreatable(selection: IValueLabel | null, actionMeta: any): void {
    if (actionMeta.action === 'clear') {
      handleSelectChange({valueRange: []});
    } else {
      if (!selection) return;
      handleValueRangeChange([selection]);
      saveCreatableOption(selector, selection.value);
    }
  }

  function handleValueRangeChange(selection: IValueLabel[]): void {
    segmentFilter.valueRange =
      selection &&
      selection.map((item) => {
        return item.value;
      });
    handleFilterChange(segmentFilter);
  }

  function getOptionMapping(valueRange: any[]) {
    return (
      valueRange &&
      valueRange.map((value) => {
        const optionMatch = valueRangeOptions.filter((option) => 'value' in option && option.value === value);
        if (optionMatch.length) return optionMatch[0];
        return {value: value, label: mapping[selector]?.[value] || value};
      })
    );
  }

  const isInputTypeComponent = isEmpty(valueRangeOptions);
  const isLargeList = valueRangeOptions.length > 3000;
  const filteredValueRangeOptions =
    isLargeList && searchInput
      ? valueRangeOptions.filter((obj) => {
          try {
            const regex = new RegExp(searchInput, 'i');
            return obj['value'].match(regex);
          } catch {
            return obj['value'].includes(searchInput);
          }
        })
      : valueRangeOptions;

  const largeListOptions = isLargeList && {
    menuIsOpen: searchInput.length > 1 && filteredValueRangeOptions.length > 0,
    onInputChange: (searchInput: string) => setSearchInput(searchInput),
    components: {MenuList},
  };

  if (selector === 'query_string')
    return (
      <QueryStringSelection
        valueRange={valueRange}
        handleValueRangeChange={handleValueRangeChange}
        saveCreatableOption={saveCreatableOption}
        userCreatedOptions={userCreatedOptions}
      />
    );
  if (isInputTypeComponent)
    return (
      <CreatableSelect
        isClearable={true}
        openMenuOnClick={!isEmpty(userCreatedOptions[selector])}
        options={parseOptionsToValueLabel(userCreatedOptions[selector])}
        styles={getSelectCustomStyles({
          control: {width: 300, margin: '0 10px 0 0', mediaSmall: {width: 260, margin: '0 5px'}},
        })}
        value={getOptionMapping(valueRange)}
        onChange={handleChangeCreatable}
        placeholder="Select or type..."
      />
    );
  return (
    <Select
      {...largeListOptions}
      isMulti={true}
      styles={getSelectCustomStyles({
        control: {width: 300, margin: '0 10px 0 0', mediaSmall: {width: 260, margin: '0 5px'}},
      })}
      options={searchInput.length > 1 || !isLargeList ? filteredValueRangeOptions : []}
      value={getOptionMapping(valueRange)}
      onChange={(selection) => handleSelectChange(selection && {valueRange: selection})}
      placeholder="Select or type..."
    />
  );
}
