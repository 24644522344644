//selectorOption values map to clearbit data fields (see segment_ids.py)
import React from 'react';

import {IValueLabel} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import LogoIcon from '../../../styles/logoIcons';
import {mapping} from '../../../termMaps/technicalNamesToLabels';
import {ISelectOptionsWithHeadline} from '../segmentInterfaces';

export function getSelectorsIP(dataProviderName: string, optionsObject: any): ISelectOptionsWithHeadline[] {
  return [
    {
      label: 'Firmographics',
      options: Object.keys(optionsObject).map((selector) => {
        return {
          value: selector,
          label: mapping.selector[selector as keyof typeof mapping.selector] || capitalize(selector),
          icon: (
            <LogoIcon
              logoHeight={'15px'}
              logoWidth={'15px'}
              logoSrc={`../${dataProviderName}-small-logo.svg`}
              logoAlt={`${capitalize(dataProviderName)} small logo`}
            />
          ),
        };
      }),
    },
  ];
}

export function getSelectorOptionsIP(
  selector: string,
  optionsObject: any
): Array<any | IValueLabel | ISelectOptionsWithHeadline> {
  if (!(selector in optionsObject)) return [];

  const {isSpecialCase, options} = handleSpecialSelectorCases(selector, optionsObject);
  if (isSpecialCase) return options;

  return optionsObject[selector].map((option: string) => {
    return {value: option, label: mapping[selector]?.[option] || option};
  });
}

function handleSpecialSelectorCases(selector: string, optionsObject: any) {
  let options: Array<any | IValueLabel | ISelectOptionsWithHeadline> = [];
  let isSpecialCase = false;
  if (selector === 'tech') {
    isSpecialCase = true;
    options = Object.entries(optionsObject[selector]).map((techCategory: any[]) => {
      return {
        label: techCategory[0],
        options: Object.entries(techCategory[1]).map((option) => {
          return {value: option[0], label: option[1]};
        }),
      };
    });
  }
  return {isSpecialCase: isSpecialCase, options: options};
}
