import {useContext} from 'react';

import {ISegment, URLType, ICrmObjects} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {OutboundSegmentItemsContext} from '../../contexts/OutboundSegmentItemsContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {useSetNewPage} from '../../functions/util';
import {ISearchFilter} from './OutboundFilterDropdown';
import {DataRow} from './table/OutboundTable';

export function getFilteredData(data: DataRow[], dataFilter: ISearchFilter) {
  const outboundFilteredData: DataRow[] = data.filter((dataRow: DataRow) => {
    const fieldValue =
      dataFilter.header in dataRow.changes ? dataRow.changes[dataFilter.header] : dataRow.original[dataFilter.header];
    if (dataFilter.searchSelection === '_not_set') return fieldValue === null;
    if (dataFilter.header === '_all' || (!dataFilter.searchValue && !dataFilter.searchSelection)) return true;
    if (dataFilter.searchValue) {
      try {
        const regex = new RegExp(dataFilter.searchValue, 'i');
        return fieldValue.match(regex);
      } catch {
        return fieldValue ? fieldValue.includes(dataFilter.searchValue) : false;
      }
    }
    return fieldValue === dataFilter.searchSelection;
  });
  return outboundFilteredData;
}

export const useSetPageOutbound = () => {
  const segmentContext = useContext(SegmentContext);
  const {segments, segmentId} = segmentContext!.state;

  const segmentName = segmentId in segments && segments[segmentId].segmentName;
  const pageTitle = segmentName ? `Outbound | ${segmentName}` : `Outbound`;

  useSetNewPage('Outbound', pageTitle);
};

export const getPageUrls = (segment: ISegment): URLType[] => {
  return Object.values(segment.pageUrls).map((page) => {
    return page.pageUrl;
  });
};

export const useGetOutboundSegmentData = () => {
  const segmentContext = useContext(SegmentContext)!;
  const {outboundAllData} = segmentContext.state;

  const outboundSegmentItemsContext = useContext(OutboundSegmentItemsContext)!;
  const {crmObjects} = outboundSegmentItemsContext;

  const crmObjectIds = Object.values(crmObjects || {}); // Don't do this within the loop due to performance reasons.
  return outboundAllData.filter((dataRow) => crmObjectIds.includes(dataRow.original.id));
};

export const useGetFilteredData = (dataFilter: ISearchFilter) => {
  const segmentContext = useContext(SegmentContext)!;
  const {outboundAllData} = segmentContext.state;
  const outboundSegmentData = useGetOutboundSegmentData();
  const outboundFilteredData: DataRow[] = getFilteredData(outboundAllData, dataFilter);
  const segmentFilteredData: DataRow[] = getFilteredData(outboundSegmentData, dataFilter);
  return {outboundFilteredData, segmentFilteredData};
};

export const useIsRenderedTableEmpty = (listTypeSelected, dataFilter: ISearchFilter) => {
  const {outboundFilteredData, segmentFilteredData} = useGetFilteredData(dataFilter);
  const noTableData = isEmpty(listTypeSelected === 'allList' ? outboundFilteredData : segmentFilteredData);
  return noTableData;
};
