import React from 'react';

import {
  Col,
  Headline,
  StyledDivider,
  Row,
  Tooltip,
  darkGray,
  ICrmObjectType,
  ICrmIntegrations,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {crmObjectTypeMapping} from '../../../termMaps/crmTerms';
import InlineNav from '../../common/InlineNav';
import {supportedCrmObjectTypes} from './../../../contexts/IntegrationContext';

interface Props {
  crmObjectType: ICrmObjectType;
  setCrmObjectType: (selection: ICrmObjectType) => void;
  crmName: keyof ICrmIntegrations;
}

export const CrmObjectTypeSwitcher = ({crmObjectType, setCrmObjectType, crmName}: Props) => {
  const getCrmObjectTypeComponentOptions = (crmName: keyof ICrmIntegrations) => {
    return supportedCrmObjectTypes[crmName].map((objectType) => {
      return {value: objectType, label: capitalize(crmObjectTypeMapping[crmName][objectType].plural)};
    });
  };

  const crmObjectTypeComponentOptions = getCrmObjectTypeComponentOptions(crmName);

  return (
    <Col alignItems="center" margin="0 0 40px 0">
      <HeaderText crmObjectType={crmObjectType} />
      <StyledDivider border margin="20px 0" />
      <Col margin={'0 0 0 40px'}>
        <InlineNav
          componentOptions={crmObjectTypeComponentOptions}
          currentComponent={crmObjectType}
          handleSelect={(selection) => {
            setCrmObjectType(selection);
          }}
        />
      </Col>
    </Col>
  );
};

const HeaderText = ({crmObjectType}: {crmObjectType: ICrmObjectType}) => {
  return (
    <Row>
      <Headline textAlign="center" margin="0 10px 0 0">
        Select fields you want to add to campaigns.
      </Headline>
      {crmObjectType === 'contact' && (
        <Tooltip
          content="Contact fields are not usable in inbound campaigns because one company might have multiple employees."
          iconStyles={{color: darkGray}}
        />
      )}
    </Row>
  );
};
