import React, {useContext, useState} from 'react';

import {
  Col,
  Headline,
  Row,
  StyledBox,
  StyledBtn,
  StyledDivider,
  Text,
  PopoverWindow,
  brandMain,
  FeatureFlag,
  IPlan,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {AccountContext} from '../../../contexts/AccountContext';
import {IntegrationContext} from '../../../contexts/IntegrationContext';
import {formatNum} from '../../../functions/util';
import {AppsumoFeatureListing, useAppsumoPlan} from './AppsumoPlan';
import ManagePlan from './ManagePlan';

export default function Plan() {
  const {hasCompanyLookupIntegration} = useContext(IntegrationContext)!;
  const [renderPlans, setRenderPlans] = useState(false);
  const appsumoPlan = useAppsumoPlan();

  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  const {email, permissions} = account;

  const accountPlan: IPlan | undefined = permissions?.plan;
  const companyData = permissions.companyLookups ? 'with company data' : 'without company data';
  const planDescription: {[key: string]: string} = {
    freemium: 'Free plan to get you started',
    basic: 'Basic level personalizations',
    growth: 'All features for a growth team',
    dev: 'Account with dev privileges',
    markettailor_tier1: 'AppSumo plan',
    markettailor_tier2: 'AppSumo plan',
    markettailor_tier3: 'AppSumo plan',
    markettailor_tier4: 'AppSumo plan',
    markettailor_tier5: 'AppSumo plan',
  };
  const PlanDescription = () => (
    <Text margin={'0 0 5px 0'}>{!accountPlan ? '' : planDescription[accountPlan] + ' ' + companyData}</Text>
  );
  return (
    <Col minWidth={'300px'}>
      <PopoverWindow display={renderPlans} handleClose={() => setRenderPlans(false)}>
        <ManagePlan handleClose={() => setRenderPlans(false)} />
      </PopoverWindow>
      <Headline margin={'0 0 10px 0'}>Current plan</Headline>
      <StyledBox maxWidth={'250px'} minWidth={'250px'} margin={'0 0 50px 0'} padding={'30px'} minHeight={'270px'}>
        <Headline color={brandMain} fontSize={'18px'} margin={'0 0 5px 0'}>
          {appsumoPlanMap[accountPlan] || capitalize(accountPlan)}
        </Headline>
        {appsumoPlan?.plan_id ? <AppsumoFeatureListing /> : <PlanDescription />}
        <StyledDivider border margin={'20px 0'} />
        <Col height={'100px'}>
          <Text margin={'0 0 10px 0'} color={'black'} fontWeight={600}>
            Current plan limits
          </Text>
          <VisitorListItem count={permissions.inboundVisitors} description={'Inbound visitors'} />
          <VisitorListItem count={permissions.outboundVisitors} description={'Outbound visitors'} />
          {(!appsumoPlan || hasCompanyLookupIntegration) && (
            <VisitorListItem count={permissions.companyLookups} description={'Company lookups'} />
          )}
        </Col>
        <FeatureFlag userEmail={email}>
          <StyledDivider border margin={'20px 0'} />
          <Col alignItems={'center'}>
            <StyledBtn onClick={() => setRenderPlans(true)}>Manage plan</StyledBtn>
          </Col>
        </FeatureFlag>
      </StyledBox>
    </Col>
  );
}

const appsumoPlanMap = {
  markettailor_tier1: 'AppSumo - Tier 1',
  markettailor_tier2: 'AppSumo - Tier 2',
  markettailor_tier3: 'AppSumo - Tier 3',
  markettailor_tier4: 'AppSumo - Tier 4',
  markettailor_tier5: 'AppSumo - Tier 5',
};

const VisitorListItem = ({count, description}: {count: number; description: string}) => (
  <Row alignItems={'baseline'}>
    <Headline color={brandMain} fontSize={'18px'}>
      {count === 1000000000 ? 'unlimited' : formatNum(count) + ' /mo'}
      {/* Unlimited is the largest AppSumo tier */}
    </Headline>
    <Text margin={'0 0 10px 10px'}>{description}</Text>
  </Row>
);
