import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';

import {useLocation} from 'react-router';

import {ErrorStateType} from '../components/editor/EditorError';
import {useGetIframeRequestData} from '../components/editor/localEditor/util';
import {deepFreeze} from '../functions/util';
import {AccountContext} from './AccountContext';
import {useGetSegmentPage, useGetPageIdFromWindowPath} from './editorContextUtil';
import {SegmentContext} from './SegmentContext';

interface IEditorContext {
  segmentId: string;
  pageId: string;
  pageUrl: string;
  errorState?: ErrorStateType;
  iframeRequestData?: string;
}

const EditorPageContext = createContext<IEditorContext | undefined>(undefined);

function EditorPageProvider({children}: {children: ReactNode}) {
  const location = useLocation();
  const accountContext = useContext(AccountContext)!;
  const clientDomain = accountContext.account?.clientDomain;
  const defaultPage = clientDomain ? 'https://' + clientDomain + '/' : '';

  const segmentContext = useContext(SegmentContext)!;
  const {isLoading: segmentContextIsLoading} = segmentContext!.state;

  const pageId = useGetPageIdFromWindowPath();
  const {segmentId, pageUrl} = useGetSegmentPage(pageId);
  const [errorState, setErrorState] = useState<ErrorStateType | undefined>();
  const {iframeRequestData, isLoading: iframeDataIsLoading} = useGetIframeRequestData(pageUrl || defaultPage);

  useEffect(() => {
    setErrorState(undefined);
    if (!location.pathname.includes('/editor/')) return;
    if (segmentContextIsLoading) return;
    if (!segmentId && !pageUrl) {
      setErrorState('no segmentId and url');
      console.warn('No segment or page selected');
      return;
    }
    if (!pageUrl) {
      setErrorState('no domain set');
      console.warn('No website to open');
      return;
    }
    if (!iframeRequestData && !iframeDataIsLoading) {
      setErrorState('no website opened');
      console.warn('Failed to open customer website:', pageUrl);
      return;
    }
  }, [pageUrl, segmentContextIsLoading, iframeRequestData, iframeDataIsLoading]);

  return (
    <EditorPageContext.Provider
      value={deepFreeze<IEditorContext>({
        segmentId,
        pageId,
        pageUrl: pageUrl,
        errorState,
        iframeRequestData,
      })}
    >
      {children}
    </EditorPageContext.Provider>
  );
}

export {EditorPageContext, EditorPageProvider};
