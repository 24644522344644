import React, {useContext, useState} from 'react';

import {LoadingSpinner, IValueLabel, IIntegrations} from '@markettailor/common-markettailor';

import {ConversionAnalyticsContext} from '../../../../contexts/ConversionAnalyticsContext';
import {IntegrationContext} from '../../../../contexts/IntegrationContext';
import AccountOptionsSelector, {IAnalyticsAccount, IGAIntegrationOptionAccountOptions} from './AccountOptionsSelector';
import CustomDimensionSelector, {IGAIntegrationOptionCustomDimension} from './CustomDimensionSelector';

interface Props {
  handleClose: () => void;
  accountSummary: IAnalyticsAccount[];
}

export interface ICustomDimension extends IValueLabel {
  active: string;
  name: string;
  index: number;
}

export type ISubmitGAOptionsResponseData = IResponseDataSuccess | IResponseTooManyCustomDimensions;
interface IResponseDataSuccess {
  message: 'success';
  integrations: IIntegrations;
}
interface IResponseTooManyCustomDimensions {
  message: 'too many custom dimensions';
  customDimensions: ICustomDimension[];
}

export type IGAIntegrationAuthOptionsType = IGAIntegrationOptionAccountOptions | IGAIntegrationOptionCustomDimension;

export default function OptionsWindowGA(props: Props) {
  const {submitGoogleAnalyticsOptions} = useContext(IntegrationContext)!;
  const {getConversions} = useContext(ConversionAnalyticsContext)!;
  const [customDimensions, setCustomDimensions] = useState<ICustomDimension[]>([]);
  const [renderCustomDimensions, setRenderCustomDimensions] = useState<boolean>(false);
  const {accountSummary} = props;

  async function handleSubmitIntegrationOptions(integrationOptions: IGAIntegrationAuthOptionsType) {
    const responseData = await submitGoogleAnalyticsOptions(integrationOptions);
    if (!responseData) return;
    if (responseData.message === 'success') {
      props.handleClose();
      await getConversions();
    } else if (responseData.message === 'too many custom dimensions') {
      setCustomDimensions(responseData.customDimensions);
      setRenderCustomDimensions(true);
    }
  }

  if (Object.keys(accountSummary).length === 0) return <LoadingSpinner />;

  if (renderCustomDimensions)
    return <CustomDimensionSelector {...{handleSubmitIntegrationOptions, customDimensions}} />;

  return <AccountOptionsSelector {...{handleSubmitIntegrationOptions, accountSummary}} />;
}
