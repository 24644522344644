import React, {useState, useContext} from 'react';

import {
  Headline,
  PopoverWindow,
  Row,
  StyledTable,
  Tooltip,
  StyledSwitch,
  BlueRadioButton,
  RowInterface,
  ITableHeader,
  IEditOption,
} from '@markettailor/common-markettailor';
import {orderBy} from 'lodash';

import {AccountContext} from '../../../contexts/AccountContext';
import {ConversionType} from '../../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext, IConversionEvent} from '../../../contexts/ConversionManagementContext';
import {GALogoSmall} from '../../integrations/conversionTracking/GoogleAnalytics';
import {SegmentLogoSmall} from '../../integrations/conversionTracking/SegmentCom';
import RenameForm from '../RenameForm';
import ToEditorForm from '../ToEditorForm';
import {PageCountCell} from './PageCountCell';
import {getMainEventRadioStates, enrichEventsWithPageUrls} from './util';

export interface IEnrichedManageEvents {
  [key: string]: IEnrichedManageEvent;
}

export interface IEnrichedManageEvent extends IConversionEvent {
  elemCount?: number;
  pageUrls: string[];
}

type OptionSetType = 'markettailor track' | 'markettailor page';
interface IListItem extends RowInterface {
  eventName: string | JSX.Element;
  attachedTo: JSX.Element;
  type: ConversionType;
  rowOptionSet?: OptionSetType;
  isActive: JSX.Element;
  isMainEvent: JSX.Element;
}

interface IListOption extends IEditOption {
  optionSet?: OptionSetType | OptionSetType[] | undefined;
}

export default function ManageConversionsTable() {
  const {clientDomain} = useContext(AccountContext)!.account || {};
  const conversionManagementContext = useContext(ConversionManagementContext)!;
  const {events, mainConversionMetric, updateMainConversionMetric, updateConversionEvent, deleteConversionEvent} =
    conversionManagementContext;
  const [toEditorOpen, setToEditorOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState('');

  const enrichedEvents: IEnrichedManageEvents = enrichEventsWithPageUrls(events, clientDomain);

  const getTableItems = (): IListItem[] => {
    const sortedItems = orderBy(Object.values(enrichedEvents), ['source', 'type', 'eventName']);
    return sortedItems.map((event) => {
      const rowOptionSetStr: OptionSetType | undefined =
        event.source === 'markettailor' ? `${event.source} ${event.type}` : undefined;
      return {
        id: event.eventId,
        eventName: <EventName event={event} />,
        attachedTo: <PageCountCell pageUrls={event.pageUrls} />,
        type: event.type,
        rowOptionSet: rowOptionSetStr,
        isActive: (
          <StyledSwitch
            defaultState={Boolean(event.isActive)}
            onChange={(state) =>
              updateConversionEvent(event.eventId, {...events[event.eventId], isActive: state}, 'isVisible')
            }
          />
        ),
        isMainEvent: (
          <BlueRadioButton
            name={event.eventId}
            radioStatuses={getMainEventRadioStates(enrichedEvents, mainConversionMetric)}
            handleChange={(eventId) => updateMainConversionMetric(event.source, eventId)}
          />
        ),
      };
    });
  };

  const getTableHeaders = (): ITableHeader[] => {
    return [
      {eventName: 'Event'},
      {type: 'Type'},
      {attachedTo: 'Attached to'},
      {
        isMainEvent: (
          <Row>
            Main event
            <Tooltip
              content="Results of the main conversion event are shown in the Segment Overview. It's best used to measure the total effectiveness of personalization."
              margin="0 0 0 6px"
            />
          </Row>
        ),
      },
      {isActive: 'Visible'},
      // {lastSeen: 'Last seen'},
    ];
  };

  const getTableOptions = (): IListOption[] => {
    return [
      {
        primary: 'Rename event',
        callback: (eventId) => {
          setSelectedEventId(eventId);
          setRenameOpen(true);
        },
      },
      {primary: 'Attach to a new element', optionSet: 'markettailor track', callback: () => setToEditorOpen(true)},
      {
        primary: 'Delete event',
        optionSet: ['markettailor track', 'markettailor page'],
        color: 'warn',
        callback: (eventId) => deleteConversionEvent(eventId),
      },
    ];
  };

  return (
    <>
      <PopoverWindow display={toEditorOpen} handleClose={() => setToEditorOpen(false)}>
        <ToEditorForm />
      </PopoverWindow>
      <PopoverWindow display={renameOpen} handleClose={() => setRenameOpen(false)}>
        <RenameForm handleClose={() => setRenameOpen(false)} eventId={selectedEventId} />
      </PopoverWindow>

      <Headline margin={'0 0 10px 0'}>All conversion events</Headline>
      <StyledTable
        headers={getTableHeaders()}
        items={getTableItems()}
        style={{cell: {padding: '8px 16px'}}}
        editOptions={getTableOptions()}
      />
    </>
  );
}

const EventName = ({event}) => {
  if (event.source === 'segment') return <SegmentLogoSmall eventName={event.eventName} />;
  if (event.source === 'googleAnalytics') return <GALogoSmall eventName={event.eventName} />;
  return event.eventName;
};
