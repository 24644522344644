import React from 'react';

import {
  Headline,
  Row,
  StyledLink,
  OnHover,
  smMin,
  StyledNavTop,
  useIsWidthSmaller,
} from '@markettailor/common-markettailor';
import MediaQuery from 'react-responsive';
import {useHistory, useLocation} from 'react-router-dom';

import {Logo} from '../../common/Logo';

function PublicTopNav() {
  const history = useHistory();
  const location = useLocation();
  const isSmaller = useIsWidthSmaller(767);
  const isSignupPage = location.pathname === '/sign-up';
  return (
    <StyledNavTop fullscreen padding={'10px 40px 0 40px'}>
      <Row alignItems={'center'} minWidth={'100%'} justifyContent={'space-between'}>
        <a href={'https://www.markettailor.io'}>
          <Logo pointer width={'160px'} padding={'0'} margin={'0'} />
        </a>
        <Row
          alignItems={'center'}
          minWidth={isSmaller ? '200px' : '300px'}
          maxWidth={isSmaller ? '200px' : '400px'}
          flexGrow={'1'}
          justifyContent={'space-around'}
          margin={'0 0 0 10px'}
        >
          <StyledLink href={'https://www.markettailor.io'}>
            <Headline color={'white'} fontSize={'16px'} cursor="pointer">
              Home
            </Headline>
          </StyledLink>
          <MediaQuery minWidth={smMin}>
            <StyledLink href={'https://www.markettailor.io/pricing'}>
              <Headline color={'white'} fontSize={'16px'} cursor="pointer">
                Pricing
              </Headline>
            </StyledLink>
          </MediaQuery>
          <Headline
            color={'white'}
            fontSize={'16px'}
            cursor="pointer"
            hoverTextDecoration="underline"
            onClick={() => history.push(isSignupPage ? '/sign-in' : '/sign-up')}
          >
            {isSignupPage ? 'Sign in' : 'Sign up'}
          </Headline>
        </Row>
      </Row>
    </StyledNavTop>
  );
}

export default PublicTopNav;
