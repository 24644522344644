import React, {useContext} from 'react';

import {Col} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {SegmentContext} from '../../contexts/SegmentContext';
import InlineNav from '../common/InlineNav';

type Props = {
  currentComponent: string;
};

export default function SegmentationInlineNav({currentComponent}: Props) {
  const history = useHistory();
  const {segmentId} = useContext(SegmentContext)!.state;
  const componentOptions = [
    {value: 'segmentation', label: 'Custom'},
    {value: 'recommended-segments', label: 'Recommended'},
  ];
  return (
    <Col margin={'0 0 0 40px'}>
      <InlineNav
        componentOptions={componentOptions}
        handleSelect={(selection) => {
          const path = '/' + selection + (selection === 'segmentation' ? '/' + segmentId : '');
          history.push(path);
        }}
        currentComponent={currentComponent}
      />
    </Col>
  );
}
