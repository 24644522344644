import React, {useContext, useState, ChangeEvent} from 'react';

import {Col, Headline, StyledBtn, IRole, Text, warnColor} from '@markettailor/common-markettailor';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {AccountContext} from '../../../contexts/AccountContext';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
    },
    '& .MuiInputBase-formControl': {
      fontFamily: 'Montserrat',
      fontSize: '14px',
    },
  },
}));

export interface IUserInvite {
  email: string;
  userRole: IRole;
}

interface Props {
  handleClose: () => void;
  setIsMenuOpen: (isOpen: boolean) => void;
}

function UserInviteForm(props: Props) {
  const classes = useStyles();
  const accountContext = useContext(AccountContext)!;
  const {inviteUser, users} = accountContext;
  const {user} = accountContext.account || {};
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState<IRole>('regular_user');

  const userAlreadyExists = () => users.map((user) => user.email).includes(userEmail);

  function handleUserInvite(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    inviteUser({email: userEmail, userRole: userRole});
    props.handleClose();
  }

  return (
    <Col minWidth="300px">
      <Headline margin={'0 0 10px 0'}>Invite a new user</Headline>
      <form className={classes.root} onSubmit={handleUserInvite}>
        <Col>
          <FormControl variant="outlined">
            <TextField
              required
              id="outlined-required"
              label={'Email'}
              variant={'outlined'}
              value={userEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="select-outlined-label">User role</InputLabel>
            <Select
              required
              labelId="select-outlined-label"
              id="select-outlined"
              label="User role"
              value={userRole}
              onOpen={() => props.setIsMenuOpen(true)}
              onClose={() => props.setIsMenuOpen(false)}
              onChange={(e: ChangeEvent<{name?: string | undefined; value: any}>) => setUserRole(e.target.value)}
            >
              <MenuItem value={'regular_user'}>Regular user</MenuItem>
              {user?.role === 'admin' && <MenuItem value={'admin'}>Admin</MenuItem>}
            </Select>
          </FormControl>
          <Col alignItems="center">
            <StyledBtn onSubmit={handleUserInvite} disabled={userAlreadyExists()}>
              Invite user
            </StyledBtn>
            {userAlreadyExists() && (
              <Text style={{fontStyle: 'italic'}} color={warnColor}>
                User already exists
              </Text>
            )}
          </Col>
        </Col>
      </form>
    </Col>
  );
}

export default UserInviteForm;
