import React, {useContext} from 'react';

import {IDomElem, IDomElems, IPage, ISegment} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import {SegmentContext} from '../../../contexts/SegmentContext';

export const useNavigateToSegmentRules = () => {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {setSegmentId} = segmentContext!;

  return (segment: ISegment) => {
    if (!segment) return;
    setSegmentId(segment.segmentId);
    history.push(
      segment.segmentType === 'outbound' ? '/outbound/' + segment.segmentId : '/segmentation/' + segment.segmentId
    );
  };
};

export const copySegment = (segment: ISegment): ISegment => {
  const pageUrls = Object.values(segment.pageUrls)
    .map(copyPage)
    .reduce((acc, page) => ({...acc, [page.pageId]: page}), {});
  const segmentName = (segment?.segmentName || 'Unnamed segment') + ' - Copy';
  return {...segment, pageUrls, segmentName, segmentId: uuid(), isActive: false};
};
const copyDomElems = (domElems: IDomElems): IDomElems =>
  Object.values(domElems)
    .map(copyDomElem)
    .reduce((acc, domElem) => ({...acc, [domElem.elemId]: domElem}), {});
const copyPage = (page: IPage): IPage => {
  const newDomElems = copyDomElems(page.domElems);
  const newId = uuid();
  return {...page, pageId: newId, domElems: newDomElems};
};
const copyDomElem = (domElem: IDomElem): IDomElem => {
  const newId = 'mt-' + uuid();
  return {
    elemId: newId,
    domOriginal: {...domElem.domOriginal, domElemId: newId},
    domEditor: {...domElem.domEditor, domElemId: newId},
  };
};
