import React, {useContext} from 'react';

import {Col, Headline, Row, StyledBox, useIsWidthSmaller} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {PageInsight} from '../../segmentation/sideContainer/audienceInsights/AudienceInsights';
import PageInsights from './PageInsights';

export default function PageInsightContainer({pageInsights}: {pageInsights: PageInsight[]}): JSX.Element {
  const segmentContext = useContext(SegmentContext);
  const {segmentId, segments} = segmentContext!.state;
  const isLarge = !useIsWidthSmaller(1600);

  return (
    <Col margin={isLarge ? '0 20px 0 20px' : '0 0 0 20px'}>
      <Row alignItems={'center'}>
        <Headline color={'black'} margin={'0 10px 10px 0'}>
          Page insights
        </Headline>
        <Headline margin={'0 0 10px 0'} fontSize={'13px'}>
          Last 30 days
        </Headline>
      </Row>
      <StyledBox minHeight={'40px'} margin={'0 0 10px 0'}>
        <Col>
          {!isEmpty(pageInsights) ? (
            <PageInsights header={segments[segmentId]?.segmentName || 'Selected segment'} insights={pageInsights} />
          ) : (
            <Headline>Your segment doesn't have any visitors yet.</Headline>
          )}
        </Col>
      </StyledBox>
    </Col>
  );
}
