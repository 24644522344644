import React, {useContext} from 'react';

import {StyledBox, Col, Headline, TextLink, OpenInNewIcon} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {SegmentContext} from '../../contexts/SegmentContext';
import SegmentDropdown from '../common/SegmentDropdown';
import SegmentLabels from './SegmentLabels';

export const SegmentSelection = ({segments, segmentId}) => {
  return (
    <>
      <Headline margin={'0 0 10px 0'}>Segment</Headline>
      <StyledBox>
        <Col>
          <Col margin={'0 0 10px 0'}>
            <SegmentDropdown />
          </Col>
          <SegmentLabels />
          <OutboundManagementLink />
        </Col>
      </StyledBox>
    </>
  );
};

const OutboundManagementLink: React.FC = () => {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;
  if (!segmentId || !segments[segmentId]?.outbound) return null;

  return (
    <TextLink
      margin="10px 0 0 0"
      fontWeight="200"
      fontSize="13px"
      style={{fontStyle: 'italic'}}
      onClick={() => history.push('/outbound/' + segmentId)}
    >
      Edit segment items <OpenInNewIcon margin="0 0 5px 2px" width="10px" />
    </TextLink>
  );
};
