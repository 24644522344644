import React, {useContext, useState} from 'react';

import {StyledNavTop, MainContentView, AbsoluteDiv, StyledBtn, Row, Col} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {IntegrationContext, crmDataSources} from '../../contexts/IntegrationContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {SegmentUrls, ChangePageUrlPopover, handleToEditorClick} from '../common/SegmentUrls';
import SegmentName from '../segmentsOverview/SegmentName';
import {OutboundSkeleton, OutboundEmpty, IntegrationNotActive} from './emptyStates';
import OutboundContainerContent from './OutboundContainerContent';
import {OutboundSegmentDetailsContainer} from './outboundSegmentDetails/OutboundSegmentDetailsContainer';
import {useSetPageOutbound} from './util';

export default function OutboundContainer() {
  const segmentContext = useContext(SegmentContext);
  const {segments, segmentId, pageId} = segmentContext!.state;
  const [renderChangeUrlWindow, setRenderChangeUrlWindow] = useState(false);
  const [redirectToEditorOnClose, setRedirectToEditorOnClose] = useState(false);
  const pageUrl = segments[segmentId]?.pageUrls[pageId]?.pageUrl;

  useSetPageOutbound();

  return (
    <>
      <StyledNavTop>
        Outbound
        <AbsoluteDiv right={'0'}>
          <StyledBtn
            data-intercom-id="outbound-toEditor-button"
            margin={'0 20px 0 10px'}
            onClick={() => handleToEditorClick(pageUrl, pageId, setRenderChangeUrlWindow, setRedirectToEditorOnClose)}
          >
            To editor
          </StyledBtn>
        </AbsoluteDiv>
      </StyledNavTop>
      <MainContentView>
        <ChangePageUrlPopover
          setRenderChangeUrlWindow={setRenderChangeUrlWindow}
          renderChangeUrlWindow={renderChangeUrlWindow}
          redirectOnClose={redirectToEditorOnClose}
        />
        <Row justifyContent="space-between" margin="0 20px 0 0">
          <Col>
            <SegmentName />
            <OutboundSegmentDetailsContainer />
          </Col>
          <SegmentUrls setRenderChangeUrlWindow={setRenderChangeUrlWindow} />
        </Row>
        <OutboundMainContent />
      </MainContentView>
    </>
  );
}

const OutboundMainContent = () => {
  const {integrations} = useContext(IntegrationContext)!;
  const segmentContext = useContext(SegmentContext);
  const {segmentId, segments, outboundAllData, outboundDataIsLoading, outboundDataSampleIsLoading} =
    segmentContext!.state;

  if (!segmentId || (outboundDataIsLoading && outboundDataSampleIsLoading)) return <OutboundSkeleton />;

  const crmDataSource = segments[segmentId].outbound!.crmDataSource;
  const integrationNoLongerActive = crmDataSources.includes(crmDataSource) && !integrations[crmDataSource]?.isActive;
  if (integrationNoLongerActive) return <IntegrationNotActive />;

  if (isEmpty(outboundAllData)) return <OutboundEmpty />;
  return <OutboundContainerContent />;
};
