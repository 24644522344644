import React, {useContext, useState} from 'react';

import {Col, Headline, Row, StyledBtn, StyledDivider, Text, brandMain} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../../contexts/AccountContext';
import {formatNum} from '../../../functions/util';
import ConfirmationPage, {stripePlanNameMapping} from './ConfirmationPage';
import FeaturePlans from './FeaturePlans';

export default function ManagePlan(props: {handleClose: () => void}) {
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = useState<keyof typeof stripePlanNameMapping | undefined>();

  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  const {permissions} = account;

  const inboundVisitors = false;
  const currentPlan = permissions?.plan;

  if (!selectedPlan) {
    return (
      <Col alignItems={'center'}>
        <Headline color={brandMain} margin={'0 0 10px 0'}>
          Manage your account plan
        </Headline>
        <Text>Choose your plan. Upgrade or cancel anytime.</Text>

        <StyledDivider border margin={'20px 0'} />
        <Row>
          <Col alignItems={'center'} margin={'10px 50px 0 0'}>
            <Text margin={'0 0 10px 0'}>Unique visitors in current plan</Text>
            <Row alignItems={'center'}>
              <Headline color={brandMain}>{formatNum(permissions.inboundVisitors) + ' /mo'}</Headline>
              <StyledBtn
                disabled={currentPlan === 'freemium'}
                onClick={() => {
                  setSelectedPlan('inboundVisitors');
                }}
              >
                Change
              </StyledBtn>
            </Row>
            <Text
              underline
              pointer
              onClick={() => {
                history.push('/usage');
              }}
            >
              See usage
            </Text>
          </Col>
          <Col alignItems={'center'} margin={'10px 0 0 0'}>
            <Text margin={'0 0 10px 0'}>Personalization based on company data</Text>
            <Row alignItems={'center'}>
              <Headline color={brandMain}>{inboundVisitors ? 'Active' : 'Inactive'}</Headline>
              <StyledBtn
                warn={inboundVisitors}
                disabled={currentPlan === 'freemium'}
                onClick={() => setSelectedPlan('inboundVisitors')}
              >
                {inboundVisitors ? 'Cancel' : 'Enable'}
              </StyledBtn>
            </Row>
          </Col>
        </Row>
        <StyledDivider border margin={'20px 0'} />
        <FeaturePlans setSelectedPlan={setSelectedPlan} />
      </Col>
    );
  } else {
    return <ConfirmationPage selectedPlan={selectedPlan} handleClose={props.handleClose} />;
  }
}
