export const convertWindowSizeToPx = (windowSize: string | number, isWidth = true): number => {
  if (typeof windowSize === 'number') return windowSize;
  const size = Number(windowSize.slice(0, windowSize.length - 2)) / 100;
  if (isNaN(size) || windowSize.length > 5) {
    console.error(windowSize);
    throw new Error('Check the window size type given as input');
  }
  return isWidth ? window.innerWidth * size : window.innerHeight * size;
};

export const isUsingMarkettailorIpData = (featureFlags: string[]) => featureFlags.includes('markettailor_ip_lookup');

export const cleanPageUrl = (pageUrl: string) => {
  pageUrl = pageUrl.split('?')[0];
  if (pageUrl.includes('//')) pageUrl = pageUrl.split('//')[1];
  if (pageUrl.slice(-1) !== '/') pageUrl = pageUrl + '/';
  return pageUrl;
};
