import React from 'react';

import {Col, Headline, StyledBox, Text, GridContainer, GridItem, brandMain} from '@markettailor/common-markettailor';

import {formatPercent} from '../../../../functions/util';
import {BaseInsight} from './AudienceInsights';
import {createSegmentTotals} from './util';

export interface SegmentOverviewTotals {
  visitors: number;
  trafficPercentage: number;
}
interface Props {
  completeInsights?: BaseInsight[];
  hasValidFilters: boolean;
}

export default function SegmentOverview({completeInsights, hasValidFilters}: Props) {
  const segmentOverviewTotals: SegmentOverviewTotals | undefined = createSegmentTotals(completeInsights);

  if (!(segmentOverviewTotals && hasValidFilters)) return null;
  return (
    <Col margin={'0 0 20px 0'} minWidth="100%">
      <Headline color={'black'} margin={'0 0 10px 0'}>
        Segment overview
      </Headline>
      <StyledBox minHeight={'40px'}>
        <Col>
          <Headline fontSize="14px" margin={'0 0 10px 0'} color={brandMain}>
            Current segment
          </Headline>
          <OverviewContent segmentOverviewTotals={segmentOverviewTotals} />
        </Col>
      </StyledBox>
    </Col>
  );
}

const OverviewContent = ({segmentOverviewTotals}) => {
  return (
    <GridContainer width="50%" columns="2fr 1fr" columnGap="0" rowGap="10px">
      <GridItem>
        <Text>Visitors</Text>
      </GridItem>
      <GridItem>
        <Text>{segmentOverviewTotals.visitors}</Text>
      </GridItem>
      <GridItem>
        <Text>Traffic</Text>
      </GridItem>
      <GridItem>
        <Text>{formatPercent(segmentOverviewTotals.trafficPercentage * 100, 0)}</Text>
      </GridItem>
    </GridContainer>
  );
};
