import React from 'react';

import {Col, AbsoluteDiv, useIsWidthSmaller, StyledDivider} from '@markettailor/common-markettailor';

import {SegmentUrls} from '../../common/SegmentUrls';
import {InsightsContainer} from './audienceInsights/InsightsContainer';

export const SideContainer = ({setRenderChangeUrlWindow}) => {
  const isMedium = useIsWidthSmaller(1400);

  if (isMedium) return null;
  return (
    <AbsoluteDiv right={'0'}>
      <Col minWidth={'420px'} maxWidth={'420px'}>
        <Col margin={'0 20px 10px 0'} alignItems="end">
          <SegmentUrls setRenderChangeUrlWindow={setRenderChangeUrlWindow} />
          <StyledDivider margin={'0 0 20px 0'} />
          <InsightsContainer />
        </Col>
      </Col>
    </AbsoluteDiv>
  );
};
