import React, {useContext} from 'react';

import {Row, Headline, StyledBtn, Text, ISegmentQuery} from '@markettailor/common-markettailor';
import {v4 as uuid} from 'uuid';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {useGetSegmentQuery, checkFiltersForCompleteness} from '../util';
import SegmentFilterContainer from './valueSelection/SegmentFilterContainer';

export default function Segmentation(): JSX.Element {
  const segmentContext = useContext(SegmentContext);
  const {updateSegment} = segmentContext!;
  const {segmentId} = segmentContext!.state;
  const segmentQuery = useGetSegmentQuery();
  const {queryRequest: segmentFilters, filterCondition} = segmentQuery;

  const createFilter = () => {
    const newSegmentQuery: ISegmentQuery = {
      queryRequest: [
        ...segmentFilters,
        {
          filterId: uuid(),
          selector: '',
          condition: '',
          valueRange: [],
        },
      ],
      filterCondition: 'and',
    };
    updateSegment(segmentId, {
      segmentQuery: newSegmentQuery,
    });
  };

  return (
    <>
      <Row>
        <Headline color={'black'} margin={'0 0 10px 0'}>
          Segment rules
        </Headline>
        <Text margin={'0 0 10px 20px'}>{checkFiltersForCompleteness(segmentFilters) ? 'Saved' : 'Incomplete'}</Text>
      </Row>
      <SegmentFilterContainer segmentFilters={segmentFilters} filterCondition={filterCondition} />
      <StyledBtn data-intercom-id="inbound-segment-new-rule" onClick={createFilter}>
        Add rule
      </StyledBtn>
    </>
  );
}
