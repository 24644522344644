import React, {useState, useContext, useEffect} from 'react';

import {StyledInputText, Text, Col} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import {SegmentContext} from '../../contexts/SegmentContext';

export default function SegmentName() {
  const segmentContext = useContext(SegmentContext);
  const {updateSegment} = segmentContext!;
  const {segmentId, segments} = segmentContext!.state;
  const [currentSegmentName, setCurrentSegmentName] = useState('');
  const [saveNameTimerId, setSaveNameTimerId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    segments[segmentId] && setCurrentSegmentName(segments[segmentId].segmentName || '');
    //eslint-disable-next-line
  }, [segmentId]);

  useEffect(() => {
    return () => {
      if (saveNameTimerId) {
        clearTimeout(saveNameTimerId);
      }
    };
  }, [saveNameTimerId]);

  async function handleNameChange(name: string) {
    if (saveNameTimerId) {
      clearTimeout(saveNameTimerId);
    }
    const timeoutId = setTimeout(() => {
      updateSegment(segmentId, {segmentName: name});
    }, 1500);
    setSaveNameTimerId(timeoutId);
    setCurrentSegmentName(name);
  }

  if (segmentId) {
    return (
      <Col margin={'0 0 10px 0'}>
        <StyledInputText
          data-intercom-id="segment-name-change"
          placeholder={'< Name your campaign >'}
          margin={'0 0 10px 0'}
          fontSize={'18px'}
          maxWidth={'400px'}
          spellCheck={false}
          value={currentSegmentName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e.target.value)}
        />
        <Text fontSize={'13px'}>Segment name</Text>
      </Col>
    );
  }

  return (
    <Col margin={'0 0 50px 0'}>
      <Skeleton width="300px" height="40px" />
    </Col>
  );
}
