import React, {useContext, useState} from 'react';

import {
  PopoverWindow,
  StyledBtn,
  Headline,
  StyledDivider,
  Text,
  Col,
  Row,
  NotificationContext,
  IGoogleAnalytics,
} from '@markettailor/common-markettailor';

import {ConversionAnalyticsContext} from '../../../contexts/ConversionAnalyticsContext';
import {IntegrationContext} from '../../../contexts/IntegrationContext';
import IntegrationBadge from '../IntegrationBadge';
import {IAnalyticsAccount} from './googleAnalytics/AccountOptionsSelector';
import OptionsWindowGA from './googleAnalytics/OptionsWindowGA';
import {gaButtonStyles, useGoogleLogin} from './googleAnalytics/util';

interface GoogleResponse {
  code: string;
}

export interface IAuthOptionsGA {
  code: string;
}

const CLIENT_ID = '384390034794-si41qif63o3bavp923jqllkpcgjenfrk.apps.googleusercontent.com';
const SCOPE =
  'https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.edit';

export default function GoogleAnalytics() {
  const {integrations, authIntegration} = useContext(IntegrationContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {activeIntegration} = useContext(ConversionAnalyticsContext)!;
  const [renderOptionsWindow, setRenderOptionsWindow] = useState(false);
  const [accountSummary, setAccountSummary] = useState<IAnalyticsAccount[]>([]);

  async function responseGoogleSuccess(googleResponse: GoogleResponse): Promise<void> {
    const res = await authIntegration('googleAnalytics', {
      code: googleResponse.code,
    });
    if (!res) {
      setInfoNotification({message: 'Integration failed, please contact support', level: 'error'});
      return;
    }
    if (res.status === 404) {
      setInfoNotification({
        message: 'Integration failed, Google user does not have a Google Analytics account',
        level: 'error',
      });
      return;
    }
    if (res.data?.accountSummary) {
      setAccountSummary(res.data.accountSummary);
      setRenderOptionsWindow(true);
    }
  }

  const isDisabled = activeIntegration && !integrations.googleAnalytics?.isActive;
  const onError = (e) => {
    console.error('Failed', e);
    setInfoNotification({message: 'Integration failed', level: 'error'});
  };
  const loginAuthCodeFlow = useGoogleLogin({
    onSuccess: responseGoogleSuccess,
    onError: onError,
    onNonOAuthError: onError,
    scope: SCOPE,
    clientId: CLIENT_ID,
  });
  return (
    <>
      <PopoverWindow hasMenu={true} display={renderOptionsWindow} handleClose={() => setRenderOptionsWindow(false)}>
        <OptionsWindowGA accountSummary={accountSummary} handleClose={() => setRenderOptionsWindow(false)} />
      </PopoverWindow>
      <IntegrationBadge
        title={'Google Analytics 4'}
        description={'Enable Google Analytics 4 to use your existing conversion tracking and goals for conversions.'}
        isActive={integrations.googleAnalytics?.isActive}
        isDisabled={isDisabled}
        logoWidth={'70px'}
        integrationName={'googleAnalytics'}
        settingsRenderer={integrations.googleAnalytics && <SettingsMenu gaSettings={integrations.googleAnalytics} />}
        customAuthButton={
          !isDisabled ? (
            <StyledBtn style={gaButtonStyles} onClick={loginAuthCodeFlow}>
              Authorize
            </StyledBtn>
          ) : (
            <StyledBtn disabled={true} margin="0">
              Authorize
            </StyledBtn>
          )
        }
      />
    </>
  );
}

const SettingsMenu = ({gaSettings}: {gaSettings: IGoogleAnalytics}) => {
  const renderRow = (name: string, value: string | number) => {
    return (
      <Row justifyContent={'space-between'}>
        <Text margin="0 0 5px 0">{name}</Text>
        <Headline>{value}</Headline>
      </Row>
    );
  };

  return (
    <Col minWidth="250px">
      <Headline textAlign="center" color="#000">
        Google Analytics settings
      </Headline>
      <StyledDivider border margin="20px 0" />
      {renderRow('Account id', gaSettings.accountName.split('/')[1])}
      {renderRow('Property id', gaSettings.propertyName.split('/')[1])}
    </Col>
  );
};

export const GALogoSmall = ({eventName}: {eventName: string}): JSX.Element => {
  return (
    <Row alignItems="center">
      <img height="15px" src="../googleAnalytics-logo-small.svg" alt="Segment small logo" />
      <Headline margin="0 0 0 10px" color="#000">
        {eventName}
      </Headline>
    </Row>
  );
};
