import React, {ReactNode, useEffect, useState} from 'react';

import {StyledBtn, StyledDivider, Text} from '@markettailor/common-markettailor';
import axios from 'axios';

import config from '../../../config.json';

interface AppsumoPlan {
  inboundVisitors: number;
  outboundVisitors: number;
  plan_id:
    | 'markettailor_tier1'
    | 'markettailor_tier2'
    | 'markettailor_tier3'
    | 'markettailor_tier4'
    | 'markettailor_tier5';
}

export const useAppsumoPlan = () => {
  const source = axios.CancelToken.source();
  const [appsumoPlan, setAppsumoPlan] = useState<AppsumoPlan>();

  useEffect(() => {
    const getAppsumoPlan = async () => {
      try {
        const res = await axios.get(config.api.baseURL + 'appsumo/plan', {
          cancelToken: source.token,
        });
        setAppsumoPlan(res.data);
      } catch (e) {
        if (axios.isCancel(e)) return;
        console.debug(e);
      }
    };
    getAppsumoPlan();
    return () => source.cancel('useAppsumoPlan unmounted');
  }, []);
  return appsumoPlan;
};

export const AppsumoFeatureListing = () => (
  <>
    <StyledDivider border margin={'20px 0'} />
    <Text margin={'0 0 15px 0'} color={'black'} fontWeight={600}>
      Your AppSumo plan includes
    </Text>
    <TextListItem>Personalization with visual editor</TextListItem>
    <TextListItem>Conversion tracking</TextListItem>
    <TextListItem>Audience and Page Insights</TextListItem>
    <TextListItem>A/B testing</TextListItem>
    <TextListItem>Outbound / Account-Based Marketing</TextListItem>
    <TextListItem>Recommended segments</TextListItem>
    <TextListItem>One account is for one site</TextListItem>
  </>
);

const TextListItem = ({children}: {children: ReactNode}) => {
  return <Text margin={'0 0 10px 0'}>{children}</Text>;
};
