import React from 'react';

import {Headline, StyledBtn, Row, Col} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import EmptyScreen from '../common/EmptyScreen';

export const RenderNoSegments = ({setOpenSegmentTypeSelection}: {setOpenSegmentTypeSelection: Function}) => {
  return (
    <EmptyScreen imageSrc="./emptySegments.svg">
      <>
        <Headline margin="0 0 10px 0">
          You're on your way to create your first personalization! First, you need to create a segment
        </Headline>
        <StyledBtn onClick={() => setOpenSegmentTypeSelection(true)}>Create your first segment</StyledBtn>
      </>
    </EmptyScreen>
  );
};

export const RenderSkeleton = () => {
  return (
    <>
      <Row margin={'0 0 50px 0'}>
        <Col margin={'0 15px 0 0 '}>
          <Skeleton width={420} height={120} />
        </Col>
        <Skeleton width={270} height={120} />
      </Row>
      <Skeleton width={1000} height={200} />
    </>
  );
};
