import React, {useContext} from 'react';

import {Row, AlignLeft, AlignRight} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {StyledNav} from '../../../styles/editor/editorStyles';
import {NavigateBackButton} from './NavigateBackButton';
import {PageTitleContainer} from './PageTitleContainer';
import {PreviewButton} from './PreviewButton';
import {PublishButton} from './PublishButton';
import {ResetAllButton} from './ResetAllButton';

export default function EditorTopNav() {
  const editorContext = useContext(EditorContext)!;
  const {editorMode} = editorContext!;

  return (
    <StyledNav>
      <AlignLeft>
        <Row>
          <NavigateBackButton />
          <PageTitleContainer />
        </Row>
      </AlignLeft>
      <AlignRight>
        {editorMode !== 'track' && <ResetAllButton />}
        {editorMode !== 'track' && <PreviewButton />}
        {editorMode !== 'track' && <PublishButton />}
      </AlignRight>
    </StyledNav>
  );
}
