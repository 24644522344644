import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import {BrowserOptions} from '@sentry/react';

export const sentryConfig: BrowserOptions = {
  dsn: 'https://2ae4623d59734d1899ed89c2e28d693e@o407463.ingest.sentry.io/5276431',
  release: '1.0',
  integrations: [new CaptureConsoleIntegration({levels: ['error']})],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // Sign in
    'Error message: Incorrect username or password.',
    'Error message: Try logging in with a password',
    // Sign up
    'Error message: Password did not conform with policy: Password not long enough',
    'Error message: Password did not conform with policy: Password must have uppercase characters',
    'Error message: Password did not conform with policy: Password must have lowercase characters',
    'Error message: Please provide username and password',
    'Error message: An account with the given email already exists.',
    'Password cannot be empty',
    "Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256",
    'InvalidPasswordException',
    'UsernameExistsException',
    // Other
    'NotAuthorizedException: Refresh Token has expired',
    'Network Error',
  ],
};
