import React from 'react';

import {omit, cloneDeep} from 'lodash';

import CSVExport from '../common/CSV/CsvExport';
import {IListItem} from './RevealTable';
import {usePrepareListData} from './util';

export default function RevealCSVExport() {
  const sortedListData = usePrepareListData();

  const removeKeys = (data) => {
    const newData = cloneDeep(data);
    return newData.map((item) => {
      item = omit(item, ['dstOffset', 'isISP', 'isWifi', 'utcOffset']);
      return item;
    });
  };

  const exportData: IListItem[] = removeKeys(sortedListData);
  const filename = 'Markettailor Company Reveal';

  return (
    <div data-intercom-id="reveal-export">
      <CSVExport data={exportData} filename={filename} />
    </div>
  );
}
