import React, {useEffect, useContext, useState, ReactNode, createContext} from 'react';

import axios, {CancelTokenSource} from 'axios';
import {isEmpty} from 'lodash';

import config from '../config.json';
import {deepFreeze} from '../functions/util';
import {mapping} from '../termMaps/technicalNamesToLabels';
import {SegmentContext} from './SegmentContext';

export type RecommendationType = {
  selector: keyof typeof mapping;
  value: string;
  count: number;
  fraction: number;
};

export type Recommendations = RecommendationType[];

export interface IRecommendationContext {
  recommendations: RecommendationType[];
  isLoading: boolean;
}

const RecommendationContext = createContext<IRecommendationContext | undefined>(undefined);

function RecommendationProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const segmentContext = useContext(SegmentContext);
  const segmentState = segmentContext!.state;
  const [allRecommendations, setAllRecommendations] = useState<Recommendations>([]);
  const [recommendations, setRecommendations] = useState<Recommendations>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getRecommendations();
    return () => source.cancel('Recommendations unmounted.');
    // eslint-disable-next-line
  }, []);

  async function getRecommendations() {
    try {
      const res = await axios.get(config.api.baseURL + 'analytics/recommendations', {
        cancelToken: source.token,
      });
      setAllRecommendations(res.data);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  }

  useEffect(() => {
    setRecommendations(filterRecommendations(allRecommendations));
    // eslint-disable-next-line
  }, [isLoading, JSON.stringify(segmentState.segments), segmentState.isLoading]);

  function filterRecommendations(recommendations: Recommendations) {
    return recommendations.filter((recommendation: RecommendationType) => isNewSegment(recommendation));
  }

  function isNewSegment(recommendation: RecommendationType) {
    const existingSegment = Object.values(segmentState.segments).find((segment) => {
      return (
        !isEmpty(segment?.segmentQuery?.queryRequest) &&
        segment?.segmentQuery?.queryRequest[0].selector === recommendation.selector &&
        segment?.segmentQuery?.queryRequest[0].condition === '__equals' &&
        segment?.segmentQuery?.queryRequest[0].valueRange[0] === recommendation.value
      );
    });
    return isEmpty(existingSegment);
  }

  return (
    <RecommendationContext.Provider value={deepFreeze<IRecommendationContext>({recommendations, isLoading})}>
      {children}
    </RecommendationContext.Provider>
  );
}

export {RecommendationContext, RecommendationProvider};
