import React, {useContext} from 'react';

import {
  Col,
  AbsoluteDiv,
  StyledPopup,
  ClickAwayDiv,
  OnHover,
  Headline,
  Round,
  ArrowDownIcon,
  useIsWidthSmaller,
  ISegment,
} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../../contexts/SegmentContext';
import StatusCellMenu, {IExperimentStatus, statuses} from './StatusCellMenu';

interface Props {
  segment: ISegment;
  selectedSegment: string | null;
  setSelectedSegment: React.Dispatch<React.SetStateAction<string | null>>;
}

export const StatusCell = ({segment, selectedSegment, setSelectedSegment}: Props): JSX.Element => {
  const segmentContext = useContext(SegmentContext);
  const {updateSegment} = segmentContext!;
  const {segmentId: elemId, isActive, abControlWeight} = segment;
  const abPersonalizationWeight = 1 - (abControlWeight || 0);

  const status: IExperimentStatus = isActive
    ? statuses[abPersonalizationWeight || 'default'] || statuses.default
    : statuses[0];
  const isMedium = useIsWidthSmaller(993);

  function handleMenuStatusClick(elemId: string, abPersonalizationWeight: number) {
    setSelectedSegment(null);
    updateSegment(elemId, {isActive: abPersonalizationWeight !== 0, abControlWeight: 1 - abPersonalizationWeight});
  }

  return (
    <Col>
      <AbsoluteDiv>
        <StyledPopup popupDisplay={elemId === selectedSegment}>
          <ClickAwayDiv
            render={elemId === selectedSegment}
            position="fixed"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setSelectedSegment(null);
            }}
          />
          <StatusCellMenu elemId={elemId} handleClick={handleMenuStatusClick} />
        </StyledPopup>
      </AbsoluteDiv>
      <OnHover cursor="pointer">
        <Col
          alignItems="center"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setSelectedSegment(elemId);
          }}
        >
          <Headline
            margin="0 0 5px 0"
            fontSize={isMedium ? '12px' : '14px'}
            color={status.color}
            style={{wordBreak: 'initial'}}
          >
            {status.text}
          </Headline>
          <Round backgroundColor="none" border={`2px solid ${status.color}`} width="15px">
            <ArrowDownIcon color={`${status.color}`} width="15px" />
          </Round>
        </Col>
      </OnHover>
    </Col>
  );
};
