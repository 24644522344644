import React, {useState, useContext} from 'react';

import {Card, FileCsvIcon} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import {SegmentContext} from '../../../../contexts/SegmentContext';
import CsvCleanup from '../../../common/CSV/CsvCleanup';
import CsvUploadButton from '../../../common/CSV/CsvUploadButton';
import {ParsedCsvDataType} from '../../../common/CSV/csvUploadUtil';

interface Props {
  csvCleanup: boolean;
  setCsvCleanup: Function;
}

export default function CSVCreation({csvCleanup, setCsvCleanup}: Props) {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {createNewSegment} = segmentContext!;
  const [csvData, setCsvData] = useState<ParsedCsvDataType>([]);
  const newSegmentId = uuid();

  if (!csvCleanup)
    return (
      <Card
        header={'CSV'}
        description={`Create a campaign based on contacts in a CSV file.`}
        midSection={<FileCsvIcon width="25px" />}
        ctaButton={
          <CsvUploadButton
            buttonStyles={{margin: '15px 0 0 0'}}
            dropEmptyColumns={true}
            handleDataOutput={(csvData) => {
              setCsvData(csvData);
              setCsvCleanup(true);
            }}
          />
        }
      />
    );
  return (
    <CsvCleanup
      csvData={csvData}
      handleClose={(filteredCsvData: object[]) => {
        createNewSegment({
          segmentId: newSegmentId,
          segmentType: 'outbound',
          crmDataSource: 'csv',
          csvData: filteredCsvData,
        });
        setCsvCleanup(false);
        history.push('/outbound/' + newSegmentId);
      }}
    />
  );
}
