import {MainContentView, StyledNavTop} from '@markettailor/common-markettailor';

import CompanyReveal from './CompanyReveal';

export default function RevealContainer() {
  return (
    <>
      <StyledNavTop>Company Reveal</StyledNavTop>
      <MainContentView>
        <CompanyReveal />
      </MainContentView>
    </>
  );
}
