import React from 'react';

import InlineNav from '../common/InlineNav';
import {OutboundListType} from './OutboundContainerContent';
import {useGetOutboundSegmentData} from './util';

interface Props {
  inlineNavAllLabelType: string;
  listTypeSelected: OutboundListType;
  handleSelect: Function;
}

export const OutboundInlineNav = ({inlineNavAllLabelType, listTypeSelected, handleSelect}: Props) => {
  const outboundSegmentData = useGetOutboundSegmentData();
  const segmentDataCount = outboundSegmentData.length;
  return (
    <InlineNav
      componentOptions={[
        {value: 'allList', label: 'All ' + inlineNavAllLabelType},
        {value: 'segmentList', label: 'Segment (' + segmentDataCount + ')'},
      ]}
      handleSelect={(selection) => handleSelect(selection)}
      currentComponent={listTypeSelected}
    />
  );
};
