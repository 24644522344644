import React, {useContext} from 'react';

import {
  Col,
  Row,
  MainContentView,
  StyledBox,
  StyledBtn,
  Headline,
  StyledNavTop,
} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../../contexts/AccountContext';
import {analytics} from '../../../functions/analytics';
import {useSetNewPage} from '../../../functions/util';
import AccountInlineNav from '../AccountInlineNav';
import AccountForm from './AccountForm';
import DemoAccountResetButton from './DemoAccountResetButton';
import {ExcludeUrlsContainer} from './ExcludeUrlsForm';
import FlushCacheButton from './FlushCache';

export default function AccountContainer() {
  const history = useHistory();
  const {isLoading: accountIsLoading} = useContext(AccountContext)!;
  useSetNewPage('Account');

  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'account'}>
          <Col margin="20px 0 0 0">
            {accountIsLoading ? <MainContentFormsSkeleton /> : <MainContentForms />}
            <Col>
              <StyledBtn
                warn
                onClick={() => {
                  analytics.track('Signed out');
                  history.push('/sign-out');
                }}
              >
                Sign out
              </StyledBtn>
              <FlushCacheButton />
              <DemoAccountResetButton />
            </Col>
          </Col>
        </AccountInlineNav>
      </MainContentView>
    </>
  );
}

function MainContentForms() {
  return (
    <Row>
      <Col>
        <Headline margin="0 0 5px 0">Account information</Headline>
        <StyledBox maxWidth={'400px'} margin={'0 30px 50px 0'}>
          <AccountForm />
        </StyledBox>
      </Col>
      <ExcludeUrlsContainer />
    </Row>
  );
}

function MainContentFormsSkeleton() {
  return (
    <Row margin="20px">
      <Skeleton width={400} height={300} />
      <Col margin={'0 0 0 40px'}>
        <Skeleton width={400} height={200} />
      </Col>
    </Row>
  );
}
