import React, {useContext} from 'react';

import {Row, StyledDivider, Col, StyledBtn} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../../contexts/AccountContext';
import {analytics} from '../../../functions/analytics';
import {CurrentBillingPeriod} from './CurrentBillingPeriod';
import {CurrentPlanLimits} from './CurrentPlanLimits';
import {UsageChart} from './UsageChart';
import {getUsageDates} from './util';

export const Usage = () => {
  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  const {billing} = account;

  const {usageStart, usageEnd} = getUsageDates(billing);

  return (
    <Row margin="20px 0 0 0">
      <UsageChart usageStart={usageStart} usageEnd={usageEnd} />
      <PlanDetails usageStart={usageStart} usageEnd={usageEnd} />
    </Row>
  );
};

const PlanDetails = ({usageStart, usageEnd}) => {
  const history = useHistory();
  return (
    <Col margin={'0 0 0 40px'}>
      <CurrentBillingPeriod usageStart={usageStart} usageEnd={usageEnd} />
      <StyledDivider border margin={'20px 0'} />
      <CurrentPlanLimits />
      <StyledDivider border margin={'20px 0'} />
      <Col alignItems={'center'}>
        <StyledBtn
          onClick={() => {
            analytics.track('Pushed upgrade plan');
            history.push('/subscription');
          }}
        >
          Upgrade plan
        </StyledBtn>
      </Col>
    </Col>
  );
};
