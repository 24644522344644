import React, {createContext, useState, useCallback, useEffect, useContext, ReactNode} from 'react';

import {URLType, NotificationContext} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {last} from 'lodash';

import config from '../config.json';
import {deepFreeze} from '../functions/util';

interface IImageContext {
  galleryImages: URLType[];
  isLoading: boolean;
  imageError: boolean;
  uploadImage: (formData: FormData, contentType: string) => void;
  deleteImage: (imageUrl: URLType) => void;
  getGalleryImages: () => void;
}

const ImageContext = createContext<IImageContext | undefined>(undefined);

function ImageProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [galleryImages, setGalleryImages] = useState<URLType[]>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getGalleryImages = useCallback(async () => {
    try {
      const res = await axios.get(config.api.baseURL + 'editor/images', {cancelToken: source.token});
      setGalleryImages(res.data);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setImageError(true);
      setIsLoading(false);
      setInfoNotification({message: 'Fetching images failed', level: 'error'});
      console.debug(e);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getGalleryImages();
  }, [getGalleryImages]);
  useEffect(() => () => source.cancel('ImageProvider unmounted'));

  const uploadImage = async (formData: FormData, contentType: string) => {
    try {
      const res = await axios.post(config.api.baseURL + 'editor/images', formData, {
        cancelToken: source.token,
        headers: {
          'content-Type': contentType,
          enctype: 'multipart/form-data',
        },
      });
      setGalleryImages([...galleryImages, res.data]);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  const deleteImage = async (imageUrl: string) => {
    try {
      const imageResource = last(imageUrl.split('/'));
      await axios.delete(config.api.baseURL + 'editor/image/' + imageResource, {
        cancelToken: source.token,
      });
      const newGalleryImages = galleryImages.filter((imageSrc) => imageSrc !== imageUrl);
      setGalleryImages(newGalleryImages);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  };

  const value = {
    imageError,
    isLoading,
    galleryImages,
    uploadImage,
    deleteImage,
    getGalleryImages,
  };
  return <ImageContext.Provider value={deepFreeze<IImageContext>(value)}>{children}</ImageContext.Provider>;
}

export {ImageContext, ImageProvider};
