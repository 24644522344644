import React, {useContext} from 'react';

import {StyledNavTop, Headline, MainContentView, Row, StyledDivider, Text} from '@markettailor/common-markettailor';

import config from '../../config.json';
import {AccountContext} from '../../contexts/AccountContext';
import {useSetNewPage} from '../../functions/util';
import GoogleAnalytics from './conversionTracking/GoogleAnalytics';
import SegmentCom from './conversionTracking/SegmentCom';
import ActiveCampaignBadge from './crm/ActiveCampaignBadge';
import OAuthCrmIntegration from './crm/OAuthCrmIntegration';
import {IPDataIntegrations} from './IPDataIntegrations';
import {RenderIntegrationSkeleton} from './util/emptyStates';

export default function IntegrationContainer() {
  useSetNewPage('Integrations');

  return (
    <>
      <StyledNavTop>Integrations</StyledNavTop>
      <MainContentView>
        <ContainerContent />
      </MainContentView>
    </>
  );
}

const ContainerContent = () => {
  const {isLoading} = useContext(AccountContext)!;
  if (isLoading) return <RenderIntegrationSkeleton />;
  return (
    <>
      <div data-intercom-id="integration-crm">
        <Headline margin={'0 0 10px 0'}>Customer Relationship Management (CRM)</Headline>
        <StyledDivider border margin={'0 40px 10px 0'} />
        <CRMIntegrations />
      </div>

      <div data-intercom-id="integration-conversions">
        <Headline margin={'40px 0 10px 0'}>Conversion tracking</Headline>
        <StyledDivider border margin={'0 40px 10px 0'} />
        <ConversionTrackingIntegrations />
      </div>

      <div data-intercom-id="integration-companyData">
        <Headline margin={'40px 0 10px 0'}>Company lookup</Headline>
        <StyledDivider border margin={'0 40px 10px 0'} />
        <IPDataIntegrations />
      </div>
    </>
  );
};

const CRMIntegrations = () => {
  return (
    <Row>
      <OAuthCrmIntegration
        integrationName="salesforce"
        logoWidth="60px"
        oauthUrl={`${config.salesforce.authURL}?client_id=${config.salesforce.clientId}&redirect_uri=${config.salesforce.redirectUri}&response_type=code`}
      />
      <OAuthCrmIntegration
        integrationName="hubspot"
        logoWidth="80px"
        oauthUrl={
          config.hubspot.authURL +
          '?client_id=' +
          encodeURIComponent(config.hubspot.clientId) +
          '&redirect_uri=' +
          config.hubspot.redirectUri +
          '&scope=' +
          encodeURIComponent(config.hubspot.scope)
        }
      />
      <ActiveCampaignBadge />
    </Row>
  );
};

const ConversionTrackingIntegrations = () => {
  return (
    <>
      <Text margin={'0 0 10px 0'} style={{fontStyle: 'italic'}}>
        With conversion tracking, you can only integrate to one service at a time.
      </Text>
      <Row>
        <GoogleAnalytics />
        <SegmentCom />
      </Row>
    </>
  );
};
