import {useContext} from 'react';

import {getSelectCustomStyles, darkGray} from '@markettailor/common-markettailor';
import {isEmpty, isEqual} from 'lodash';

import {EditorContext} from '../../contexts/EditorContext';
import {removeEmptyDomElems, removeUnchangedFields} from '../../contexts/editorContextUtil';
import {SegmentContext} from '../../contexts/SegmentContext';
import {useSetNewPage} from '../../functions/util';

export const useHasAnyUnsavedChanges = (): boolean | undefined => {
  // Track mode uses auto save so this is only relevant in edit
  const unsavedEdit = useHasAnyUnsavedChangesEditMode();
  return unsavedEdit;
};

const useHasAnyUnsavedChangesEditMode = (): boolean | undefined => {
  const {editorMode, domElems: editorDomElems} = useContext(EditorContext)!;
  const {segments, segmentId, pageId} = useContext(SegmentContext)!.state;
  if (!['edit', 'original'].includes(editorMode)) return false;
  const newEditorDomElems = removeUnchangedFields(removeEmptyDomElems(editorDomElems));
  const segmentDomElems = segments[segmentId]?.pageUrls[pageId]?.domElems;
  if (!segmentDomElems) return false;
  return !isEqual(segmentDomElems, newEditorDomElems);
};

export const useElementHasChanges = (elemId: string): boolean | undefined => {
  const unsavedEdit = useElementHasChangesEditMode(elemId);
  const unsavedTrack = useElementHasChangesTrackMode(elemId);
  return unsavedTrack || unsavedEdit;
};

const useElementHasChangesEditMode = (elemId: string): boolean | undefined => {
  const {editorMode, domElems} = useContext(EditorContext)!;
  if (!['edit', 'original'].includes(editorMode)) return false;

  if (isEmpty(domElems) || !elemId || !(elemId in domElems)) return false;
  const {domEditor, domOriginal} = domElems[elemId];
  if (isEmpty(domEditor)) return false;
  return !isEqual(domEditor, domOriginal);
};

const useElementHasChangesTrackMode = (elemId: string): boolean | undefined => {
  const {editorMode, conversionDomElems} = useContext(EditorContext)!;
  if (editorMode !== 'track') return false;

  if (conversionDomElems?.[elemId]?.conversionEventId) return true;
  return false;
};

export const useHasElements = (): boolean | undefined => {
  const {domElems} = useContext(EditorContext)!;
  if (!isEmpty(removeEmptyDomElems(domElems))) return true;
};

export const customStyles = getSelectCustomStyles({
  fontSize: '12px',
  control: {width: '130px', color: darkGray, minHeight: '28px'},
  container: {width: '130px'},
  dropdownIndicator: {padding: '0 4px'},
  singleValue: {
    fontWeight: 400,
    color: '#000',
    background: 'white',
    borderRadius: '12px',
    padding: '0px 4px',
  },
});

export const useSetPageEditor = () => {
  const {segments, segmentId} = useContext(SegmentContext)!.state;
  const {docTitle} = useContext(EditorContext)!;

  const segmentNameStr = segments[segmentId]?.segmentName ? `| ${segments[segmentId].segmentName}` : '';
  const docTitleStr = docTitle ? `| "${docTitle}"` : '';

  useSetNewPage('Editor', `Editor ${segmentNameStr} ${docTitleStr}`);
};
