import React, {useContext, Fragment} from 'react';

import {Col, Text, GridContainer, GridItem, StyledDivider} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {AccountContext} from '../../../contexts/AccountContext';
import {formatPercent, cleanDomainFromUrl} from '../../../functions/util';
import {PageInsight} from '../../segmentation/sideContainer/audienceInsights/AudienceInsights';

export default function PageInsights({
  header,
  insights,
}: {
  header: string;
  insights: PageInsight[];
}): JSX.Element | null {
  const accountContext = useContext(AccountContext)!;
  const {clientDomain} = accountContext.account || {};

  if (!clientDomain || isEmpty(insights)) return null;
  return (
    <Col margin={'10px 10px 15px 10px'}>
      <PageInsightHeader header={header} />
      <StyledDivider border />
      <PageInsightContent clientDomain={clientDomain} insights={insights} />
    </Col>
  );
}

const PageInsightHeader = ({header}: {header: string}) => {
  return (
    <GridContainer columns="3fr 1fr 1fr" columnGap="5px" rowGap="5px">
      {renderRow(
        <Text color="black" fontWeight={600}>
          {header}
        </Text>,
        'Visitors',
        'Traffic (%)'
      )}
    </GridContainer>
  );
};

const PageInsightContent = ({clientDomain, insights}: {clientDomain: string; insights: PageInsight[]}) => {
  return (
    <GridContainer columns="3fr 1fr 1fr" columnGap="5px" rowGap="12px">
      {insights.map((insight, index) => {
        return (
          <Fragment key={index}>
            {renderRow(
              !clientDomain ? '' : cleanDomainFromUrl(insight.pageUrl, clientDomain),
              insight.count,
              formatPercent(insight.fraction * 100, 0)
            )}
          </Fragment>
        );
      })}
    </GridContainer>
  );
};

const renderRow = (name: string | JSX.Element, value: number | string, percentage: string) => {
  return (
    <>
      <GridItem>
        <Text>{name}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{value}</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Text>{percentage}</Text>
      </GridItem>
    </>
  );
};
