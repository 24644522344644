import React, {useState} from 'react';

import {isEmpty} from 'lodash';

import {useGetSegmentQuery} from '../../util';
import {BaseInsight} from './AudienceInsights';
import {RenderAudienceInsights} from './RenderAudienceInsights';
import SegmentOverview from './SegmentOverview';
import {createValidQuery, IQuery} from './util';

export function InsightsContainer() {
  const [completeInsights, setCompleteInsights] = useState<BaseInsight[] | undefined>(undefined);
  const segmentQuery = useGetSegmentQuery();
  const segmentFilters = segmentQuery!.queryRequest;
  const filterCondition = segmentQuery!.filterCondition;
  const [query, setQuery] = useState<IQuery>(createValidQuery(segmentFilters, filterCondition));

  return (
    <div data-intercom-id="inbound-audience-insights" style={{display: 'contents'}}>
      <SegmentOverview completeInsights={completeInsights} hasValidFilters={!isEmpty(query.queryRequest)} />
      <RenderAudienceInsights query={query} setCompleteInsights={setCompleteInsights} setQuery={setQuery} />
    </div>
  );
}
