import React from 'react';

import {AbsoluteDiv, BackArrowIcon, darkGray, Row, Text} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

export default function NavigateBackButton() {
  const history = useHistory();
  return (
    <AbsoluteDiv left={'170px'}>
      <Row onClick={() => history.push('/conversions')} alignItems="center" style={{cursor: 'pointer'}}>
        <BackArrowIcon margin={'10px'} cursor={'pointer'} width="20px" />
        <Text margin={'0 10px 0 0'} color={darkGray} fontSize="14px">
          To conversions
        </Text>
      </Row>
    </AbsoluteDiv>
  );
}
