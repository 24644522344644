import React, {useContext} from 'react';

import {LineChartBase} from '@markettailor/common-markettailor';
import {Moment} from 'moment';

import {AccountContext} from '../../../contexts/AccountContext';
import {StripeContext} from '../../../contexts/StripeContext';
import {getChartData, getHighestLimit} from './util';

interface Props {
  usageStart: Moment;
  usageEnd: Moment;
}

export function UsageChart({usageStart, usageEnd}: Props) {
  const {usage} = useContext(StripeContext)!.state;
  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  const {permissions} = account;

  const highestLimit = getHighestLimit(permissions);

  if (!usage) return null;
  const usageChartData = getChartData(usage);

  return (
    <LineChartBase
      height={400}
      width={700}
      title={'Monthly unique visitors'}
      timeUnit={'day'}
      limitLineText={'Current plan'}
      minX={usageStart.clone().subtract(1, 'days')}
      maxX={usageEnd.clone().add(1, 'days')}
      suggestedMaxY={highestLimit * 1.1}
      lineDate={usageEnd}
      lineDateText={'Next billing'}
      datasets={usageChartData}
    />
  );
}
