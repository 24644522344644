import React from 'react';

import {Headline, StyledBox, StyledDivider, Text, StyledBtn, Col} from '@markettailor/common-markettailor';

interface NewEventCardProps {
  title: string;
  body: string;
  onClick: Function;
  btnText: string;
  icon: JSX.Element;
}

export default function NewEventCard({title, body, icon, onClick, btnText}: NewEventCardProps) {
  return (
    <StyledBox width={'250px'} height={'200px'}>
      <Headline color={'black'}>{title}</Headline>
      <StyledDivider border margin="0" />
      <Col height="30px" margin="10px 0">
        {icon}
      </Col>
      <Text>{body}</Text>
      <Col alignSelf="center" height="100%" justifyContent="flex-end">
        <StyledBtn onClick={onClick}>{btnText}</StyledBtn>
      </Col>
    </StyledBox>
  );
}
