import React, {useContext} from 'react';

import {StyledBtn, lightBlue} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {useGetPagePreviewUrl} from '../../pages/util';
import {useHasElements, useHasAnyUnsavedChanges} from '../util';

export const PreviewButton = () => {
  const editorContext = useContext(EditorContext)!;
  const {pageId, errorState, isSavingChanges} = editorContext!;

  const hasNoChanges = !useHasElements();
  const hasError = Boolean(errorState);
  const hasAnyUnsavedChanges = useHasAnyUnsavedChanges();

  const previewUrl = useGetPagePreviewUrl(pageId);

  return (
    <StyledBtn
      fontSize={'13px'}
      boxShadow="none"
      padding={'10px 15px'}
      background={lightBlue}
      disabled={hasNoChanges || hasError || hasAnyUnsavedChanges || !previewUrl || isSavingChanges}
      onClick={() => window.open(previewUrl, '_blank')}
      title={'Only saved changes are shown in preview mode. You can also share this link with a teammate!'}
    >
      Preview
    </StyledBtn>
  );
};
