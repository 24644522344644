import React from 'react';

import {Headline, Row, Text, Tooltip} from '@markettailor/common-markettailor';

export const PageCountCell = ({pageUrls}: {pageUrls: string[]}) => {
  const attachedToStr = pageUrls.length === 1 ? '1 page' : `${pageUrls.length} pages`;

  const TooltipContent = () => {
    return (
      <>
        {pageUrls.map((pageUrl, index) => (
          <Row key={pageUrl + index}>
            <Text color="#fff">{pageUrl}</Text>
          </Row>
        ))}
      </>
    );
  };

  return (
    <Row alignItems="center" justifyContent="center">
      {pageUrls.length ? (
        <Tooltip content={<TooltipContent />}>
          <Headline color="#000">{attachedToStr}</Headline>
        </Tooltip>
      ) : (
        <Headline color="#000">{attachedToStr}</Headline>
      )}
    </Row>
  );
};
