import React from 'react';

import {Col, Row} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

export const RenderSkeleton = () => {
  return (
    <>
      <Row margin={'0 0 60px 0'} justifyContent={'space-between'}>
        <Col margin={'0 600px 0 0'}>
          <Skeleton width={400} height={40} />
        </Col>
        <Skeleton width={330} height={60} />
      </Row>
      <Col>
        <Skeleton width={630} height={100} />
      </Col>
    </>
  );
};
