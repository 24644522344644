import React, {useContext, useState} from 'react';

import {
  Col,
  Headline,
  Row,
  StyledBtn,
  StyledDivider,
  Text,
  brandMain,
  StyledSwitch,
  FeatureFlag,
} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {AccountContext} from '../../../contexts/AccountContext';
import {StripeContext} from '../../../contexts/StripeContext';
import {formatNum, formatPercent} from '../../../functions/util';
import {mapping} from '../../../termMaps/technicalNamesToLabels';
import {currencies, defaultCurrency} from '../../util/currencies';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root': {
      width: '50px',
      margin: '0',
    },
    '& .MuiInputBase-root': {
      margin: '0',
      color: brandMain,
      fontWeight: 'bold',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat',
      padding: '5px 10px 5px 5px',
      textAlign: 'right',
      margin: '0',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Montserrat',
    },
  },
}));

export const stripePlanNameMapping = {
  freemium: 'Freemium plan',
  //basic: 'Basic plan',
  //growth: 'Growth plan',
  //visitorRequests: 'Unique visitors',
  inboundVisitors: 'Company data',
  companyLookups: 'Company identifications',
  outboundVisitors: 'Outbound visitors',
};

interface Props {
  selectedPlan: keyof typeof stripePlanNameMapping;
  handleClose: () => void;
}

export default function ConfirmationPageLoadingWrapper(props: Props) {
  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  return <ConfirmationPage {...props} />;
}

function ConfirmationPage(props: Props) {
  const classes = useStyles();
  const account = useContext(AccountContext)!.account!;
  const {email, permissions} = account;
  const stripeContext = useContext(StripeContext)!;
  const {changeAccountPlan} = stripeContext!;
  const {stripe} = stripeContext!.state;
  const {selectedPlan, handleClose} = props;
  const [visitorRequestsQuantity, setVisitorRequestsQuantity] = useState(permissions.companyLookups);
  const [ipDataEnabled, setIpDataEnabled] = useState<boolean>(Boolean(permissions.inboundVisitors));
  const [billedYearly, setBilledYearly] = useState(false);
  const yearlyBillingDiscount = formatPercent(20, 0);
  const isVisitorRequests = selectedPlan === 'inboundVisitors';
  const visitorRequestsQuantityBilled = Math.max(visitorRequestsQuantity - permissions.companyLookups / 1000, 0);

  function getPlanBasePrice() {
    if (!stripe?.productPricing) return 0;
    if (!(isVisitorRequests && visitorRequestsQuantity - permissions.companyLookups > 0)) return 0;
    const selectedIpPlan = ipDataEnabled ? 'ipRequests' : 'visitorRequests';
    return stripe.productPricing[selectedIpPlan].price / 100;
  }

  function handleQuantityInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (isNaN(Number(e.target.value))) return;
    setVisitorRequestsQuantity(Number(e.target.value));
  }

  function getAccountTaxRate() {
    const country = stripe?.paymentMethod && stripe?.paymentMethod.billing_details.address?.country;
    if (!country || !stripe?.taxRates) return 0;
    const billingCountry = mapping.country[country];
    const taxRateCountry = stripe.taxRates.find((taxRate) => taxRate.jurisdiction === billingCountry);
    return taxRateCountry?.percentage || 0;
  }

  function getPlanPrice() {
    if (selectedPlan === 'freemium') return 0;
    if (isVisitorRequests) return visitorRequestsQuantityBilled * getPlanBasePrice();
    const price = stripe?.productPricing[selectedPlan].price;
    return price ? price / 100 : 0;
  }

  const currency = stripe?.productPricing.basic.currency;
  const currencyUnit = currency ? currencies[currency] : currencies[defaultCurrency];
  const planBasePrice = getPlanBasePrice();
  const accountTaxRate = getAccountTaxRate();
  const planPrice = getPlanPrice();
  const planTaxes = planPrice * (accountTaxRate / 100);

  return (
    <Col alignItems={'center'} minWidth={'400px'}>
      <Headline color={brandMain} margin={'0 0 10px 0'}>
        Confirm your subscription change
      </Headline>
      <Text>Upgrade or cancel anytime.</Text>
      <StyledDivider border margin={'30px 0'} />
      <Headline color={brandMain} margin={'0 0 40px 0'}>
        {stripePlanNameMapping[selectedPlan]}
      </Headline>
      <Col minWidth={'100%'}>
        {isVisitorRequests && (
          <>
            <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'} alignItems={'center'}>
              <Text>Unique visitors</Text>
              <Row alignItems={'center'}>
                <form className={classes.root}>
                  <TextField
                    id={'visitorRequests'}
                    variant={'outlined'}
                    autoComplete={'off'}
                    name={'visitorRequests'}
                    value={visitorRequestsQuantity}
                    onChange={handleQuantityInput}
                  />
                </form>
                <Headline margin={'0 0 0 5px'} color={brandMain}>
                  000 /mo
                </Headline>
              </Row>
            </Row>
            <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'} alignItems={'center'}>
              <Text>Company data</Text>
              <Row alignItems={'center'}>
                <Text margin={'0 10px 0 0'}>{ipDataEnabled ? 'Enabled' : 'Disabled'}</Text>
                <StyledSwitch defaultState={ipDataEnabled} onChange={setIpDataEnabled} />
              </Row>
            </Row>
            <StyledDivider border margin={'10px 0'} />
            <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'}>
              <Text>Additional 1k visitors ({permissions.companyLookups} free)</Text>
              <Text>
                {visitorRequestsQuantityBilled} x {currencyUnit + planBasePrice.toFixed(2)}
              </Text>
            </Row>
          </>
        )}
        <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'}>
          <Text>Subtotal</Text>
          <Text>{currencyUnit + formatNum(planPrice, 2)}</Text>
        </Row>
        <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'}>
          <Text>Taxes ─ {formatPercent(accountTaxRate, 0)}</Text>
          <Text>{currencyUnit + formatNum(planTaxes, 2)}</Text>
        </Row>
        <StyledDivider border margin={'5px 0'} />
        <Row justifyContent={'space-between'} margin={'0 10px 10px 10px'}>
          <Text color={'black'} fontWeight={600}>
            Monthly total
          </Text>
          <Text color={'black'} fontWeight={600}>
            {currencyUnit + formatNum(planPrice + planTaxes, 2)}
          </Text>
        </Row>
        <FeatureFlag userEmail={email}>
          <Row justifyContent={'space-between'} margin={'10px'}>
            <Text color={'black'}>Billing cycle</Text>
            <Row>
              <Text color={'black'} margin={'0 5px 0 0'}>
                Monthly
              </Text>
              <StyledSwitch defaultState={billedYearly} onChange={setBilledYearly} />
              <Text color={'black'} margin={'0 0 0 5px'}>
                Yearly (-{yearlyBillingDiscount})
              </Text>
            </Row>
          </Row>
        </FeatureFlag>
      </Col>
      <StyledDivider margin={'20px 0'} />
      <StyledBtn
        onClick={() => {
          handleClose();
          changeAccountPlan({
            planName: selectedPlan,
            visitorRequestsQuantity: visitorRequestsQuantity,
            ipDataEnabled: ipDataEnabled,
            billedYearly: billedYearly,
          });
        }}
      >
        Confirm
      </StyledBtn>
    </Col>
  );
}
