import React from 'react';

import {
  Headline,
  Activity,
  Row,
  Text,
  GridContainer,
  GridItem,
  Tooltip,
  darkGray,
  extraLightGray,
} from '@markettailor/common-markettailor';

import {formatPercent, getSignByValue} from '../../../functions/util';

function conversionLiftBounded(conversionLift: number, decimals: number) {
  return getSignByValue(conversionLift) + formatPercent(Math.min(conversionLift, 999), decimals);
}

interface IConversionProps {
  conversionCount: number;
  conversionCountControl: number;
  conversionRate: number;
  conversionRateControl: number;
  conversionRateLift: number;
}

interface ConversionRateElementProps {
  conversionsFormatted: IConversionProps;
  segmentId: string;
}

export const ConversionRateElement = ({conversionsFormatted, segmentId}: ConversionRateElementProps): JSX.Element => {
  const {conversionCount, conversionRate, conversionRateLift} = conversionsFormatted;

  const isNonPersonalizedSegment = segmentId === '0000-0000-0000-0000';
  const isGray = !conversionCount || !conversionRateLift;

  return (
    <Row alignItems="center" justifyContent="center">
      <Tooltip
        content={
          <TooltipContent
            isNonPersonalizedSegment={isNonPersonalizedSegment}
            conversionsFormatted={conversionsFormatted}
          />
        }
      >
        {isNonPersonalizedSegment ? (
          <Headline fontSize="15px" margin="0 5px 0 0" whiteSpace={'nowrap'}>
            {formatPercent(conversionRate)}
          </Headline>
        ) : (
          <Activity
            active={conversionRateLift > 0}
            color={isGray ? darkGray : ''}
            backgroundColor={isGray ? extraLightGray : ''}
          >
            {!conversionCount ? '0 %' : conversionLiftBounded(conversionRateLift, 0)}
          </Activity>
        )}
      </Tooltip>
    </Row>
  );
};

interface TooltipContentProps {
  conversionsFormatted: IConversionProps;
  isNonPersonalizedSegment: boolean;
}

const TooltipContent = ({isNonPersonalizedSegment, conversionsFormatted}: TooltipContentProps) => {
  const {conversionCount, conversionCountControl, conversionRate, conversionRateControl, conversionRateLift} =
    conversionsFormatted;
  const columns = isNonPersonalizedSegment ? 'auto auto' : 'auto auto auto';
  return (
    <GridContainer columns={columns} columnGap="15px">
      <GridItemText />
      {!isNonPersonalizedSegment && <GridItemText>Personalized</GridItemText>}
      <GridItemText>Control</GridItemText>
      <GridItemText textAlign="left">Conversions:</GridItemText>
      {!isNonPersonalizedSegment && <GridItemText>{conversionCount ?? 0}</GridItemText>}
      <GridItemText>{conversionCountControl ?? 0}</GridItemText>
      <GridItemText textAlign="left">Conversion rate:</GridItemText>
      {!isNonPersonalizedSegment && (
        <GridItemText>{!conversionCount ? '─' : conversionRate.toFixed(1) + ' %'}</GridItemText>
      )}
      <GridItemText>{!conversionCountControl ? '─' : conversionRateControl.toFixed(1) + ' %'}</GridItemText>
      {!isNonPersonalizedSegment && <GridItemText textAlign="left">Conversion lift:</GridItemText>}
      {!isNonPersonalizedSegment && (
        <GridItemText>{!conversionCount ? '─' : conversionLiftBounded(conversionRateLift, 1)}</GridItemText>
      )}
    </GridContainer>
  );
};

const GridItemText = ({children, textAlign}: {children?: string | number; textAlign?: string}) => {
  textAlign = textAlign || 'center';
  return (
    <GridItem>
      <Text color="#fff" width="100%" textAlign={textAlign}>
        {children}
      </Text>
    </GridItem>
  );
};
