import React, {ChangeEvent, useContext, useEffect} from 'react';

import {
  Col,
  Header,
  Headline,
  Row,
  StyledBox,
  StyledBtn,
  StyledDivider,
  Text,
  TextLink,
  smMin,
  CheckmarkIcon,
  lightWarn,
  AuthContext,
} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import MediaQuery from 'react-responsive';
import {useHistory} from 'react-router-dom';

import {useSetNewPage} from '../../../functions/util';
import '../../../styles/common/muiOverrides.css';
import GoogleSignInButton from './GoogleSignInButton';

export default function SignUp() {
  const history = useHistory();
  const authContext = useContext(AuthContext)!;
  const {clearMessageStates, submitSignUpPassword, setUsername, setPassword} = authContext;
  const {username, errorMessage} = authContext.state;
  const isAppsumo = new URLSearchParams(window.location.search).get('appsumo') === 'true';

  useSetNewPage('Sign up');

  useEffect(() => {
    const username = new URLSearchParams(window.location.search).get('username');
    if (!username) return;
    setUsername(username);
  }, []);

  useEffect(() => {
    localStorage.setItem('googleAutoSignUp', 'true');
  }, []);

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submitSignUpPassword();
    history.push('/welcome');
  };

  const handleUsernameInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    setUsername(e.target.value);
  };

  const handlePasswordInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  return (
    <Row alignItems={'center'}>
      <SideMarketingText />
      <div>
        <Col alignItems={'center'}>
          <Header color={'white'} margin={'0 0 20px 0'} fontSize={'20px'} fontWeight={500}>
            Create a new account
          </Header>
        </Col>
        <StyledBox padding={'20px'} minWidth={'260px'} maxWidth={'260px'} minHeight={'280px'}>
          <form onSubmit={handleSignUp}>
            <Col>
              <TextField
                required
                id="username"
                name="username"
                label="Email"
                type={'email'}
                value={username}
                onChange={handleUsernameInput}
                variant={'outlined'}
                autoComplete="username"
                disabled={isAppsumo}
              />
              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                onChange={handlePasswordInput}
                variant={'outlined'}
                autoComplete="new-password"
              />
              {errorMessage && (
                <Col>
                  <Text color={lightWarn} fontWeight={500}>
                    {errorMessage}
                  </Text>
                </Col>
              )}
              <Col alignItems={'center'} margin={'0 0 20px 0 '}>
                <StyledBtn margin={'0 0 0 0 '} type={'submit'} onClick={handleSignUp}>
                  Sign up
                </StyledBtn>
                <Text margin={'15px 0 15px 0 '}>or</Text>
                <GoogleSignInButton label="Sign up with Google" />
                <TermsLabel />
              </Col>
            </Col>
          </form>
          <Col alignItems={'center'}>
            <Text margin={'0 auto'}>
              Have an account?{' '}
              <TextLink
                onClick={() => {
                  clearMessageStates();
                  history.push('/sign-in');
                }}
              >
                Sign in
              </TextLink>
            </Text>
          </Col>
        </StyledBox>
      </div>
    </Row>
  );
}

const SideMarketingText = () => (
  <MediaQuery minWidth={smMin}>
    <Col minWidth={'380px'} maxWidth={'380px'} margin={'0 20px 0 0'}>
      <Row alignItems={'center'} margin={'0 0 10px 0'} minWidth={'380px'} maxWidth={'380px'}>
        <CheckmarkIcon margin={'0 10px 0 0'} color="white" width="25px" />
        <Headline color={'white'} fontWeight={600} fontSize={'16px'}>
          Boost conversions 1-3x by reaching segments better.
        </Headline>
      </Row>
      <StyledDivider />
      <Row alignItems={'center'} margin={'0 0 10px 0'} minWidth={'380px'} maxWidth={'380px'}>
        <CheckmarkIcon margin={'0 10px 0 0'} color="white" width="25px" />
        <Headline color={'white'} fontWeight={600} fontSize={'16px'}>
          Make underperforming customer segments profitable.
        </Headline>
      </Row>
      <StyledDivider />
      <Row alignItems={'center'} margin={'0 0 10px 0'} minWidth={'380px'} maxWidth={'380px'}>
        <CheckmarkIcon margin={'0 10px 0 0'} color="white" width="25px" />
        <Headline color={'white'} fontWeight={600} fontSize={'16px'}>
          No coding skills required. Save hundreds of developer hours.
        </Headline>
      </Row>
    </Col>
  </MediaQuery>
);

const TermsLabel = () => (
  <Text fontSize="13px" margin={'0 0 10px 0'} style={{fontStyle: 'italic'}}>
    *By signing up, I agree to Markettailor's{' '}
    <TextLink fontSize="13px" href={'https://www.markettailor.io/terms-of-service'} target={'_blank'}>
      Terms of Service
    </TextLink>
  </Text>
);
