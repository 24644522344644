import React from 'react';

interface Props {
  logoWidth: string;
  logoHeight: string;
  logoSrc: string;
  logoAlt: string;
}

export default function LogoIcon(props: Props) {
  return <img height={props.logoHeight} width={props.logoWidth} src={props.logoSrc} alt={props.logoAlt} />;
}
