import React, {useState} from 'react';

import {getSelectCustomStyles, IValueLabel} from '@markettailor/common-markettailor';
import {uniqBy, orderBy} from 'lodash';
import Select from 'react-select';

import {ISearchFilter} from './OutboundFilterDropdown';
import {DataRow} from './table/OutboundTable';

interface Props {
  search: string;
  setSearch: (value: React.SetStateAction<string>) => void;
  searchSelection: IValueLabel | null;
  setSearchSelection: React.Dispatch<React.SetStateAction<IValueLabel | null>>;
  optionSelection: IValueLabel;
  handleFilterChange: (filterData: ISearchFilter) => void;
  outboundAllData: DataRow[];
}

export default function OutboundSearchSelector({
  search,
  setSearch,
  searchSelection,
  setSearchSelection,
  optionSelection,
  handleFilterChange,
  outboundAllData,
}: Props) {
  function handleSearchSelection(selection: IValueLabel | null) {
    if (selection === searchSelection && search === '') return;
    setSearch('');
    setSearchSelection(selection);
    handleFilterChange({
      header: optionSelection.value,
      searchValue: '',
      searchSelection: selection?.value || '',
    });
  }

  function handleSearchInput(searchInput: string) {
    if (searchInput === search) return;
    setSearch(searchInput);
    if (searchInput) {
      handleFilterChange({header: optionSelection.value, searchValue: searchInput, searchSelection: ''});
    }
  }

  const isLargeList = outboundAllData.length > 1000;
  const searchOptions = (!isLargeList && getSearchOptions(outboundAllData, optionSelection, search)) || [];
  const [lastValue, setLastValue] = useState<string>('');

  return (
    <Select
      isSearchable={true}
      isClearable={true}
      isDisabled={optionSelection.value === '_all'}
      options={searchOptions.slice(0, 50)}
      inputValue={search}
      onBlur={() => {
        !search &&
          handleFilterChange({
            header: optionSelection.value,
            searchValue: search,
            searchSelection: searchSelection?.value || '',
          });
      }}
      value={searchSelection}
      onInputChange={handleSearchInput}
      onChange={handleSearchSelection}
      styles={getSelectCustomStyles({
        control: {width: 250},
      })}
      onMenuClose={() => setLastValue(search)}
      placeholder={lastValue || 'Search...'}
    />
  );
}

function getSearchOptions(outboundAllData: DataRow[], optionSelection: IValueLabel, search: string) {
  const getInitialSearchOptions = () => {
    return outboundAllData.map((dataRow: DataRow) => {
      const value = dataRow.changes[optionSelection.value] || dataRow.original[optionSelection.value];
      return {
        value: value !== null ? value : '_not_set',
        label: value !== null ? value : '(not set)',
      };
    });
  };

  const filterOptionsBySearch = (search: string) => {
    return searchOptions.filter((obj) => {
      try {
        const regex = new RegExp(search, 'i');
        return obj.value.match(regex);
      } catch {
        return obj.value.includes(search);
      }
    });
  };

  const orderOptions = (searchOptions: IValueLabel[]) => {
    return orderBy(searchOptions, (item) => {
      if (item.value !== '_not_set') {
        return !Number.isNaN(Number(item.value)) ? Number(item.value) : item.value?.toLowerCase();
      }
    });
  };

  const addNotSetToOptions = (searchOptions: IValueLabel[]) => {
    return [
      {
        value: '_not_set',
        label: '(not set)',
      },
      ...searchOptions,
    ];
  };

  if (optionSelection.value === '_all') return [];
  let searchOptions: IValueLabel[] = getInitialSearchOptions();
  if (search) searchOptions = filterOptionsBySearch(search);
  searchOptions = orderOptions(searchOptions);
  searchOptions = addNotSetToOptions(searchOptions);
  searchOptions = uniqBy(searchOptions, (item) => item.value);
  return searchOptions;
}
