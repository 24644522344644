import React, {useContext, useState, ChangeEvent} from 'react';

import {
  Row,
  Col,
  StyledDivider,
  Text,
  IDomElemFullState,
  IElemStyle,
  IconX,
  lightBlue,
} from '@markettailor/common-markettailor';
import {valuesIn, cloneDeep} from 'lodash';
import Select from 'react-select';

import {EditorContext} from '../../../contexts/EditorContext';
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ItalicIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '../../../styles/editor/customIcons';
import {IconBox, StyledInputEditor} from '../../../styles/editor/editorStyles';
import {customStyles} from '../util';
import ColorToolbar from './ColorToolbar';
import {tags, fontWeights, getTagNameValueLabel, getFontWeightValueLabel, getFontSize} from './util';

interface Props {
  selectedElem: IDomElemFullState;
}

export default function TypographyToolbar(props: Props) {
  const editorContext = useContext(EditorContext)!;
  const {changeElemContent} = editorContext;
  const [state, setState] = useState({});
  const {selectedElem} = cloneDeep(props);
  const isDisabled = !selectedElem.domElemId;
  const {fontStyle, textDecoration, textAlign} = selectedElem.style;

  function handleChange(value: string, styleAttr: keyof IElemStyle) {
    if (styleAttr === 'fontSize' && value) {
      value = String(value).concat('px');
    }
    selectedElem.style[styleAttr] = value;
    changeElemContent(selectedElem.domElemId, {
      style: selectedElem.style,
    });
  }

  function handleTagNameChange(selection: {value: string; label: string} | null) {
    if (!selection || !selectedElem.style) return;
    selectedElem.style.fontSize = null;
    selectedElem.style.fontWeight = null;
    changeElemContent(selectedElem.domElemId, {
      tagName: selection.value,
      style: selectedElem.style,
    });
  }

  function handleSelectChange(selection: {value: string; label: string} | null, styleAttr: keyof IElemStyle) {
    if (!selection) {
      return;
    }
    selectedElem.style[styleAttr] = selection.value;
    changeElemContent(selectedElem.domElemId, {
      style: selectedElem.style,
    });
    setState({...state, [styleAttr]: selection});
  }

  return (
    <>
      <Col margin="0 10px">
        <Row alignItems={'baseline'} justifyContent={'space-between'} margin={'0 0 5px 0'}>
          <Text margin={'0 10px 0 0'}>Heading</Text>
          <Select
            styles={customStyles}
            options={valuesIn(tags)}
            value={getTagNameValueLabel(selectedElem)}
            onChange={handleTagNameChange}
            placeholder={'Heading'}
            isDisabled={isDisabled}
            isSearchable={false}
          />
        </Row>
        <Row justifyContent={'space-between'} alignItems={'baseline'} margin={'0 0 5px 0'}>
          <Text margin={'0 10px 0 0'}>Size</Text>
          <Row alignItems={'baseline'}>
            <Text margin={'0 5px 0 0'}>px</Text>
            <StyledInputEditor
              placeholder={'Font-size'}
              value={getFontSize(selectedElem)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, 'fontSize')}
              disabled={isDisabled}
            />
          </Row>
        </Row>
        <Row alignItems={'baseline'} justifyContent={'space-between'} margin={'0 0 5px 0'}>
          <Text margin={'0 10px 0 0'}>Weight</Text>
          <Select
            styles={customStyles}
            options={valuesIn(fontWeights)}
            value={getFontWeightValueLabel(selectedElem)}
            onChange={(e: {value: string; label: string} | null) => handleSelectChange(e, 'fontWeight')}
            placeholder={'Font-weight'}
            isDisabled={isDisabled}
            isSearchable={false}
          />
        </Row>
        <ColorToolbar isDisabled={isDisabled} selectedElem={selectedElem} />
      </Col>
      <StyledDivider border />
      <Row alignItems={'center'}>
        <IconBox margin={'0 20px 0 10px'}>
          <IconX
            width="25px"
            cursor="pointer"
            $hoverColor={lightBlue}
            onClick={
              isDisabled
                ? undefined
                : () => {
                    handleChange('none', 'textDecoration');
                    handleChange('', 'fontStyle');
                  }
            }
          />
          <ItalicIcon
            selected={fontStyle === 'italic'}
            onClick={isDisabled ? undefined : () => handleChange('italic', 'fontStyle')}
          />
          <UnderlineIcon
            selected={textDecoration === 'underline'}
            onClick={isDisabled ? undefined : () => handleChange('underline', 'textDecoration')}
          />
          <StrikethroughIcon
            selected={textDecoration === 'line-through'}
            onClick={isDisabled ? undefined : () => handleChange('line-through', 'textDecoration')}
          />
        </IconBox>
        <IconBox>
          <AlignLeftIcon
            selected={textAlign === 'left'}
            onClick={isDisabled ? undefined : () => handleChange('', 'textAlign')}
          />
          <AlignCenterIcon
            selected={textAlign === 'center'}
            onClick={isDisabled ? undefined : () => handleChange('center', 'textAlign')}
          />
          <AlignRightIcon
            selected={textAlign === 'right'}
            onClick={isDisabled ? undefined : () => handleChange('right', 'textAlign')}
          />
          <AlignJustifyIcon
            selected={textAlign === 'justify'}
            onClick={isDisabled ? undefined : () => handleChange('justify', 'textAlign')}
          />
        </IconBox>
      </Row>
    </>
  );
}
