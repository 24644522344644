import React, {useContext} from 'react';

import {
  BackArrowIcon,
  Col,
  Row,
  StyledBtn,
  Headline,
  darkGray,
  NotificationContext,
  StyledDivider,
  ExclamationIcon,
} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {EditorContext} from '../../../contexts/EditorContext';
import {useHasAnyUnsavedChanges} from '../util';

export const NavigateBackButton = () => {
  const history = useHistory();
  const editorContext = useContext(EditorContext)!;
  const {editorMode, errorState, saveEditorChanges} = editorContext!;
  const {setWindowNotification, closeWindowNotification} = useContext(NotificationContext)!;

  const hasError = Boolean(errorState);
  const hasChanges = useHasAnyUnsavedChanges();

  const openUnsavedChangesNoti = () => {
    const id = 'Unsaved editor changes';
    setWindowNotification({
      id: id,
      message: (
        <UnsavedChangesNoti
          handleSaveChanges={() => {
            saveEditorChanges();
            closeWindowNotification(id);
            history.goBack();
          }}
          exitWithoutSaving={() => {
            closeWindowNotification(id);
            history.goBack();
          }}
        />
      ),
    });
  };

  return (
    <BackArrowIcon
      margin="10px 20px 10px 10px"
      width="25px"
      $hoverColor="white"
      cursor="pointer"
      color={hasError ? 'white' : darkGray}
      onClick={() => {
        if (editorMode !== 'edit') {
          history.goBack();
          return;
        }
        if (hasChanges) openUnsavedChangesNoti();
        else history.goBack();
      }}
    />
  );
};

const UnsavedChangesNoti = ({handleSaveChanges, exitWithoutSaving}) => {
  return (
    <Col>
      <Col alignItems="center" margin="0 0 10px 0">
        <ExclamationIcon width="25px" />
      </Col>
      <Headline textAlign="center">You have unsaved changes</Headline>
      <StyledDivider margin="10px 0" />
      <Headline textAlign="center">Do you want to save before exiting editor?</Headline>
      <StyledDivider border margin="10px 0 50px 0" />
      <Row justifyContent="space-between">
        <StyledBtn onClick={exitWithoutSaving} warn margin="0">
          Exit without saving
        </StyledBtn>
        <StyledBtn onClick={handleSaveChanges} margin="0">
          Save
        </StyledBtn>
      </Row>
    </Col>
  );
};
