import React, {useContext, useState} from 'react';

import {
  Activity,
  SettingsIcon,
  Headline,
  PopoverWindow,
  Row,
  StyledBtn,
  StyledBox,
  StyledDivider,
  Text,
  extraDarkGray,
  lightGray,
  Col,
  IIntegrations,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {IntegrationContext} from '../../contexts/IntegrationContext';

interface Props {
  title: string | JSX.Element;
  description: string;
  isActive?: boolean;
  logoWidth: string;
  integrationName: keyof IIntegrations;
  isDisabled?: boolean;
  customAuthButton?: JSX.Element;
  customDisableAuthButton?: JSX.Element;
  handleAuth?: (integrationName: string) => void;
  settingsRenderer?: JSX.Element | null;
}

export default function IntegrationBadge(props: Props): JSX.Element {
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);

  return (
    <StyledBox maxWidth={'250px'} height={'260px'} margin="0 40px 0 0">
      {props.settingsRenderer && (
        <PopoverWindow display={openSettingsMenu} handleClose={() => setOpenSettingsMenu(false)}>
          {props.settingsRenderer}
        </PopoverWindow>
      )}
      <IntegrationLogo {...props} />
      <Row justifyContent="space-between" alignItems="center" margin="0 0 10px 0">
        {typeof props.title === 'string' ? <Headline color={'black'}>{props.title}</Headline> : props.title}
      </Row>
      <Text margin={'0 0 15px 0'} fontSize={'12px'}>
        {props.description}
      </Text>

      <Col margin="auto 0 0 0">
        {props.settingsRenderer && <SettingsMenu {...props} setOpenSettingsMenu={setOpenSettingsMenu} />}
        <StyledDivider border margin="0 0 10px 0" />
        <Row alignItems={'baseline'} justifyContent={'space-between'}>
          <Activity active={Boolean(props.isActive)} />
          <AuthorizationButton {...props} />
        </Row>
      </Col>
    </StyledBox>
  );
}

const AuthorizationButton = (props) => {
  const {removeIntegration} = useContext(IntegrationContext)!;

  if (props.isActive) {
    return (
      props.customDisableAuthButton || (
        <StyledBtn warn margin="0" onClick={() => removeIntegration(props.integrationName)} disabled={props.isDisabled}>
          Disable
        </StyledBtn>
      )
    );
  }
  return (
    props.customAuthButton || (
      <StyledBtn margin="0" onClick={() => props.handleAuth!(props.integrationName)} disabled={props.isDisabled}>
        Authorize
      </StyledBtn>
    )
  );
};

const SettingsMenu = (props) => {
  return (
    <Row
      data-intercom-id={`integration-crm-${props.integrationName}-settings`}
      alignItems={'baseline'}
      margin="0 0 5px 0"
      onClick={() => {
        props.isActive && props.setOpenSettingsMenu(true);
      }}
    >
      <div>
        <SettingsIcon
          color={props.isActive ? extraDarkGray : lightGray}
          $hoverColor={props.isActive ? extraDarkGray : lightGray}
          width="25px"
          margin="0 5px 0 0"
          cursor={props.isActive ? 'pointer' : 'initial'}
        />
      </div>
      <Headline
        color={props.isActive ? extraDarkGray : lightGray}
        hoverTextDecoration={props.isActive ? 'underline' : 'initial'}
        fontSize="14px"
        cursor={props.isActive ? 'pointer' : 'initial'}
      >
        Settings
      </Headline>
    </Row>
  );
};

const IntegrationLogo = (props: Props) => {
  return (
    <Col height={'70px'} alignItems="center" justifyContent="center">
      <img
        width={props.logoWidth}
        src={`./${props.integrationName}-logo.svg`}
        alt={`${capitalize(props.integrationName)} logo`}
      />
    </Col>
  );
};
