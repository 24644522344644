import citiesJson from './cities.json';
import clearbitCompanyTagsJson from './clearbitCompanyTags.json';
import clearbitIndustryJson from './clearbitIndustry.json';
import clearbitIndustryGroupJson from './clearbitIndustryGroup.json';
import clearbitSubIndustryJson from './clearbitSubIndustry.json';
import clearbitTechJson from './clearbitTech.json';
import regionsJson from './regions.json';
import sicJson from './SIC.json';
import zoominfoIndustriesJson from './zoominfoIndustries.json';

interface Cities {
  [countryCode: string]: string[];
}

interface Regions {
  [countryCode: string]: string[];
}

interface SicCode {
  [sicCode: string]: string;
}

interface TechClearbit {
  [tech: string]: string;
}

export interface IClearbitTechCategory {
  [category: string]: TechClearbit;
}

interface TechClearbitLabels {
  [tech: string]: string;
}

export const cities: Cities = citiesJson;
export const regions: Regions = regionsJson;
export const sicCode: SicCode = sicJson;
export const clearbitTech: IClearbitTechCategory = clearbitTechJson;
export let clearbitTechLabels: TechClearbitLabels = {};
export const clearbitIndustryGroup: string[] = clearbitIndustryGroupJson;
export const clearbitIndustry: string[] = clearbitIndustryJson;
export const clearbitSubIndustry: string[] = clearbitSubIndustryJson;
export const clearbitCompanyTags: string[] = clearbitCompanyTagsJson;
export const zoominfoIndustries = Object.entries(zoominfoIndustriesJson).flatMap(([category, industries]) => [
  category,
  ...industries,
]);

Object.values(clearbitTechJson).forEach((val) => {
  clearbitTechLabels = {...val, ...clearbitTechLabels};
});
