import React, {ChangeEvent, useContext, useState} from 'react';

import {Col, Headline, PopoverWindow, Row, StyledBtn, Text} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

import {SegmentContext} from '../../contexts/SegmentContext';
import {getOutboundTableHeaders} from './table/OutboundTable';

export const AddRowBtn = () => {
  const segmentContext = useContext(SegmentContext)!;
  const {addOutboundCsvRows} = segmentContext;
  const {outboundAllData, outboundHeaderMap, segments, segmentId} = segmentContext.state;
  const headers = getOutboundTableHeaders(outboundAllData, outboundHeaderMap);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const closePopup = () => setPopupIsOpen(false);
  const openPopup = () => setPopupIsOpen(true);

  const [formContent, setFormContent] = useState({});
  const changeValue = (header: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFormContent((prev) => ({...prev, [header]: event.target.value}));

  const submit = () => {
    addOutboundCsvRows([formContent]);
    closePopup();
  };

  if (segments[segmentId].outbound?.crmDataSource !== 'csv') return null;

  return (
    <>
      <PopoverWindow display={popupIsOpen} handleClose={closePopup}>
        <Headline margin={'20px 0 30px 0'}>Add new contect to CSV</Headline>
        <Col alignItems={'stretch'}>
          {headers.map((header) => (
            <Row key={header.name} justifyContent="space-between">
              <Text margin={'18px 15px'}>{header.label}</Text>
              <TextField
                id={header.name}
                name={header.name}
                variant={'outlined'}
                type={'text'}
                onChange={changeValue(header.name)}
              />
            </Row>
          ))}
        </Col>
        <StyledBtn onClick={submit}>Add</StyledBtn>
      </PopoverWindow>
      <StyledBtn fontSize={'13px'} boxShadow={'none'} padding={'10px 15px'} onClick={openPopup}>
        Add new contact
      </StyledBtn>
    </>
  );
};
