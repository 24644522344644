import {mapping} from '../../../termMaps/technicalNamesToLabels';

interface ConditionInterface {
  value: string;
  label: string;
  isDisabled?: boolean;
}

export function getSelectorConditions(selector: string): ConditionInterface[] {
  const numericOperatorAllowed = [
    'estimatedAnnualRevenue',
    'employeesRange',
    'revenue',
    'employees',
    'totalSessions',
    'totalPageViews',
  ].includes(selector);
  const stringOperatorAllowed = [
    'referer',
    'visitedPages',
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_term',
    'utm_content',
    'query_string',
  ].includes(selector);
  return [
    {value: '__equals', label: mapping.condition.__equals},
    {value: '__not__equals', label: mapping.condition.__not__equals},
    {value: '__lte', label: mapping.condition.__lte, isDisabled: !numericOperatorAllowed},
    {value: '__gte', label: mapping.condition.__gte, isDisabled: !numericOperatorAllowed},
    {value: '__startswith', label: mapping.condition.__startswith, isDisabled: !stringOperatorAllowed},
    {value: '__contains', label: mapping.condition.__contains, isDisabled: !stringOperatorAllowed},
    {value: '__not__contains', label: mapping.condition.__not__contains, isDisabled: !stringOperatorAllowed},
  ];
}
