// @ts-nocheck
import {useState, useRef, useEffect, ReactNode} from 'react';

import {darkGray, StyledBox, Headline, Row, Col, ArrowUpIcon, ArrowDownIcon} from '@markettailor/common-markettailor';
import {orderBy, capitalize} from 'lodash';
import {
  Column,
  Table,
  CellMeasurer,
  CellMeasurerCache,
  SortDirectionType,
  TableHeaderRenderer,
} from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import {DataRow, TableRow} from '../outbound/table/OutboundTable';
import './styledTableVirtualized.css';
import {convertWindowSizeToPx} from './util';

interface TableStyle {
  width?: number | string;
  height?: string;
}
interface ICellStyle {
  height?: number;
}
interface Style {
  table?: TableStyle;
  header?: ICellStyle;
  row?: ICellStyle;
}

interface SortState {
  sortBy: string | undefined;
  sortDirection: SortDirectionType | undefined;
}
interface Props {
  headers: object[];
  items: DataRow[];
  rowGetter: (dataRow: DataRow) => TableRow;
  updateItems: (items: DataRow[]) => void;
  recalculateRowHeight?: boolean;
  style?: Style;
}

const getIdealTableWidth = (width: string | number): number => {
  const parsedWidth = convertWindowSizeToPx(width);
  return window.innerWidth < 769 ? parsedWidth - 120 : parsedWidth - 250;
};

export default function StyledTableVirtualized(props: Props) {
  const [tableWidth, setTableWidth] = useState(getIdealTableWidth(props.style?.table?.width || 500));
  const headersValues = props.headers.map((headerObj) => {
    return Object.keys(headerObj)[0];
  });
  const [sortState, setSortState] = useState<SortState>({sortBy: headersValues[1], sortDirection: undefined});

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 20,
    })
  );

  props.recalculateRowHeight && cache.current.clearAll();
  useEffect(() => {
    const listener = window.addEventListener('resize', () => {
      const idealTableWidth = getIdealTableWidth(props.style?.table?.width || 500);
      if (idealTableWidth < tableWidth - 60 || tableWidth + 60 < idealTableWidth) {
        setTableWidth(idealTableWidth);
      }
    });
    return () => window.removeEventListener('resize', () => listener);
    //eslint-disable-next-line
  }, []);

  function sortItems({sortBy, sortDirection}: ISortItemInput) {
    const getSortedItems = (items: DataRow[], sortDirectionLower) => {
      return orderBy(
        items,
        (item) => {
          const comparisonValue = sortBy in item.changes ? item.changes[sortBy] : item.original[sortBy];
          return !Number.isNaN(Number(comparisonValue)) ? Number(comparisonValue) : comparisonValue?.toLowerCase();
        },
        [sortDirectionLower]
      );
    };

    const convertTypes = (sortedItems: DataRow[]) => {
      const sortedItemsConverted = sortedItems.map((row) => {
        return {...row, sortBy: row[sortBy] !== null ? String(row[sortBy]) : null};
      });
      return sortedItemsConverted;
    };

    const sortDirectionLower: any = sortDirection.toLowerCase();
    let sortedItems = getSortedItems(props.items, sortDirectionLower);
    sortedItems = convertTypes(sortedItems);
    props.updateItems(sortedItems);
    setSortState({sortBy: sortBy, sortDirection: sortDirection});
    cache.current.clearAll();
  }

  let columnWidth = 215;
  let tableContentWidth = columnWidth * (props.headers.length - 1) - 5;
  if (tableContentWidth < tableWidth) {
    tableContentWidth = tableWidth;
    columnWidth = tableWidth / (props.headers.length - 1);
  }
  const maxRowHeight = props.style?.row?.height || 36;
  const tableHeight: number = convertWindowSizeToPx(props.style?.table?.height || '85vh', false);
  const tableMinHeight: number =
    props.items.length * maxRowHeight < tableHeight ? props.items.length * maxRowHeight + 20 : tableHeight;

  return (
    <StyledBox
      width={tableWidth + 'px'}
      padding="14px 0 40px 0"
      className="virtualized-table-styledBox"
      overflowX={tableContentWidth > tableWidth ? 'scroll' : 'hidden'}
    >
      <div style={{width: tableContentWidth + 'px', height: tableMinHeight + 10 + 'px'}}>
        <AutoSizer>
          {({height, width}) => {
            return (
              <Table
                width={width}
                height={height}
                headerHeight={props.style?.header?.height || 20}
                rowHeight={cache.current.rowHeight}
                rowCount={props.items.length}
                rowGetter={({index}) => props.items[index]}
                overscanRowCount={10}
                sortBy={sortState.sortBy}
                sortDirection={sortState.sortDirection}
              >
                {props.headers.map((headerObj, idx) => {
                  const headerValue = Object.keys(headerObj)[0];
                  return (
                    <Column
                      key={headerValue}
                      label={capitalize(headerObj[headerValue])}
                      dataKey={headerValue}
                      width={idx === 0 ? 30 : columnWidth}
                      headerRenderer={getHeaderRenderer(sortItems)}
                      cellRenderer={({columnIndex, rowIndex, parent}) => {
                        const row = props.rowGetter(props.items[rowIndex]);
                        const cellValue = row[headersValues[columnIndex]];
                        return (
                          <CellMeasurer
                            key={row.id}
                            cache={cache.current}
                            columnIndex={columnIndex}
                            parent={parent}
                            rowIndex={rowIndex}
                          >
                            {cellValue}
                          </CellMeasurer>
                        );
                      }}
                    />
                  );
                })}
              </Table>
            );
          }}
        </AutoSizer>
      </div>
    </StyledBox>
  );
}

type ISortItemInput = {sortBy: string; sortDirection: SortDirectionType};
type ISortItems = (inpObj: ISortItemInput) => void;

const getHeaderRenderer = (sortItems: ISortItems) => {
  const headerRenderer: TableHeaderRenderer = ({
    dataKey,
    label,
    sortBy,
    sortDirection,
  }: {
    dataKey: string;
    label?: ReactNode;
    sortBy?: string;
    sortDirection?: SortDirectionType | undefined;
  }) => {
    const renderSortIcons = !['icon', 'personalizedLink'].includes(dataKey);
    return (
      <Row alignItems="center">
        <Headline color="white" hoverColor="white" fontSize="14px" fontSizeLarge="14px" fontSizeExtraLarge="14px">
          {label}
        </Headline>
        {renderSortIcons && (
          <Col margin="0 0 0 10px">
            <SortIcons dataKey={dataKey} sortBy={sortBy} sortDirection={sortDirection} sortItems={sortItems} />
          </Col>
        )}
      </Row>
    );
  };
  return headerRenderer;
};

const SortIcons = ({dataKey, sortBy, sortDirection, sortItems}) => {
  return (
    <>
      <ArrowUpIcon
        width="20px"
        cursor="pointer"
        color={dataKey === sortBy && sortDirection === 'ASC' ? 'white' : darkGray}
        onClick={() => sortItems({sortBy: dataKey, sortDirection: 'ASC'})}
      />
      <ArrowDownIcon
        width="20px"
        cursor="pointer"
        color={dataKey === sortBy && sortDirection === 'DESC' ? 'white' : darkGray}
        onClick={() => sortItems({sortBy: dataKey, sortDirection: 'DESC'})}
      />
    </>
  );
};
