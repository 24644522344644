import React, {FC, useContext, useState} from 'react';

import {OpenInNewIcon, PopoverWindow, Row, StyledSwitch, TextLink} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {OutboundSegmentDetailsPopover} from './OutboundSegmentDetailsPopover';

export const OutboundSegmentDetailsContainer: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;
  const segment = segments[segmentId];
  if (!segment) return null;
  const crmDataSource = segment.outbound!.crmDataSource;
  if (crmDataSource !== 'activeCampaign') return null;

  return (
    <>
      <PopoverWindow display={isOpen} handleClose={() => setIsOpen(false)}>
        <OutboundSegmentDetailsPopover />
      </PopoverWindow>
      <TextLink
        margin="0 0 0 0"
        fontWeight="200"
        fontSize="13px"
        style={{fontStyle: 'italic'}}
        onClick={() => setIsOpen(true)}
      >
        Details <OpenInNewIcon margin="0 0 5px 2px" width="10px" />
      </TextLink>
    </>
  );
};
