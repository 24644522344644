import React, {useState} from 'react';

import {Col, MainContentView, StyledBtn, PopoverWindow, StyledNavTop} from '@markettailor/common-markettailor';

import {useSetNewPage} from '../../../functions/util';
import {usePermissionRole} from '../../util/Permission';
import AccountInlineNav from '../AccountInlineNav';
import UserInviteForm from './UserInviteForm';
import UserList from './UserList';

export default function Users() {
  const [openInviteUserForm, setOpenInviteUserForm] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAdmin = usePermissionRole('admin');

  useSetNewPage('Users');

  return (
    <>
      <StyledNavTop>Account</StyledNavTop>
      <MainContentView>
        <AccountInlineNav component={'users'}>
          <PopoverWindow display={openInviteUserForm} handleClose={() => !isMenuOpen && setOpenInviteUserForm(false)}>
            <UserInviteForm
              handleClose={() => setOpenInviteUserForm(false)}
              setIsMenuOpen={(state: boolean) => setIsMenuOpen(state)}
            />
          </PopoverWindow>
          <Col margin="20px 0 0 0">
            <UserList />
            <StyledBtn disabled={!isAdmin} margin="20px 0 0 0" onClick={() => setOpenInviteUserForm(true)}>
              Invite a user
            </StyledBtn>
          </Col>
        </AccountInlineNav>
      </MainContentView>
    </>
  );
}
