import {IDomElemFullState} from '@markettailor/common-markettailor';
import {rgbToHex} from '@material-ui/core';
import {cloneDeep} from 'lodash';
import {RGBColor} from 'react-color';

import {ITextareaCaret} from './TypographyEditor';

export const htmlToEditorFormat = (innerHTML: string): string => {
  const replaceNewlineChars = (innerHTML: string) => innerHTML.replaceAll('<br>', '\n');
  const replaceEncodedCharacters = (innerHTML: string) => innerHTML.replaceAll('&amp;', '&');

  let editorSafeInnerHTML = innerHTML;
  editorSafeInnerHTML = replaceNewlineChars(editorSafeInnerHTML);
  editorSafeInnerHTML = replaceEncodedCharacters(editorSafeInnerHTML);
  return editorSafeInnerHTML;
};

export const editorFormatToHtml = (innerHTML: string) => {
  const replaceOpenBracketsForTag = (innerHTML: string, tag: string) => {
    const regex = new RegExp(`&lt${tag}((?!&lt).)*?&gt.*?&lt/${tag}&gt`, 'gs');
    const matches = innerHTML.match(regex);
    matches &&
      matches.forEach((match) => {
        const replacement = match
          .replace(`&lt${tag}`, `<${tag}`)
          .replace(`&gt`, '>')
          .replace(`&lt/${tag}&gt`, `</${tag}>`);
        innerHTML = innerHTML.replaceAll(match, replacement);
      });
    return innerHTML;
  };

  const replaceOpenBrackets = (innerHTML: string): string => {
    const bracketReplacedStr = innerHTML.replaceAll('<', '&lt').replaceAll('>', '&gt');
    let cleanedInnerHTML = bracketReplacedStr;
    const tagList = [
      'br',
      'b',
      'strong',
      'i',
      'em',
      'mark',
      'small',
      'del',
      'ins',
      'sub',
      'sup',
      'strike',
      's',
      'span',
      'a',
      'svg',
      'path',
      'u',
      'font',
    ];
    tagList.forEach((tag) => {
      let oldHTML = cleanedInnerHTML;
      let newHTML = replaceOpenBracketsForTag(oldHTML, tag);
      while (oldHTML !== newHTML) {
        oldHTML = newHTML;
        newHTML = replaceOpenBracketsForTag(oldHTML, tag);
      }
      cleanedInnerHTML = newHTML;
    });

    return cleanedInnerHTML;
  };

  const replaceNewlineChars = (innerHTML: string): string => innerHTML.replaceAll('\n', '<br>');

  innerHTML = replaceOpenBrackets(innerHTML);
  innerHTML = replaceNewlineChars(innerHTML);
  return innerHTML;
};

export const addSelectorToInnerHTML = (innerHTML: string, selector: string, textareaCaret: ITextareaCaret) => {
  const newInnerHTML =
    innerHTML.substring(0, textareaCaret.start) + '{{' + selector + '}}' + innerHTML.substring(textareaCaret.end);
  return newInnerHTML;
};

export const tags = {
  H1: {value: 'H1', label: 'H1'},
  H2: {value: 'H2', label: 'H2'},
  H3: {value: 'H3', label: 'H3'},
  H4: {value: 'H4', label: 'H4'},
  H5: {value: 'H5', label: 'H5'},
  H6: {value: 'H6', label: 'H6'},
  P: {value: 'P', label: 'Paragraph'},
};

export const fontWeights = {
  '100': {value: '100', label: '100 ─ Thin'},
  '200': {value: '200', label: '200 ─ Extra light'},
  '300': {value: '300', label: '300 ─ Light'},
  '400': {value: '400', label: '400 ─ Normal'},
  '500': {value: '500', label: '500 ─ Medium'},
  '600': {value: '600', label: '600 ─ Semi bold'},
  '700': {value: '700', label: '700 ─ Bold'},
  '800': {value: '800', label: '800 ─ Extra bold'},
  '900': {value: '900', label: '900 ─ Heavy'},
};

export function getTagNameValueLabel(elem: IDomElemFullState) {
  if (elem.tagName in tags) {
    return tags[elem.tagName as keyof typeof tags];
  }
}

export function getFontWeightValueLabel(elem: IDomElemFullState) {
  if (elem.style.fontWeight && elem.style.fontWeight in fontWeights) {
    return fontWeights[elem.style.fontWeight as keyof typeof fontWeights];
  }
}

export function getFontSize(elem: IDomElemFullState) {
  const newEditorStyle = cloneDeep(elem.style);
  if (!newEditorStyle.fontSize) {
    return '';
  }
  if (newEditorStyle.fontSize.includes('px')) {
    newEditorStyle.fontSize = newEditorStyle.fontSize.slice(0, newEditorStyle.fontSize.length - 2);
  }
  return newEditorStyle.fontSize;
}

export function parsePickerToStringRGBA(rgba: RGBColor) {
  function stripEndFF(hex: string) {
    if (hex.slice(7) === 'ff') {
      return hex.slice(0, 7);
    }
    return hex;
  }
  const hex = rgbToHex('rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + Math.floor((rgba.a || 0) * 255) + ')');
  return stripEndFF(hex);
}

export function validateColorOutput(color: string) {
  if (color[0] !== '#') {
    console.error('Color validation failed. "#" missing from string');
    return false;
  }
  return true;
}
