import {useContext, useEffect} from 'react';

import {AuthContext, LoadingPage} from '@markettailor/common-markettailor';

import {isDev} from '../../functions/util';

export default function SignOut() {
  const signOut = useSignAccountOut();
  useEffect(() => {
    signOut();
  }, []);
  return <LoadingPage text={'Signing out'} />;
}

export function useSignAccountOut() {
  const authContext = useContext(AuthContext);

  const signOut = async () => {
    await authContext.signOut({});
    const destination = isDev() ? document.location.origin + '/sign-in' : 'https://www.markettailor.io';
    window.location.href = destination;
  };
  return signOut;
}
