import React, {useContext} from 'react';

import {
  Col,
  Header,
  StyledBox,
  StyledBtn,
  Text,
  TextLink,
  brandMain,
  AuthContext,
} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import {useSetNewPage} from '../../../functions/util';
import '../../../styles/common/muiOverrides.css';

export default function ForgotPassword() {
  const history = useHistory();
  const authContext = useContext(AuthContext)!;
  const {handleForgotPassword} = authContext;

  useSetNewPage('Forgot password');

  return (
    <>
      <Col alignItems={'center'}>
        <Header color={'white'} margin={'0 0 20px 0'} fontSize={'20px'} fontWeight={500}>
          Change your password
        </Header>
      </Col>
      <StyledBox padding={'40px 20px 20px 20px'} minWidth={'260px'} maxWidth={'260px'} minHeight={'240px'}>
        <form>
          <Col>
            <TextField
              required
              id="email"
              type="email"
              name={'username'}
              label="Email"
              value={authContext.state.username}
              onChange={authContext.handleUsernameInput}
              variant={'outlined'}
            />
            {authContext.state.successMessage && (
              <Col>
                <Text color={brandMain} fontWeight={500} margin={'0 0 10px 0 '}>
                  {authContext.state.successMessage}
                </Text>
              </Col>
            )}
            <Col alignItems={'center'}>
              <StyledBtn margin={'10px 0 30px 0 '} onClick={handleForgotPassword}>
                Change
              </StyledBtn>
            </Col>
            <Col alignItems={'center'}>
              <Text margin={'30px 0 10px 0 '}>Suddenly remembered it?</Text>
            </Col>
            <Col alignItems={'center'}>
              <TextLink
                onClick={() => {
                  authContext.clearMessageStates();
                  history.push('sign-in');
                }}
              >
                Back to sign in
              </TextLink>
            </Col>
          </Col>
        </form>
      </StyledBox>{' '}
    </>
  );
}
