import React, {useContext} from 'react';

import {StyledBtn} from '@markettailor/common-markettailor';
import axios from 'axios';

import config from '../../../config.json';
import {isDebug, isLocalhost} from '../../../functions/util';

export default function FlushCacheButton() {
  if (!isLocalhost() && !isDebug()) return null;
  return (
    <StyledBtn margin="0 10px 10px 10px" onClick={flushCache}>
      Flush cache
    </StyledBtn>
  );
}

async function flushCache() {
  await axios.post(config.api.baseURL + '/account/cache/flush', {});
  window.location.reload();
}
