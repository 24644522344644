import React, {useState} from 'react';

import {
  PopoverWindow,
  Headline,
  Row,
  EyeIcon,
  CursorClickIcon,
  Col,
  darkGray,
  StyledDivider,
} from '@markettailor/common-markettailor';

import {ConversionType} from '../../contexts/ConversionAnalyticsContext';
import {CreatePageEventForm, CreateTrackEventForm} from './NewConversionGoal/CreateEventForm';
import NewEventCard from './NewConversionGoal/NewEventCard';
import {SegmentEventText} from './util';

interface Props {
  eventCardsIsOpen: boolean;
  setEventCardsIsOpen: (isOpen: boolean) => void;
}

export default function NewConversionGoalPopover({eventCardsIsOpen, setEventCardsIsOpen}: Props) {
  const [eventTypeSelected, setEventTypeSelected] = useState<ConversionType | undefined>(undefined);

  const eventCreationForms = {
    track: <CreateTrackEventForm />,
    page: <CreatePageEventForm handleClose={() => setEventCardsIsOpen(false)} />,
  };

  const renderComponent = eventTypeSelected ? (
    eventCreationForms[eventTypeSelected]
  ) : (
    <NewEventCards setEventTypeSelected={setEventTypeSelected} />
  );

  return (
    <PopoverWindow
      display={eventCardsIsOpen || Boolean(eventTypeSelected)}
      handleClose={() => setEventCardsIsOpen(false)}
    >
      {renderComponent}
    </PopoverWindow>
  );
}

const NewEventCards = ({setEventTypeSelected}: {setEventTypeSelected: (eventType: ConversionType) => void}) => {
  return (
    <>
      <Headline margin={'0 0 20px 0'}>Add a new conversion event</Headline>
      <Col alignItems="center">
        <Row>
          <Row margin="0 30px 0 0">
            <TrackEventCard openEventCreation={() => setEventTypeSelected('track')} />
          </Row>
          <PageEventCard openEventCreation={() => setEventTypeSelected('page')} />
        </Row>
        <StyledDivider border margin="20px 0" />
        <SegmentEventText />
      </Col>
    </>
  );
};

function TrackEventCard({openEventCreation}: {openEventCreation: () => void}): JSX.Element {
  const title = 'Track click event in editor';
  const body =
    'Track click events in the visual editor. You can track button clicks, link clicks, and form submissions.';
  const icon = <CursorClickIcon color={darkGray} width="25px" />;
  const btnText = 'Add';
  return <NewEventCard {...{title, body, onClick: openEventCreation, icon, btnText}} />;
}

function PageEventCard({openEventCreation}: {openEventCreation: () => void}): JSX.Element {
  const title = 'Page view';
  const body =
    'Track a conversion when a user views a particular page. This works best with "Thank you" pages after a form submission.';
  const icon = <EyeIcon color={darkGray} />;
  const btnText = 'Add';
  return <NewEventCard {...{title, body, onClick: openEventCreation, icon, btnText}} />;
}
