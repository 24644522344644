import React, {useState, useContext} from 'react';

import {Col, Headline, StyledBtn} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';

export default function RenameForm({eventId, handleClose}: {eventId: string; handleClose: () => void}) {
  const conversionManagementContext = useContext(ConversionManagementContext)!;
  const {events, updateConversionEvent} = conversionManagementContext;
  const [newEventName, setNewEventName] = useState(events[eventId].eventName);

  const handleSubmit = () => {
    updateConversionEvent(eventId, {...events[eventId], eventName: newEventName}, 'rename');
    handleClose();
  };

  return (
    <>
      <Headline margin={'0 0 30px 0'}>Rename conversion event</Headline>
      <TextField
        required
        label="New event name"
        name={'url'}
        type={'text'}
        value={newEventName}
        onChange={(e) => setNewEventName(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
      />
      <Col alignItems={'center'}>
        <StyledBtn margin={'20px 0 0 0'} onClick={() => handleSubmit()}>
          Rename event
        </StyledBtn>
      </Col>
    </>
  );
}
