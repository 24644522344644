import React from 'react';

import {getSelectCustomStyles} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {mapping} from '../../../../termMaps/technicalNamesToLabels';
import {countryOptions} from '../paymentUtil';

export const CountrySelection = ({addressDetails, setAddressDetails}) => {
  return (
    <Select
      required
      styles={getSelectCustomStyles({
        fontSize: 16,
        fontSizeSmall: 16,
        control: {
          width: 400,
          margin: '0 0 20px 0',
          padding: '10px 0 10px 7px',
          mediaSmall: {width: 400},
        },
        dropdownIndicator: {padding: '8px'},
      })}
      options={countryOptions}
      value={
        addressDetails.country
          ? {value: addressDetails.country, label: mapping.country[addressDetails.country]}
          : undefined
      }
      isSearchable={true}
      onChange={(selection) => {
        setAddressDetails({
          ...addressDetails,
          country: selection ? selection.value : '',
        });
      }}
      placeholder={'Country *'}
    />
  );
};
