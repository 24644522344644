import React from 'react';

import {Col, Row} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

export const RenderSkeleton = () => {
  return (
    <Col margin={'50px 0 0 40px'}>
      <Row margin={'0 30px 30px 0'}>
        <Row margin={'0 30px 0 0'}>
          <Skeleton width={250} height={300} />
        </Row>
        <Skeleton width={250} height={300} />
      </Row>
      <Skeleton width={250} height={300} />
    </Col>
  );
};
