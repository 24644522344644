import React, {useState, useContext, FormEvent} from 'react';

import {AlignCenterScreen, Col, lightBlue, PageBg, StyledBox, StyledBtn, Text} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../contexts/AccountContext';
import {getEmailDomainFromCookie, useSetNewPage} from '../../functions/util';
import AccountLoadingWrapper from '../common/AccountLoadingWrapper';

export default function WelcomePageWrapper() {
  return (
    <AccountLoadingWrapper>
      <WelcomePage />
    </AccountLoadingWrapper>
  );
}

function WelcomePage() {
  const history = useHistory();
  const accountContext = useContext(AccountContext)!;
  const {handleSubmitAccountInfo} = accountContext;
  const {clientDomain, companyName, email} = accountContext?.account || {};
  const emailDomain = getEmailDomainFromCookie();
  const [newClientDomain, setNewClientDomain] = useState(clientDomain || emailDomain);
  const [newCompanyName, setNewCompanyName] = useState(companyName || '');

  useSetNewPage('Welcome');

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await handleSubmitAccountInfo({email: email || '', clientDomain: newClientDomain, companyName: newCompanyName});
    history.push('/installation');
  }

  const populateDefaults = () => setNewClientDomain('www.markettailor.io');

  return (
    <PageBg>
      <AlignCenterScreen>
        <Col alignItems={'center'}>
          <Text color={'white'} margin={'0 0 20px 0'} fontSize={'16px'} fontWeight={700}>
            Welcome to Markettailor!
          </Text>
        </Col>
        <Col alignItems={'center'}>
          <Text color={'white'} margin={'0 0 20px 0'} fontSize={'16px'} fontWeight={500} textAlign={'center'}>
            We need your website address and company name to get you started!
          </Text>
        </Col>
        <Col alignItems={'center'}>
          <StyledBox padding={'20px'} minWidth={'260px'} maxWidth={'260px'}>
            <form onSubmit={handleSubmit}>
              <Col>
                <TextField
                  required
                  id="website"
                  name={'clientDomain'}
                  type="text"
                  label="Website"
                  value={newClientDomain || ''}
                  onChange={(e) => setNewClientDomain(e.target.value)}
                  variant={'outlined'}
                />
                <Text
                  margin="-15px 0 30px 0"
                  padding="13px 0 13px 0"
                  lineHeight="0"
                  color={lightBlue}
                  hoverTextDecoration="underline"
                  onClick={populateDefaults}
                  fontStyle="italic"
                  style={{zIndex: 10}}
                >
                  Or use a test website
                </Text>
                <TextField
                  required
                  id="companyName"
                  type="text"
                  name={'companyName'}
                  label="Company name"
                  value={newCompanyName || ''}
                  onChange={(e) => setNewCompanyName(e.target.value)}
                  variant={'outlined'}
                />
                <Col alignItems={'center'}>
                  <StyledBtn
                    disabled={!newClientDomain || !newCompanyName}
                    margin={'10px 0 30px 0 '}
                    onClick={handleSubmit}
                  >
                    Submit
                  </StyledBtn>
                </Col>
              </Col>
            </form>
          </StyledBox>
        </Col>
      </AlignCenterScreen>
    </PageBg>
  );
}
