import React, {useContext} from 'react';

import {URLType} from '@markettailor/common-markettailor';
import {isEmpty, omit, cloneDeep} from 'lodash';

import {SegmentContext} from '../../contexts/SegmentContext';
import CSVExport from '../common/CSV/CsvExport';
import {DataRow} from './table/OutboundTable';
import {getPageUrls, useGetFilteredData} from './util';

interface ExportData {
  personalizedLinkPart: string;
  [key: string]: string | null | number | boolean;
}

export default function OutboundCSVExport({dataFilter}) {
  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;
  const segment = segments[segmentId];
  const {segmentFilteredData} = useGetFilteredData(dataFilter);

  const prepareDataForExport = (data: DataRow[]): ExportData[] => {
    const personalizedLinkPrefix = segment.outbound?.personalizedLinkPrefix;
    const addPersonalizationLinks = (data: DataRow[]) => {
      const addPageLinks = (item: DataRow) => {
        pageUrls.forEach((pageUrl, index) => {
          const fullPersonalizedPageLink = pageUrl + `?${personalizedLinkPrefix}=${item.original.personalizedLink}`;
          item[`personalizedPage${index + 1}`] = fullPersonalizedPageLink;
        });
        return item;
      };

      const pageUrls: URLType[] = getPageUrls(segment);
      data.forEach((item) => {
        const personalizedLinkPart = item.original.personalizedLink;
        item['personalizedLinkPart'] = personalizedLinkPrefix + '=' + personalizedLinkPart;
        item = addPageLinks(item);
      });
      return data;
    };

    const applyChanges = (data: DataRow[]) => {
      return data.map((item) => {
        const rootFields = omit(item, ['original', 'changes']);
        return {...item.original, ...item.changes, ...rootFields};
      });
    };

    const removeKeys = (data) => {
      return data.map((item) => {
        const exportItem = omit(item, ['id', 'changes', 'personalizedLink']);
        return exportItem;
      });
    };

    const newData = cloneDeep(data);
    const dataWithPages = addPersonalizationLinks(newData);
    const dataWithChanges = applyChanges(dataWithPages);
    const exportData = removeKeys(dataWithChanges);
    return exportData;
  };

  const exportData = prepareDataForExport(segmentFilteredData);
  const filename = segment.segmentName;

  return (
    <div data-intercom-id="outbound-export-button">
      <CSVExport
        data={exportData}
        disabled={isEmpty(segmentFilteredData)}
        filename={'Markettailor - ' + filename || 'Markettailor outbound campaign'}
      />
    </div>
  );
}
