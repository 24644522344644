import React, {useContext} from 'react';

import {Row, Text, Headline, brandMain, GridContainer, GridItem} from '@markettailor/common-markettailor';

import {AccountContext} from '../../../contexts/AccountContext';
import {formatNum} from '../../../functions/util';

export const CurrentPlanLimits = () => {
  const account = useContext(AccountContext)?.account;
  if (!account) return null;
  const {billing, permissions} = account;

  return (
    <>
      <Text margin={'0 0 10px 0'}>Current plan limits</Text>
      <GridContainer columns="1fr 1fr" rowGap="5px">
        {renderLimit('Inbound visitors', permissions.inboundVisitors, billing.inboundVisitorsUsed)}
        {renderLimit('Outbound visitors', permissions.outboundVisitors, billing.outboundVisitorsUsed)}
        {renderLimit('Company lookups', permissions.companyLookups, billing.companyLookupsUsed)}
      </GridContainer>
    </>
  );
};

const renderLimit = (name: string, limit: number, used: number) => {
  return (
    <>
      <GridItem>
        <Row>
          <Headline color={brandMain} fontSize={'18px'}>
            {formatNum(limit)}
          </Headline>
          <Headline margin={'0 0 0 5px'} fontSize="14px" fontSizeLarge="14px" fontSizeExtraLarge="14px">{`(${formatNum(
            limit - used
          )} left)`}</Headline>
        </Row>
      </GridItem>
      <GridItem>
        <Text margin={'0 0 0 10px'}>{name}</Text>
      </GridItem>
    </>
  );
};
