import React from 'react';

import {Row, Headline, Text, darkGray, lightBlue} from '@markettailor/common-markettailor';

import {DataRow} from './OutboundTable';
import './outboundTableStyle.css';

interface Props {
  dataRow: DataRow;
  col: string;
  columnIndex: number;
  setEditableCellSelection: Function;
}

export const NormalCell = ({dataRow, col, columnIndex, setEditableCellSelection}: Props) => {
  const hasChanges = col in dataRow.changes;
  const cellValue = hasChanges ? dataRow.changes[col] : dataRow.original[col];
  return (
    <Row
      key={dataRow.original.id}
      minWidth="100%"
      alignItems="center"
      justifyContent="space-between"
      className={`table-cell-${dataRow.original.id + columnIndex}`}
    >
      <Headline fontSize="14px" fontSizeLarge="14px" fontSizeExtraLarge="14px" color={hasChanges ? '#000' : darkGray}>
        {cellValue}
      </Headline>
      <Text
        margin="0px 0px 0px 8px"
        color={lightBlue}
        className={'edit-icon'}
        onClick={() => {
          const cellEditValue = cellValue !== null ? cellValue : '';
          setEditableCellSelection({
            rowId: dataRow.original.id,
            column: col,
            initialValue: cellEditValue,
          });
        }}
      >
        (edit)
      </Text>
    </Row>
  );
};
