import React, {useContext} from 'react';

import {AbsoluteDiv, StyledBtn} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';

import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';

export default function NavigateManageButton() {
  const history = useHistory();
  const {events} = useContext(ConversionManagementContext)!;

  return (
    <AbsoluteDiv data-intercom-id="nav-manageConversions" right={'0'}>
      <StyledBtn
        margin={'0 20px 0 10px'}
        disabled={isEmpty(events)}
        onClick={() => history.push('/conversions/manage')}
      >
        Manage conversions
      </StyledBtn>
    </AbsoluteDiv>
  );
}
