import React, {useState, useContext} from 'react';

import {Headline, Row, PopoverWindow, darkGray} from '@markettailor/common-markettailor';

import {ConversionAnalyticsContext} from '../../../contexts/ConversionAnalyticsContext';
import {IntegrationContext} from '../../../contexts/IntegrationContext';
import IntegrationBadge from '../IntegrationBadge';
import SegmentIntegrationSettings from './SegmentIntegrationSettings';

export default function SegmentCom() {
  const {integrations} = useContext(IntegrationContext)!;
  const {activeIntegration} = useContext(ConversionAnalyticsContext)!;
  const [openSettings, setOpenSettings] = useState(false);

  const isDisabled = !integrations.segment || (activeIntegration && !integrations.segment?.isActive);
  return (
    <>
      <PopoverWindow display={openSettings} handleClose={() => setOpenSettings(false)}>
        <SegmentIntegrationSettings />
      </PopoverWindow>
      <IntegrationBadge
        title={
          <Row alignItems="baseline">
            <Headline color="#000">Segment</Headline>
            <Headline
              color={darkGray}
              fontSize="12px"
              fontSizeLarge="12px"
              fontSizeExtraLarge="12px"
              margin="0 0 0 5px"
            >
              Recommended
            </Headline>
          </Row>
        }
        description={
          'Enable Segment conversion tracking to use your existing analytics for tracking personalization performance.'
        }
        isActive={integrations.segment?.isActive}
        integrationName={'segment'}
        logoWidth={'105px'}
        isDisabled={isDisabled}
        settingsRenderer={<SegmentIntegrationSettings />}
        handleAuth={() => setOpenSettings(true)}
      />
    </>
  );
}

export const SegmentLogoSmall = ({eventName}: {eventName: string}): JSX.Element => {
  return (
    <Row alignItems="center">
      <img height="15px" src="../segment-logo-small.svg" alt="Segment small logo" />
      <Headline margin="0 0 0 10px" color="#000">
        {eventName}
      </Headline>
    </Row>
  );
};
