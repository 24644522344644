import React, {useContext} from 'react';

import {StyledBtn, lightBlue, NotificationContext} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {EditorContext} from '../../../contexts/EditorContext';
import {removeEmptyDomElems} from '../../../contexts/editorContextUtil';
import {SegmentContext} from '../../../contexts/SegmentContext';
import {analytics} from '../../../functions/analytics';
import {useHasElements} from '../util';

export const PublishButton = () => {
  const history = useHistory();
  const editorContext = useContext(EditorContext)!;
  const {pageId, errorState, domElems, saveEditorChanges} = editorContext!;
  const segmentContext = useContext(SegmentContext)!;
  const {updatePage} = segmentContext;
  const {setInfoNotification} = useContext(NotificationContext)!;

  const hasNoChanges = !useHasElements();
  const hasError = Boolean(errorState);

  async function saveEditorAndPublish() {
    if (hasError) {
      setInfoNotification({message: 'Failed to publish. Something went terribly wrong!', level: 'error'});
      return;
    }
    await saveEditorChanges();
    await updatePage(pageId, {isActive: true});

    const newDomElems = removeEmptyDomElems(domElems);
    analytics.track('Published personalization', {
      'Changed element count': Object.keys(newDomElems).length,
    });

    setInfoNotification({message: '🎉 Personalization published!'});
    history.push('/pages');
  }

  return (
    <StyledBtn
      data-intercom-id="editor-publish-button"
      fontSize={'13px'}
      boxShadow="none"
      padding="10px 15px"
      background={lightBlue}
      disabled={hasNoChanges || hasError}
      onClick={saveEditorAndPublish}
    >
      Publish
      <span style={{margin: '0 0 0 5px'}} role={'img'} aria-label={''}>
        🚀
      </span>
    </StyledBtn>
  );
};
