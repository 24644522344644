import React, {useContext, ChangeEvent} from 'react';

import {IDomElemFullState} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {StyledTextBox} from '../../../styles/editor/editorStyles';

interface Props {
  id?: string;
  attr: keyof IDomElemFullState;
  isDisabled?: boolean;
  margin?: string;
  height?: string;
  customHandleChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  customValue?: string;
  refProp?: any;
}

export default function AttributeEditor(props: Props): JSX.Element {
  const editorContext = useContext(EditorContext);
  const {selectedElem, changeElemContent} = editorContext!;
  const {id, attr, isDisabled, margin, height, customHandleChange, customValue, refProp} = props;

  function defaultHandleChange(e: ChangeEvent<HTMLTextAreaElement>): void {
    if (!e.target) return;
    changeElemContent(selectedElem.domElemId, {...selectedElem, [attr]: e.target.value});
  }
  const handleChange = customHandleChange || defaultHandleChange;

  return (
    <StyledTextBox
      id={id}
      data-intercom-id={`editor-${attr}`}
      ref={refProp}
      disabled={isDisabled}
      margin={margin}
      height={height}
      value={customValue !== undefined ? customValue : selectedElem[attr] || ''}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e)}
      placeholder={'Click any text element...'}
    />
  );
}
