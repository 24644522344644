import React, {useContext} from 'react';

import {
  IValueLabel,
  getSelectCustomStyles,
  ICrmIntegrations,
  IIpDataIntegrations,
} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {AccountContext} from '../../../../contexts/AccountContext';
import {IntegrationContext, crmIntegrations} from '../../../../contexts/IntegrationContext';
import {sendSelectorAnalytics} from '../../../../functions/analytics';
import {useAllMapping} from '../../../../termMaps/technicalNamesToLabels';
import {getSelectorsDefault} from '../../segmentationOptions/groupedOptionsDefault';
import {ISelectOptionsWithHeadline} from '../../segmentInterfaces';
import {IconOption, useGetIntegrationSelectors} from '../../util';

interface Props {
  selector: string;
  handleSelectChange: Function;
  activeIntegrations: (keyof IIpDataIntegrations | keyof ICrmIntegrations)[];
}
export default function SelectorSelection(props: Props) {
  const {updatingCrmIntegrationOptions} = useContext(IntegrationContext)!;
  const {selector, handleSelectChange, activeIntegrations} = props;
  const mapping = useAllMapping();
  const {permissions} = useContext(AccountContext)!.account || {};
  const integrationSelectors = useGetIntegrationSelectors(activeIntegrations);
  const selectorOptions: Array<IValueLabel | ISelectOptionsWithHeadline> = integrationSelectors
    ? [...integrationSelectors, ...getSelectorsDefault()]
    : [];

  function getSelectorMapping(selector: string | undefined): IValueLabel | undefined {
    if (selector === undefined) return selector;
    else if (mapping.selector[selector]) return {value: selector, label: mapping.selector[selector]};
    else if (crmIntegrations.some((v) => selector!.includes(v))) {
      selector = selector.split('&|')[2];
      if (mapping.selector[selector]) return {value: selector, label: mapping.selector[selector]};
      return {value: selector, label: selector};
    }
  }

  return (
    <Select
      styles={getSelectCustomStyles()}
      isLoading={updatingCrmIntegrationOptions}
      options={selectorOptions}
      value={getSelectorMapping(selector)}
      components={{Option: IconOption}}
      isDisabled={!permissions}
      onChange={(selection: IValueLabel | undefined) => {
        sendSelectorAnalytics(selection?.value, selection?.label, permissions!);
        handleSelectChange({selector: selection?.value, condition: '', valueRange: []});
      }}
      placeholder={'Select an attribute'}
    />
  );
}
