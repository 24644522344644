import React from 'react';

import {StyledDivider, ToggleArrow, Row, OnHover} from '@markettailor/common-markettailor';

import {useToggle} from '../../functions/util';
import {StyledEditorText} from '../../styles/editor/editorStyles';

interface Props {
  header: string;
  menuOpenInitial: boolean;
  children: JSX.Element;
  toggleDataTestId?: string;
}

export default function ControlContainer(props: Props) {
  const {active, setToggle} = useToggle(props.menuOpenInitial);

  return (
    <>
      <OnHover cursor={'pointer'}>
        <Row onClick={() => setToggle()} data-testid={props.toggleDataTestId}>
          <StyledEditorText>{props.header}</StyledEditorText>
          <ToggleArrow active={active} margin="5px 0 0 0" />
        </Row>
      </OnHover>
      <StyledDivider border />
      {active && (
        <>
          {props.children}
          <StyledDivider />
        </>
      )}
    </>
  );
}
