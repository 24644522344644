import React, {useState} from 'react';

import {Headline, Col, StyledBtn, PopoverWindow} from '@markettailor/common-markettailor';

import AttributeEditor from '../sideControls/AttributeEditor';
import ImageGallery from './ImageGallery';

export default function ImageEditor({isImage}: {isImage: boolean}) {
  const [openImageContainer, setOpenImageContainer] = useState(false);

  return (
    <Col margin={'10px 0 0 0'}>
      <PopoverWindow display={openImageContainer} handleClose={() => setOpenImageContainer(false)} boxMinWidth={'70vw'}>
        <ImageGallery handleClose={() => setOpenImageContainer(false)} />
      </PopoverWindow>
      <Headline margin={'0 0 8px 5px'} fontSize={'13px'}>
        Image source (src)
      </Headline>
      <AttributeEditor attr={'src'} margin={'0 0 10px 0'} isDisabled={!isImage} />
      <Headline margin={'0 0 8px 5px'} fontSize={'13px'}>
        Alt text (alt)
      </Headline>
      <AttributeEditor attr={'alt'} height={'14px'} isDisabled={!isImage} />
      <Col margin={'10px 0 0 0'} alignSelf="center">
        <StyledBtn disabled={!isImage} onClick={() => setOpenImageContainer(true)}>
          Change image
        </StyledBtn>
      </Col>
    </Col>
  );
}
