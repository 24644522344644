import React, {useContext, useState, ChangeEvent} from 'react';

import {
  Col,
  Headline,
  StyledBtn,
  StyledInputText,
  Text,
  lightWarn,
  NotificationContext,
} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {AccountContext} from '../../contexts/AccountContext';
import {SegmentContext} from '../../contexts/SegmentContext';

interface Props {
  handleClose: () => void;
  onSubmitText: (text: string) => void;
  submitNoti?: string;
  type?: string;
  headline: string;
  defaultInputText: string;
  buttonText?: string;
  validClientDomain: boolean;
}
export default function PageUrlForm(props: Props): JSX.Element {
  const segmentContext = useContext(SegmentContext);
  const {clientDomain} = useContext(AccountContext)!.account || {};
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [isDisabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    inputText: props.defaultInputText || '',
    errorText: '',
  });
  const {segments, segmentId} = segmentContext!.state;

  function changeText(e: ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    setDisabled(false);
    if (e.target.value === '') {
      setDisabled(true);
    }
    setState({...state, inputText: e.target.value});
  }

  function handleSubmit(): void {
    if (validateSubmitText()) {
      props.onSubmitText(state.inputText);
      if (props.submitNoti) setInfoNotification({message: props.submitNoti});
      props.handleClose();
    }
  }

  function isValidUrl(): boolean {
    setState({...state, errorText: ''});
    const inputText = state.inputText.trim();
    if (!(inputText.startsWith('https://') || inputText.startsWith('http://'))) {
      setState({
        ...state,
        errorText: "*You didn't paste a valid URL. It's best to copy paste directly from your site.",
      });
      return false;
    }
    if (clientDomain && props.validClientDomain && !inputText.includes(clientDomain)) {
      setState({
        ...state,
        errorText:
          "*The URL doesn't belong to your domain. If this wasn't intended, change your domain in the account settings.",
      });
      return false;
    }
    if (!isPageUrlNew(inputText)) {
      setState({...state, errorText: '*This page already exists in your current segment.'});
      return false;
    }
    return true;
  }

  function isPageUrlNew(url: string): boolean {
    for (const page of Object.values(segments[segmentId].pageUrls)) {
      const {pageUrl} = page;
      if (url === pageUrl || url === pageUrl + '/' || url + '/' === pageUrl) {
        return false;
      }
    }
    return true;
  }

  function validateSubmitText(): boolean {
    let isValid: boolean;
    if (props.type === 'url') {
      isValid = isValidUrl();
    } else {
      isValid = true;
    }
    return isValid;
  }

  return (
    <Col alignItems={'center'}>
      <Headline margin={'20px 0 60px 0 '}>{props.headline}</Headline>
      <StyledInputText
        minWidth={'320px'}
        fontSize={'16px'}
        placeholder={capitalize(props.type)}
        name={'inputText'}
        type={props.type || 'text'}
        value={state.inputText}
        onChange={changeText}
        onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
        autoComplete={'off'}
        spellCheck={'off'}
      />
      {state.errorText && (
        <Text margin={'15px 0 0 0 '} color={lightWarn}>
          {state.errorText}
        </Text>
      )}
      <StyledBtn disabled={isDisabled} margin={'50px 0 0 0 '} onClick={handleSubmit}>
        {props.buttonText || 'Submit'}
      </StyledBtn>
    </Col>
  );
}
