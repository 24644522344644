import React from 'react';

import {
  CodeIcon,
  IntegrationIcon,
  PagesIcon,
  SegmentIcon,
  SettingsIcon,
  TargetIcon,
  smMax,
  BuildingIcon,
  lightGray,
  NavText,
  StyledNavBottom,
  StyledNavElem,
  StyledSideNav,
} from '@markettailor/common-markettailor';
import MediaQuery from 'react-responsive';
import {useHistory} from 'react-router-dom';

import {Logo, LogoSmall} from './Logo';

export default function SideNavContainer(props: {children: React.ReactElement}) {
  const history = useHistory();

  function handleSelect(selected: string) {
    const to = '/' + selected;
    history.push(to);
  }

  function isSelected(navElemName: string) {
    return navElemName === window.location.pathname.slice(1);
  }

  return (
    <>
      <StyledSideNav>
        <MediaQuery minWidth={'769px'}>
          <Logo />
        </MediaQuery>
        <MediaQuery maxWidth={smMax}>
          <LogoSmall />
        </MediaQuery>
        <StyledNavElem
          data-testid="nav-segments"
          data-intercom-id="nav-segments"
          selected={isSelected('segments')}
          onClick={() => handleSelect('segments')}
        >
          <SegmentIcon margin="0 10px 0 0" width="20px" color={lightGray} />
          <NavText>Segments</NavText>
        </StyledNavElem>
        <StyledNavElem selected={isSelected('pages')} onClick={() => handleSelect('pages')} data-testid="nav-pages">
          <PagesIcon margin="0 10px 0 0" width="18px" color={lightGray} />
          <NavText>Pages</NavText>
        </StyledNavElem>
        <StyledNavElem
          data-testid="nav-conversions"
          data-intercom-id="nav-conversions"
          selected={isSelected('conversions')}
          onClick={() => handleSelect('conversions')}
        >
          <TargetIcon margin="0 10px 0 0" width="18px" color={lightGray} />
          <NavText>Conversions</NavText>
        </StyledNavElem>
        <StyledNavElem
          data-testid="nav-integrations"
          data-intercom-id="nav-integrations"
          selected={isSelected('integrations')}
          onClick={() => handleSelect('integrations')}
        >
          <IntegrationIcon margin="0 10px 0 0" width="18px" color={lightGray} />
          <NavText>Integrations</NavText>
        </StyledNavElem>
        <StyledNavElem
          data-testid="nav-company-reveal"
          data-intercom-id="nav-company-reveal"
          selected={isSelected('company-reveal')}
          onClick={() => handleSelect('company-reveal')}
        >
          <BuildingIcon width="19px" margin="0 10px 0 0" color={lightGray} />
          <NavText>Company Reveal</NavText>
        </StyledNavElem>
        <StyledNavBottom>
          <StyledNavElem
            data-testid="nav-account"
            data-intercom-id="nav-account"
            selected={isSelected('account')}
            onClick={() => handleSelect('account')}
          >
            <SettingsIcon margin="0 10px 0 0" width="20px" color={lightGray} />
            <NavText>Account</NavText>
          </StyledNavElem>
          <StyledNavElem
            data-testid="nav-installation"
            data-intercom-id="nav-installation"
            selected={isSelected('installation')}
            onClick={() => handleSelect('installation')}
          >
            <CodeIcon margin="0 10px 0 0" width="20px" color={lightGray} />
            <NavText>Installation</NavText>
          </StyledNavElem>
        </StyledNavBottom>
      </StyledSideNav>
      {props.children}
    </>
  );
}
