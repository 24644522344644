import React from 'react';

import {MainContentView, StyledNavTop} from '@markettailor/common-markettailor';

import PageContainerContent from './PageContainerContent';

export default function PageContainer() {
  return (
    <>
      <StyledNavTop>Personalized pages</StyledNavTop>
      <MainContentView>
        <PageContainerContent />
      </MainContentView>
    </>
  );
}
