import React, {useState} from 'react';

import {PopoverWindow, Row} from '@markettailor/common-markettailor';

import InboundCreation from './InboundCreation';
import OutboundCreation from './OutboundCreation';

interface Props {
  openSegmentTypeSelection: boolean;
  setOpenSegmentTypeSelection: (segmentTypeSelectionState: boolean) => void;
}

export default function SegmentCreationWindow(props: Props) {
  const {openSegmentTypeSelection, setOpenSegmentTypeSelection} = props;
  const [outboundTypeSelection, setOutboundTypeSelection] = useState(false);

  return (
    <PopoverWindow
      display={openSegmentTypeSelection}
      handleClose={() => {
        setOpenSegmentTypeSelection(false);
        setOutboundTypeSelection(false);
      }}
    >
      <Row data-intercom-id="create-segment-selection">
        {!outboundTypeSelection && (
          <Row margin="0 20px 0 0">
            <InboundCreation />
          </Row>
        )}
        <OutboundCreation
          outboundTypeSelection={outboundTypeSelection}
          setOutboundTypeSelection={setOutboundTypeSelection}
        />
      </Row>
    </PopoverWindow>
  );
}
