import React, {useEffect, useState, useContext} from 'react';

import {
  Col,
  NotificationContext,
  useIsWidthSmaller,
  ICrmIntegrations,
  ICrmObjectType,
  Text,
} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {cloneDeep, findIndex} from 'lodash';

import config from '../../../config.json';
import {IntegrationContext, supportedCrmObjectTypes} from '../../../contexts/IntegrationContext';
import {RenderOptionsSkeleton} from '../util/emptyStates';
import CrmDataFields from './CrmDataFields';
import {CrmObjectTypeSwitcher} from './CrmObjectTypeSwitcher';

export interface IDataField {
  value: string;
  label: string;
  group: string;
  isActive: boolean;
  description?: string;
}

interface FieldOptions {
  company?: IDataField[];
  contact?: IDataField[];
  opportunity?: IDataField[];
  deal?: IDataField[];
}
interface Props {
  crmName: keyof ICrmIntegrations;
}

export default function CrmSettings({crmName}: Props) {
  const [fieldOptions, setFieldOptions] = useState<FieldOptions>({});
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {getIntegrationOptions} = useContext(IntegrationContext)!;
  const source: CancelTokenSource = axios.CancelToken.source();
  const [crmObjectType, setCrmObjectType] = useState<ICrmObjectType>('company');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    supportedCrmObjectTypes[crmName].forEach((field: ICrmObjectType) => {
      getInitialCrmFields(field);
    });
    return () => source.cancel('CrmSettings unmounted');
  }, []); //eslint-disable-line

  const getInitialCrmFields = async (crmObjectType: ICrmObjectType) => {
    try {
      const res = await axios.get<IDataField[]>(
        config.api.baseURL + `crm/fields?crmObjectType=${crmObjectType}&integration=${crmName}`,
        {
          cancelToken: source.token,
        }
      );
      setFieldOptions((prevState) => ({...prevState, [crmObjectType]: res.data}));
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Fetching CRM fields failed', level: 'error'});
      setError('Something went wrong when fetching options.');
      console.debug(e);
    }
  };

  const updateDataFields = async (dataField: IDataField) => {
    const newFieldOptions = cloneDeep(fieldOptions[crmObjectType])!;
    const dataFieldIndex = findIndex(newFieldOptions, {value: dataField.value});
    newFieldOptions[dataFieldIndex].isActive = dataField.isActive;
    setFieldOptions((prevState) => ({...prevState, [crmObjectType]: newFieldOptions}));
    const selectedFields =
      newFieldOptions && newFieldOptions.filter((field) => field.isActive).map((field) => field.value);
    await axios.post(
      config.api.baseURL + 'crm/fields',
      {customFields: selectedFields, crmObjectType, integration: crmName},
      {cancelToken: source.token}
    );
    getIntegrationOptions(crmName);
  };

  const isMedium = useIsWidthSmaller(1150);
  const currentDataFields = fieldOptions[crmObjectType];
  return (
    <Col maxWidth="1000px" minWidth={isMedium ? '85vw' : '1000px'}>
      <CrmObjectTypeSwitcher crmObjectType={crmObjectType} setCrmObjectType={setCrmObjectType} crmName={crmName} />
      {currentDataFields ? (
        <CrmDataFields updateDataFields={updateDataFields} currentDataFields={currentDataFields} />
      ) : error ? (
        <Text textAlign="center">{error}</Text>
      ) : (
        <RenderOptionsSkeleton />
      )}
    </Col>
  );
}
