import React, {ReactNode, useEffect, useContext, useState, createContext} from 'react';

import {NotificationContext} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';

import config from '../config.json';
import {deepFreeze} from '../functions/util';

//kickfire response
export interface IRevealCompany {
  website: string;
  tradeName?: string;
  companyName?: string;
  confidence?: number;
  city?: string;
  countryShort?: string;
  employees?: string;
  revenue?: string;
  naicsDesc?: string;
  lastSeen: Date;
}

export interface IRevealContext {
  companies: IRevealCompany[];
  isLoading: boolean;
}

const RevealContext = createContext<IRevealContext | undefined>(undefined);

function RevealProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [companies, setCompanies] = useState<IRevealCompany[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getCompanies();
    return () => source.cancel('RevealProvider unmounted');
    //eslint-disable-next-line
  }, []);

  const getCompanies = async () => {
    try {
      const res = await axios.get(config.api.baseURL + 'companyReveal', {
        cancelToken: source.token,
      });
      setCompanies(res.data);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Fetching companies for Company Reveal failed', level: 'error'});
      console.debug(e);
    }
  };

  return (
    <RevealContext.Provider
      value={deepFreeze<IRevealContext>({
        companies,
        isLoading,
      })}
    >
      {children}
    </RevealContext.Provider>
  );
}

export {RevealContext, RevealProvider};
