import React, {useContext} from 'react';

import {MainContentView, StyledNavTop} from '@markettailor/common-markettailor';
import {isEmpty} from 'lodash';

import {ConversionManagementContext} from '../../contexts/ConversionManagementContext';
import {useSetNewPage} from '../../functions/util';
import {RenderSkeleton, EmptyEvents} from './emptyStates';
import NavigateBackButton from './NavigateBackButton';
import ManageConversionsTable from './table/ManageConversionsTable';

export default function ManageConversionsContainer() {
  useSetNewPage('Manage conversions');

  return (
    <>
      <StyledNavTop>
        Manage conversions
        <NavigateBackButton />
      </StyledNavTop>
      <MainContentView>
        <ContainerContent />
      </MainContentView>
    </>
  );
}

const ContainerContent = () => {
  const conversionManagementContext = useContext(ConversionManagementContext)!;
  const {isLoading, events} = conversionManagementContext;

  if (isLoading) return <RenderSkeleton />;
  if (isEmpty(events)) return <EmptyEvents />;
  return <ManageConversionsTable />;
};
