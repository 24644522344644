import {AuthProvider, DeploymentScreen} from '@markettailor/common-markettailor';
import * as Sentry from '@sentry/react';
import {Amplify, Auth} from 'aws-amplify';
import axios from 'axios';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {createRoot} from 'react-dom/client';

import {AppWithAuth} from './AppWithAuth';
import {awsConfig} from './awsConfig';
import config from './config.json';
import {StripeKeyProvider} from './contexts/StripeKeyContext';
import {analytics} from './functions/analytics';
import {isDeploying, isDev} from './functions/util';
import history from './history';
import './index.css';
import {sentryConfig} from './sentryConfig';

if (!isDev()) {
  Sentry.init(sentryConfig);
  LogRocket.init(config.analytics.LOGROCKET_ID);
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

sessionStorage.setItem('mt_landingParams', window.location.search);
Amplify.configure(awsConfig);

const navigationHooks = {
  toSignIn: () => history.push('/sign-in'),
  toSignUpOauth: () => history.push('/sign-oauth'),
  toNewPassword: () => history.push('/new-password'),
  toSignOut: () => history.push('/sign-out'),
  toWelcome: () => history.push('/welcome'),
  toIndex: () => history.push('/'),
};

const container = document.getElementById('root');
const root = createRoot(container!);
if (isDeploying()) root.render(<DeploymentScreen />);
else {
  root.render(
    <StripeKeyProvider>
      <AuthProvider
        analytics={analytics}
        baseUrl={config.api.baseURL}
        navigationHooks={navigationHooks}
        axios={axios}
        Auth={Auth}
      >
        <AppWithAuth />
      </AuthProvider>
    </StripeKeyProvider>
  );
}
