import React, {useContext, useState, useEffect} from 'react';

import {ClickAwayDiv, Row, Text, AbsoluteDiv, StyledPopup, IDomElemFullState} from '@markettailor/common-markettailor';
import {rgbToHex} from '@material-ui/core';
import {SketchPicker, ColorResult} from 'react-color';

import {EditorContext} from '../../../contexts/EditorContext';
import {StyledColorBox, StyledInputEditor} from '../../../styles/editor/editorStyles';
import {parsePickerToStringRGBA, validateColorOutput} from './util';

interface Props {
  isDisabled: boolean;
  selectedElem: IDomElemFullState;
}

interface State {
  color: string;
  backgroundColor: string;
  colorPicker: string;
  backgroundColorPicker: string;
  colorDisplay: boolean;
  backgroundColorDisplay: boolean;
}

export default function ColorToolbar(props: Props) {
  const editorContext = useContext(EditorContext)!;
  const {selectedElem} = props;
  const [state, setState] = useState<State>({
    color: '000000',
    backgroundColor: '000000',
    colorPicker: 'rgb(0,0,0)',
    backgroundColorPicker: 'rgb(0,0,0)',
    colorDisplay: false,
    backgroundColorDisplay: false,
  });

  useEffect(() => {
    const updateObj: {[attribute: string]: string} = {color: state.color, backgroundColor: state.backgroundColor};
    for (const colorAttr of ['color', 'backgroundColor']) {
      if (selectedElem.style[colorAttr]) {
        updateObj[colorAttr] = rgbToHex(selectedElem.style[colorAttr]).slice(1).split('.')[0];
        updateObj[colorAttr + 'Picker'] = selectedElem.style[colorAttr];
      }
    }
    setState({...state, ...updateObj});
    //eslint-disable-next-line
  }, [selectedElem.domElemId]);

  function saveElemContent(colorValue: string, styleAttr: string) {
    if (validateColorOutput(colorValue)) {
      selectedElem.style[styleAttr] = colorValue;
      editorContext.changeElemContent(selectedElem.domElemId, {
        style: selectedElem.style,
      });
    }
  }

  function handleColorInputHexChange(colorValue: string, styleAttr: string) {
    setState({...state, [styleAttr]: colorValue, [styleAttr + 'Picker']: '#' + colorValue});
    saveElemContent('#' + colorValue, styleAttr);
  }

  function changeColorFromPicker(selection: ColorResult, styleAttr: string) {
    const hexStr = parsePickerToStringRGBA(selection.rgb);
    setState({...state, [styleAttr + 'Picker']: selection.rgb, [styleAttr]: hexStr.slice(1)});
    saveElemContent(hexStr, styleAttr);
  }

  function displayColorPopup(styleAttr: 'color' | 'backgroundColor') {
    setState({...state, [styleAttr]: !state[styleAttr]});
  }

  return (
    <>
      {['color', 'backgroundColor'].map((attr: any) => {
        const colorDisplay: any = attr + 'Display';
        return (
          <Row key={attr} justifyContent={'space-between'} alignItems={'center'} margin={'0 0 5px 0'}>
            <Row alignItems={'center'}>
              <Text margin={'0 10px 0 0'}>{attr === 'color' ? 'Color' : 'Background'}</Text>
              <AbsoluteDiv right={'50px'}>
                <StyledPopup popupDisplay={state[attr + 'Display']}>
                  <ClickAwayDiv
                    render={state[attr + 'Display']}
                    position="fixed"
                    onClick={() => setState({...state, [attr + 'Display']: false})}
                  />
                  <SketchPicker
                    color={state[attr + 'Picker']}
                    onChangeComplete={(selection) => changeColorFromPicker(selection, attr)}
                    presetColors={[]}
                  />
                </StyledPopup>
              </AbsoluteDiv>
            </Row>
            <Row alignItems={'center'}>
              <StyledColorBox
                color={state[attr]}
                onClick={props.isDisabled ? null : () => displayColorPopup(colorDisplay)}
                isDisabled={props.isDisabled}
              />
              <Text fontSize={'16px'}>#</Text>
              <StyledInputEditor
                value={state[attr]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleColorInputHexChange(e.target.value, attr)}
                disabled={props.isDisabled}
              />
            </Row>
          </Row>
        );
      })}
    </>
  );
}
