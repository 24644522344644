import React, {useContext} from 'react';

import {StyledBtn} from '@markettailor/common-markettailor';
import axios from 'axios';

import config from '../../../config.json';
import {AccountContext} from '../../../contexts/AccountContext';

function useIsDemoAccount() {
  const accountId = useContext(AccountContext)?.account?.accountId;
  return accountId === '7c74704f-5425-43bd-8cb3-1704c61d8ade';
}

async function resetDemoData() {
  await axios.post(config.api.baseURL + 'demo/reset', {});
  window.location.reload();
}

export default function DemoAccountResetButton() {
  const isDemoAccount = useIsDemoAccount();
  if (!isDemoAccount && window.location.hostname !== 'localhost') return null;
  return (
    <StyledBtn warn margin="0 10px 10px 10px" disabled={!isDemoAccount} onClick={resetDemoData}>
      Reset demo data
    </StyledBtn>
  );
}
