import React, {useContext, useState} from 'react';

import {
  Col,
  StyledBorder,
  StyledBtn,
  Text,
  StyledDivider,
  useCustomInputStyles,
  lightWarn,
  NotificationContext,
  Headline,
  brandMain,
} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {useElements, useStripe, CardElement} from '@stripe/react-stripe-js';
import {capitalize} from 'lodash';

import {AccountContext} from '../../../../contexts/AccountContext';
import {StripeContext} from '../../../../contexts/StripeContext';
import stripeCountriesJson from '../data/stripeCountries.json';
import {cardOptions} from '../paymentUtil';
import {CountrySelection} from './CountrySelection';

type StateDetailsType = 'addressDetails' | 'billingDetails';

export default function PaymentInformationForm(props: {handleClose: () => void}) {
  const {email} = useContext(AccountContext)!.account || {};
  const {setInfoNotification} = useContext(NotificationContext)!;
  const stripeContext = useContext(StripeContext)!;
  const {submitPaymentMethod, submitTaxId} = stripeContext;
  const {stripe} = stripeContext.state;
  const classes = useCustomInputStyles();
  const stripeClass = useStripe();
  const elements = useElements();
  const [cardInvalid, setCardInvalid] = useState(false);
  const [taxId, setTaxId] = useState(stripe?.taxId);
  const name = stripe?.paymentMethod?.billing_details?.name;
  const [billingDetails, setBillingDetails] = useState({
    name: name || '',
    email: email,
    address: {},
  });
  const oldAddress = stripe?.paymentMethod?.billing_details?.address;
  const [addressDetails, setAddressDetails] = useState({
    country: oldAddress?.country || '',
    line1: oldAddress?.line1 || '',
    line2: oldAddress?.line2 || '',
    postal_code: oldAddress?.postal_code || '',
    city: oldAddress?.city || '',
    state: oldAddress?.state || '',
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setCardInvalid(false);

    if (!stripeClass || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)!;

    billingDetails.address = addressDetails;
    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripeClass.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    });

    if (error) {
      setCardInvalid(true);
      setInfoNotification({message: 'Changing payment method failed', level: 'error'});
      return;
    }

    submitPaymentMethod(paymentMethod);
    if (taxId) {
      submitTaxId({taxId: taxId, country: addressDetails.country});
    }
    props.handleClose();
  }

  const getInput = (name: string, label = '', stateDetails: StateDetailsType = 'billingDetails') => {
    label = label || capitalize(name);
    const type = name === 'email' ? 'email' : 'text';
    return (
      <TextField
        required
        id={name}
        label={label}
        name={name}
        type={type}
        variant={'outlined'}
        value={stateDetails === 'billingDetails' ? billingDetails[name] : addressDetails[name]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          stateDetails === 'billingDetails'
            ? setBillingDetails({...billingDetails, [name]: e.target.value})
            : setAddressDetails({...addressDetails, [name]: e.target.value});
        }}
      />
    );
  };

  return (
    <Col alignItems={'center'} minWidth={'400px'}>
      <Headline color={brandMain}>Add your payment information</Headline>
      <StyledDivider border margin={'20px 0'} />

      <Col minWidth={'400px'}>
        <form onSubmit={handleSubmit} className={classes.root}>
          {cardInvalid && (
            <Text color={lightWarn} margin={'0 0 10px 0'}>
              Add your card details
            </Text>
          )}
          <StyledBorder>
            <CardElement options={cardOptions} />
          </StyledBorder>
          <Col alignItems={'center'} margin={'20px 0 0 0 '} minWidth={'400px'}>
            {getInput('email')}
            {getInput('name', 'Cardholder name')}
            <CountrySelection addressDetails={addressDetails} setAddressDetails={setAddressDetails} />
            {getInput('line1', 'Address line 1', 'addressDetails')}
            {getInput('line2', 'Address line 2', 'addressDetails')}
            {getInput('postal_code', 'Postal code', 'addressDetails')}
            {getInput('city', 'City', 'addressDetails')}
            {getInput('state', 'State', 'addressDetails')}
            <StyledDivider border margin={'0 0 20px 0'} />
            <TaxIdInput taxId={taxId} setTaxId={setTaxId} addressDetails={addressDetails} />

            <StyledBtn
              type="submit"
              disabled={
                !stripeClass ||
                !billingDetails.name ||
                !billingDetails.email ||
                !addressDetails.country ||
                !addressDetails.line1 ||
                !addressDetails.postal_code ||
                !addressDetails.city
              }
            >
              Update
            </StyledBtn>
          </Col>
        </form>
      </Col>
    </Col>
  );
}

const TaxIdInput = ({taxId, setTaxId, addressDetails}) => {
  return (
    <TextField
      id="taxId"
      label={
        stripeCountriesJson.includes(addressDetails.country)
          ? 'Tax ID'
          : 'Tax ID not available for the selected country'
      }
      name={'taxId'}
      type={'text'}
      variant={'outlined'}
      value={taxId}
      disabled={!stripeCountriesJson.includes(addressDetails.country)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setTaxId(e.target.value);
      }}
    />
  );
};
