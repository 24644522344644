import {useContext, useEffect} from 'react';

import {
  ISegments,
  ISegment,
  ISegmentFilter,
  IIntegrations,
  ISegmentType,
  IPermissions,
  IBilling,
} from '@markettailor/common-markettailor';
import {capitalize, isEmpty} from 'lodash';
import moment from 'moment';

import {IAccount} from '../contexts/AccountContext';
import {IEvents} from '../contexts/ConversionManagementContext';
import {
  IntegrationContext,
  ipDataIntegrations,
  conversionIntegrations,
  crmIntegrations,
} from '../contexts/IntegrationContext';
import {SegmentContext} from '../contexts/SegmentContext';
import {SegmentAnalytics} from './initAnalytics';

export const getAccountId = (): string => {
  return sessionStorage.getItem('accountId')!;
};

export const analytics = new SegmentAnalytics();

export const getIntegrationMapping = (
  integrationName
): {name: keyof IIntegrations; label: string; type: string | undefined} => {
  const label = getIntegrationLabel(integrationName);
  const type = getIntegrationType(integrationName);
  return {name: integrationName, label, type};
};

export const sendAccountAnalytics = (account: IAccount) => {
  const {
    companyName,
    clientDomain,
    clientScriptFileId,
    lastVisitorDate,
    permissions,
    billing,
    accountId,
    user,
    createdDate,
  } = account;
  sessionStorage.setItem('accountId', accountId);

  analytics.identify(user.userId, {
    email: user.email,
    'User role': capitalize(user.role),
    company: {
      id: accountId,
      name: companyName,
      website: clientDomain,
    },
  });
  analytics.group(accountId, {
    plan: capitalize(permissions.plan),
    name: companyName,
    company: companyName,
    website: clientDomain,
    createdAt: moment(createdDate).unix(),
    'Last visitor date': lastVisitorDate,
    'Client script link': `<script src="https://clients.markettailor.io/${clientScriptFileId}.js"></script>`,
  });
  sendPlanLimitAnalytics(user.userId, permissions, billing);
};

const sendPlanLimitAnalytics = (userId: string, permissions: IPermissions, billing: IBilling) => {
  const getQuotaPercentagesAndNumbers = (permissions: IPermissions, billing: IBilling) => {
    const getQuotaPercentage = (used: number, quota: number) => {
      return !used ? 0 : Math.round(quota / used) * 100;
    };

    const NUMBER_QUOTAS = ['outboundVisitors', 'inboundVisitors', 'companyLookups'];
    const labelMapping = {
      outboundVisitors: 'Outbound visitor',
      inboundVisitors: 'Inbound visitor',
      companyLookups: 'Company lookup',
    };
    return NUMBER_QUOTAS.reduce((acc, quotaType) => {
      const used = billing[quotaType + 'Used'];
      const quota = permissions[quotaType];
      return {
        ...acc,
        [`${labelMapping[quotaType]} limit`]: used || 0,
        [`${labelMapping[quotaType]}s used`]: quota || 0,
        [`${labelMapping[quotaType]} usage ratio`]: getQuotaPercentage(quota, used),
      };
    }, {});
  };

  analytics.identify(userId, getQuotaPercentagesAndNumbers(permissions, billing));
};

const getIntegrationLabel = (integrationName: keyof IIntegrations): string => {
  const integrationLabelMapping = {googleAnalytics: 'Google Analytics'};
  return integrationLabelMapping[integrationName] || capitalize(integrationName);
};

const getIntegrationType = (integrationName): string | undefined => {
  if (ipDataIntegrations.includes(integrationName)) return 'IP data';
  if (conversionIntegrations.includes(integrationName)) return 'Conversion tracking';
  if (crmIntegrations.includes(integrationName)) return 'CRM';
};

export function saveSegmentsTotalAnalytics(segments: ISegments) {
  function getTotalPageCountFromSegments(segments: ISegments) {
    return Object.keys(segments).reduce((acc, segmentId) => {
      return (acc += Object.keys(segments[segmentId].pageUrls).length);
    }, 0);
  }

  const getSegmentTypeCounts = (segments: ISegments, segmentType: ISegmentType) => {
    return Object.values(segments).reduce((acc, segment) => {
      return segment.segmentType === segmentType ? acc + 1 : acc;
    }, 0);
  };

  analytics.group(getAccountId(), {
    'Total segment count': Object.keys(segments).length,
    'Total page count': getTotalPageCountFromSegments(segments),
    'Inbound segment count': getSegmentTypeCounts(segments, 'inbound'),
    'Outbound segment count': getSegmentTypeCounts(segments, 'outbound'),
  });
}

interface ISummaryData {
  nonPersonalizedVisitors: number;
  personalizedVisitors: number;
  totalConversionRateLift: number;
}

export function saveSummaryAnalytics(summaryData: ISummaryData) {
  analytics.group(getAccountId(), {
    'Non-personalized visitor count': summaryData.nonPersonalizedVisitors,
    'Personalized visitor count': summaryData.personalizedVisitors,
    'Total conversion rate lift': summaryData.totalConversionRateLift,
  });
}

export const sendIntegrationAnalytics = (integrations: IIntegrations) => {
  const getActiveIntegrations = (integrations: IIntegrations) => {
    const allIntegrations = [...ipDataIntegrations, ...conversionIntegrations, ...crmIntegrations];
    return allIntegrations.reduce((acc, integrationName) => {
      const integrationNameLabel = getIntegrationMapping(integrationName).label;
      const isActive = Boolean(integrations[integrationName]?.isActive);
      return {...acc, [`${integrationNameLabel} integrated`]: isActive};
    }, {});
  };

  analytics.group(getAccountId(), {
    ...getActiveIntegrations(integrations),
  });
};

export const saveUpdatedSegmentAnalytics = (segment: ISegment, mapping) => {
  function getFilterCategories(segmentFilters: ISegmentFilter[]): string[] {
    return segmentFilters.map((segmentFilter) => {
      const mappedName = mapping.selector[segmentFilter.selector];
      return mappedName || segmentFilter.selector;
    });
  }

  const analyticsObj = {};
  if (segment.segmentQuery?.queryRequest)
    analyticsObj['Segment selectors'] = getFilterCategories(segment.segmentQuery.queryRequest);

  analytics.track('Updated segment', analyticsObj);
};

export const sendSelectorAnalytics = (
  selector: string | undefined,
  selectorLabel: string | undefined,
  permissions: IPermissions
) => {
  if (!selector || !selectorLabel) return;
  analytics.track('Selected inbound rule', {'Rule value': selector, 'Rule label': selectorLabel});

  const crmSelector = crmIntegrations.find((crmIntegration) => selector.includes(crmIntegration));
  crmSelector &&
    permissions.companyLookups === 0 &&
    analytics.track('Used CRM rules without company lookups', {
      integration: getIntegrationLabel(crmSelector),
    });
};

export const useSendConversionAnalytics = (
  mainConversionMetric: string | undefined,
  events: IEvents,
  eventsIsLoading: boolean
) => {
  const {integrations} = useContext(IntegrationContext)!;
  const {segments, isLoading: segmentsIsLoading} = useContext(SegmentContext)!.state;

  const sendMainMetricReminder = (mainConversionMetricExists: boolean) => {
    !isEmpty(segments) &&
      !isEmpty(events) &&
      !mainConversionMetricExists &&
      analytics.track('Triggered main conversion event reminder');
  };
  const updateConversionTrackingAnalytics = (mainConversionMetricExists: boolean) => {
    const hasSetupConversionTracking =
      (integrations.googleAnalytics?.isActive || integrations.segment?.isActive || !isEmpty(events)) &&
      mainConversionMetricExists;
    analytics.group(getAccountId(), {
      'Conversion tracking setup': hasSetupConversionTracking,
      'Conversion event count': Object.keys(events).length,
    });
  };

  useEffect(() => {
    if (segmentsIsLoading || eventsIsLoading) return;
    const mainConversionMetricExists = Boolean(mainConversionMetric && mainConversionMetric in events);
    sendMainMetricReminder(mainConversionMetricExists);
    updateConversionTrackingAnalytics(mainConversionMetricExists);
    //eslint-disable-next-line
  }, [mainConversionMetric, segments, events, segmentsIsLoading, eventsIsLoading]);
};
