import React, {useContext} from 'react';

import {
  Headline,
  Row,
  StyledDivider,
  Text,
  TextLink,
  Col,
  StyledSwitch,
  CopyableBox,
} from '@markettailor/common-markettailor';

import {IntegrationContext} from '../../../contexts/IntegrationContext';

export interface IAuthOptionsSegment {
  isActive: boolean;
}

export default function SegmentIntegrationSettings() {
  const {integrations} = useContext(IntegrationContext)!;
  if (!integrations.segment) return null;
  const {publicKey, isActive} = integrations.segment;

  return (
    <Col maxWidth="550px" minWidth="550px">
      <Headline textAlign="center" color="black">
        Segment integration
      </Headline>
      <StyledDivider border margin="10px 0 20px 0" />
      <AddApiKeySection publicKey={publicKey} />
      <StyledDivider border margin="25px 0" />
      <IntegrationActiveSection isActive={isActive} />
      <StyledDivider border margin="25px 0" />
      <GAIntegrationSection />
      <StyledDivider border margin="25px 0" />
      <Text>It might take a while for events appear in the Manage Conversions tab after integrating Segment.</Text>
    </Col>
  );
}

const GAIntegrationSection = () => {
  const gaSegmentLink = 'https://segment.com/docs/connections/destinations/catalog/google-analytics/#custom-dimensions';

  return (
    <>
      <Headline margin="0 0 5px 0" color="black">
        3. (optional) Using Segment with Google Analytics
      </Headline>
      <Text>
        If you want to use Google Analytics with Segment, follow{' '}
        <TextLink href={gaSegmentLink}>these instructions</TextLink> to set it up.
      </Text>
    </>
  );
};

const IntegrationActiveSection = ({isActive}) => {
  const {integrations, authIntegration} = useContext(IntegrationContext)!;
  if (!integrations.segment) return null;

  const handleIntegrationChange = (selection: boolean) => authIntegration('segment', {isActive: selection});

  return (
    <>
      <Headline margin="0 0 5px 0" color="black">
        2. Set the integration as Active
      </Headline>
      <Text margin="0 0 20px 0">
        Set the integration as Active to go live with Segment conversion tracking. You can only have one conversion
        tracking integration active at a time.
      </Text>
      <Row>
        <Text margin="0 10px 0 0">Inactive</Text>
        <StyledSwitch defaultState={isActive} onChange={handleIntegrationChange} />
        <Text margin="0 0 0 10px">Active</Text>
      </Row>
    </>
  );
};
const AddApiKeySection = ({publicKey}) => {
  const instructionsLink = 'https://segment.com/docs/connections/destinations/catalog/markettailor/';
  return (
    <>
      <Headline margin="0 0 5px 0" color="black">
        1. Add the API key to Segment
      </Headline>
      <Text margin="0 0 20px 0">
        Here's your API key that you need to add to Segment. Follow{' '}
        <TextLink href={instructionsLink}>these instructions</TextLink> to complete the integration.
      </Text>
      <CopyableBox>{publicKey}</CopyableBox>
    </>
  );
};
