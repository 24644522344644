import React from 'react';

import {Headline, StyledBox, StyledBtn} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {StyledEditor, StyledViewboxNoPage} from '../../styles/editor/editorStyles';
import SideControls from './sideControls/SideControls';
import EditorTopNav from './topNav/EditorTopNav';

interface IErrorObject {
  message: string;
  buttonText: string;
  buttonCallback: () => void;
}

export interface IErrorStateMapping {
  'no segmentId and url': IErrorObject;
  'no domain set': IErrorObject;
  'no website opened': IErrorObject;
}

export type ErrorStateType = keyof IErrorStateMapping;

export default function EditorError({errorState}: {errorState: ErrorStateType}) {
  const history = useHistory();

  const errorStateMapping: IErrorStateMapping = {
    'no segmentId and url': {
      message: 'Specify a segment and a page to open',
      buttonText: 'To segments',
      buttonCallback: () => history.push('/segments'),
    },
    'no domain set': {
      message: 'Make sure you have a website URL associated with your account',
      buttonText: 'To account',
      buttonCallback: () => history.push('/account'),
    },
    'no website opened': {
      message: "We couldn't open your website. Try refreshing the editor or edit the page URL from the pages tab.",
      buttonText: 'To pages',
      buttonCallback: () => history.push('/pages'),
    },
  };
  const error = errorStateMapping[errorState];
  const {message, buttonText, buttonCallback} = error;
  return (
    <>
      <EditorTopNav />
      <StyledEditor>
        <StyledViewboxNoPage>
          <StyledBox justifyContent={'center'} alignItems={'center'}>
            <Headline margin={'20px 0 20px 0'} textAlign={'center'}>
              {message}
            </Headline>
            <StyledBtn onClick={buttonCallback}>{buttonText}</StyledBtn>
          </StyledBox>
        </StyledViewboxNoPage>
        <SideControls />
      </StyledEditor>
    </>
  );
}
