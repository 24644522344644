import React, {useContext} from 'react';

import {Text, Col, Row, StyledLabel, IFilterCondition, ISegment} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../contexts/SegmentContext';
import {useGetSegmentLabels} from './util';

export default function SegmentLabels(): JSX.Element | null {
  const segmentContext = useContext(SegmentContext);
  const {segmentId, segments} = segmentContext!.state;

  const filterCondition: IFilterCondition = segments[segmentId]?.segmentQuery?.filterCondition || 'and';
  const segment: ISegment | undefined = segments[segmentId];

  const segmentLabels = useGetSegmentLabels(segment, segmentId);
  if (!segmentLabels) return null;
  return (
    <>
      {segmentLabels.map((segmentLabel, idx) => {
        return (
          <Col key={idx}>
            <Row>
              <StyledLabel>{segmentLabel}</StyledLabel>
            </Row>
            {segmentLabels.length > 1 && idx !== segmentLabels.length - 1 && (
              <Text fontSize="12px" margin="5px 0">
                {filterCondition}
              </Text>
            )}
          </Col>
        );
      })}
    </>
  );
}
