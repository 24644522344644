import {useContext} from 'react';

import {IRole} from '@markettailor/common-markettailor';

import {AccountContext} from '../../contexts/AccountContext';
import {isDev} from './../../functions/util';

export default function Permission({
  permission,
  children,
}: {
  permission?: IRole;
  children: JSX.Element;
}): JSX.Element | null {
  if (!permission && isDev()) return children;
  if (permission && usePermissionRole(permission)) return children;
  return null;
}

export function usePermissionRole(permission: IRole): boolean {
  const accountContext = useContext(AccountContext)!;
  const {user} = accountContext.account || {};

  return user?.role === permission;
}

export function usePermissionFlag(permissionFlag: string): boolean {
  const accountContext = useContext(AccountContext)!;
  const {permissions} = accountContext.account || {};

  return Boolean(permissions?.featureFlags && permissions.featureFlags.includes(permissionFlag));
}
