import React, {FC, useContext} from 'react';

import {
  GridContainer,
  GridItem,
  ICrmObjectType,
  InfoCircleIcon,
  Row,
  Text,
  Tooltip,
} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {ReactComponent as DynamicListIcon} from './dynamic-list-icon.svg';
import {ListModeSwitch} from './ListModeSwitch';
import {ReactComponent as StaticListIcon} from './static-list-icon.svg';

export const OutboundSegmentDetailsPopover: FC = () => {
  const segmentContext = useContext(SegmentContext)!;
  const {segmentId, segments} = segmentContext.state;
  const segment = segments[segmentId];
  if (!segment) return null;
  const crmDataSource = segment.outbound!.crmDataSource;
  const crmObjectType = segment.outbound!.crmObjectType;
  if (crmDataSource !== 'activeCampaign') return null;

  const link = getActiveCampaignLink(crmObjectType, segmentId);

  const InfoText = (
    <Text>
      You can use the personalization tag in ActiveCampaign templates. The value will be in query string format, e.g.{' '}
      <Text style={{fontStyle: 'italic'}}>abm-87d9d=Marke-f2wA-B9</Text>
    </Text>
  );

  return (
    <GridContainer columns="1fr 2fr" rowGap="30px" columnGap="50px">
      <GridItem>
        <Row>
          <Text>CRM personalization tag</Text>
          <Tooltip content={InfoText}>
            <InfoCircleIcon margin="0 0 0 10px" />
          </Tooltip>
        </Row>
      </GridItem>
      <GridItem>
        <Text>%{link}%</Text>
      </GridItem>
      {crmDataSource === 'activeCampaign' && crmObjectType === 'contact' ? <AutomaticSyncGridRow /> : null}
    </GridContainer>
  );
};

const AutomaticSyncGridRow = () => {
  return (
    <>
      <GridItem>
        <Text>Automatic sync</Text>
      </GridItem>
      <GridItem>
        <Row alignItems="center">
          <StaticListIcon fill="grey" />
          <Text margin="0 10px">Manual</Text>
          <ListModeSwitch />
          <Text margin="0 10px">Automatic</Text>
          <DynamicListIcon fill="grey" />
        </Row>
      </GridItem>
    </>
  );
};

const getActiveCampaignLink = (crmObjectType: ICrmObjectType, segmentId: string) => {
  const linkPrefixMap = {company: 'ACCT_', contact: '', deal: 'DEAL_'};
  if (!(crmObjectType in linkPrefixMap)) console.error('Link prefix not found for type', crmObjectType);

  const linkPrefix = linkPrefixMap[crmObjectType] || '';
  const link = (linkPrefix + 'MT link ' + segmentId.slice(0, 8)).replaceAll(' ', '_').toUpperCase();
  return link;
};
