import React, {useContext} from 'react';

import {Headline, copyToClipboard, ISegment, NotificationContext} from '@markettailor/common-markettailor';

import {DataRow} from './OutboundTable';

interface Props {
  dataRow: DataRow;
  segment: ISegment;
  pageId?: string;
}

export const PersonalizedLinkCell = ({dataRow, segment, pageId}: Props) => {
  const {setInfoNotification} = useContext(NotificationContext);

  const pageUrl = pageId ? segment.pageUrls[pageId]?.pageUrl : '';
  const {personalizedLinkPrefix} = segment.outbound!;
  const fullPersonalizedPageLink: string =
    pageUrl + '?' + personalizedLinkPrefix + '=' + dataRow.original.personalizedLink;

  return (
    <Headline
      fontSize="14px"
      style={{cursor: 'pointer'}}
      onClick={() => {
        copyToClipboard(
          pageId ? fullPersonalizedPageLink : personalizedLinkPrefix + '=' + dataRow.original.personalizedLink
        );
        setInfoNotification({message: 'Copied to clipboard!'});
      }}
    >
      {personalizedLinkPrefix + '=' + dataRow.original.personalizedLink}
    </Headline>
  );
};
