import React, {useContext} from 'react';

import {
  Col,
  Headline,
  StyledTable,
  ITableHeader,
  useIsWidthSmaller,
  ISegments,
} from '@markettailor/common-markettailor';
import {orderBy} from 'lodash';

import {ConversionAnalyticsContext, IConversions} from '../../../contexts/ConversionAnalyticsContext';
import {ConversionManagementContext, IConversionEvent} from '../../../contexts/ConversionManagementContext';
import {SegmentContext} from '../../../contexts/SegmentContext';
import {PersonalizedVisitorsCell} from '../../common/tables/PersonalizedVisitorsCell';
import {sortSegments} from '../../segmentation/util';
import {getConversionRate, getSegmentConversionRateLift} from '../../segmentsOverview/util';
import {ConversionRateElement} from './ConversionRateElement';

interface ConversionTableCellInterface {
  id: string;
  segmentName: string | JSX.Element;
  personalizedVisitors: number | JSX.Element;
  [field: string]: string | number | JSX.Element;
}

export default function ConversionTable() {
  const {segments} = useContext(SegmentContext)!.state;
  const {conversions} = useContext(ConversionAnalyticsContext)!;
  const {events} = useContext(ConversionManagementContext)!;
  const visibleEventIds = Object.values(events)
    .filter((event) => event.isActive)
    .map((event) => event.eventId);
  const segmentIds: string[] = ['0000-0000-0000-0000', ...sortSegments(segments).map((segment) => segment.segmentId)];
  const activeEvents = Object.values(events).filter((event) => event.isActive);

  const tableHeaders = createTableHeaders(activeEvents);
  const tableItems = createItemsView(segmentIds, conversions, segments, visibleEventIds);

  const tableWidth = tableHeaders.length * 170;
  const windowIsSmallerThanTable = useIsWidthSmaller(tableWidth);

  return (
    <Col data-intercom-id="conversions-table">
      <Headline margin={'0 0 10px 0'}>Conversions (last 30 days)</Headline>
      <StyledTable
        headers={tableHeaders}
        items={tableItems}
        highlightOptions={{rowId: '0000-0000-0000-0000'}}
        style={{
          headText: {fontSize: '14px', fontSizeExtraLarge: '14px', textAlign: 'center'},
          cellText: {fontSize: '14px', fontSizeExtraLarge: '14px'},
          table: {
            maxWidth: tableWidth + 'px',
            overflowX: windowIsSmallerThanTable ? 'scroll' : 'hidden',
          },
          tableGrid: {
            columns: `[first-start] minmax(200px, 1fr) [first-end second-start] minmax(110px, 1fr) [second-end col-start] repeat(${activeEvents.length}, 1fr) [col-end]`,
          },
        }}
      />
    </Col>
  );
}

const createItemsView = (
  segmentIds: string[],
  conversions: IConversions,
  segments: ISegments,
  visibleEventIds: string[]
): ConversionTableCellInterface[] => {
  const listItemsModel = createItemsModel(segmentIds, conversions, segments, visibleEventIds);
  return listItemsModel.map((item) => {
    const {segmentId, segmentName, personalizedVisitors, controlVisitors, conversionRates} = item;
    return {
      id: segmentId,
      segmentName: ['Non-personalized', 'Unnamed segment'].includes(segmentName) ? (
        <Headline fontSize="14px" fontSizeMedium="12px">
          {segmentName}
        </Headline>
      ) : (
        segmentName
      ),
      personalizedVisitors:
        segmentId === '0000-0000-0000-0000' ? (
          <Headline fontSize="14px">{controlVisitors}</Headline>
        ) : (
          <PersonalizedVisitorsCell personalizedVisitors={personalizedVisitors} controlVisitors={controlVisitors} />
        ),
      ...conversionRates
        .map((event) => ({
          [event.eventId]: <ConversionRateElement conversionsFormatted={event} segmentId={segmentId} />,
        }))
        .reduce((acc, eventData) => ({...acc, ...eventData}), {}),
    };
  });
};

export const createItemsModel = (
  segmentIds: string[],
  conversions: IConversions,
  segments: ISegments,
  visibleEventIds: string[]
) => {
  return segmentIds.map((segmentId) => {
    const personalizedVisitors = conversions[segmentId]?.personalized?.visitorCount || 0;
    const controlVisitors = conversions[segmentId]?.control?.visitorCount || 0;
    const segmentName =
      segmentId === '0000-0000-0000-0000' ? 'Non-personalized' : segments[segmentId]?.segmentName || 'Unnamed segment';
    const conversionRates = visibleEventIds.map((eventId) => ({
      eventId,
      conversionCount: conversions[segmentId]?.personalized?.events[eventId]?.count || 0,
      conversionCountControl: conversions[segmentId]?.control?.events[eventId]?.count || 0,
      conversionRate: getConversionRate(segmentId, eventId, conversions),
      conversionRateControl: getConversionRate(segmentId, eventId, conversions, 'control'),
      conversionRateLift: getSegmentConversionRateLift(segmentId, eventId, conversions),
    }));

    return {segmentId, segmentName, personalizedVisitors, controlVisitors, conversionRates};
  });
};

const createTableHeaders = (activeEvents: IConversionEvent[]): ITableHeader[] => {
  const sortedEvents = orderBy(activeEvents, ['source', 'type', 'eventName']);
  return [
    {segmentName: 'Segments'},
    {personalizedVisitors: 'Visitors'},
    ...sortedEvents.map((event) => {
      return {[event.eventId]: event.eventName};
    }),
  ];
};
