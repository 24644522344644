import React, {useContext} from 'react';

import {PopoverBox, AbsoluteDiv, ClickAwayDiv} from '@markettailor/common-markettailor';
import {capitalize} from 'lodash';

import {companyDynamicValueNames, defaultDynamicValueNames} from '../../../contexts/dynamicValueUtils';
import {EditorContext} from '../../../contexts/EditorContext';
import type {IDynamicValues} from '../../../contexts/editorContextTypes';
import {crmIntegrations, supportedCrmObjectTypes} from './../../../contexts/IntegrationContext';
import DynamicValueMenuBlock from './DynamicValueMenuBlock';
import {PersonalizationVariableBtnProps} from './PersonalizationVariableBtn';

interface DynamicValuesMenuProps extends PersonalizationVariableBtnProps {
  openVariableMenu: boolean;
  setOpenVariableMenu: (state: boolean) => void;
}

type CrmDynamicValuesType = {crmName: string; objectType: string; crmDynamicValues: IDynamicValues};

export function DynamicValuesMenu({handleSelection, openVariableMenu, setOpenVariableMenu}: DynamicValuesMenuProps) {
  const {dynamicValuesOrdered} = useContext(EditorContext)!;

  const defaultDynamicValues = dynamicValuesOrdered.filter((dynamicVal) =>
    defaultDynamicValueNames.includes(dynamicVal.key)
  );
  const companyDynamicValues = dynamicValuesOrdered.filter((dynamicVal) =>
    companyDynamicValueNames.includes(dynamicVal.key)
  );

  const crmDynamicValuesPerCategory = createEmptyCrmDynamicValuesPerCategory();
  addCrmDynamicValues(dynamicValuesOrdered, crmDynamicValuesPerCategory);
  addCsvDynamicValues(dynamicValuesOrdered, crmDynamicValuesPerCategory);

  return (
    <>
      <ClickAwayDiv render={openVariableMenu} onClick={() => setOpenVariableMenu(false)} />
      <AbsoluteDiv right={'80px'}>
        <PopoverBox
          display={openVariableMenu}
          handleClose={() => setOpenVariableMenu(false)}
          style={{minWidth: '150px', maxWidth: '400px', maxHeight: '400px', padding: '0'}}
        >
          {crmDynamicValuesPerCategory.map(({crmName, objectType, crmDynamicValues}) => (
            <DynamicValueMenuBlock
              key={capitalize(crmName) + ' - ' + objectType}
              headline={capitalize(crmName) + ' - ' + objectType}
              dynamicValues={crmDynamicValues}
              handleSelection={handleSelection}
              setOpenVariableMenu={setOpenVariableMenu}
            />
          ))}
          <DynamicValueMenuBlock
            headline="Company data"
            dynamicValues={companyDynamicValues}
            handleSelection={handleSelection}
            setOpenVariableMenu={setOpenVariableMenu}
          />
          <DynamicValueMenuBlock
            headline="Basic"
            dynamicValues={defaultDynamicValues}
            handleSelection={handleSelection}
            setOpenVariableMenu={setOpenVariableMenu}
          />
        </PopoverBox>
      </AbsoluteDiv>
    </>
  );
}

const createEmptyCrmDynamicValuesPerCategory = () => {
  // const objectTypes = ['company', 'contact', 'deal'];
  const crmDynamicValuesPerCategory = crmIntegrations.flatMap((crmName) =>
    supportedCrmObjectTypes[crmName].map(
      (objectType): CrmDynamicValuesType => ({crmName, objectType, crmDynamicValues: []})
    )
  );
  return crmDynamicValuesPerCategory;
};

const addCsvDynamicValues = (
  dynamicValuesOrdered: IDynamicValues,
  crmDynamicValuesPerCategory: CrmDynamicValuesType[]
) => {
  const csvDynamicValues = dynamicValuesOrdered.filter((dynamicVal) => dynamicVal.key.includes('mt_csv_'));
  crmDynamicValuesPerCategory.push({crmName: 'csv', objectType: 'all data', crmDynamicValues: csvDynamicValues});
};

const addCrmDynamicValues = (
  dynamicValuesOrdered: IDynamicValues,
  crmDynamicValuesPerCategory: CrmDynamicValuesType[]
) => {
  crmDynamicValuesPerCategory.forEach((crmDynamicValue) => {
    dynamicValuesOrdered
      .filter((dynamicVal) => dynamicVal.key.includes(`mt_${crmDynamicValue.crmName}_${crmDynamicValue.objectType}`))
      .forEach((dynamicVal) => {
        crmDynamicValue.crmDynamicValues.push(dynamicVal);
      });
  });
};
