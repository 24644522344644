import React, {useContext} from 'react';

import {Row, StyledBtn, Headline, LoadingSpinner} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../contexts/SegmentContext';
import {OutboundListType} from './OutboundContainerContent';
import OutboundFilterDropdown, {ISearchFilter} from './OutboundFilterDropdown';
import {DataRow} from './table/OutboundTable';
import {getFilteredData, useGetOutboundSegmentData} from './util';

interface Props {
  removeListRows: Function;
  addListRows: Function;
  dataFilter: ISearchFilter;
  setDataFilter: Function;
  listTypeSelected: OutboundListType;
}

export const FilterContainer = ({removeListRows, addListRows, dataFilter, setDataFilter, listTypeSelected}: Props) => {
  const segmentContext = useContext(SegmentContext)!;
  const {outboundAllData, outboundDataIsLoading} = segmentContext.state;
  const outboundSegmentData = useGetOutboundSegmentData();

  const outboundFilteredData: DataRow[] = getFilteredData(outboundAllData, dataFilter);
  const segmentFilteredData: DataRow[] = getFilteredData(outboundSegmentData, dataFilter);

  const isDataInSegment = outboundFilteredData.every((dataRow) =>
    outboundSegmentData.map((dataRow) => dataRow.original.id).includes(dataRow.original.id)
  );

  const buttonLabel = isDataInSegment
    ? 'Remove ' + outboundFilteredData.length
    : 'Add ' + (outboundFilteredData.length - segmentFilteredData.length);

  const onButtonClick = () => {
    isDataInSegment ? removeListRows(outboundFilteredData) : addListRows(outboundFilteredData);
  };

  return (
    <>
      <Headline margin="40px 0 5px 0">Filter</Headline>
      <OutboundFilterDropdown
        handleFilterChange={(dataFilter: ISearchFilter) => setDataFilter(dataFilter)}
        resetFilters={listTypeSelected === 'allList'}
      />
      <Row alignItems="center">
        <ApplyChangesButton label={buttonLabel} onClick={onButtonClick} />
        {outboundDataIsLoading && <LoadingSpinner margin="10px 0 0 0" />}
      </Row>
    </>
  );
};

const ApplyChangesButton = ({label, onClick}) => {
  return (
    <StyledBtn margin="10px 10px 0 0" onClick={onClick}>
      {label}
    </StyledBtn>
  );
};
