import React from 'react';

import {Col, Headline, StyledBtn, Text} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';

import EmptyScreen from '../common/EmptyScreen';

export function NoPermission() {
  return (
    <EmptyScreen>
      <>
        <Headline margin="0 0 20px 0">You need Company Lookups in your plan to access Company Reveal.</Headline>
        <a href="https://markettailor.io/pricing" target="_blank" rel="noreferrer">
          <StyledBtn>Visit pricing page</StyledBtn>
        </a>
        <Text margin="20px 0 0 0">You can also reach us through the chat.</Text>
      </>
    </EmptyScreen>
  );
}
export function RevealSkeleton() {
  return (
    <Col margin="30px 0 0 0">
      {[...Array(5)].map((_, idx) => {
        return (
          <Col key={idx} margin="10px 0 10px 0">
            <Skeleton width={'70vw'} height={'8vh'} />
          </Col>
        );
      })}
    </Col>
  );
}

export function EmptyRevealCompanies() {
  return (
    <EmptyScreen imageSrc="/emptyCompanyReveal.svg">
      <Headline>Patience, you don't have any visiting companies to show yet.</Headline>
    </EmptyScreen>
  );
}
