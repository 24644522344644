import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
    },
    '& .MuiFormHelperText-root': {
      fontFamily: 'Montserrat',
    },
  },
}));
