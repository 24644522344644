import React, {useContext} from 'react';

import {Text, OnHover, Headline, StyledDivider, Row, Col, highlightColor} from '@markettailor/common-markettailor';
import {capitalize, truncate} from 'lodash';

import {EditorContext} from '../../../contexts/EditorContext';
import {SegmentContext} from '../../../contexts/SegmentContext';
import {analytics} from '../../../functions/analytics';
import {useAllMapping} from '../../../termMaps/technicalNamesToLabels';

interface VariableMenuItemProps {
  handleSelection: (selector: string) => void;
  setOpenVariableMenu: (state: boolean) => void;
  selector: string;
  exampleValue: string;
  idx: number;
}

export function VariableMenuItem({
  handleSelection,
  setOpenVariableMenu,
  selector,
  exampleValue,
  idx,
}: VariableMenuItemProps) {
  const editorContext = useContext(EditorContext)!;
  const {outboundHeaderMap} = useContext(SegmentContext)!.state;
  const mapping = useAllMapping();
  const {dynamicValuesOrdered} = editorContext;

  const getMappedSelectorOrDefault = (selector: string) => {
    const crmField = deserializeField(selector, '_', 'mt_');
    const selectorFieldName = crmField?.fieldName || selector.replace('mt_', '');
    const selectorFieldPath = crmField ? serializeField(crmField) : '';
    const renderedSelector =
      mapping.selector[selectorFieldPath] || mapping.selector[selectorFieldName] || selectorFieldName;
    return outboundHeaderMap[renderedSelector] || renderedSelector;
  };

  const renderedSelector = getMappedSelectorOrDefault(selector);
  return (
    <Col key={selector}>
      <OnHover
        cursor="pointer"
        background={highlightColor}
        onClick={() => {
          analytics.track('Used personalization variable', {'Selection category': renderedSelector});
          handleSelection(selector);
          setOpenVariableMenu(false);
        }}
      >
        <ItemContent selector={renderedSelector} exampleValue={exampleValue} />
        {dynamicValuesOrdered.length - 1 !== idx && <StyledDivider border margin="0" />}
      </OnHover>
    </Col>
  );
}

const ItemContent = ({selector, exampleValue}) => {
  return (
    <Row alignItems="flex-end" style={{padding: '10px'}}>
      <Headline margin="0 10px 0 0" fontSize="14px">
        {capitalize(selector)}
      </Headline>
      <Text>{truncate(exampleValue, {length: 30})}</Text>
    </Row>
  );
};

interface IField {
  source: string;
  objectType: string;
  fieldName: string;
}

const deserializeField = (field: string, separator = '&|', prefix = '') => {
  const parts = field.replace(prefix, '').split(separator);
  if (parts.length < 3) return;
  return {source: parts[0], objectType: parts[1], fieldName: parts.slice(2).join('_')};
};

const serializeField = (field: IField, separator = '&|', prefix = '') =>
  prefix + [field.source, field.objectType, field.fieldName].join(separator);
