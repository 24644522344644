import React from 'react';

import {getSelectCustomStyles} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {useAllMapping} from '../../../../termMaps/technicalNamesToLabels';
import {getSelectorConditions} from '../../segmentationOptions/conditions';

interface Props {
  selector: string;
  condition: string;
  handleSelectChange: Function;
}
export default function ConditionSelection(props: Props) {
  const {selector, condition, handleSelectChange} = props;
  const mapping = useAllMapping();
  return (
    <Select
      styles={getSelectCustomStyles({
        control: {width: 110, margin: '0 10px', mediaSmall: {width: 90, margin: '0 5px'}},
      })}
      options={getSelectorConditions(selector)}
      isSearchable={false}
      value={condition ? {value: condition, label: mapping.condition[condition]} : []}
      onChange={(selection) => handleSelectChange({condition: selection?.value})}
      placeholder={'Condition'}
    />
  );
}
