import {useEffect, useState} from 'react';

import {IDomElemIdentity} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';

import config from '../../config.json';

export interface IConversionDomElem extends IDomElemIdentity {
  conversionEventId?: string;
}

export type ConversionDomElemsType = {[key: string]: IConversionDomElem};

export const useConversionDomElems = (source: CancelTokenSource) => {
  const [conversionDomElems, setConversionDomElems] = useState<ConversionDomElemsType>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        await getConversionDomElems();
        setIsLoading(false);
      } catch (e) {
        if (axios.isCancel(e)) return;
        throw e;
      }
    };
    initialLoad();
  }, []);

  const getConversionDomElems = async () => {
    const res = await axios.get(config.api.baseURL + 'conversionDomElems', {
      cancelToken: source.token,
    });
    setConversionDomElems(res.data);
  };

  const createConversionDomElem = async (elem: IConversionDomElem) => {
    const elemId = elem.domElemId;
    setConversionDomElems((prev) => ({...prev, [elemId]: elem}));
    try {
      const res = await axios.post(config.api.baseURL + 'conversionDomElems', elem, {
        cancelToken: source.token,
      });
      setConversionDomElems(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      throw e;
    }
  };

  const deleteConversionDomElem = async (elemId: string) => {
    const conversionDomElemsNew = {...conversionDomElems};
    delete conversionDomElemsNew[elemId];
    setConversionDomElems(conversionDomElemsNew);
    try {
      const res = await axios.delete(config.api.baseURL + 'conversionDomElems', {
        params: {domElemId: elemId},
        cancelToken: source.token,
      });
      setConversionDomElems(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      throw e;
    }
  };

  return {isLoading, conversionDomElems, getConversionDomElems, createConversionDomElem, deleteConversionDomElem};
};
