import React from 'react';

import {lightGray, lightBlue} from '@markettailor/common-markettailor';
import {FormatItalic} from '@styled-icons/material-rounded/FormatItalic';
import {FormatUnderlined} from '@styled-icons/material-rounded/FormatUnderlined';
import {StrikethroughS} from '@styled-icons/material-rounded/StrikethroughS';
import styled from 'styled-components';

interface IconInterface {
  width?: string;
  margin?: string;
  selected?: boolean;
}

export const StrikethroughIcon = styled(StrikethroughS)<IconInterface>`
  width: ${(props) => props.width || '20px'};
  margin: ${(props) => props.margin || '0'};
  color: ${(props) => (props.selected ? lightBlue : lightGray)};
  &:hover {
    color: ${lightBlue};
    cursor: pointer;
  }
`;

export const UnderlineIcon = styled(FormatUnderlined)<IconInterface>`
  width: ${(props) => props.width || '20px'};
  margin: ${(props) => props.margin || '0'};
  color: ${(props) => (props.selected ? lightBlue : lightGray)};
  &:hover {
    color: ${lightBlue};
    cursor: pointer;
  }
`;

export const ItalicIcon = styled(FormatItalic)<IconInterface>`
  width: ${(props) => props.width || '20px'};
  margin: ${(props) => props.margin || '0'};
  color: ${(props) => (props.selected ? lightBlue : lightGray)};
  &:hover {
    color: ${lightBlue};
    cursor: pointer;
  }
`;

interface IconProps {
  selected: boolean;
  onClick: any;
}

export function AlignJustifyIcon(props: IconProps) {
  return (
    <StyledSVG selected={props.selected} onClick={props.onClick} viewBox="0 0 18 18">
      <StyledLine x1="15" x2="3" y1="9" y2="9" />
      <StyledLine x1="15" x2="3" y1="14" y2="14" />
      <StyledLine x1="15" x2="3" y1="4" y2="4" />
    </StyledSVG>
  );
}

export function AlignCenterIcon(props: IconProps) {
  return (
    <StyledSVG selected={props.selected} onClick={props.onClick} viewBox="0 0 18 18">
      <StyledLine x1="15" x2="3" y1="9" y2="9" />
      <StyledLine x1="14" x2="4" y1="14" y2="14" />
      <StyledLine x1="12" x2="6" y1="4" y2="4" />
    </StyledSVG>
  );
}

export function AlignRightIcon(props: IconProps) {
  return (
    <StyledSVG selected={props.selected} onClick={props.onClick} viewBox="0 0 18 18">
      <StyledLine x1="15" x2="3" y1="9" y2="9" />
      <StyledLine x1="15" x2="5" y1="14" y2="14" />
      <StyledLine x1="15" x2="9" y1="4" y2="4" />
    </StyledSVG>
  );
}

export function AlignLeftIcon(props: IconProps) {
  return (
    <StyledSVG selected={props.selected} onClick={props.onClick} viewBox="0 0 18 18">
      <StyledLine x1="3" x2="15" y1="9" y2="9" />
      <StyledLine x1="3" x2="13" y1="14" y2="14" />
      <StyledLine x1="3" x2="9" y1="4" y2="4" />
    </StyledSVG>
  );
}

const StyledLine = styled.line`
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

interface StyledSVGProps {
  selected?: boolean;
}

const StyledSVG = styled.svg<StyledSVGProps>`
  margin: 4px;
  width: 20px;
  height: 20px;
  stroke: ${(props) => (props.selected ? lightBlue : lightGray)};
  &:hover {
    stroke: ${lightBlue};
    cursor: pointer;
  }
`;
