import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';

import {NotificationContext, ICrmObjects} from '@markettailor/common-markettailor';
import axios from 'axios';

import config from '../config.json';
import {deepFreeze} from '../functions/util';
import {SegmentContext} from './SegmentContext';

interface IOutboundSegmentItemsContext {
  crmObjects?: ICrmObjects;
  updateOutboundSegmentItem: (newCrmObjects: ICrmObjects) => Promise<void>;
}

interface IOutboundSegmentItemGet {
  items: ICrmObjects | undefined;
}

export const OutboundSegmentItemsContext = createContext<IOutboundSegmentItemsContext | undefined>(undefined);

export const OutboundSegmentItemsProvider = ({children}: {children: ReactNode}) => {
  const source = axios.CancelToken.source();

  const {setInfoNotification} = useContext(NotificationContext)!;
  const {segmentId} = useContext(SegmentContext)!.state;

  const [crmObjects, setCrmObjects] = useState<ICrmObjects>();

  useEffect(() => {
    if (!segmentId) return;
    const getCrmObjects = async () => {
      try {
        const response = await axios.get<IOutboundSegmentItemGet>(
          config.api.baseURL + `outbound/segmentItem/${segmentId}`,
          {cancelToken: source.token}
        );
        setCrmObjects(response.data.items);
      } catch (e) {
        if (axios.isCancel(e)) return;
        setInfoNotification({message: 'Fetching outbound segment items failed', level: 'error'});
        console.debug(e);
      }
    };
    getCrmObjects();
    return () => source.cancel('OutboundSegmentItemsProvider unmounted');
  }, [segmentId]);

  const updateOutboundSegmentItem = async (newCrmObjects: ICrmObjects) => {
    if (!crmObjects) return;
    try {
      setCrmObjects(newCrmObjects);
      await axios.put(
        config.api.baseURL + `outbound/segmentItem/${segmentId}`,
        {crmObjects: newCrmObjects},
        {cancelToken: source.token}
      );
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Updating outbound segment items failed', level: 'error'});
      console.debug(e);
    }
  };

  return (
    <OutboundSegmentItemsContext.Provider
      value={deepFreeze<IOutboundSegmentItemsContext>({
        updateOutboundSegmentItem,
        crmObjects,
      })}
    >
      {children}
    </OutboundSegmentItemsContext.Provider>
  );
};
