import React, {useContext, useState, useEffect} from 'react';

import {Col} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {isEmpty} from 'lodash';
import Skeleton from 'react-loading-skeleton';

import config from '../../../../config.json';
import {AccountContext} from '../../../../contexts/AccountContext';
import {useGetSegmentQuery} from '../../util';
import AudienceInsights, {AudienceInsight} from './AudienceInsights';
import {createValidQuery, cleanURLselectors, sortInsights} from './util';

export const RenderAudienceInsights = ({setCompleteInsights, query, setQuery}) => {
  const source: CancelTokenSource = axios.CancelToken.source();

  const accountContext = useContext(AccountContext)!;
  const {clientDomain} = accountContext.account || {};
  const [insightsAll, setInsightsAll] = useState<AudienceInsight[]>([]);
  const [parsedInsights, setParsedInsights] = useState<AudienceInsight[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const segmentQuery = useGetSegmentQuery();
  const segmentFilters = segmentQuery!.queryRequest;
  const filterCondition = segmentQuery!.filterCondition;

  useEffect(() => {
    getInsights();
    return () => source.cancel('Audience insights unmounted.');
    // eslint-disable-next-line
  }, [JSON.stringify(query)]);

  useEffect(() => {
    setQuery(createValidQuery(segmentFilters, filterCondition));
    // eslint-disable-next-line
  }, [JSON.stringify(segmentFilters), filterCondition]);

  useEffect(() => {
    if (!insightsAll) return;
    const parsedInsights: AudienceInsight[] = clientDomain
      ? cleanURLselectors(insightsAll, query.selector, clientDomain)
      : insightsAll;
    const filteredInsights = sortInsights(parsedInsights);
    setParsedInsights(filteredInsights);
    // eslint-disable-next-line
  }, [insightsAll, clientDomain]);

  async function getInsights() {
    try {
      if (!isEmpty(query.queryRequest) || query.selector) {
        setIsLoading(true);
        const res = await axios.post(config.api.baseURL + 'analytics/inbound', query, {
          cancelToken: source.token,
        });
        query.selector && setInsightsAll(res.data.partialFilterInsights);
        res.data.completeFilterInsights && setCompleteInsights(res.data.completeFilterInsights);
      }
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setIsLoading(false);
      console.debug(e);
    }
  }

  if (isLoading) return <Skeleton width="380px" height="300px" />;
  if (!query.selector) return null;
  return (
    <Col margin={'0 0 20px 0'} minWidth="100%">
      <AudienceInsights
        querySelector={query.selector}
        hasCompleteFilters={!isEmpty(query.queryRequest)}
        parsedInsights={parsedInsights}
        filterCondition={filterCondition}
      />
    </Col>
  );
};
