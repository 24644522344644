import React, {useContext, useEffect, useCallback} from 'react';

import {Col, Header, StyledBox, StyledBtn, Text, lightWarn, AuthContext} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';

import {useSetNewPage} from '../../../functions/util';
import '../../../styles/common/muiOverrides.css';

export default function ResetPassword() {
  const authContext = useContext(AuthContext)!;
  const {setResetPasswordValues} = authContext;
  const {username, code} = authContext.state;

  useSetNewPage('Reset password');

  const getResetData = useCallback(() => {
    const landingParams = sessionStorage.getItem('mt_landingParams') || '';

    const resetData: {username?: string; code?: string} = {username: username, code: code};
    const queryData = landingParams.substr(1).split('&');
    for (const value of queryData) {
      const val = value.split('=');
      if (['code', 'username'].includes(val[0])) {
        resetData[val[0]] = val[1];
      }
    }
    sessionStorage.removeItem('mt_landingParams'); // Cleanup
    return resetData;
  }, [username, code]);

  useEffect(() => {
    setResetPasswordValues(getResetData());
  }, [setResetPasswordValues, getResetData]);

  function changePassword(e: React.ChangeEvent) {
    e.preventDefault();
    authContext.changeForgottenPassword();
  }

  return (
    <>
      <Col alignItems={'center'}>
        <Header color={'white'} margin={'0 0 20px 0'} fontSize={'20px'} fontWeight={500}>
          Submit a new password
        </Header>
      </Col>
      <StyledBox padding={'20px'}>
        <form>
          <Col>
            <TextField
              id="password-input"
              type="password"
              name={'password'}
              label="New password"
              placeholder={'New password'}
              onChange={authContext.handlePasswordInput}
              variant={'outlined'}
            />
            <Col>
              {authContext.state.successMessage && <Text fontWeight={500}>{authContext.state.successMessage}</Text>}
              {authContext.state.errorMessage && (
                <Text color={lightWarn} fontWeight={500}>
                  {authContext.state.errorMessage}
                </Text>
              )}
            </Col>
            <Col alignItems={'center'}>
              <StyledBtn margin={'10px 0 20px 0 '} onClick={changePassword}>
                Change
              </StyledBtn>
            </Col>
          </Col>
        </form>
      </StyledBox>
    </>
  );
}
