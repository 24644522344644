import React, {useContext} from 'react';

import {StyledTable, RowInterface, ITableHeader, useIsWidthSmaller} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {SegmentContext} from '../../../contexts/SegmentContext';
import {getPageIdOrFirst} from '../../../functions/util';
import {EditButton} from './EditButton';
import {copySegment, useNavigateToSegmentRules} from './utils';

export interface TableItem extends RowInterface {
  segmentName: string;
  isActive: boolean | JSX.Element;
  pageCount?: string;
  personalizedVisitors: JSX.Element;
  conversionRateLift: JSX.Element;
  lastEditDate: JSX.Element;
}

interface Props {
  items: TableItem[];
}

export default function SegmentsTable({items}: Props) {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {createNewSegment, updateSegment, deleteSegment, setPageId, setSegmentId} = segmentContext!;
  const {segments} = segmentContext!.state;

  const openSegmentRules = (segmentId: string) => {
    if (!segmentId) return;
    const pageId = getPageIdOrFirst(segmentId, segments);
    if (pageId === undefined) console.debug('No page in segment: ', segmentId, pageId);
    else setPageId(pageId);
    setSegmentId(segmentId);
    history.push(
      segments[segmentId].segmentType === 'outbound' ? '/outbound/' + segmentId : '/segmentation/' + segmentId
    );
  };

  const navigateToSegmentRules = useNavigateToSegmentRules();
  const duplicateSegment = async (segmentId: string) => {
    const oldSegment = segments[segmentId];
    const newSegment = copySegment(oldSegment);
    await createNewSegment(newSegment);
    navigateToSegmentRules(newSegment);
  };

  const openPage = async (segmentId: string) => {
    setSegmentId(segmentId);
    history.push('/pages');
  };

  const commonHeaders: ITableHeader[] = [
    {segmentName: 'Segment'},
    {isActive: 'Status'},
    {segmentType: 'Type'},
    {pageCount: 'Pages'},
    {personalizedVisitors: 'Personalized visitors'},
  ];
  const mediumDisplayHeaders: ITableHeader[] = [{conversionRateLift: 'Conversion lift'}, {lastEditDate: 'Last edited'}];
  const headers = useIsWidthSmaller(1000)
    ? commonHeaders
    : [...commonHeaders, ...mediumDisplayHeaders, {editOptions: ''}];

  const editOptions = [
    {
      primary: 'Edit segment definition',
      callback: openSegmentRules,
    },
    {
      primary: 'Pause segment',
      alt: 'Resume segment',
      callback: (segmentId: string, selectedText: string) => {
        updateSegment(segmentId, {isActive: selectedText === 'Resume segment'});
      },
    },
    {
      primary: 'Delete segment',
      color: 'warn',
      callback: (segmentId: string) => {
        deleteSegment(segmentId);
      },
    },
  ];
  const tableItems = items.map((row) => {
    const rowEditOptions = [...editOptions];
    if (row.segmentType === 'Inbound')
      rowEditOptions.splice(1, 0, {
        primary: 'Duplicate',
        callback: duplicateSegment,
      });
    const rowWithEditOptions = {...row, editOptions: <EditButton row={row} editOptions={rowEditOptions} />};
    return rowWithEditOptions;
  });

  return (
    <StyledTable
      style={{
        row: {cursor: 'initial'},
        table: {maxWidth: '1400px'},
      }}
      handleRowClick={openPage}
      items={tableItems}
      headers={headers}
    />
  );
}
