import React, {useEffect, useRef} from 'react';

import {brandMain} from '@markettailor/common-markettailor';

export const customStyle = {
  control: {
    width: 350,
    margin: '0 0 20px 0',
    mediaSmall: {
      width: 350,
      margin: '0 5px',
    },
  },
};

export const gaButtonStyles = {
  fontFamily: 'Montserrat',
  fontWeight: '600',
  minHeight: '30px',
  border: 'none',
  color: 'white',
  fontSize: '14px',
  borderRadius: '20px',
  boxShadow: '2px 2px 6px #a6a6a6',
  textAlign: 'center',
  userSelect: 'none',
  margin: '0',
  minWidth: '100px',
  maxWidth: '100px',
  padding: '10px 15px',
  background: brandMain,
  cursor: 'pointer',
};

type ResponseCallback = (response: any) => void;
/**
 * Modelled after
 * Package: https://www.npmjs.com/package/@react-oauth/google
 * Function: import { useGoogleLogin } from '@react-oauth/google';
 * Link: https://github.com/MomenSherif/react-oauth/blob/master/packages/%40react-oauth/google/src/hooks/useGoogleLogin.ts
 */
export function useGoogleLogin({
  onSuccess,
  onError,
  onNonOAuthError,
  scope,
  clientId,
}: {
  onSuccess: ResponseCallback;
  onError: ResponseCallback;
  onNonOAuthError: ResponseCallback;
  scope: string;
  clientId: string;
}) {
  const clientRef = useRef<any>();
  const scriptLoadedSuccessfully = useGoogleScriptTag();

  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const onNonOAuthErrorRef = useRef(onNonOAuthError);
  onNonOAuthErrorRef.current = onNonOAuthError;

  useEffect(() => {
    if (!scriptLoadedSuccessfully) return;

    // @ts-ignore
    const client = window?.google?.accounts.oauth2.initCodeClient({
      client_id: clientId,
      scope: `openid profile email ${scope}`,
      callback: (response) => {
        if (response.error) return onErrorRef.current?.(response);
        onSuccessRef.current?.(response as any);
      },
      error_callback: (nonOAuthError) => {
        onNonOAuthErrorRef.current?.(nonOAuthError);
      },
    });

    clientRef.current = client;
  }, [scriptLoadedSuccessfully, scope]);

  const loginAuthCodeFlow = React.useCallback(() => clientRef.current?.requestCode(), []);

  return loginAuthCodeFlow;
}

const useGoogleScriptTag = () => {
  const [isOAuthClientLoaded, setIsOAuthClientLoaded] = React.useState(false);
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => {
      setIsOAuthClientLoaded(true);
    };
    scriptTag.onerror = () => {
      setIsOAuthClientLoaded(false);
    };
    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);
  return isOAuthClientLoaded;
};
