import React, {useContext, ChangeEvent} from 'react';

import {Row, StyledBtn, StyledDivider, Text, Col, warnColor} from '@markettailor/common-markettailor';

import {EditorContext} from '../../../contexts/EditorContext';
import {StyledEditorText, StyledSideControls, StyledInputEditor} from '../../../styles/editor/editorStyles';
import {EventEditor} from '../conversionEvents/EventEditor';
import {useHasAnyUnsavedChanges, useElementHasChanges} from '../util';
import {ChangeCounter} from './ChangeCounter';
import {EditorMode} from './EditorMode';
import {HtmlEditor} from './HTMLEditor';

export default function SideControls() {
  const editorContext = useContext(EditorContext);
  const {editorMode, selectedElem} = editorContext!;

  if (editorMode === 'track') return <TrackModeControls />;
  return (
    <StyledSideControls>
      <StyledEditorText>Editor</StyledEditorText>
      <Row
        data-intercom-id="editor-editorMode"
        margin={'0 10px 5px 0'}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text margin={'0 10px 0 10px'}>Mode</Text>
        <EditorMode />
      </Row>
      <EditorDimensions />
      <SaveChangesButton />
      <Col alignItems="end" margin="0 10px 0 0">
        <ChangeCounter />
      </Col>
      <StyledDivider border margin={'10px 0 0 0'} />
      {editorMode === 'edit' && <HtmlEditor selectedElem={selectedElem} />}
      <ResetButton />
    </StyledSideControls>
  );
}

const EditorDimensions = () => {
  const editorContext = useContext(EditorContext)!;
  const {width, height, setHeight, setWidth} = editorContext.editorDimensionsController;
  return (
    <Col margin="0 10px 0 10px">
      <Row justifyContent={'space-between'} alignItems={'baseline'} margin={'0 0 5px 0'}>
        <Text margin={'0 10px 0 0'}>Width</Text>
        <Row alignItems={'baseline'}>
          <StyledInputEditor
            placeholder={'width'}
            value={width}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setWidth(e.target.value)}
          />
        </Row>
      </Row>
      <Row justifyContent={'space-between'} alignItems={'baseline'} margin={'0 0 5px 0'}>
        <Text margin={'0 10px 0 0'}>Height</Text>
        <Row alignItems={'baseline'}>
          <StyledInputEditor
            placeholder={'height'}
            value={height}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setHeight(e.target.value)}
          />
        </Row>
      </Row>
    </Col>
  );
};

const SaveChangesButton = () => {
  const saveEditorChanges = useContext(EditorContext)?.saveEditorChanges;
  const hasAnyUnsavedChanges = useHasAnyUnsavedChanges();

  return (
    <StyledBtn
      data-intercom-id="editor-saveChanges-button"
      borderRadius={'4px'}
      boxShadow={!hasAnyUnsavedChanges ? 'none' : '2px 2px 6px #a6a6a6'}
      maxWidth={'100%'}
      minWidth={'90%'}
      minHeight={'40px'}
      height={'40px'}
      disabled={!hasAnyUnsavedChanges}
      onClick={saveEditorChanges}
    >
      Save changes
    </StyledBtn>
  );
};

const ResetButton = ({buttonText = 'Reset element', backgroundColor = warnColor}) => {
  const editorContext = useContext(EditorContext);
  const {selectedElemId, selectedElem, resetElement} = editorContext!;
  const resetEnabled = useElementHasChanges(selectedElem.domElemId || selectedElemId);

  return (
    <StyledBtn
      data-intercom-id="editor-resetElement-button"
      background={backgroundColor}
      borderRadius={'4px'}
      maxWidth={'100%'}
      minWidth={'90%'}
      minHeight={'40px'}
      height={'40px'}
      boxShadow={!resetEnabled ? 'none' : '2px 2px 6px #a6a6a6'}
      disabled={!resetEnabled}
      onClick={() => resetElement(selectedElem.domElemId || selectedElemId)}
    >
      {buttonText}
    </StyledBtn>
  );
};

const TrackModeControls = () => {
  return (
    <StyledSideControls>
      <StyledEditorText>Attach new events</StyledEditorText>
      <EventEditor />
      <StyledDivider margin={'10px 0 0 0'} />
      <ResetButton buttonText={'Remove event'} backgroundColor={warnColor} />
    </StyledSideControls>
  );
};
