import React, {useContext, useState, FormEvent, ChangeEvent} from 'react';

import {StyledBtn, NotificationContext} from '@markettailor/common-markettailor';
import {TextField} from '@material-ui/core';
import {isEqual} from 'lodash';

import {AccountContext} from '../../../contexts/AccountContext';
import {useStyles} from '../../../styles/common/formInputStyles';
import AccountLoadingWrapper from '../../common/AccountLoadingWrapper';

export default function AccountFormWrapper() {
  return (
    <AccountLoadingWrapper>
      <AccountForm />
    </AccountLoadingWrapper>
  );
}

function AccountForm() {
  const accountContext = useContext(AccountContext)!;
  const {handleSubmitAccountInfo} = accountContext;
  const {email, clientDomain, companyName} = accountContext.account || {};
  const {setInfoNotification} = useContext(NotificationContext)!;
  const classes = useStyles();

  const savedState = {
    email: email || '',
    clientDomain: clientDomain || '',
    companyName: companyName || '',
  };
  const [state, setState] = useState(savedState);

  const hasChanges = !isEqual(state, savedState);
  const hasEmptyFields = Object.values(state).some((val) => !val);
  const isDisabled = !hasChanges || hasEmptyFields;

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    await handleSubmitAccountInfo(state);
    setInfoNotification({message: 'Account info changed!'});
  }

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.preventDefault();
    setState({...state, [e.target.name]: e.target.value});
  }

  return (
    <form id={'formId'} className={classes.root} autoComplete="off" action={'#'} onSubmit={handleSubmit}>
      <div>
        <TextField
          required
          id="email"
          label="Contact email"
          name={'email'}
          type={'email'}
          value={state.email}
          onChange={handleChange}
        />
        <TextField
          required
          id="companyName"
          label="Company name"
          name={'companyName'}
          type={'text'}
          value={state.companyName || ''}
          onChange={handleChange}
        />
        <TextField
          required
          id="clientDomain"
          label="Website URL"
          name={'clientDomain'}
          type={'text'}
          value={state.clientDomain || ''}
          onChange={handleChange}
          helperText="The full URL, e.g. https://www.markettailor.io"
        />
      </div>
      <StyledBtn disabled={isDisabled} onClick={handleSubmit}>
        {hasChanges ? 'Save' : 'Saved'}
      </StyledBtn>
    </form>
  );
}
