import {IValueLabel} from '@markettailor/common-markettailor';

import {isDev} from '../../../functions/util';
import {mapping} from '../../../termMaps/technicalNamesToLabels';
import {ISelectOptionsWithHeadline} from '../segmentInterfaces';
import {getFieldsSortedByLabels} from '../util';
import {cities, regions} from './data/dataInterfaces';

export function getSelectorsDefault(): ISelectOptionsWithHeadline[] {
  return Object.entries(defaultSelectorCategories).map(([category, selector]) => {
    return {
      label: category,
      options: selector.map((selector) => {
        return {value: selector, label: mapping.selector[selector as keyof typeof mapping.selector]};
      }),
    };
  });
}

export function getSelectorOptionsDefault(selector: string): IValueLabel[] {
  if (defaultValueRangeOptions[selector as keyof typeof defaultValueRangeOptions]) {
    const selectorKey = selector as keyof typeof defaultValueRangeOptions;
    return defaultValueRangeOptions[selectorKey].map((value: string) => {
      const map = mapping[selectorKey];
      return {
        value: value,
        label: Object.keys(mapping).includes(selector) ? map[value as keyof typeof map] : value,
      };
    });
  }
  return [];
}

function addCountryTag(values: {[countryCode: string]: string[]}) {
  const options: string[] = [];
  for (const country of Object.keys(values)) {
    for (const value of Object.values(values[country])) {
      options.push(value + ' (' + country + ')');
    }
  }
  return options;
}

const defaultSelectorCategories = {
  Geolocation: ['country', 'city', 'region', 'timeZone'],
  Language: ['preferredLanguage'],
  Behavior: ['totalPageViews', 'visitedPages', 'totalSessions', 'referer'],
  'UTM Parameters': ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'],
  Technology: ['webTechTags', 'techTagCategories'],
  'Custom value': ['query_string'],
};

const defaultValueRangeOptions = {
  country: getFieldsSortedByLabels(mapping.country),
  city: addCountryTag(cities),
  region: addCountryTag(regions),
  timeZone: [
    '-08:00',
    '-07:00',
    '-06:00',
    '-05:00',
    '-04:00',
    '-03:30',
    '-03:00',
    '-02:00',
    '-01:00',
    '+00:00',
    '+01:00',
    '+02:00',
    '+03:00',
    '+03:30',
    '+04:00',
    '+04:30',
    '+05:00',
    '+05:30',
    '+05:45',
    '+06:00',
    '+06:30',
    '+07:00',
    '+08:00',
    //'+08:45',
    '+09:00',
    '+09:30',
    '+10:00',
    //'+10:30',
    '+11:00',
    '+12:00',
    '+13:00',
    //'+13:45',
    '+14:00',
    '-11:00',
    '-10:00',
    //'-09:30',
    '-09:00',
  ],
  preferredLanguage: getFieldsSortedByLabels(mapping.preferredLanguage),
};

//https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
