import React, {useContext, useState, useEffect} from 'react';

import {
  StyledTable,
  Col,
  Headline,
  IPage,
  RowInterface,
  useIsWidthSmaller,
  ISegment,
} from '@markettailor/common-markettailor';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../../contexts/AccountContext';
import {SegmentContext} from '../../../contexts/SegmentContext';
import {formatNum, cleanDomainFromUrl} from '../../../functions/util';
import {PersonalizedVisitorsCell} from '../../common/tables/PersonalizedVisitorsCell';
import {cleanPageUrl} from '../../common/util';
import {PageVisitorCounts} from '../../segmentation/sideContainer/audienceInsights/AudienceInsights';
import {getPagePreviewUrl} from '../util';

interface ListItem extends RowInterface {
  pageUrl: string;
  status: boolean;
  personalizedVisitors: JSX.Element;
  conversionRateLift: string;
  lastEditDate: JSX.Element;
}

interface Props {
  pageVisitorCounts: PageVisitorCounts[];
}

export default function PageTable({pageVisitorCounts}: Props) {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext)!;
  const accountContext = useContext(AccountContext)!;
  const {clientDomain} = accountContext.account || {};
  const {setPageId, updatePage, deletePage} = segmentContext;
  const {segmentId, segments} = segmentContext.state;
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const isLarge = !useIsWidthSmaller(1600);

  useEffect(() => {
    clientDomain && setListItems(createListItems(clientDomain, segments[segmentId]));
    //eslint-disable-next-line
  }, [clientDomain, segmentId, pageVisitorCounts, JSON.stringify(segments[segmentId])]);

  function createListItems(clientDomain: string, segment: ISegment): ListItem[] {
    return Object.values(segment.pageUrls)
      .map((page) => getListItem(clientDomain, page))
      .sort((a, b) => (a.pageUrl > b.pageUrl ? 1 : -1));
  }

  const getListItem = (clientDomain: string, page: IPage): ListItem => {
    //will be refactored. PageInsights should be pageId based
    const pageUrlShort = !clientDomain ? '' : cleanDomainFromUrl(page.pageUrl, clientDomain);

    const getVisitorCount = (type: 'personalized' | 'control') => {
      const pageCountInsight = pageVisitorCounts.find((pageInsight) => {
        return cleanPageUrl(pageInsight.pageUrl) === cleanPageUrl(page.pageUrl) && pageInsight.type === type;
      });
      return pageCountInsight?.count || 0;
    };

    const personalizedVisitors = getVisitorCount('personalized');
    const controlVisitors = getVisitorCount('control');

    return {
      id: page.pageId,
      rowIsActive: page.isActive,
      pageUrl: pageUrlShort,
      status: page.isActive,
      personalizedVisitors: (
        <PersonalizedVisitorsCell
          personalizedVisitors={formatNum(personalizedVisitors)}
          controlVisitors={formatNum(controlVisitors)}
        />
      ),
      conversionRateLift: '-',
      lastEditDate: (
        <Headline fontSize="14px" fontSizeMedium="12px">
          {moment(page.lastEditDate).fromNow()}
        </Headline>
      ),
    };
  };

  const listOptions = [
    {
      primary: 'Edit page in editor',
      callback: (pageId: string) => {
        setPageId(pageId);
        history.push('/editor/' + pageId);
      },
    },
    {
      primary: 'Pause page',
      alt: 'Resume page',
      callback: (pageId: string, selectedText: string) => {
        const isActive = selectedText === 'Resume page';
        updatePage(pageId, {isActive});
      },
    },
    {
      primary: 'Preview personalized page',
      callback: (pageId: string) => {
        const previewUrl = getPagePreviewUrl(pageId, segments[segmentId]);
        window.open(previewUrl, '_blank');
      },
    },
    {
      primary: 'Preview original page',
      callback: (pageId: string) => {
        const pageUrl = segments[segmentId]?.pageUrls[pageId]?.pageUrl || '';
        window.open(pageUrl + '?preview=0000-0000-0000-0000', '_blank');
      },
    },
    {
      primary: 'Delete page',
      color: 'warn',
      callback: (pageId: string) => {
        deletePage(pageId);
      },
    },
  ];

  return (
    <Col data-intercom-id="pages-table">
      <Headline margin={'0 0 10px 0'}>Personalized pages</Headline>
      <StyledTable
        style={{
          table: {
            maxWidth: isLarge ? '900px' : '800px',
          },
        }}
        rowHighlightDisabled={true}
        headers={[
          {pageUrl: 'Pages'},
          {status: 'Status'},
          {personalizedVisitors: 'Personalized visitors'},
          {conversionRateLift: 'Conversion lift'},
          {lastEditDate: 'Last edited'},
        ]}
        items={listItems}
        editOptions={listOptions}
      />
    </Col>
  );
}
