import React, {useContext} from 'react';

import {
  ISegmentFilter,
  Row,
  IValueLabel,
  ISegments,
  ISegment,
  IIntegrations,
  IIpDataIntegrations,
  ICrmIntegrations,
  ISegmentQuery,
} from '@markettailor/common-markettailor';
import {isEmpty, orderBy, cloneDeep} from 'lodash';
import {components} from 'react-select';
import {FixedSizeList as List} from 'react-window';
import {v4 as uuid} from 'uuid';

import {AccountContext} from '../../contexts/AccountContext';
import {IntegrationContext, crmIntegrations} from '../../contexts/IntegrationContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {useSetNewPage} from '../../functions/util';
import '../../styles/segmentation/customListStyles.css';
import {isUsingMarkettailorIpData} from '../common/util';
import {optionsClearbit} from './segmentationOptions/groupedOptionsClearbit';
import {getSelectorOptionsCRM, getSelectorsCRM} from './segmentationOptions/groupedOptionsCRM';
import {getSelectorOptionsIP, getSelectorsIP} from './segmentationOptions/groupedOptionsIP';
import {optionsKickfire} from './segmentationOptions/groupedOptionsKickfire';
import {optionsZoominfo} from './segmentationOptions/groupedOptionsZoominfo';
import {ISelectOptionsWithHeadline} from './segmentInterfaces';

export interface UserCreatedOptions {
  [key: string]: string[];
}
export const emptySegmentFilter = [
  {
    filterId: uuid(),
    selector: '',
    condition: '',
    valueRange: [],
  },
];

export const useGetSegmentQuery = (): ISegmentQuery => {
  const segmentContext = useContext(SegmentContext);
  const {segments, segmentId} = segmentContext!.state;
  const segmentQuery = cloneDeep(segments[segmentId]?.segmentQuery);
  return !segmentQuery || isEmpty(segmentQuery.queryRequest)
    ? {queryRequest: emptySegmentFilter, filterCondition: 'and'}
    : segmentQuery;
};

const {Option} = components;
export const IconOption = (props: any) => (
  <Option {...props}>
    <Row>
      <Row margin={'0 10px 0 0'}>{props.data.icon}</Row>
      {props.data.label}
    </Row>
  </Option>
);

interface MenuProps {
  options: any;
  children: any;
  maxHeight: any;
  getValue: any;
}

export const MenuList = (props: MenuProps) => {
  const height = 35;
  let {options, children, maxHeight, getValue} = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  maxHeight = 250;
  const width = 300;

  return (
    <List
      {...props}
      height={maxHeight}
      width={width}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({index, style}) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export function saveOptionsToLocalStorage(options: object) {
  window.localStorage.setItem('userCreatedOptions', JSON.stringify(options));
}

export function getUserCreatedOptionsFromLocalStorage(): UserCreatedOptions {
  const userCreatedOptions = window.localStorage.getItem('userCreatedOptions');
  return userCreatedOptions ? JSON.parse(userCreatedOptions) : {};
}

export function parseOptionsToValueLabel(options: string[]): IValueLabel[] {
  if (!options) return [];
  return options.map((option) => {
    return {value: option, label: option};
  });
}

interface ValueLabelMapping {
  [technicalName: string]: string;
}

export function getFieldsSortedByLabels(valueLabelMapping: ValueLabelMapping): string[] {
  const result = Object.entries(valueLabelMapping)
    .map(([key, value]) => [value, key])
    .sort()
    .map(([_, key]) => key);
  return result;
}
export function getFieldsSortedByValues(valueLabelMapping: ValueLabelMapping): string[] {
  const result = Object.keys(valueLabelMapping).sort();
  return result;
}

export function getActiveIntegrations(
  integrations: IIntegrations,
  selectorIntegrations: Array<keyof IIpDataIntegrations | keyof ICrmIntegrations>
): Array<keyof IIpDataIntegrations | keyof ICrmIntegrations> {
  return selectorIntegrations.filter((integrationName) => {
    return integrations[integrationName]?.isActive;
  });
}

export function checkFiltersForCompleteness(segmentFilters: Partial<ISegmentFilter>[]) {
  for (const segment of segmentFilters) {
    if (!segment.selector || !segment.condition || !segment.valueRange || !segment.valueRange.length) return false;
    if (segment.selector === 'query_string') {
      if (segment.valueRange.length !== 1) return false;

      for (const val of segment.valueRange[0].split('=')) {
        if (isEmpty(val)) return false;
      }
    }
    for (const values of segment.valueRange) {
      if (values === '') return false;
    }
  }
  return true;
}

export function getIntegrationOptions(
  getters,
  activeIntegrations: (keyof ICrmIntegrations | keyof IIpDataIntegrations)[],
  getterInputs?: string
) {
  const integrationOptions = activeIntegrations.map((integrationName) => {
    const getterFunc: Function = getterInputs ? () => getters[integrationName](getterInputs) : getters[integrationName];
    return getterFunc();
  });
  return integrationOptions.flat();
}

export const sortSegments = (segments: ISegments): ISegment[] => {
  return orderBy(Object.values(segments), ['segmentType', 'segmentName']);
};

export const useSetPageInbound = () => {
  const segmentContext = useContext(SegmentContext);
  const {segments, segmentId} = segmentContext!.state;

  const segmentName = segmentId in segments && segments[segmentId].segmentName;
  const pageTitle = segmentName ? `Inbound | ${segmentName}` : 'Inbound segmentation';

  useSetNewPage('Inbound segmentation', pageTitle);
};

const getAvailableIntegrations = (
  featureFlags: string[],
  activeIntegrations: (keyof IIpDataIntegrations | keyof ICrmIntegrations)[]
) => {
  const availableIntegrations = [...activeIntegrations];
  isUsingMarkettailorIpData(featureFlags) && availableIntegrations.push('kickfire');
  return availableIntegrations;
};

export const useGetIntegrationSelectors = (
  activeIntegrations: (keyof IIpDataIntegrations | keyof ICrmIntegrations)[]
) => {
  const {integrations} = useContext(IntegrationContext)!;
  const {permissions} = useContext(AccountContext)!.account || {};
  if (!permissions) return;

  const availableIntegrations = getAvailableIntegrations(permissions.featureFlags, activeIntegrations);

  function getSelectorsByIntegrationName(): ISelectOptionsWithHeadline[] {
    const getters = {
      kickfire: () => getSelectorsIP('kickfire', optionsKickfire),
      clearbit: () => getSelectorsIP('clearbit', optionsClearbit),
      zoominfo: () => getSelectorsIP('zoominfo', optionsZoominfo),
    };
    crmIntegrations.forEach((integrationName) => {
      getters[integrationName] = () => getSelectorsCRM(integrationName, integrations[integrationName]);
    });

    const selectors: ISelectOptionsWithHeadline[] = getIntegrationOptions(getters, availableIntegrations);
    return selectors;
  }
  return getSelectorsByIntegrationName();
};

export const useGetOptionsByIntegrationName = (
  selector: string,
  activeIntegrations: (keyof IIpDataIntegrations | keyof ICrmIntegrations)[]
) => {
  const {integrations} = useContext(IntegrationContext)!;
  function getOptionsByIntegrationName(selector: string): Array<ISelectOptionsWithHeadline | IValueLabel> {
    const getters: any = {
      kickfire: (selector: string) => getSelectorOptionsIP(selector, optionsKickfire),
      clearbit: (selector: string) => getSelectorOptionsIP(selector, optionsClearbit),
      zoominfo: (selector: string) => getSelectorOptionsIP(selector, optionsZoominfo),
    };
    crmIntegrations.forEach((integrationName) => {
      getters[integrationName] = (selector: string) => getSelectorOptionsCRM(selector, integrations[integrationName]);
    });
    const options: Array<ISelectOptionsWithHeadline | IValueLabel> = getIntegrationOptions(
      getters,
      activeIntegrations,
      selector
    );
    return options;
  }
  return getOptionsByIntegrationName(selector);
};
