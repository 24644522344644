import React, {useContext, useState} from 'react';

import {PopoverWindow, NotificationContext, IIntegrations} from '@markettailor/common-markettailor';

import {IntegrationContext} from '../../contexts/IntegrationContext';
import IntegrationBadge from './IntegrationBadge';
import OptionsWindowApiKey from './OptionsWindowApiKey';

export interface IAuthOptionsCommonApiKey {
  apiKey: string;
}

interface Props {
  title: string;
  description: string;
  integrationName: keyof IIntegrations;
  logoWidth: string;
  isDisabled?: boolean;
  settingsRenderer?: JSX.Element | null;
}

export default function CommonApiKeyIntegration(props: Props): JSX.Element {
  const {integrations, authIntegration} = useContext(IntegrationContext)!;
  const {setInfoNotification} = useContext(NotificationContext)!;
  const [openIntegrationWindow, setOpenIntegrationWindow] = useState(false);

  async function handleIntegration(apiKey: string): Promise<void> {
    const res = await authIntegration(props.integrationName, {
      apiKey: apiKey,
    });
    if (res && res.status === 200) {
      setInfoNotification({message: 'Integration successful!'});
      setOpenIntegrationWindow(false);
    } else {
      setInfoNotification({message: 'Integration failed, check your API key again', level: 'error'});
    }
  }

  return (
    <>
      <PopoverWindow hasMenu={true} display={openIntegrationWindow} handleClose={() => setOpenIntegrationWindow(false)}>
        <OptionsWindowApiKey handleSubmit={(apiKey: string) => handleIntegration(apiKey)} title={props.title} />
      </PopoverWindow>
      <IntegrationBadge
        title={props.title}
        integrationName={props.integrationName}
        description={props.description}
        isDisabled={props.isDisabled}
        isActive={integrations[props.integrationName]?.isActive || false}
        logoWidth={props.logoWidth}
        handleAuth={() => setOpenIntegrationWindow(true)}
        settingsRenderer={props.settingsRenderer}
      />
    </>
  );
}
