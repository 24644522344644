import React, {useContext, useState} from 'react';

import {Col, MainContentView, StyledDivider, Row} from '@markettailor/common-markettailor';

import {SegmentContext} from '../../contexts/SegmentContext';
import {handleToEditorClick, ChangePageUrlPopover} from '../common/SegmentUrls';
import SegmentationInlineNav from '../recommendedSegments/SegmentationsInlineNav';
import SegmentName from '../segmentsOverview/SegmentName';
import {RenderSkeleton} from './emptyStates';
import {InboundTopNav} from './InboundTopNav';
import Segmentation from './segmentation/Segmentation';
import {SideContainer} from './sideContainer/SideContainer';
import {useSetPageInbound} from './util';

export default function InboundContainer(): JSX.Element {
  const segmentContext = useContext(SegmentContext);
  const {segments, segmentId, pageId} = segmentContext!.state;
  const [redirectToEditorOnClose, setRedirectToEditorOnClose] = useState(false);
  const [renderChangeUrlWindow, setRenderChangeUrlWindow] = useState(false);
  const pageUrl = segments[segmentId]?.pageUrls?.[pageId]?.pageUrl;

  useSetPageInbound();

  return (
    <>
      <InboundTopNav
        handleToEditorClick={() =>
          handleToEditorClick(pageUrl, pageId, setRenderChangeUrlWindow, setRedirectToEditorOnClose)
        }
      />
      <MainContentView>
        <ChangePageUrlPopover
          redirectOnClose={redirectToEditorOnClose}
          renderChangeUrlWindow={renderChangeUrlWindow}
          setRenderChangeUrlWindow={setRenderChangeUrlWindow}
        />
        <ContainerContent setRenderChangeUrlWindow={setRenderChangeUrlWindow} />
      </MainContentView>
    </>
  );
}

const ContainerContent = ({setRenderChangeUrlWindow}) => {
  const segmentContext = useContext(SegmentContext);
  const {isLoading} = segmentContext!.state;

  if (isLoading) return <RenderSkeleton />;
  return (
    <>
      <Row>
        <SegmentationInlineNav currentComponent="segmentation" />
        <StyledDivider margin="0 0 20px 0" />
        <SideContainer setRenderChangeUrlWindow={setRenderChangeUrlWindow} />
      </Row>
      <StyledDivider margin="30px 0" />
      <Col>
        <SegmentName />
        <Segmentation />
      </Col>
    </>
  );
};
