import React, {useContext} from 'react';

import {PopoverWindow} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../contexts/AccountContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import PageUrlForm from '../common/PageUrlForm';

interface Props {
  renderNewPageWindow: boolean;
  setRenderNewPageWindow: Function;
}
export const NewPagePopup = ({renderNewPageWindow, setRenderNewPageWindow}: Props) => {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const accountContext = useContext(AccountContext)!;
  const {createNewPage} = segmentContext!;
  const {clientDomain} = accountContext.account || {};

  async function onSubmitNewUrl(url: string) {
    const pageId = await createNewPage(url);
    history.push('/editor/' + pageId);
  }

  return (
    <PopoverWindow display={renderNewPageWindow} handleClose={() => setRenderNewPageWindow(false)}>
      <PageUrlForm
        handleClose={() => setRenderNewPageWindow(false)}
        onSubmitText={onSubmitNewUrl}
        submitNoti={'New page added!'}
        type={'url'}
        headline={'Paste here the new URL you want to personalize'}
        defaultInputText={'https://' + (clientDomain || '')}
        buttonText={'Add page'}
        validClientDomain={Boolean(clientDomain)}
      />
    </PopoverWindow>
  );
};
