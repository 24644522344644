import React, {ReactNode} from 'react';

import {Col} from '@markettailor/common-markettailor';
import {useHistory} from 'react-router-dom';

import {setTitle} from '../../functions/util';
import InlineNav from '../common/InlineNav';

type Props = {
  children?: ReactNode;
  component: string;
};

export default function AccountInlineNav(props: Props) {
  const history = useHistory();
  const componentsOptions = [
    {value: 'account', label: 'Account'},
    {value: 'users', label: 'Users'},
    {value: 'usage', label: 'Usage'},
    {value: 'subscription', label: 'Subscription'},
    {value: 'privacy', label: 'Privacy'},
    {value: 'change-password', label: 'Change password'},
  ];

  return (
    <Col margin={'0 0 0 40px'}>
      <InlineNav
        children={props.children}
        currentComponent={props.component}
        handleSelect={(selection) => {
          history.push('/' + selection);
          componentsOptions.forEach((option) => {
            if (option.value === selection) setTitle(option.label);
          });
        }}
        componentOptions={componentsOptions}
      />
    </Col>
  );
}
