import React, {useEffect, useState, useContext, ReactNode, createContext, useCallback} from 'react';

import {NotificationContext} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';

import config from '../config.json';
import {deepFreeze, useIsMounted, useInterval} from '../functions/util';
import {IntegrationContext} from './IntegrationContext';

export interface ISegmentAnalytics {
  [segmentId: string]: number;
}

export type ConversionType = 'page' | 'track';
export interface ConversionEvent {
  eventId: string;
  eventName: string;
  count: number;
  source: ConversionSourceType;
  type?: ConversionType;
}
export interface ConversionEvents {
  [conversionEvent: string]: ConversionEvent;
}
export interface IGroupConversions {
  visitorCount: number;
  events: ConversionEvents;
}
export interface IConversionRateLiftSummary {
  conversionRateLift: number;
  isSignificant: boolean;
  timeEstimateToIsSignificant?: number;
}
interface IConversionRateLifts {
  [key: string]: IConversionRateLiftSummary;
}
interface SegmentConversions {
  personalized?: IGroupConversions;
  control?: IGroupConversions;
  conversionRateLifts?: IConversionRateLifts;
}

export type ConversionSourceType = 'markettailor' | 'segment' | 'googleAnalytics';
export interface IConversions {
  [segmentId: string]: SegmentConversions;
}

export type ActiveIntegrationType = 'googleAnalytics' | 'segment' | undefined;
export interface IConversionContext {
  getConversions: () => Promise<void>;
  conversions: IConversions;
  isLoading: boolean;
  activeIntegration: ActiveIntegrationType;
  segmentAnalytics: ISegmentAnalytics;
}

const ConversionAnalyticsContext = createContext<IConversionContext | undefined>(undefined);

function ConversionAnalyticsProvider({children}: {children: ReactNode}) {
  const {integrations, isLoading: integrationsIsLoading} = useContext(IntegrationContext)!;
  const isMounted = useIsMounted();

  const [source, _] = useState(axios.CancelToken.source());
  useEffect(() => () => source.cancel('Conversions unmounted.'), []);

  const {setInfoNotification} = useContext(NotificationContext)!;
  const [conversions, setConversions] = useState<IConversions>({});
  const [isLoading, setIsLoading] = useState(true);
  const [segmentAnalytics, setSegmentAnalytics] = useState<ISegmentAnalytics>({});

  const isGaActive = Boolean(integrations.googleAnalytics?.isActive);
  const isSegmentActive = Boolean(integrations.segment?.isActive);
  const activeIntegration = isGaActive ? 'googleAnalytics' : isSegmentActive ? 'segment' : undefined;

  useEffect(() => {
    if (integrationsIsLoading) return;
    const loadData = async () => {
      setIsLoading(true);
      const conversionsPromise = getConversions();
      const segmentAnalyticsPromise = getSegmentAnalytics();

      await Promise.all([conversionsPromise, segmentAnalyticsPromise]);
      if (!isMounted.current) return;
      setIsLoading(false);
    };

    loadData();
    // eslint-disable-next-line
  }, [activeIntegration, integrationsIsLoading]);

  const getConversionsCallback = useCallback(getConversions, []);
  useInterval(getConversionsCallback, 1000 * 60 * 15);

  async function getConversions() {
    try {
      const res = await axios.get(config.api.baseURL + 'analytics/conversions', {
        cancelToken: source.token,
      });
      setConversions(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Updating conversion data failed', level: 'error'});
      console.debug(e);
    }
  }

  async function getSegmentAnalytics() {
    try {
      const res = await axios.get(config.api.baseURL + 'analytics/segments', {
        cancelToken: source.token,
      });
      setSegmentAnalytics(res.data);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
    }
  }

  return (
    <ConversionAnalyticsContext.Provider
      value={deepFreeze<IConversionContext>({
        getConversions,
        conversions,
        isLoading,
        activeIntegration,
        segmentAnalytics,
      })}
    >
      {children}
    </ConversionAnalyticsContext.Provider>
  );
}

export {ConversionAnalyticsContext, ConversionAnalyticsProvider};
