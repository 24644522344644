import React, {useContext} from 'react';

import {PopoverWindow, Headline, Row, Text, OnHover, lightBlue, StyledBox} from '@markettailor/common-markettailor';
import Skeleton from 'react-loading-skeleton';
import {useHistory} from 'react-router-dom';

import {AccountContext} from '../../contexts/AccountContext';
import {SegmentContext} from '../../contexts/SegmentContext';
import {formatUrl} from '../../functions/util';
import history from '../../history';
import PageUrlForm from './PageUrlForm';

export function handleToEditorClick(
  pageUrl: string,
  pageId: string,
  setRenderChangeUrlWindow: Function,
  setRedirectToEditorOnClose: Function
): void {
  if (!pageUrl) {
    setRenderChangeUrlWindow(true);
    setRedirectToEditorOnClose(true);
  } else {
    history.push('/editor/' + pageId);
  }
}
interface SegmentUrlsProps {
  setRenderChangeUrlWindow: (windowState: boolean) => void;
}

export function SegmentUrls({setRenderChangeUrlWindow}: SegmentUrlsProps) {
  const segmentContext = useContext(SegmentContext);
  const {segmentId, pageId, segments, isLoading} = segmentContext!.state;

  const pageUrl = segments[segmentId]?.pageUrls?.[pageId]?.pageUrl;

  if (isLoading) return <Skeleton width={200} height={100} />;
  return (
    <>
      <StyledBox data-intercom-id="segment-first-page" width="fit-content" height="fit-content">
        <Headline margin={'0 0 5px 0'} fontSize={'13px'} fontSizeLarge={'13px'} fontSizeExtraLarge={'13px'}>
          First page to personalize
        </Headline>
        <Row>
          <Text margin={'0 10px 0 0'}>{pageUrl ? formatUrl(pageUrl) : 'Segment has no pages'}</Text>
          <OnHover cursor="pointer">
            <Text color={lightBlue} onClick={() => setRenderChangeUrlWindow(true)}>
              {pageUrl ? '(Edit)' : '(Add)'}
            </Text>
          </OnHover>
        </Row>
      </StyledBox>
    </>
  );
}

interface ChangePageUrlPopoverProps extends SegmentUrlsProps {
  redirectOnClose: boolean;
  renderChangeUrlWindow: boolean;
}

export const ChangePageUrlPopover = ({
  redirectOnClose,
  renderChangeUrlWindow,
  setRenderChangeUrlWindow,
}: ChangePageUrlPopoverProps) => {
  const history = useHistory();
  const segmentContext = useContext(SegmentContext);
  const {segmentId, pageId, segments} = segmentContext!.state;
  const {updatePage, createNewPage} = segmentContext!;

  const accountContext = useContext(AccountContext)!;
  const {clientDomain} = accountContext.account || {};
  const pageUrl = segments[segmentId]?.pageUrls?.[pageId]?.pageUrl;

  const upsertPageUrl = async (pageUrl: string) => {
    if (pageId) {
      await updatePage(pageId, {pageUrl});
      return;
    }
    const newPageId = await createNewPage(pageUrl);
    return newPageId;
  };

  const handleUrlChange = async (url: string): Promise<void> => {
    const newPageId = await upsertPageUrl(url);
    if (redirectOnClose) history.push('/editor/' + newPageId || pageId);
  };

  return (
    <>
      <PopoverWindow display={renderChangeUrlWindow} handleClose={() => setRenderChangeUrlWindow(false)}>
        <PageUrlForm
          onSubmitText={(url: string) => handleUrlChange(url)}
          handleClose={() => setRenderChangeUrlWindow(false)}
          type={'url'}
          submitNoti={pageUrl ? 'Page URL changed!' : 'Page URL saved!'}
          headline={'Paste here the URL you want to personalize'}
          defaultInputText={pageUrl || 'https://' + (clientDomain || '')}
          buttonText={'Change URL'}
          validClientDomain={Boolean(clientDomain)}
        />
      </PopoverWindow>
    </>
  );
};
