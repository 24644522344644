import React, {createContext, useState, useEffect, useContext, useCallback, ReactNode} from 'react';

import {IDomElems, IDomElemChanges, IDomElemFullState, NotificationContext} from '@markettailor/common-markettailor';
import {cloneDeep, isEmpty} from 'lodash';

import {ErrorStateType} from '../components/editor/EditorError';
import {setInitialIframeContent, formatUrlProtocol} from '../components/editor/localEditor/util';
import {analytics} from '../functions/analytics';
import {deepFreeze} from '../functions/util';
import {ConversionDomElemsType} from './conversionManagement/domElems';
import {ConversionManagementContext} from './ConversionManagementContext';
import {useGetDynamicValues} from './dynamicValueUtils';
import type {
  EditorModeType,
  IExportElem,
  IframeToEditorMessage,
  IDocTitleMessage,
  IEditorClickMessage,
  IDynamicValues,
} from './editorContextTypes';
import {
  getUpdatedConversionElem,
  checkFontStyleFormatting,
  createNewElementFromClicked,
  emptySelectedElem,
  makeChangesToExistingElement,
  populateEditorWithDomChanges,
  populateEditorWithDomIds,
  populateEditorWithDomIdsTrack,
  useGetSegmentPage,
  sendToBrowser,
  useGetInitialEditorMode,
  useAddPostMessageListenerToWindow,
  removeEmptyConversionDomElems,
  mapConversionDomElemsToDomElems,
  removeUnchangedFields,
  addUnchangedDomEditorFields,
  removeEmptyDomElems,
} from './editorContextUtil';
import {EditorPageContext} from './EditorPageContext';
import {SegmentContext} from './SegmentContext';

interface IEditorDimensionsController {
  width: string;
  height: string;
  setHeight: (height: string) => void;
  setWidth: (width: string) => void;
}

interface IEditorContext {
  segmentId: string;
  pageId: string;
  selectedElemId: string;
  selectedElem: IDomElemFullState;
  domElems: IDomElems;
  editorMode: EditorModeType;
  pageUrl: string;
  dynamicValuesOrdered: IDynamicValues;
  docTitle?: string;
  conversionDomElems?: ConversionDomElemsType;
  errorState?: ErrorStateType;
  isSavingChanges: boolean;
  iframeRequestData?: string;
  changeElemContent: (
    elemId: string,
    elem: Partial<IDomElemChanges>,
    isReset?: boolean
  ) => void | ConversionDomElemsType;
  saveEditorChanges: () => Promise<void>;
  resetElement: (elemId: string) => void;
  resetAllElements: () => Promise<void>;
  changeEditorMode: (mode?: EditorModeType) => void;
  editorDimensionsController: IEditorDimensionsController;
}

const useEditorDimensions = () => {
  const [height, setHeight] = useState<string>('100%');
  const [width, setWidth] = useState<string>('100%');
  return {width, height, setHeight, setWidth};
};

export const EDITOR_ID = 'visualEditor-XFrame';

const EditorContext = createContext<IEditorContext | undefined>(undefined);

function EditorProvider({children}: {children: ReactNode}) {
  const {pageId, pageUrl, errorState, iframeRequestData} = useContext(EditorPageContext)!;
  const segmentContext = useContext(SegmentContext)!;
  const {updatePage} = segmentContext;
  const {isLoading: segmentContextIsLoading} = segmentContext!.state;
  const {setInfoNotification} = useContext(NotificationContext)!;

  const {
    isLoading: conversionManagementContextIsLoading,
    conversionDomElems: conversionDomElemsOld,
    createConversionDomElem,
    deleteConversionDomElem,
  } = useContext(ConversionManagementContext)!;
  const editorDimensionsController = useEditorDimensions();

  const [selectedElemId, setSelectedElemId] = useState<string>('');
  const [selectedElem, setSelectedElem] = useState<IDomElemFullState>({...emptySelectedElem});
  const [docTitle, setDocTitle] = useState<string | undefined>();
  const [iframeReadyForDomElems, setIframeReadyForDomElems] = useState<boolean>(false);
  const [conversionDomElems, setConversionDomElems] = useState<ConversionDomElemsType>();
  const [domElems, setDomElems] = useState<IDomElems | undefined>();
  if (domElems && domElems['undefined']) console.error('domElems should not contain undefined keys');

  const {segmentId, domElems: initialDomElems} = useGetSegmentPage(pageId);
  const [editorMode, setEditorMode] = useGetInitialEditorMode(segmentId, pageUrl);
  const dynamicValuesOrdered = useGetDynamicValues(segmentId);
  useAddPostMessageListenerToWindow(editorMessageRouter);
  const [isSavingChanges, setIsSavingChanges] = useState(false);

  useEffect(() => {
    // Init edit mode dom elems
    if (!iframeReadyForDomElems) return;
    if (segmentContextIsLoading || editorMode === 'track') return;
    if (!domElems) setDomElems(addUnchangedDomEditorFields(initialDomElems));
    populateEditor();
    //eslint-disable-next-line
  }, [segmentContextIsLoading, Boolean(dynamicValuesOrdered), Boolean(domElems), iframeReadyForDomElems]);

  useEffect(() => {
    // Init track mode dom elems
    if (!iframeReadyForDomElems) return;
    if (conversionManagementContextIsLoading || editorMode !== 'track') return;
    if (!conversionDomElems) setConversionDomElems(conversionDomElemsOld);
    if (!domElems) setDomElems(mapConversionDomElemsToDomElems(conversionDomElemsOld));
    populateEditor();
    //eslint-disable-next-line
  }, [pageUrl, editorMode, conversionManagementContextIsLoading, conversionDomElemsOld, iframeReadyForDomElems]);

  //---Initial browser communication starts---

  function editorMessageRouter(message: IframeToEditorMessage) {
    const origin = message.origin;
    const task = message.data?.task;
    const formattedPageUrl = formatUrlProtocol(pageUrl);
    const messageIsFromLocalEditor =
      task &&
      (formattedPageUrl.includes(origin) || origin.includes('app.markettailor.io') || origin.includes('localhost'));
    if (!messageIsFromLocalEditor) return;

    const taskRouter = {
      'iframe ready to populate': () => setIframeReadyForDomElems(true),
      'set docTitle': (data: IDocTitleMessage) => setDocTitle(data.docTitle),
      'editor click': (data: IEditorClickMessage) => editorClick(data),
    };

    const func = taskRouter[task];
    if (!func) {
      console.error('Unknown editor message', task);
      return;
    }
    //@ts-ignore
    func(message.data);
  }

  const populateEditor = useCallback(() => {
    if (errorState) return;
    if (editorMode === 'edit' && domElems && dynamicValuesOrdered)
      populateEditorWithDomChanges(domElems, dynamicValuesOrdered);
    else if (editorMode === 'original' && domElems) populateEditorWithDomIds(domElems);
    else if (editorMode === 'track') populateEditorWithDomIdsTrack(conversionDomElemsOld);
  }, [errorState, editorMode, domElems, dynamicValuesOrdered, conversionDomElemsOld]);

  const editorClick = (data: IEditorClickMessage) => {
    const elem: IExportElem | undefined = data.elem;
    if (!elem) return;
    changeEditorStateOnElemClick(elem);
  };

  const changeEditorMode = (mode?: EditorModeType) => {
    if (mode === editorMode || !mode) return;
    setEditorMode(mode);
    if (mode === 'edit') {
      dynamicValuesOrdered && domElems && populateEditorWithDomChanges(domElems, dynamicValuesOrdered);
      return;
    }
    if (mode === 'original') {
      setIframeReadyForDomElems(false);
      setInitialIframeContent(iframeRequestData);
      setSelectedElem({...emptySelectedElem});
      setSelectedElemId('');
    }
  };

  //---Initial browser communication ends---

  //---User interaction starts---

  function changeEditorStateOnElemClick(elem: IExportElem) {
    if (errorState || !domElems) return;
    const newDomElems = cloneDeep(domElems);
    const clickedElem: IDomElemFullState = {...emptySelectedElem, ...elem};
    const newClickedElem = checkFontStyleFormatting(clickedElem);
    const isNewElement = !domElems[newClickedElem.domElemId]?.domOriginal;
    if (newClickedElem.domElemId === 'undefined') {
      console.error('Got undefined ID. isNewElement:', isNewElement);
      return;
    }

    if (editorMode === 'original') {
      const newElemId = isNewElement ? '' : newClickedElem.domElemId;
      setSelectedElemId(newElemId);
      return;
    }
    if (isNewElement) {
      const createdDomElem = createNewElementFromClicked(newClickedElem);
      newDomElems[createdDomElem.elemId] = createdDomElem;
      setSelectedElemId(createdDomElem.elemId);
      setSelectedElem({...createdDomElem.domOriginal});
      sendToBrowser({task: 'change elemId', elemId: createdDomElem.elemId});
    } else {
      const modifiedDomElem = makeChangesToExistingElement(newClickedElem, newDomElems);
      const elemId = modifiedDomElem.elemId;
      newDomElems[elemId] = modifiedDomElem;
      setSelectedElemId(elemId);
      setSelectedElem({
        ...modifiedDomElem.domOriginal,
        ...modifiedDomElem.domEditor,
        style: {...modifiedDomElem.domOriginal.style, ...modifiedDomElem.domEditor.style},
      });
    }
    setDomElems(newDomElems);
  }

  const resetElement = (elemId: string) => {
    if (!domElems) return;
    const resetSelectedElemWithDelay = (domOriginal) => {
      // Selected element ID needs to change in order for the sub-components to recognize that the selected elem has changed
      // Thus, a delay is needed so that there is a state change: selectedElemId => empty string => selectedElemId
      setTimeout(() => setSelectedElem({...domOriginal}));
    };

    const {domOriginal} = domElems[elemId];
    changeElemContent(elemId, {...emptySelectedElem, ...domOriginal, conversionEventId: ''}, true);

    const newDomElem = {...domElems[elemId], domEditor: {...domElems[elemId].domOriginal}};
    const newDomElems = {...domElems, [elemId]: newDomElem};
    setDomElems(newDomElems);

    setSelectedElem({...emptySelectedElem});
    if (editorMode === 'edit') resetSelectedElemWithDelay(domOriginal);
  };

  const resetAllElements = async () => {
    try {
      setIframeReadyForDomElems(false);
      setInitialIframeContent(iframeRequestData);
      setDomElems({});
      setSelectedElem({...emptySelectedElem});
      await updatePage(pageId, {isActive: true, domElems: {}});
      analytics.track('Reset editor elements');
      setInfoNotification({message: 'Page reset!'});
    } catch (e) {
      setInfoNotification({message: 'Reset failed', level: 'error'});
    }
  };

  const saveEditorChanges = async () => {
    if (!domElems) return;
    const saveHtmlChanges = async (domElems: IDomElems) => {
      setIsSavingChanges(true);
      const newDomElems = removeUnchangedFields(removeEmptyDomElems(domElems));
      await updatePage(pageId, {domElems: newDomElems});
      setIsSavingChanges(false);
      setInfoNotification({message: 'Changes saved!'});
    };

    if (editorMode === 'edit') return await saveHtmlChanges(domElems);
  };

  //---Utility methods---
  const changeElemContentTrack = (elemId: string, elemChanges: IDomElemChanges) => {
    if (!conversionDomElems) return;
    const {newConversionDomElems, newConversionDomElem} = getUpdatedConversionElem(
      selectedElem,
      conversionDomElems,
      elemChanges
    );
    const clearedConversionDomElems = removeEmptyConversionDomElems(newConversionDomElems);
    setConversionDomElems(clearedConversionDomElems);
    setSelectedElem({...emptySelectedElem, ...newConversionDomElem});
    sendToBrowser({
      task: 'change elem content',
      elemChanges: newConversionDomElem,
      previousElemVersion: newConversionDomElem,
    });
    if (!newConversionDomElem.conversionEventId) deleteConversionDomElem(elemId);
    if (newConversionDomElem.conversionEventId) createConversionDomElem(newConversionDomElem);
    return clearedConversionDomElems;
  };

  const changeElemContentEdit = (elemId: string, elemChanges: IDomElemChanges) => {
    if (!domElems) return;
    if (!(elemId in domElems)) {
      console.warn('Should not end up here?');
      return;
    }

    const {domEditor, domOriginal} = domElems[elemId];
    if (!domEditor) {
      console.error(
        "You're trying to change element property, without selecting element. This is a dev issue of not disabling controls while no element is selected."
      );
      return;
    }

    const previousElemVersion = !isEmpty(domEditor) ? {...domOriginal, ...domEditor} : domOriginal;
    const newDomEditor = {
      ...selectedElem,
      ...elemChanges,
      style: {...selectedElem.style, ...elemChanges.style},
    };
    setSelectedElem(newDomEditor);
    setDomElems({...domElems, [elemId]: {...domElems[elemId], domEditor: newDomEditor}});
    sendToBrowser({task: 'change elem content', elemChanges: newDomEditor, previousElemVersion});
  };

  const changeElemContent = (elemId: string, elemChanges: Partial<IDomElemChanges>, isReset = false) => {
    const elemChangesWithId = {...elemChanges, domElemId: elemId, hasEdits: !isReset};
    if (errorState) return;
    if (editorMode === 'track') return changeElemContentTrack(elemId, elemChangesWithId);
    if (editorMode === 'edit' || (editorMode === 'original' && isReset))
      changeElemContentEdit(elemId, elemChangesWithId);
  };

  return (
    <EditorContext.Provider
      value={deepFreeze<IEditorContext>({
        segmentId,
        pageId,
        editorMode,
        pageUrl,
        docTitle,
        domElems: domElems || {},
        selectedElemId,
        selectedElem,
        conversionDomElems,
        errorState,
        dynamicValuesOrdered: dynamicValuesOrdered || [],
        isSavingChanges,
        iframeRequestData,
        changeElemContent,
        saveEditorChanges,
        resetElement,
        resetAllElements,
        changeEditorMode,
        editorDimensionsController,
      })}
    >
      {children}
    </EditorContext.Provider>
  );
}

export {EditorContext, EditorProvider};
