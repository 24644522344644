import React, {useContext} from 'react';

import {isEmpty} from 'lodash';
import Skeleton from 'react-loading-skeleton';

import {ConversionAnalyticsContext} from '../../../contexts/ConversionAnalyticsContext';
import {SegmentContext} from '../../../contexts/SegmentContext';
import {formatNum} from '../../../functions/util';
import {PersonalizedVisitorsCell} from '../../common/tables/PersonalizedVisitorsCell';

export const PersonalizedVisitors = ({segmentId}): JSX.Element => {
  const {conversions, isLoading: conversionsIsLoading} = useContext(ConversionAnalyticsContext)!;
  const segmentContext = useContext(SegmentContext);
  const {segments} = segmentContext!.state;

  const mapSegmentAnalytics = () => {
    return Object.entries(segments).reduce((acc, [segmentId, _]) => {
      return {
        ...acc,
        [segmentId]: {
          personalizedVisitors: conversions[segmentId]?.personalized?.visitorCount || 0,
          controlVisitors: conversions[segmentId]?.control?.visitorCount || 0,
        },
      };
    }, {});
  };

  if (conversionsIsLoading) return <Skeleton width={50} height={30} />;
  const newSegmentAnalytics = !isEmpty(conversions) ? mapSegmentAnalytics() : {};
  const personalizedVisitors = formatNum(newSegmentAnalytics[segmentId]?.personalizedVisitors);
  const controlVisitors = formatNum(newSegmentAnalytics[segmentId]?.controlVisitors);
  return <PersonalizedVisitorsCell personalizedVisitors={personalizedVisitors} controlVisitors={controlVisitors} />;
};
