import React, {useContext, useState} from 'react';

import {IconX, Col, NotificationContext, darkRed, lightBlue, lightGray} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {cloneDeep, isEmpty} from 'lodash';

import config from '../../../config.json';
import {OutboundSegmentItemsContext} from '../../../contexts/OutboundSegmentItemsContext';
import {SegmentContext} from '../../../contexts/SegmentContext';
import '../../../styles/animations.css';
import StyledTableVirtualized from '../../common/StyledTableVirtualized';
import {OutboundListType} from '../OutboundContainerContent';
import {ISearchFilter} from '../OutboundFilterDropdown';
import {useGetFilteredData} from '../util';
import EditableCell from './EditableCell';
import {NormalCell} from './NormalCell';
import {PersonalizedLinkCell} from './PersonalizedLinkCell';

export interface TableRow {
  id: string;
  personalizedLink?: JSX.Element;
  icon: JSX.Element;
  [key: string]: string | JSX.Element | undefined;
}

export interface DataRowOriginal {
  id: string;
  personalizedLink: string;
  [key: string]: string;
}

export interface DataRow {
  original: DataRowOriginal;
  changes: {[key: string]: string};
}

export interface EditableCellSelection {
  rowId: string;
  column: string;
  initialValue: string;
}

interface Props {
  listTypeSelected: OutboundListType;
  dataFilter: ISearchFilter;
  removeListRows: (dataRows: DataRow[]) => void;
  addListRows: (dataRows: DataRow[]) => void;
}

export const OutboundTable = ({listTypeSelected, dataFilter, removeListRows, addListRows}: Props) => {
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;

  const segmentContext = useContext(SegmentContext)!;
  const {setOutboundAllData} = segmentContext!;
  const {segments, segmentId, pageId, outboundHeaderMap, outboundAllData} = segmentContext.state;
  const segment = segments[segmentId];

  const outboundSegmentItemsContext = useContext(OutboundSegmentItemsContext)!;
  const {crmObjects} = outboundSegmentItemsContext;

  const {outboundFilteredData, segmentFilteredData} = useGetFilteredData(dataFilter);
  const listItems: DataRow[] = listTypeSelected === 'allList' ? outboundFilteredData : segmentFilteredData;

  const [editableCellSelection, setEditableCellSelection] = useState<EditableCellSelection | undefined>(undefined);

  const tableHeaders = getOutboundTableHeaders(outboundAllData, outboundHeaderMap);

  const saveLabelChanges = (id: string, changesObj) => {
    try {
      axios.put(config.api.baseURL + 'outbound/label/' + segmentId + '/' + id, changesObj, {
        cancelToken: source.token,
      });
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Changing row failed', level: 'error'});
    }
  };

  function saveCellContentEdits(selectedRow: DataRow, header: string, cellContent: string) {
    const createChangedOutboundData = (
      newSelectedRow: DataRow,
      cellContent: string
    ): [DataRow[], number | undefined] => {
      const deleteIfOriginalValue = (rowItem: DataRow, cellContent: string) => {
        if (header in rowItem.changes && rowItem.original[header]?.toString() === cellContent) {
          delete rowItem.changes[header];
        }
        return rowItem;
      };

      const applyChanges = (rowItem: DataRow, cellContent: string) => {
        let newRowItem = cloneDeep(rowItem);
        newRowItem.changes = {...rowItem.changes, [header]: cellContent};
        newRowItem = deleteIfOriginalValue(newRowItem, cellContent);
        return newRowItem;
      };

      let changedRowIndex: number | undefined;
      const newOutboundData = outboundAllData.map((rowItem: DataRow, rowIndex) => {
        if (rowItem.original.id === newSelectedRow.original.id) {
          rowItem = applyChanges(rowItem, cellContent);
          changedRowIndex = rowIndex;
        }
        return rowItem;
      });
      return [newOutboundData, changedRowIndex];
    };

    const newSelectedRow = cloneDeep(selectedRow);
    const cellContentChanged = newSelectedRow[header] !== cellContent || newSelectedRow.changes[header];
    if (cellContentChanged) {
      const [newOutboundAllData, changedRowIndex] = createChangedOutboundData(newSelectedRow, cellContent);
      if (changedRowIndex === undefined) return;
      const changesObj = newOutboundAllData[changedRowIndex].changes;
      setOutboundAllData(newOutboundAllData);
      saveLabelChanges(selectedRow.original.id, changesObj);
    }
    setEditableCellSelection(undefined);
  }

  function getDataCells(dataRow: DataRow): Partial<TableRow> {
    const newDataRow: Partial<TableRow> = {};

    tableHeaders.forEach((header, columnIndex: number) => {
      const renderEditCell = Boolean(
        editableCellSelection &&
          editableCellSelection.rowId === dataRow.original.id &&
          editableCellSelection.column === header.name
      );
      newDataRow[header.name] =
        editableCellSelection && renderEditCell ? (
          <EditableCell
            initialValue={String(editableCellSelection.initialValue)}
            handleClickAway={(content: string) => saveCellContentEdits(dataRow, editableCellSelection.column, content)}
          />
        ) : (
          <NormalCell
            dataRow={dataRow}
            col={header.name}
            columnIndex={columnIndex}
            setEditableCellSelection={setEditableCellSelection}
          />
        );
      newDataRow.personalizedLink = <PersonalizedLinkCell dataRow={dataRow} pageId={pageId} segment={segment} />;
    });
    return newDataRow;
  }

  function getListRow(dataRow: DataRow): TableRow {
    const isInList = Object.values(crmObjects || {}).includes(dataRow.original.id);

    return {
      id: dataRow.original.id,
      ...getDataCells(dataRow),
      icon: (
        <AddRemoveIcon
          key={dataRow.original.id}
          isInList={isInList}
          onClick={() => (isInList ? removeListRows([dataRow]) : addListRows([dataRow]))}
        />
      ),
    };
  }

  const getTableHeaders = (): object[] => {
    const headerLabels = [{icon: ''}, ...tableHeaders.map((header) => ({[header.name]: header.label}))];
    return listTypeSelected === 'allList' ? headerLabels : [...headerLabels, {personalizedLink: 'Personalized link'}];
  };

  return (
    <div data-intercom-id="outbound-table">
      <StyledTableVirtualized
        headers={getTableHeaders()}
        rowGetter={getListRow}
        items={listItems}
        updateItems={(updatedItems) => setOutboundAllData(updatedItems)}
        recalculateRowHeight={Boolean(editableCellSelection)}
        style={{
          table: {
            width: '100vw',
            height: '85vh',
          },
        }}
      />
    </div>
  );
};

const AddRemoveIcon = ({isInList, onClick}) => {
  return (
    <Col className={isInList ? 'rotate0' : 'rotate45'}>
      <IconX
        width="30px"
        color={lightGray}
        cursor="pointer"
        $hoverColor={isInList ? darkRed : lightBlue}
        onClick={onClick}
      />
    </Col>
  );
};

export const getOutboundTableHeaders = (
  outboundAllData: DataRow[],
  outboundHeaderMap: {
    [key: string]: string;
  }
) => {
  if (isEmpty(outboundAllData)) return [];
  const headers = Object.keys(outboundAllData[0].original)
    .filter((header) => !['personalizedLink', 'id'].includes(header))
    .map((header) => ({name: header, label: outboundHeaderMap[header] || header}));
  return headers;
};
