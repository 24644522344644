import React from 'react';

import {Headline, Col, Text} from '@markettailor/common-markettailor';

export const PersonalizedVisitorsCell = ({
  personalizedVisitors,
  controlVisitors,
}: {
  personalizedVisitors: string | number | undefined;
  controlVisitors: string | number | undefined;
}): JSX.Element => {
  return (
    <Col alignItems="center">
      <Headline margin="0 0 5px 0" fontSize="14px" color="#000">
        {personalizedVisitors || 0}
      </Headline>
      <Text fontSize="10px" textAlign="center" lineHeight="16px">
        {`vs ${controlVisitors || 0} in control`}
      </Text>
    </Col>
  );
};
