import React, {createContext, ReactNode, useEffect, useState} from 'react';

import {loadStripe, Stripe} from '@stripe/stripe-js';
import axios, {AxiosResponse, CancelTokenSource} from 'axios';

import config from '../config.json';
import {deepFreeze} from '../functions/util';

interface IStripeConfigResponse {
  publishableKey: string;
}

interface IStripeKeyContext {
  isLoading: boolean;
  loadStripePromise?: Promise<Stripe | null>;
}

const StripeKeyContext = createContext<IStripeKeyContext | undefined>(undefined);

function StripeKeyProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const [isLoading, setIsLoading] = useState(true);
  const [loadStripePromise, setLoadStripePromise] = useState<Promise<Stripe | null>>();

  const getStripeKey = async () => {
    try {
      const res = await axios.get<IStripeConfigResponse>(config.api.baseURL + 'stripe', {cancelToken: source.token});
      const stripeKey = res.data.publishableKey;
      setLoadStripePromise(loadStripe(stripeKey));
      setIsLoading(false);
      return res;
    } catch (e) {
      // @ts-ignore
      console.error(e.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStripeKey();
    return () => source.cancel('StripeKeyProvider unmounted');
  }, []);

  const value = {isLoading, loadStripePromise};
  return <StripeKeyContext.Provider value={deepFreeze<IStripeKeyContext>(value)}>{children}</StripeKeyContext.Provider>;
}

export {StripeKeyContext, StripeKeyProvider};
