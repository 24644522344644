import React, {useState, useEffect, useContext, ReactNode, createContext} from 'react';

import {IPlan, NotificationContext} from '@markettailor/common-markettailor';
import {PaymentMethod} from '@stripe/stripe-js';
import axios, {CancelTokenSource} from 'axios';
import {capitalize} from 'lodash';

import config from '../config.json';
import {analytics} from '../functions/analytics';
import {deepFreeze} from '../functions/util';
import {AccountContext} from './AccountContext';

interface TaxRates {
  jurisdiction: string;
  percentage: number;
}

interface Price {
  currency: string;
  price: number;
}
interface Invoices {
  history: any[];
  upcoming: any;
}

interface ProductSelection {
  freemium: Price;
  basic: Price; //these are outdated
  growth: Price;
  ipRequests: Price;
  visitorRequests: Price;
}

interface Stripe {
  paymentMethod?: PaymentMethod;
  invoices: Invoices;
  productPricing: ProductSelection;
  taxRates: TaxRates[];
  taxId: string;
}

interface TaxInfo {
  taxId: string;
  country: string;
}

interface IFeature {
  name: string;
  plan: IPlan;
}

interface StripeSubscriptionPostData {
  planName: string;
  billedYearly: boolean;
  visitorRequestsQuantity?: number;
  ipDataEnabled?: boolean;
}

export interface IUsageDataPoint {
  count: number;
  date: Date;
}
export interface IUsage {
  inboundVisitors: IUsageDataPoint[];
  outboundVisitors: IUsageDataPoint[];
  companyLookups: IUsageDataPoint[];
}
interface StripeProviderState {
  isLoading: boolean;
  features: any[];
  usage?: IUsage;
  stripe?: Stripe;
}

interface IStripeContext {
  state: StripeProviderState;
  submitPaymentMethod: (paymentMethod: PaymentMethod | undefined) => Promise<void>;
  submitTaxId: (taxInfo: TaxInfo) => Promise<void>;
  changeAccountPlan: (planProperties: StripeSubscriptionPostData) => Promise<void>;
}

const StripeContext = createContext<IStripeContext | undefined>(undefined);
function StripeProvider({children}: {children: ReactNode}) {
  const source: CancelTokenSource = axios.CancelToken.source();
  const {setInfoNotification} = useContext(NotificationContext)!;
  const {accountId} = useContext(AccountContext)!.account || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stripe, setStripe] = useState<Stripe | undefined>();
  const [features, setFeatures] = useState<IFeature[]>([]);
  const [usage, setUsage] = useState<IUsage>();

  useEffect(() => {
    getStripeAccountDetails();
    //eslint-disable-next-line
    return () => source.cancel('Stripe context unmounted');
  }, []);

  const getStripeAccountDetails = async () => {
    try {
      const paymentPromise = getFromApi('stripe/paymentMethod', setStripe, 'paymentMethod');
      const pricingPromise = getFromApi('stripe/price', setStripe, 'productPricing');
      const invoicePromise = getFromApi('stripe/invoice', setStripe, 'invoices');
      const taxRatePromise = getFromApi('stripe/taxRates', setStripe, 'taxRates');
      const taxIdPromise = getFromApi('stripe/taxId', setStripe, 'taxId');

      const featuresPromise = getFromApi('features', setFeatures);
      const usagePromise = getFromApi('analytics/usage', setUsage);
      await Promise.all([
        paymentPromise,
        pricingPromise,
        invoicePromise,
        taxIdPromise,
        featuresPromise,
        taxRatePromise,
        usagePromise,
      ]);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Fetching payment/usage information failed', level: 'error'});
    }
  };

  const getFromApi = async (path: string, setter: Function, stateKey: string | undefined = undefined) => {
    const res = await axios.get(config.api.baseURL + path, {
      cancelToken: source.token,
    });
    if (!stateKey) setter(res.data);
    else setter((prevState) => ({...prevState, [stateKey]: res.data}));
  };

  const submitPaymentMethod = async (paymentMethod: PaymentMethod | undefined) => {
    try {
      const res = await axios.post(config.api.baseURL + 'stripe/paymentMethod', paymentMethod, {
        cancelToken: source.token,
      });
      if (res.data.message === 'New payment method set') {
        setStripe((prevState) => {
          if (!prevState) return prevState;
          return {...prevState, paymentMethod: paymentMethod};
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Failed to update payment method', level: 'error'});
    }
  };

  const submitTaxId = async (taxInfo: TaxInfo) => {
    try {
      if (taxInfo.taxId !== stripe?.taxId) {
        const res = await axios.put(config.api.baseURL + 'stripe/taxId', taxInfo, {
          cancelToken: source.token,
        });
        setStripe((prevState) => {
          if (!prevState) return prevState;
          return {...prevState, taxId: res.data.value};
        });
      }
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Failed to update VAT ID', level: 'error'});
    }
  };

  const changeAccountPlan = async (planProperties: StripeSubscriptionPostData) => {
    try {
      if (planProperties.planName !== 'visitorRequests') {
        delete planProperties.ipDataEnabled;
        delete planProperties.visitorRequestsQuantity;
      }
      const res = await axios.post(config.api.baseURL + 'stripe/subscription', planProperties);
      res.data.permissions = {
        ...res.data.permissions,
        plan: res.data.permissions.plan,
      };
      setInfoNotification({message: 'Plan updated successfully!'});
      analytics.track('Changed plan', {plan: planProperties.planName});
      analytics.group(accountId, {
        ...planProperties,
        plan: capitalize(res.data.permissions.plan),
      });
      getFromApi('stripe/invoice', setStripe, 'invoices');
    } catch (e) {
      if (axios.isCancel(e)) return;
      setInfoNotification({message: 'Changing subscription failed', level: 'error'});
    }
  };

  const state = {stripe, isLoading, features, usage};

  return (
    <StripeContext.Provider
      value={deepFreeze<IStripeContext>({
        state,
        submitPaymentMethod,
        submitTaxId,
        changeAccountPlan,
      })}
    >
      {children}
    </StripeContext.Provider>
  );
}

export {StripeContext, StripeProvider};
