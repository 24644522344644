import React, {useContext, useState, useEffect} from 'react';

import {StyledBtn, StyledDivider} from '@markettailor/common-markettailor';
import axios, {CancelTokenSource} from 'axios';
import {isEmpty} from 'lodash';

import config from '../../config.json';
import {SegmentContext} from '../../contexts/SegmentContext';
import {useSetNewPage} from '../../functions/util';
import {PageInsight, PageVisitorCounts} from '../segmentation/sideContainer/audienceInsights/AudienceInsights';
import {EmptyPages, EmptySegments, RenderSkeleton, NoSegmentSelected} from './emptyStates';
import {PageInsightContainerRenderer} from './insights/PageInsightContainerRenderer';
import {getTopInsights} from './insights/util';
import {NewPagePopup} from './NewPagePopup';
import {SegmentSelection} from './SegmentSelection';
import PageTable from './table/PageTable';

export default function PageContainerContent() {
  const segmentContext = useContext(SegmentContext);
  const [renderNewPageWindow, setRenderNewPageWindow] = useState(false);
  const {segmentId, segments, isLoading} = segmentContext!.state;
  const [pageInsights, setPageInsights] = useState<PageInsight[]>([]);
  const [pageVisitorCounts, setPageVisitorCounts] = useState<PageVisitorCounts[]>([]);
  const [isInsightsLoading, setIsInsightsLoading] = useState<boolean>(true);
  const source: CancelTokenSource = axios.CancelToken.source();

  useSetNewPage('Pages');

  useEffect(() => {
    segments[segmentId] ? getInsights(segmentId) : setIsInsightsLoading(false);
    return () => source.cancel('Audience insights unmounted.');
    // eslint-disable-next-line
  }, [segmentId]);

  async function getInsights(segmentId: string) {
    try {
      setIsInsightsLoading(true);
      const res = await axios.get(config.api.baseURL + 'analytics/pages/' + segmentId, {
        cancelToken: source.token,
      });
      const parsedInsights = getTopInsights(res.data.pageInsights || []);
      setPageInsights(parsedInsights);
      setPageVisitorCounts(res.data.pageVisitorCounts || []);
      setIsInsightsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return;
      console.debug(e);
      setIsInsightsLoading(false);
    }
  }

  if (isLoading) return <RenderSkeleton />;
  if (isEmpty(segments)) return <EmptySegments />;
  return (
    <>
      <NewPagePopup renderNewPageWindow={renderNewPageWindow} setRenderNewPageWindow={setRenderNewPageWindow} />
      {segmentId && <PageInsightContainerRenderer isInsightsLoading={isInsightsLoading} pageInsights={pageInsights} />}
      <SegmentSelection segments={segments} segmentId={segmentId} />
      <StyledDivider margin={'0 0 60px 0'} />
      <ContainerContent
        pageInsights={pageInsights}
        pageVisitorCounts={pageVisitorCounts}
        setRenderNewPageWindow={setRenderNewPageWindow}
      />
    </>
  );
}

const ContainerContent = ({
  pageInsights,
  pageVisitorCounts,
  setRenderNewPageWindow,
}: {
  pageInsights: PageInsight[];
  pageVisitorCounts: PageVisitorCounts[];
  setRenderNewPageWindow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const segmentContext = useContext(SegmentContext);
  const {segmentId, segments} = segmentContext!.state;

  if (!segmentId) return <NoSegmentSelected />;
  if (isEmpty(segments[segmentId]?.pageUrls))
    return <EmptyPages hasPageInsights={!isEmpty(pageInsights)} handleClick={() => setRenderNewPageWindow(true)} />;
  return (
    <>
      <PageTable pageVisitorCounts={pageVisitorCounts} />
      <StyledBtn
        data-intercom-id="new-page-button"
        margin={'20px 0 40px 0'}
        onClick={() => setRenderNewPageWindow(true)}
      >
        Add a new page
      </StyledBtn>
    </>
  );
};
