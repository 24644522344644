import React, {useState} from 'react';

import {Col, Headline, Row, StyledBtn, Text, StyledDivider, BlueCheckbox} from '@markettailor/common-markettailor';
import {mapValues, omit} from 'lodash';

import {getLabelExampleRow} from '../../../functions/util';
import {ParsedCsvDataType} from './csvUploadUtil';

interface Props {
  csvData: ParsedCsvDataType;
  handleClose: (filteredCsvData: ParsedCsvDataType) => void;
}

interface ExampleData {
  [key: string]: string | undefined | null | number;
}

export default function CsvCleanup(props: Props) {
  const [error, setError] = useState('');
  const [checkedColumns, setCheckedColumns] = useState<object>(mapValues({...props.csvData[0]}, () => true));
  const {csvData, handleClose} = props;

  const createCampaign = () => {
    const droppedColumns = Object.keys(checkedColumns).filter((header) => !checkedColumns[header]);
    const filteredCsvData = csvData.map((row) => omit(row, droppedColumns));
    if (!checkIdsAreUnique(filteredCsvData)) {
      setError('If the id column is included, the values need to be unique');
      return;
    }
    handleClose(filteredCsvData);
  };

  const exampleRow = getLabelExampleRow<ExampleData>(csvData);

  return (
    <Col maxWidth="800px" minWidth="600px">
      <Headline textAlign="center">Select columns you want to include in your campaign.</Headline>
      <StyledDivider border margin="20px 0" />
      <Col>
        <SelectAllButton checkedColumns={checkedColumns} setCheckedColumns={setCheckedColumns} />
        <Row alignItems="center">
          <Headline margin="0 5px 0 0">Column</Headline>
          <Text>(Count)</Text>
          <Text margin="0 10px">─</Text>
          <Text style={{fontStyle: 'italic'}}>Example</Text>
        </Row>
        <StyledDivider border margin="5px 0" />
        {csvData.length > 0 &&
          Object.entries(exampleRow).map(([header, exampleValue]) => {
            const populatedValuesCount = csvData.reduce((acc, row) => {
              return row[header] !== null ? acc + 1 : acc;
            }, 0);
            return (
              <CheckRow
                key={header}
                header={header}
                isChecked={checkedColumns[header]}
                onClick={() => {
                  setCheckedColumns({...checkedColumns, [header]: !checkedColumns[header]});
                }}
                count={populatedValuesCount}
                value={exampleValue}
              />
            );
          })}
      </Col>
      <StyledDivider border margin="20px 0" />
      <Col alignItems="center">
        <StyledBtn onClick={createCampaign}>Create campaign</StyledBtn>
      </Col>
      {error && (
        <Text textAlign="center" color="red" margin="25px 0 0 0">
          {error}
        </Text>
      )}
    </Col>
  );
}

interface CheckRowProps {
  header: string;
  isChecked: boolean;
  onClick: () => void;
  count: number;
  value: string | number | null | undefined;
}

const CheckRow = ({header, isChecked, onClick, count, value}: CheckRowProps) => {
  return (
    <Row alignItems="center">
      <BlueCheckbox css={{}} checked={isChecked} onChange={onClick} />
      <Headline margin="0 5px 0 0">{header}</Headline>
      <Text>{`(${count})`}</Text>
      <Text margin="0 10px">─</Text>
      <Text style={{fontStyle: 'italic'}}>{value}</Text>
    </Row>
  );
};

const SelectAllButton = ({checkedColumns, setCheckedColumns}) => {
  const someUnselected = Object.values(checkedColumns).some((column) => column === false);

  return (
    <StyledBtn
      margin="0 0 20px 0"
      onClick={() => setCheckedColumns(mapValues({...checkedColumns}, () => Boolean(someUnselected)))}
    >
      {someUnselected ? 'Select all' : 'Unselect all'}
    </StyledBtn>
  );
};

const checkIdsAreUnique = (csvData: ParsedCsvDataType) => {
  if (!csvData.some((row) => row['id'])) return true;
  const idsList = csvData.map((row) => row['id']);
  const idsSet = new Set(idsList);
  if (idsList.length === idsSet.size) return true;
  return false;
};
