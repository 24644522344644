import React from 'react';

import {Card, darkGray, SendIcon} from '@markettailor/common-markettailor';

import OutboundTypeSelection from './outboundTypeCreation/OutboundTypeSelection';

interface Props {
  outboundTypeSelection: boolean;
  setOutboundTypeSelection: Function;
}

export default function OutboundCreation({outboundTypeSelection, setOutboundTypeSelection}: Props) {
  if (outboundTypeSelection) return <OutboundTypeSelection />;
  return (
    <Card
      header={'Outbound'}
      description={'Create an outbound marketing campaign based on target accounts in your CRM or a CSV file.'}
      midSection={<SendIcon width="35px" color={darkGray} />}
      ctaText={'Continue'}
      ctaCallback={() => {
        setOutboundTypeSelection(true);
      }}
    />
  );
}
