import React from 'react';

import {StyledNavTop, StyledBtn, AbsoluteDiv} from '@markettailor/common-markettailor';

import {useGetSegmentQuery, checkFiltersForCompleteness} from './util';

export const InboundTopNav = ({handleToEditorClick}: {handleToEditorClick: Function}) => {
  const segmentQuery = useGetSegmentQuery();

  return (
    <StyledNavTop>
      Define segment
      <AbsoluteDiv right={'0'}>
        <StyledBtn
          data-intercom-id="inbound-toEditor-button"
          disabled={!checkFiltersForCompleteness(segmentQuery.queryRequest)}
          margin={'0 20px 0 10px'}
          onClick={handleToEditorClick}
        >
          To editor
        </StyledBtn>
      </AbsoluteDiv>
    </StyledNavTop>
  );
};
