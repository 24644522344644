import React, {useContext, useState} from 'react';

import {AndOrSwitch, Col, Row, StyledBox, ISegmentFilter, IFilterCondition} from '@markettailor/common-markettailor';
import {cloneDeep} from 'lodash';

import {SegmentContext} from '../../../../contexts/SegmentContext';
import {RenderSegmentFilter} from '../RenderSegmentFilter';

interface Props {
  segmentFilters: ISegmentFilter[];
  filterCondition: IFilterCondition;
}

export default function SegmentFilterContainer(props: Props) {
  const segmentContext = useContext(SegmentContext);
  const {updateSegment} = segmentContext!;
  const {segmentId} = segmentContext!.state;
  const {segmentFilters, filterCondition} = props;
  const [selectedAndOr, setSelectedAndOr] = useState<IFilterCondition>(filterCondition);

  function handleSwitchSelect(selected: IFilterCondition): void {
    const newSegmentFilters = cloneDeep(segmentFilters);
    updateSegment(segmentId, {
      segmentQuery: {
        queryRequest: newSegmentFilters,
        filterCondition: selected,
      },
    });
    setSelectedAndOr(selected);
  }

  return (
    <StyledBox
      data-intercom-id="inbound-segment-rules"
      minHeight={'40px'}
      display={'flex'}
      margin={'0 0 10px 0'}
      width="fit-content"
    >
      <Row alignItems={'center'}>
        <AndOrSwitch
          display={segmentFilters.length > 1}
          selectedAndOr={selectedAndOr}
          selectSwitch={(selected) => handleSwitchSelect(selected)}
        />
        <Col>
          {segmentFilters.map((segmentFilter: ISegmentFilter, index) => {
            return (
              <RenderSegmentFilter
                key={index}
                segmentFilter={segmentFilter}
                index={index}
                segmentFilters={segmentFilters}
                filterCondition={filterCondition}
              />
            );
          })}
        </Col>
      </Row>
    </StyledBox>
  );
}
