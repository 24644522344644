import React, {useContext, useState} from 'react';

import {
  Col,
  Headline,
  StyledBtn,
  getSelectCustomStyles,
  IValueLabel,
  NotificationContext,
} from '@markettailor/common-markettailor';
import Select from 'react-select';

import {customStyle} from './util';

interface Option {
  name: string;
  display_name: string;
}

interface IAnalyticsProperties extends Option {
  property_type: string;
}

export interface IAnalyticsAccount extends Option {
  properties: IAnalyticsProperties[];
}

interface IAnalyticsOptions {
  account: IAnalyticsAccount[];
  property: IAnalyticsProperties[];
}

export interface IGAIntegrationOptionAccountOptions {
  accountName: string;
  propertyName: string;
}

interface Props {
  handleSubmitIntegrationOptions: (options: IGAIntegrationOptionAccountOptions) => void;
  accountSummary: IAnalyticsAccount[];
}

interface IIntegrationOptions {
  accountName?: string;
  propertyName?: string;
}

const keyIndex = {
  account: 0,
  property: 1,
};

export default function AccountOptionsSelector({handleSubmitIntegrationOptions, accountSummary}: Props) {
  const [selectedId, setSelectedId] = useState({account: 0, property: 0});
  const {setInfoNotification} = useContext(NotificationContext)!;

  function handleSelectorChange(selection: IValueLabel, key: keyof typeof keyIndex = 'account') {
    const {account, property} = selectedId;
    const selectedIdList = [account, property];

    const newSelected: any[] = selectedIdList.map((elem, index) =>
      index === keyIndex[key] ? selection.value : index > keyIndex[key] ? 0 : elem
    );

    setSelectedId({
      account: newSelected[0],
      property: newSelected[1],
    });
  }

  function getOptions(key: keyof IAnalyticsOptions) {
    const options: IAnalyticsOptions = {
      account: accountSummary,
      property: accountSummary[selectedId.account]?.properties || [],
    };

    const optionArray: Option[] = options[key];
    const accountOptions = optionArray.map((elem: Option, index: number) => {
      return {
        label: elem.name.split('/')[1] + ' ─ ' + elem.display_name,
        id: elem.name,
        value: String(index),
      };
    });

    return accountOptions;
  }

  const integrationOptions: IIntegrationOptions = {
    accountName: getOptions('account')[selectedId.account]?.id,
    propertyName: getOptions('property')[selectedId.property]?.id,
  };

  const handleSubmitIntegrationOptionsWrapper = (integrationOptions: IIntegrationOptions) => {
    const {accountName, propertyName} = integrationOptions;
    if (!accountName || !propertyName) {
      console.warn('Cannot submit empty fields');
      setInfoNotification({
        message: 'Cannot submit empty fields',
        level: 'warn',
      });
      return;
    }
    return handleSubmitIntegrationOptions({accountName, propertyName});
  };

  return (
    <Col minWidth={'500px'} alignItems={'center'}>
      <Headline margin={'20px 0 40px 50px '}>
        Select the Google Analytics property you want to integrate with Markettailor
      </Headline>
      <Col alignItems={'center'}>
        <Select
          styles={getSelectCustomStyles(customStyle)}
          options={getOptions('account')}
          value={getOptions('account')[selectedId.account]}
          isSearchable={false}
          onChange={(selection: IValueLabel | null) => handleSelectorChange(selection!, 'account')}
          placeholder={'Account'}
        />
        <Select
          styles={getSelectCustomStyles(customStyle)}
          options={getOptions('property')}
          value={getOptions('property')[selectedId.property] || ''}
          isSearchable={false}
          onChange={(selection: IValueLabel | null) => handleSelectorChange(selection!, 'property')}
          placeholder={'Web property'}
        />
        <StyledBtn onClick={() => handleSubmitIntegrationOptionsWrapper(integrationOptions)}>Submit</StyledBtn>
      </Col>
    </Col>
  );
}
